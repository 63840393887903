import { Dictionary } from '../../types';

type ORDER_TYPE = 'ASC' | 'DSC';

interface useSortTableProps {
  order: string;
  setOrder: (event: string) => void;
  data: Dictionary[];
  setData: (data: Dictionary[]) => void;
  isNextPage?: any;
}

export default function useSortTable({ order, setOrder, data, setData }: useSortTableProps) {
  const handleSortItems = (col: string) => {
    if (order === 'ASC') {
      // const sorted = [...data].sort((a, b) => (a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1));
      const sorted = [...data].sort((a, b) =>
        typeof a[col] !== 'number'
          ? a[col]?.toLowerCase() > b[col]?.toLowerCase()
            ? 1
            : -1
          : a[col] > b[col]
          ? 1
          : -1,
      );
      setData(sorted);
      setOrder('DSC');
    }
    if (order === 'DSC') {
      const sorted = [...data].sort((a, b) =>
        typeof a[col] !== 'number'
          ? a[col]?.toLowerCase() < b[col]?.toLowerCase()
            ? 1
            : -1
          : a[col] < b[col]
          ? 1
          : -1,
      );
      setData(sorted);
      setOrder('ASC');
    }
  };

  // const sorting = (col: string) => {
  //     if (order === 'ASC') {
  //       const sorted = [...kycData].sort((a, b) => (a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1));
  //       setKycData(sorted);
  //       setOrder('DSC');
  //     }
  //     if (order === 'DSC') {
  //       const sorted = [...kycData].sort((a, b) => (a[col]?.toLowerCase() < b[col]?.toLowerCase() ? 1 : -1));
  //       setKycData(sorted);

  //       setOrder('ASC');
  //     }
  //   };

  return { handleSortItems };
}
export const CustomUseSortTable = ({ order, setOrder, data, setData, isNextPage }: useSortTableProps) => {
  const CustomHandleSortItems = (col: string) => {
    if (order === 'ASC') {
      // const sorted = [...data].sort((a, b) => (a[col]?.toLowerCase() > b[col]?.toLowerCase() ? 1 : -1));
      const sorted = [...data].sort((a, b) =>
        typeof a[col] !== 'number'
          ? a[col]?.toLowerCase() > b[col]?.toLowerCase()
            ? 1
            : -1
          : a[col] > b[col]
          ? 1
          : -1,
      );
      setData(sorted);
      isNextPage && isNextPage === true ? setOrder('ASC') : setOrder('DSC');
    }
    if (order === 'DSC') {
      const sorted = [...data].sort((a, b) =>
        typeof a[col] !== 'number'
          ? a[col]?.toLowerCase() < b[col]?.toLowerCase()
            ? 1
            : -1
          : a[col] < b[col]
          ? 1
          : -1,
      );
      setData(sorted);
      isNextPage && isNextPage === true ? setOrder('DSC') : setOrder('ASC');
    }
  };

  return { CustomHandleSortItems };
};
