import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { AppContainer, MoreIconView, ActivityActionModal, ProfileActivationToggleModal } from '../../atoms';
import { colors, images, icons, handleSelectedOption, getSelectedOptionValue, useDelayedFunction } from '../../utils';
import {
  SearchInput,
  Pagination,
  ExportLabel,
  FilterCard,
  FilterLabel,
  ZojaCheckbox,
  DownloadSuccessModal,
  NavTab,
  PaymentEmptyState,
  DepartmentTable,
  CreateDepartmentModal,
} from '../../components';

import { departmentDataHeader, UserPageTabs } from './data';
import { Dictionary } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getDepartmentsRequest,
  createDepartmentRequest,
  createDepartmentReset,
  updateDepartmentRequest,
  updateDepartmentReset,
  downloadDepartmentRecords,
  downloadDepartmentRecordsReset,
  updateDepartmentStatusReset,
  updateDepartmentStatusRequest,
} from '../../redux/slice';
import { ZojaButton, ZojaSelect } from '../../components/tailwind';
import { Oval } from 'react-loader-spinner';
import { CustomUseSortTable } from '../../utils/hooks/useSortTable';
import { TableContainer, UserContainer } from '../users/style';
import { DepartmentContainer, DepartmentTop } from './style';

const namedEdit = 'Modify';
const namedDeactivate = 'Deactivate Dept';
const namedReactivate = 'Reactivate Dept';

//Table more dropdown
const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Modify',
    value: 'modify',
  },
  {
    id: 2,
    title: 'Deactivate Dept',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate Dept',
    value: 'reactivate',
  },
];

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const LastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

const initialValues: Dictionary = {
  status: '',
  role: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
  search: '',
};

function Department() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const successModalRef = useRef<any>(null);

  const [showCreateDepartmentModal, setShowCreateDepartmentModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedDepartmentItem, setSelectedDepartmentItem] = useState<Dictionary>({});
  const [moreIconIsVisible, setMoreIconIsVisible] = useState(false);
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [departmentsTotalPages, setDepartmentsTotalPages] = useState(5);
  const [, setSelectedTransactionActionText] = useState('');
  const [searchDepartmentsValue, setSearchDepartmentsValue] = useState('');
  const [perPage, setPerPage] = useState<any>('10');
  const [, setDepartmentsData] = useState<any[]>([]);
  const [departmentsDataList, setDepartmentsDataList] = useState<any[]>([]);
  const [createInternalDepartmentIsModalVisible, setCreateDepartmentIsModalVisible] = useState(false);
  const [editDepartmentModalVisible, setEditDepartmentModalVisible] = useState(false);

  const [createDepartmentSuccessModalVisible, setCreateDepartmentSuccessModalVisible] = useState(false);
  const [toggleGetDepartment, setToggleGetDepartment] = useState(false);
  const [profileActivationSuccessIsModalVisible, setProfileActivationSuccessIsModalVisible] = useState(false);
  const [profileActivationIsModalVisible, setProfileActivationIsModalVisible] = useState(false);
  const [departmentAccountStatus, setDepartmentAccountStatus] = useState('');
  const [deactiveMessage, setDeactiveMessage] = useState('');

  const [order, setOrder] = useState('ASC');

  // export states
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [activePageTab, setActivePageTab] = useState('departments');
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [lastSeen, setLastSeen] = useState(LastSeenOptions);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [isSort, setIsSort] = useState<any>(false);
  const [sortHeader, setSortHeader] = useState<any>('');
  const [isNextPage, setIsNextPage] = useState<any>(false);

  const decideDepartmentCurrentStatus: string =
    departmentAccountStatus === 'active' ? namedDeactivate : namedReactivate;
  //More Icon for Department
  const moreIconOption = [namedEdit, decideDepartmentCurrentStatus];

  // redux state
  const departmentsState = useAppSelector(state => state.getDepartments);
  const { status: departmentsStatus } = departmentsState;

  const createDepartmentState = useAppSelector(state => state.createDepartment);
  const { status: createDepartmentStatus } = createDepartmentState;

  const updateDepartmentState = useAppSelector(state => state.updateDepartment);
  const { status: updateDepartmentStatus } = updateDepartmentState;

  const updateDepartmentStatusState = useAppSelector(state => state.updateDepartmentStatus);
  const { status: updateDepartmentStatusStatus } = updateDepartmentStatusState;

  // download records status states
  const { status: downloadDepartmentStatus } = useAppSelector(state => state.downloadDepartmentRecords);

  // get department by status
  useEffect(() => {
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    let payload = { ...filterOptions };

    dispatch(
      getDepartmentsRequest({
        ...payload,
        status: '',
        sort_by: getSelectedSortBy,
        order_by: getSelectedOrderBy,
        search: '',
        page: currentPage,
        per_page: perPage,
      }),
    );
  }, [activePageTab, toggleGetDepartment, updateDepartmentStatusState, currentPage]);

  useEffect(() => {
    if (departmentsStatus === 'succeeded') {
      let updateDepartmentData: any[] = [];
      departmentsState?.data?.departments?.data?.forEach((item: Dictionary, index: number) => {
        updateDepartmentData.push({
          id: index + 1,
          description: item?.description === null ? 'N/A' : item?.description,
          hod_name: item?.hod_name === null ? 'N/A' : item?.hod_name,
          departmentName: item?.name === null ? 'N/A' : item?.name,
          departmentId: item?.id === null ? 'N/A' : item?.id,
          status: item?.status === null ? 'N/A' : item?.status,
          teamCount: item?.number_of_members === null ? 'N/A' : item?.number_of_members,
          dateCreated: item?.created_at,
        });
      });

      const {
        meta: { last_page },
      } = departmentsState?.data?.departments;

      setDepartmentsTotalPages(last_page);
      setDepartmentsData(updateDepartmentData);
      setDepartmentsDataList(updateDepartmentData);
    }
  }, [departmentsState]);

  // successful deactivate or reactivate departments
  useEffect(() => {
    if (updateDepartmentStatusStatus === 'succeeded') {
      setProfileActivationSuccessIsModalVisible(true);
    }
  }, [updateDepartmentStatusState]);

  useEffect(() => {
    if (createDepartmentStatus === 'succeeded') {
      setCreateDepartmentIsModalVisible(false);
      setCreateDepartmentSuccessModalVisible(true);
    }
    if (updateDepartmentStatus === 'succeeded') {
      setEditDepartmentModalVisible(false);
      setCreateDepartmentSuccessModalVisible(true);
    }
  }, [createDepartmentState, updateDepartmentState]);

  // handle different modules
  const handleMoreIconOptions = async (item: any) => {
    setMoreIconIsVisible(false);
    setDepartmentAccountStatus(item?.title === 'Reactivate Dept' ? 'inactive' : 'active');

    if (item.title === namedEdit) {
      setEditDepartmentModalVisible(true);
    }
    if (item.title === namedDeactivate || item.title === namedReactivate) {
      setProfileActivationIsModalVisible(true);
    }
  };

  const handleCloseCreateDepartmentModal = () => {
    setCreateDepartmentSuccessModalVisible(false);
    dispatch(createDepartmentReset());
    dispatch(updateDepartmentReset());
    setToggleGetDepartment(!toggleGetDepartment);
  };

  const handleUpdateDepartment = (item: Dictionary) => {
    const { name, hod_name, description } = item;

    const payload = {
      name,
      hod_name,
      description,
      departmentId: selectedDepartmentItem?.departmentId,
    };

    dispatch(updateDepartmentRequest(payload));
  };

  const handleCreateDepartment = (item: Dictionary) => {
    const { name, hod_name, description } = item;
    const payload = {
      name,
      hod_name,
      description,
    };
    dispatch(createDepartmentRequest(payload));
  };

  useEffect(() => {
    if (createDepartmentStatus === 'succeeded') {
      setShowCreateDepartmentModal(false);
    }
  }, [createDepartmentStatus]);

  // reactivate or deactivate department action
  const handleDepartmentProfileActivity = () => {
    let payload: Dictionary;
    if (departmentAccountStatus === 'active') {
      payload = {
        departmentId: selectedDepartmentItem?.departmentId,
        data: {
          status: 'inactive',
          reason: deactiveMessage,
        },
      };
    } else {
      payload = {
        departmentId: selectedDepartmentItem?.departmentId,
        data: {
          status: 'active',
        },
      };
    }

    dispatch(updateDepartmentStatusRequest(payload));
  };

  const handleProfileActivationSuccessClose = () => {
    setProfileActivationSuccessIsModalVisible(false);
    dispatch(updateDepartmentStatusReset());
  };

  const handleOnchangeDepartment = (value: any) => {
    setSearchDepartmentsValue(value);
  };

  // filter and export methods
  const handleExportInternalDepartmentFile = (type: string) => {
    let exportPayload = { ...filterOptions, export: type, search: searchDepartmentsValue };
    dispatch(downloadDepartmentRecords(exportPayload));
    setShowExportOptions(false);
  };

  // filter table handler

  const handleSubmitDepartmentsFilter = () => {
    let payload = { ...filterOptions };

    dispatch(
      getDepartmentsRequest({ ...payload, search: searchDepartmentsValue, per_page: perPage, page: currentPage }),
    );
  };

  // on check get onboarded value
  useEffect(() => {
    const getOnboardedvalue = getSelectedOptionValue(dateOnboarded);
    const getLastSeenValue = getSelectedOptionValue(lastSeen);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    let onboarded_data = getOnboardedvalue;

    setFilterOptions({
      ...filterOptions,
      date_onboarded: onboarded_data,
      last_seen: getLastSeenValue,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
    });
  }, [dateOnboarded, lastSeen, sortBy, orderBy]);

  // show download success modal
  useEffect(() => {
    if (downloadDepartmentStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadDepartmentStatus]);

  // fetch role and permission by term on input change
  const delay = 1000;
  const { delayedFunction } = useDelayedFunction(delay);

  useEffect(() => {
    if (searchDepartmentsValue.length >= 2 || searchDepartmentsValue.length === 0) {
      delayedFunction(handleSubmitDepartmentsFilter);
    }
  }, [searchDepartmentsValue]);

  useEffect(() => {
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
  }, [departmentsStatus]);

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    setPerPage(pageNumber);

    let payload = { ...filterOptions };

    dispatch(
      getDepartmentsRequest({
        ...payload,
        search: searchValue,
        page: currentPage,
        per_page: pageNumber,
      }),
    );
  };

  // Custom Hooks
  const { CustomHandleSortItems } = CustomUseSortTable({
    order: order,
    setOrder,
    data: departmentsDataList,
    setData: setDepartmentsDataList,
    isNextPage: isNextPage,
  });

  return (
    <AppContainer
      navTitle={
        <div className="tw-flex tw-gap- tw-items-center">
          <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">DEPARTMENTS</span>
        </div>
      }>
      <>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            dispatch(downloadDepartmentRecordsReset());
            setShowSuccessDownloadModal(false);
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
        />
        <UserContainer>
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}
          <NavTab navList={UserPageTabs} selected={activePageTab} setSelected={setActivePageTab} />

          <DepartmentContainer>
            <DepartmentTop className="tw-flex tw-flex-col lg:tw-flex-row tw-items-start tw-justify-between lg:tw-items-center tw-gap-y-2">
              <ZojaButton
                extraClass={`tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
                text={'New Department'}
                icon={<AiOutlinePlus color={colors.white} size={15} />}
                onClick={() => setShowCreateDepartmentModal(true)}
              />
              <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
                <SearchInput
                  backgroundColor={'transparent'}
                  name="searchProfileValue"
                  value={searchDepartmentsValue}
                  onChange={(e: any) => handleOnchangeDepartment(e.target.value)}
                  placeholder="Search by name"
                />
                <FilterLabel
                  title="Filter Options"
                  icon={<icons.BsFilter />}
                  onClick={() => setShowFilterOptions(true)}>
                  <FilterCard
                    showFilter={showFilterOptions}
                    title="Filter Options"
                    onClick={() => handleSubmitDepartmentsFilter()}
                    extraClass="tw-w-[13rem]">
                    <div className="tw-mt-4 tw-h-max tw-overflow-y-scroll tw-overflow-hidden">
                      <ZojaSelect
                        options={[
                          { value: '', label: 'Status' },
                          { value: 'active', label: 'active' },
                          { value: 'inactive', label: 'inactive' },
                        ]}
                        name="status"
                        showArrown={true}
                        setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                        extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                        arrowDownClass="tw-right-lg tw-top-3.5"
                      />
                    </div>
                  </FilterCard>
                </FilterLabel>

                <FilterLabel
                  title="Sort By"
                  icon={<icons.TbArrowsSort color="#5E6366" />}
                  onClick={() => setShowSortBy(true)}>
                  <FilterCard
                    showFilter={showSortBy}
                    title="Sort by"
                    onClick={() => handleSubmitDepartmentsFilter()}
                    submitButtonText="Apply"
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <>
                      <div className="tw-pt-2">
                        {sortBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                            />
                          </div>
                        ))}
                      </div>
                      <div className="tw-pt-2 tw-border-">
                        {orderBy.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FilterCard>
                </FilterLabel>

                <ExportLabel
                  title="Export"
                  onClick={() => setShowExportOptions(true)}
                  loading={downloadDepartmentStatus === 'loading' ? true : false}>
                  <FilterCard
                    showFilter={showExportOptions}
                    title="Export as"
                    onClick={() => null}
                    showSubmitButton={false}
                    extraClass="tw-w-[10rem] -tw-left-28">
                    <div className="tw-flex tw-flex-col tw-mt-4">
                      <span
                        className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                        onClick={() => handleExportInternalDepartmentFile('csv')}>
                        CSV
                      </span>
                    </div>
                  </FilterCard>
                </ExportLabel>
              </div>
            </DepartmentTop>
            {departmentsStatus === 'loading' ? (
              <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                <Oval
                  height="80"
                  width="80"
                  color="#222b88cf"
                  ariaLabel="tail-spin-loading"
                  secondaryColor="#222b882b"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <TableContainer>
                {departmentsDataList.length >= 1 ? (
                  <DepartmentTable
                    data={departmentsDataList}
                    setSelectedItem={setSelectedDepartmentItem}
                    selectedItem={selectedDepartmentItem}
                    tableColumns={departmentDataHeader}
                    dropdownList={dropdownList}
                    sorting={CustomHandleSortItems}
                    setDepartmentAccountStatus={setDepartmentAccountStatus}
                    order={order}
                    setOrder={setOrder}
                    handleMoreIconOptions={handleMoreIconOptions}
                    setIsSort={setIsSort}
                    setSortHeader={setSortHeader}
                    setIsNextPage={setIsNextPage}
                  />
                ) : (
                  <PaymentEmptyState text="No Department found" containerClass="tw-h-[50vh]" imageWidth={200} />
                )}
                {departmentsDataList.length >= 1 && (
                  <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                    <Pagination
                      currentPage={currentPage}
                      totalPages={departmentsTotalPages}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                        setIsNextPage(true);
                        setIsSort(true);
                      }}
                    />
                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                      <span className="tw-text-xs tw-w-16">Per page</span>
                      <ZojaSelect
                        options={[
                          { label: '10', value: '10' },
                          { label: '20', value: '20' },
                          { label: '50', value: '50' },
                          { label: '100', value: '100' },
                          { label: '200', value: '200' },
                        ]}
                        value={departmentsState?.data?.departments?.meta?.per_page}
                        setSelectedOption={selected => handlePageNumberChange(selected)}
                        extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                      />
                    </div>
                  </div>
                )}
              </TableContainer>
            )}
          </DepartmentContainer>
          {/* )} */}

          <MoreIconView
            setSelectedText={setSelectedTransactionActionText}
            isModalVisible={moreIconIsVisible}
            closeModal={() => setMoreIconIsVisible(false)}
            options={moreIconOption}
            onClick={item => handleMoreIconOptions(item)}
          />

          <CreateDepartmentModal
            type="create"
            show={showCreateDepartmentModal}
            onSubmit={(item: Dictionary) => handleCreateDepartment(item)}
            handleClose={() => setShowCreateDepartmentModal(false)}
            loading={createDepartmentStatus === 'loading'}
            actionStatus={createDepartmentStatus}
          />
          <CreateDepartmentModal
            type="update"
            show={editDepartmentModalVisible}
            onSubmit={(item: Dictionary) => handleUpdateDepartment(item)}
            handleClose={() => setEditDepartmentModalVisible(false)}
            loading={updateDepartmentStatus === 'loading'}
            defaultValues={{
              defaultDepartmentName: selectedDepartmentItem.hasOwnProperty('departmentName')
                ? selectedDepartmentItem.departmentName
                : '',
              defaultHodName: selectedDepartmentItem.hasOwnProperty('hod_name') ? selectedDepartmentItem.hod_name : '',
              defaultDepartmentDescription: selectedDepartmentItem.hasOwnProperty('description')
                ? selectedDepartmentItem.description
                : '',
            }}
          />

          <ActivityActionModal
            isModalVisible={createDepartmentSuccessModalVisible}
            closeModal={handleCloseCreateDepartmentModal}
            title={
              updateDepartmentStatus === 'succeeded'
                ? 'Department Details Successfully Updated'
                : 'Department Successfully Created'
            }
            actionText="Close"
            image={updateDepartmentStatus === 'succeeded' ? images.check : images.sent}
            actionClick={handleCloseCreateDepartmentModal}
            isLoading={false}
          />

          {/* activate or deactive department */}
          <ProfileActivationToggleModal
            isModalVisible={profileActivationIsModalVisible}
            activityStatus={departmentAccountStatus}
            actionClicked={handleDepartmentProfileActivity}
            closeModal={() => setProfileActivationIsModalVisible(false)}
            setDeactiveMessage={setDeactiveMessage}
            text={
              departmentAccountStatus === 'active'
                ? 'Please Input reason for deactivation'
                : 'Are you sure you want to reactivate this department?'
            }
          />

          {/* this modal shows when admin successfully activate or deactivate a department */}
          <ActivityActionModal
            isModalVisible={profileActivationSuccessIsModalVisible}
            closeModal={handleProfileActivationSuccessClose}
            actionClick={handleProfileActivationSuccessClose}
            image={images.check}
            isLoading={false}
            actionText="Close"
            title=""
            text={
              departmentAccountStatus === 'active'
                ? 'Profile has been successfuly deactivated'
                : 'Profile has been successfuly reactivated'
            }
          />

          {/* this modal is to delete department */}
        </UserContainer>
      </>
    </AppContainer>
  );
}

export default Department;
