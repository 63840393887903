import { NavLink } from 'react-router-dom';

interface PageIndicatorProps {
  navList: {
    id: string | number;
    url: string;
    title: string;
  }[];
  selected?: string;
  setSelected?: (arg: any) => void;
}

const NavTab = ({ navList, selected, setSelected }: PageIndicatorProps) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-x-auto md:tw-gap-x-8">
        {navList.map((items: any, idx: number) => (
          <NavLink
            to={items.url}
            key={items.id}
            end={true}
            className={({ isActive }) => {
              return `tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
                isActive ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]' : 'tw-text-[#6A616F]'
              }`;
            }}>
            {items.title}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default NavTab;
