import { AppContainer } from '../../atoms';
import { routesPath } from '../../utils';
import { useNavigate } from 'react-router';
import { NewAppContainer } from './style';

const { SETTINGS } = routesPath;

function NewAppNotification() {
  const navigate = useNavigate();

  // console.log(formvalues);
  return (
    <AppContainer
      goBack={() => navigate(SETTINGS)}
      navTitle={`App Contents`}
      navHelper="In-App Notification | New App Notification ">
      <NewAppContainer>
        {/* <NewNotification
        // radioData={notificationRecipents}
        requestStatus={createNotificationStatus}
          onSubmit={(item: Dictionary) => handleCreateNotificationBtn(item)}
        /> */}
      </NewAppContainer>
    </AppContainer>
  );
}

export default NewAppNotification;
