import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {}
};

const customerAccountStatementSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
    setAccountStatementFilters: (state, action) => {
      state.filters = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getCustomerAccountStatement.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getCustomerAccountStatement.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getCustomerAccountStatement.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getCustomerAccountStatement = createAsyncThunk('getCustomerAccountStatement', async (payload: Dictionary, { dispatch }) => {
  const { account_number, start_date, end_date, time, page, per_page } = payload;
  const url = `/admin/customer-account-statement?account_number=${account_number}&start_date=${start_date}&end_date=${end_date}&time=${time}&page=${page}&per_page=${per_page}`;
    dispatch(setAccountStatementFilters({
      account_number,
      start_date,
      end_date,
      time
    }))
  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const customerAccountStatementReset = customerAccountStatementSlice.actions.reset;
export const setAccountStatementFilters = customerAccountStatementSlice.actions.setAccountStatementFilters;
export const customerAccountStatementSliceReducer = customerAccountStatementSlice.reducer;
