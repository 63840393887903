import { MdClose } from "react-icons/md"
import { ZojaButton, ZojaModal } from "../tailwind"
import { images } from "../../utils"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { useRef, useState } from "react"
import { FaEye } from "react-icons/fa"

interface IProps {
    show: boolean,
    handleClose: () => void,
    imageUrl: any,
}

const PreviewImageModal = ({ show, handleClose, imageUrl }: IProps) => {
    const [rotate, setRotate] = useState(0)

    const contentRef = useRef(null)
    return (
        <ZojaModal
            show={show}
            handleClose={handleClose}
            closeOnClickOutside={true}
            contentRef={contentRef}
            height='auto'
            position='center'
            extraClass='tw-mt-12 tw-items-center tw-rounded-xl md:tw-w-[45rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto'
            children={<div className='tw-rounded-lg tw-text-center tw-p-10 tw-flex tw-flex-col tw-items-center tw-relative tw-pt-12' ref={contentRef}>
                <span className="tw-text-center tw-text-sm tw-font-medium tw-text-[#162082] tw-block tw-mb-4">Image View</span>
                <TransformWrapper
                    initialScale={1}
                    initialPositionX={200}
                    initialPositionY={100}
                >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                        <>
                            <TransformComponent contentClass="">
                                <img className='tw-object-cover md:tw-h-[30rem] md:tw-w-[40rem]' src={imageUrl} alt="action-img" style={{transform: `rotate(${rotate}deg)`}}/>
                            </TransformComponent>
                            <div className="tw-flex tw-gap-x-4 tw-items-center tw-justify-center tw-my-8">
                                <ZojaButton
                                    onClick={() => {
                                        setRotate((prevState) => {
                                            if (prevState !== 360) {
                                                prevState = prevState + 90
                                            }else{
                                                prevState = 0
                                            }
                                            return prevState
                                        })
                                        // resetTransform()
                                    }}
                                    extraClass={`tw-rounded-lg tw-p-2.5 tw-px-5 tw-text-sm tw-font-thin tw-border tw-border-[#162082] tw-rounded-md ${false && 'tw-px-10'}`}
                                    text={<span className='tw-hidden md:tw-block'>Rotate</span>}
                                    loading={false}
                                    icon={<img className='tw-w-[45%] md:tw-w-[25%]' src={images.rotationIcon} alt='icon' />}
                                />
                                <ZojaButton
                                    onClick={() => zoomIn()}
                                    extraClass={`tw-rounded-lg tw-p-2.5 tw-px-5 tw-text-sm tw-font-thin tw-border tw-border-[#162082] tw-rounded-md ${false && 'tw-px-10'}`}
                                    text={<span className='tw-hidden md:tw-block'>Zoom In</span>}
                                    loading={false}
                                    icon={<img className='tw-w-[45%] md:tw-w-[25%]' src={images.zoomIn} alt='icon' />}
                                />
                                <ZojaButton
                                    onClick={() => zoomOut()}
                                    extraClass={`tw-rounded-lg tw-p-2.5 tw-px-5 tw-text-sm tw-font-thin tw-border tw-border-[#162082] tw-rounded-md ${false && 'tw-px-10'}`}
                                    text={<span className='tw-hidden md:tw-block'>Zoom Out</span>}
                                    loading={false}
                                    icon={<img className='tw-w-[45%] md:tw-w-[25%]' src={images.zoomOut} alt='icon' />}
                                />
                            </div>
                            <a href={imageUrl} target="_blank" className="tw-rounded-md tw-border tw-border-[#162082] tw-text-sm tw-p-2.5 tw-px-8 tw-no-underline tw-text-gray-700">Full View</a>
                        </>
                    )}
                </TransformWrapper>

                <span className='tw-text-white tw-bg-isPrimary tw-block tw-p-1 tw-rounded-md tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer' onClick={handleClose}><MdClose /></span>
            </div>}
        />
    )
}

export default PreviewImageModal