import { useEffect, useState } from 'react';
import { AppContainer, ActivityActionModal, CountInfo, MoreIconView } from '../../atoms';
import {
  articleDataHeader,
  faqDataHeader,
  notificationDataHeader,
  // settingsCountData,
} from './data';

import { colors, dateFormat, images, routesPath } from '../../utils';
import { BorderedText, FaqTable, NotificationTable, ArticleTable, Pagination, SearchInput } from '../../components';
import { Dictionary } from '../../types';
import { NotificationTop, TableContainer } from './style';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router';
import { deleteArticleRequest, getArticlesRequest } from '../../redux/slice';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';

import {
  getAllFaqsRequest,
  deleteFaqRequest,
  deleteFaqReset,
  getNotificationRequest,
  deleteNotificationRequest,
  settingsCountRequest,
} from '../../redux/slice';
import { Oval } from 'react-loader-spinner';

const emptyListCenterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const {
  NEWAPPNOTIFICATION,
  EMAILNOTIFICATION,
  NEWARTICLE,
  NEWFAQ,
  APPNOTIFICATIONUPDATE,
  EMAILNOTIFICATIONUPDATE,
  ARTICLEUPDATE,
  FAQUPDATE,
} = routesPath;

const beforeDeleteAction = 'Delete';
const afterDeleteAction = 'Close';

function Settings() {
  const dispatch = useAppDispatch();

  const [selectedSettingsCard, setSelectedSettingsCard] = useState<Dictionary>({});
  const [searchValue, setSearchValue] = useState('');
  const pageSize = 30;

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [notificationData, setNotificationData] = useState<any[]>([]);
  const [notificationDataList, setNotificationDataList] = useState<any[]>([]);
  const [articlesData, setArticlesData] = useState<any[]>([]);
  const [articlesDataList, setArticlesDataList] = useState<any[]>([]);
  const [selectedArticle, setSelectedArticle] = useState<any>({});
  const [selectedNotification, setSelectedNotification] = useState<any>({});
  const [deleteIsModalVisible, setDeleteIsModalVisible] = useState(false);
  const [actionText, setActionText] = useState(beforeDeleteAction);

  const [faqsData, setFaqsData] = useState<any[]>([]);
  const [faqsDataList, setFaqsDataList] = useState<any[]>([]);
  const [currentPageFaq, setCurrentPageFaq] = useState(1);
  const [totalPagesFaq, setTotalPagesFaq] = useState(5);

  const [, setSelectedNotificationText] = useState('');
  const [selectedFaqData, setSelectedFaqData] = useState<Dictionary>({});
  const [isDeleteFaqModalVisible, setIsDeleteFaqModalVisible] = useState(false);
  const [settingsCountData, setSettingsCountData] = useState([
    {
      id: 1,
      count: 0,
      title: 'In-app Notification',
      tag: 'in app notification',
    },
    {
      id: 2,
      count: 0,
      title: 'Email Notification',
      tag: 'email notification',
    },
    {
      id: 3,
      count: 0,
      title: 'Articles',
      tag: 'articles',
    },
    {
      id: 4,
      count: 0,
      title: 'FAQs',
      tag: 'faqs',
    },
  ]);

  const viewDetails = 'View Details';
  const deleteEntry = 'Delete Entry';
  const moreIconOption = [viewDetails, deleteEntry];

  const [moreIsVisible, setMoreIsVisible] = useState(false);

  // redux state
  const articlesState = useAppSelector(state => state.getArticles);
  const { status: getArticlesStatus } = articlesState;

  const deleteArticleState = useAppSelector(state => state.deleteArticle);
  const { status: deleteArticleStatus } = deleteArticleState;

  const notificationState = useAppSelector(state => state.getNotification);
  const { status: getNotificationStatus } = notificationState;

  const deleteNotificationState = useAppSelector(state => state.deleteNotification);
  const { status: deleteNotificationStatus } = deleteNotificationState;

  const settingsCountState = useAppSelector(state => state.settingsCount);
  const { status: settingsCountStatus } = settingsCountState;

  // const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const objectLength = Object.keys(selectedSettingsCard).length;

  // redux state
  const faqsState = useAppSelector(state => state.getAllFaqs);
  const { status: faqsStatus } = faqsState;

  const deleteFaqState = useAppSelector(state => state.deleteFaq);
  const { status: deletedFaqStatus } = deleteFaqState;

  // api settings count
  useEffect(() => {
    dispatch(settingsCountRequest({}));
  }, [deletedFaqStatus, deleteNotificationStatus, deleteArticleStatus, dispatch]);

  useEffect(() => {
    if (settingsCountStatus === 'succeeded') {
      // let updatedList: any[] = [];

      const { articles_count, email_notifications_count, faq_count, in_app_notifications_count } =
        settingsCountState?.data;

      const updatedList: any[] = [
        {
          id: 1,
          count: in_app_notifications_count,
          title: 'In-app Notification',
          tag: 'in app notification',
        },
        {
          id: 2,
          count: email_notifications_count,
          title: 'Email Notification',
          tag: 'email notification',
        },
        {
          id: 3,
          count: articles_count,
          title: 'Articles',
          tag: 'articles',
        },
        {
          id: 4,
          count: faq_count,
          title: 'FAQs',
          tag: 'faqs',
        },
      ];

      setSettingsCountData(updatedList);
    }
  }, [settingsCountStatus, deletedFaqStatus, deleteNotificationStatus, deleteArticleStatus]);

  // api faq
  useEffect(() => {
    dispatch(
      getAllFaqsRequest({
        per_page: pageSize,
        page: currentPageFaq,
      }),
    );
  }, [deletedFaqStatus, currentPageFaq, dispatch]);

  useEffect(() => {
    if (faqsStatus === 'succeeded') {
      let updatedList: any[] = [];

      faqsState?.data?.faqs?.data.forEach((item: any, index: number) => {
        updatedList.push({
          itemId: item.id,
          id: index + 1,
          faqTitle: item?.question,
          helpful: '10',
          notHelpful: '10',
          createdBy: item?.author?.name.length < 2 ? 'N/A' : item?.author?.name,
          dateCreated: dateFormat(item?.created_at),
        });
      });

      const {
        meta: { last_page },
      } = faqsState?.data?.faqs;

      setTotalPagesFaq(last_page);

      setFaqsData(updatedList);
      setFaqsDataList(updatedList);
    }
  }, [faqsState]);

  // handle different more icon text
  const handleMoreIconOptionsApp = async (item: string) => {
    if (item === viewDetails) {
      navigate(`${APPNOTIFICATIONUPDATE}${selectedNotification.notificationId}`);
    }

    if (item === deleteEntry) {
      setMoreIsVisible(false);
      setDeleteIsModalVisible(true);
    }
  };
  const handleMoreIconOptionsEmail = async (item: string) => {
    if (item === viewDetails) {
      navigate(`${EMAILNOTIFICATIONUPDATE}${selectedNotification.notificationId}`);
    }

    if (item === deleteEntry) {
      setMoreIsVisible(false);
      setDeleteIsModalVisible(true);
    }
  };
  const handleMoreIconOptionsArticle = async (item: string) => {
    if (item === viewDetails) {
      navigate(`${ARTICLEUPDATE}${selectedArticle.articleId}`);
    }

    if (item === deleteEntry) {
      setMoreIsVisible(false);
      setDeleteIsModalVisible(true);
    }
  };
  const handleMoreIconOptionsFaq = async (item: string) => {
    if (item === viewDetails) {
      navigate(`${FAQUPDATE}${selectedFaqData?.itemId?.toString()}`);
    }
    if (item === deleteEntry) {
      setMoreIsVisible(false);
      setIsDeleteFaqModalVisible(true);
    }
  };

  const handleSelectedFaq = (item: any) => {
    setMoreIsVisible(true);
    setSelectedFaqData(item);
  };
  const handleDeleteFaq = () => {
    if (deletedFaqStatus === 'succeeded') {
      setIsDeleteFaqModalVisible(false);
      dispatch(deleteFaqReset());
    } else {
      dispatch(
        deleteFaqRequest({
          id: selectedFaqData?.itemId,
        }),
      );
    }
  };

  const handleArticleDelete = () => {
    if (actionText === beforeDeleteAction && deleteIsModalVisible === true) {
      dispatch(deleteArticleRequest({ articleId: selectedArticle.articleId }));
    } else {
      setActionText(beforeDeleteAction);
      setDeleteIsModalVisible(false);
    }
  };

  // DELETE NOTIFICATION
  const handleNotificationDelete = () => {
    if (actionText === beforeDeleteAction && deleteIsModalVisible === true) {
      dispatch(deleteNotificationRequest({ id: selectedNotification.notificationId }));
    } else {
      setActionText(beforeDeleteAction);
      setDeleteIsModalVisible(false);
    }
  };

  const handleCloseModal = () => {
    setActionText(beforeDeleteAction);
    setDeleteIsModalVisible(false);
  };

  // ARTICLES API
  useEffect(() => {
    if (deleteArticleStatus === 'succeeded') {
      setActionText(afterDeleteAction);
    }
  }, [deleteArticleStatus]);

  useEffect(() => {
    if (selectedSettingsCard.id === 3) {
      dispatch(
        getArticlesRequest({
          per_page: pageSize,
          page: currentPage,
        }),
      );
    }
  }, [selectedSettingsCard, currentPage, pageSize, deleteArticleStatus]);

  useEffect(() => {
    if (getArticlesStatus === 'succeeded') {
      let updatedList: any[] = [];

      articlesState?.data?.articles?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          title: item?.title,
          status: item?.status,
          createdAt: item?.created_at,
          timeUpdated: item?.updated_at,
          articleId: item?.id,
          imageUrl: item?.image_url,
          createdBy: item?.author?.name,
        });
      });

      const {
        meta: { last_page },
      } = articlesState?.data?.articles;

      setTotalPages(last_page);

      setArticlesData(updatedList);
      setArticlesDataList(updatedList);
    }
  }, [articlesState, getArticlesStatus, deleteArticleStatus]);

  // NOTIFICATION API
  useEffect(() => {
    if (deleteNotificationStatus === 'succeeded') {
      setActionText(afterDeleteAction);
    }
  }, [deleteNotificationStatus]);

  useEffect(() => {
    if (selectedSettingsCard.id === 1 || selectedSettingsCard.id === 2) {
      dispatch(
        getNotificationRequest({
          per_page: pageSize,
          page: currentPage,
          type: selectedSettingsCard.tag,
        }),
      );
    }
  }, [selectedSettingsCard, currentPage, pageSize, dispatch, deleteNotificationStatus]);

  useEffect(() => {
    if (getNotificationStatus === 'succeeded') {
      let updatedList: any[] = [];

      notificationState?.data?.notifications?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          createdBy: item?.author?.name,
          createdAt: item?.created_at,
          deliveryDate: item?.delivery_date,
          deliveryMeridiem: item?.delivery_meridiem,
          deliveryTime: item?.delivery_time,
          notificationId: item?.id,
          image: item?.image,
          interval: item?.interval,
          link: item?.link,
          message: item?.message,
          recipients: item?.recipients,
          status: item?.status,
          title: item?.title,
          type: item?.type,
        });
      });

      const {
        meta: { last_page },
      } = notificationState?.data?.notifications;
      setTotalPages(last_page);

      setNotificationData(updatedList);
      setNotificationDataList(updatedList);
    }
  }, [getNotificationStatus, notificationState]);

  const settingsBoxShadow = '0px 30px 55px 0px rgba(120, 120, 143, 0.10)';

  const handleCloseFaq = () => {
    setIsDeleteFaqModalVisible(false);
    dispatch(deleteFaqReset());
  };

  return (
    <AppContainer navTitle="App Contents" navHelper={selectedSettingsCard?.title}>
      <div>
        <CountInfo
          data={settingsCountData}
          shadow={settingsBoxShadow}
          setSelectedData={setSelectedSettingsCard}
          selectedCard={selectedSettingsCard}
          type="settings"
          loading={settingsCountStatus === 'loading'}
        />
        {objectLength < 1 && (
          <div style={emptyListCenterStyle}>
            <img src={images.emptyList} alt="Empty container" />
          </div>
        )}
        <TableContainer>
          {selectedSettingsCard.id === 1 &&
            (getNotificationStatus === 'loading' ? (
              <>
                <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <NotificationTop>
                  <BorderedText
                    text="New Notification"
                    backgroundColor={colors.primary}
                    color={colors.white}
                    icon={<AiOutlinePlus color={colors.white} />}
                    onClick={() => navigate(`${NEWAPPNOTIFICATION}`)}
                  />

                  <SearchInput
                    backgroundColor={colors.white}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length === 0) {
                        setNotificationDataList(notificationData);
                      } else {
                        const filteredResult = notificationDataList.filter(item =>
                          item.title.toLowerCase().includes(searchValue),
                        );

                        setNotificationDataList(filteredResult);
                      }
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search Records"
                  />
                </NotificationTop>

                <NotificationTable
                  headerData={notificationDataHeader}
                  header={true}
                  type="notification"
                  data={notificationDataList}
                  onClick={(item: Dictionary) => setMoreIsVisible(true)}
                  setSelectedItem={setSelectedNotification}
                />
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={selectedPage => {
                    setCurrentPage(selectedPage);
                  }}
                  // isLoading={getNotificationStatus === 'loading'}
                />
                <MoreIconView
                  setSelectedText={setSelectedNotificationText}
                  isModalVisible={moreIsVisible}
                  closeModal={() => setMoreIsVisible(false)}
                  options={moreIconOption}
                  onClick={item => handleMoreIconOptionsApp(item)}
                />
                <ActivityActionModal
                  actionText={actionText}
                  title=""
                  text={
                    actionText === beforeDeleteAction
                      ? 'Are you sure you want to delete this record?'
                      : 'Record has been successfully deleted'
                  }
                  isModalVisible={deleteIsModalVisible}
                  closeModal={handleCloseModal}
                  actionClick={handleNotificationDelete}
                  image={actionText === beforeDeleteAction ? images.reject : images.check}
                  isLoading={deleteNotificationStatus === 'loading'}
                  requestStatus={deleteArticleStatus}
                  secondaryActionText={actionText === beforeDeleteAction ? 'Cancel' : ''}
                />
              </>
            ))}

          {selectedSettingsCard.id === 2 &&
            (getNotificationStatus === 'loading' ? (
              <>
                <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <NotificationTop>
                  <BorderedText
                    text="New Notification"
                    backgroundColor={colors.primary}
                    color={colors.white}
                    icon={<AiOutlinePlus color={colors.white} />}
                    onClick={() => navigate(`${EMAILNOTIFICATION}`)}
                  />
                  <SearchInput
                    backgroundColor={colors.white}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length === 0) {
                        setNotificationDataList(notificationData);
                      } else {
                        const filteredResult = notificationDataList.filter(item =>
                          item.title.toLowerCase().includes(searchValue),
                        );

                        setNotificationDataList(filteredResult);
                      }
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search Records"
                  />
                </NotificationTop>

                <NotificationTable
                  headerData={notificationDataHeader}
                  header={true}
                  data={notificationDataList}
                  setSelectedItem={setSelectedNotification}
                  onClick={(item: Dictionary) => setMoreIsVisible(true)}
                  // onClick={item => navigate(`${USERDETAILS}${item.userId}`)}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={selectedPage => {
                    setCurrentPage(selectedPage);
                  }}
                  // isLoading={getNotificationStatus === 'loading'}
                />
                <MoreIconView
                  setSelectedText={setSelectedNotificationText}
                  isModalVisible={moreIsVisible}
                  closeModal={() => setMoreIsVisible(false)}
                  options={moreIconOption}
                  onClick={item => handleMoreIconOptionsEmail(item)}
                />
                <ActivityActionModal
                  actionText={actionText}
                  title=""
                  text={
                    actionText === beforeDeleteAction
                      ? 'Are you sure you want to delete this record?'
                      : 'Record has been successfully deleted'
                  }
                  isModalVisible={deleteIsModalVisible}
                  closeModal={handleCloseModal}
                  actionClick={handleNotificationDelete}
                  image={actionText === beforeDeleteAction ? images.reject : images.check}
                  isLoading={false}
                  requestStatus={deleteNotificationStatus}
                  secondaryActionText={actionText === beforeDeleteAction ? 'Cancel' : ''}
                />
              </>
            ))}

          {/* THIS IS FOR ARTICLE */}
          {selectedSettingsCard.id === 3 &&
            (getArticlesStatus === 'loading' ? (
              <>
                <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <NotificationTop>
                  <BorderedText
                    text="New Article"
                    backgroundColor={colors.primary}
                    color={colors.white}
                    icon={<AiOutlinePlus color={colors.white} />}
                    onClick={() => navigate(`${NEWARTICLE}`)}
                  />
                  <SearchInput
                    backgroundColor={colors.white}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length === 0) {
                        setArticlesDataList(articlesData);
                      } else {
                        const filteredResult = articlesDataList.filter(item =>
                          item.title.toLowerCase().includes(searchValue),
                        );

                        setArticlesDataList(filteredResult);
                      }
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search Records"
                  />
                </NotificationTop>

                <ArticleTable
                  headerData={articleDataHeader}
                  header={true}
                  type="article"
                  data={articlesDataList}
                  onClick={(item: Dictionary) => setMoreIsVisible(true)}
                  setSelectedItem={setSelectedArticle}
                />

                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={selectedPage => {
                    setCurrentPage(selectedPage);
                  }}
                  // isLoading={getArticlesStatus === 'loading'}
                />
                <MoreIconView
                  setSelectedText={setSelectedNotificationText}
                  isModalVisible={moreIsVisible}
                  closeModal={() => setMoreIsVisible(false)}
                  options={moreIconOption}
                  onClick={item => handleMoreIconOptionsArticle(item)}
                />
                <ActivityActionModal
                  actionText={actionText}
                  title=""
                  text={
                    actionText === beforeDeleteAction
                      ? 'Are you sure you want to delete this record?'
                      : 'Record has been successfully deleted'
                  }
                  isModalVisible={deleteIsModalVisible}
                  closeModal={handleCloseModal}
                  actionClick={handleArticleDelete}
                  image={actionText === beforeDeleteAction ? images.reject : images.check}
                  isLoading={false}
                  requestStatus={deleteArticleStatus}
                  secondaryActionText={actionText === beforeDeleteAction ? 'Cancel' : ''}
                />
              </>
            ))}
          {selectedSettingsCard.id === 4 &&
            (faqsStatus === 'loading' ? (
              <>
                <div className="tw-h-[60vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </>
            ) : (
              <>
                <NotificationTop>
                  <BorderedText
                    text="New FAQ"
                    backgroundColor={colors.primary}
                    color={colors.white}
                    icon={<AiOutlinePlus color={colors.white} />}
                    onClick={() => navigate(`${NEWFAQ}`)}
                  />
                  <SearchInput
                    backgroundColor={colors.white}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length === 0) {
                        setFaqsDataList(faqsData);
                      } else {
                        const filteredResult = faqsDataList.filter(item =>
                          item.faqTitle.toLowerCase().includes(searchValue),
                        );

                        setFaqsDataList(filteredResult);
                      }
                      setSearchValue(e.target.value);
                    }}
                    placeholder="Search Records"
                  />
                </NotificationTop>

                <FaqTable
                  headerData={faqDataHeader}
                  header={true}
                  data={faqsDataList}
                  onClick={(item: Dictionary) => handleSelectedFaq(item)}
                />

                <Pagination
                  // isLoading={faqsStatus === 'loading'}
                  currentPage={currentPageFaq}
                  totalPages={totalPagesFaq}
                  onPageChange={selectedPage => {
                    setCurrentPageFaq(selectedPage);
                  }}
                />

                <MoreIconView
                  setSelectedText={setSelectedNotificationText}
                  isModalVisible={moreIsVisible}
                  closeModal={() => setMoreIsVisible(false)}
                  options={moreIconOption}
                  onClick={item => handleMoreIconOptionsFaq(item)}
                />
                {/* This Modal Promps to delete */}
                <ActivityActionModal
                  isLoading={deletedFaqStatus === 'loading'}
                  actionClick={handleDeleteFaq}
                  closeModal={handleCloseFaq}
                  isModalVisible={isDeleteFaqModalVisible}
                  text={
                    deletedFaqStatus === 'succeeded'
                      ? 'You have successfully deleted the Faq'
                      : `You have sure you want to delete this notification?`
                  }
                  actionText={deletedFaqStatus === 'succeeded' ? 'Close' : 'Delete'}
                  actionBtnBackgroundColor={deletedFaqStatus === 'succeeded' ? 'tw-bg-isPrimary' : 'tw-bg-isRed'}
                  image={deletedFaqStatus === 'succeeded' ? images.check : images.deactivateUser}
                  secondaryActionText={deletedFaqStatus === 'succeeded' ? '' : 'Close'}
                />
              </>
            ))}
        </TableContainer>
        {/* <LoaderModal
          isModalVisible={
            getArticlesStatus === 'loading' || faqsStatus === 'loading' || getNotificationStatus === 'loading'
          }
          text="Loading please wait..."
          closeModal={() => {}}
        /> */}
      </div>
    </AppContainer>
  );
}

export default Settings;
