import React, { useState } from 'react';
import FilterColapsible from '../filterColapsible';
import ZojaCheckbox from '../checkbox';
import CustomDatePicker from '../customDatePicker';
import { ZojaButton, ZojaInput } from '../tailwind';
import { handleSelectedOption, toggleItemChecked, toggleItemCheckedOneItem } from '../../utils';
import ReusableFilter from '../reusableFilter';
import { Dictionary } from '../../types';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';

const IncomeReportFilter = ({
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  submitButtonText,
  setTransactionFilterOption,
  onClick,
  setSearch,
  searchedUsers,
}: any) => {
  const dispatch = useAppDispatch();

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  const transactionFilterOptions = useAppSelector(state => state.filterOptions);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setTransactionFilterOption({ ...transactionFilterOptions, [name]: value }));
  };

  return (
    <div>
      <div className="tw-h-[40vh] hide_scrollbar">
        <div className="tw-mt-4">
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'accountInfo'}
            filterOptions={filterOption}
            title="Account info"
            show={showOptions.accountInfo}
            setShow={() => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })}
            extraClass="tw-shadow-none"
            items={filterOption.accountInfo}
            type={'inputCheck'}
            setSearch={setSearch}
            searchedUsers={searchedUsers}
            searchedUserAccountState={searchedUserAccountState}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'revenue'}
            filterOptions={filterOption}
            title="Revenue(N)"
            show={showOptions.revenue}
            setShow={() => setShowOptions({ ...showOptions, revenue: !showOptions.revenue })}
            extraClass="tw-shadow-none"
            items={filterOption.revenue}
          />

          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'profitMargin'}
            filterOptions={filterOption}
            title="Profit Margin"
            show={showOptions.profitMargin}
            setShow={() => setShowOptions({ ...showOptions, profitMargin: !showOptions.profitMargin })}
            extraClass="tw-shadow-none"
            items={filterOption.profitMargin}
          />
          <ReusableFilter
            setChecked={toggleItemCheckedOneItem}
            setItem={setFilterOption}
            itemName={'timeRange'}
            filterOptions={filterOption}
            title="Time Range"
            show={showOptions.timeRange}
            setShow={() => setShowOptions({ ...showOptions, timeRange: !showOptions.timeRange })}
            extraClass="tw-shadow-none"
            items={filterOption.timeRange}
            type={'checkDate'}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'paymentMethod'}
            filterOptions={filterOption}
            title="Payment Method"
            show={showOptions.paymentMethod}
            setShow={() => setShowOptions({ ...showOptions, paymentMethod: !showOptions.paymentMethod })}
            extraClass="tw-shadow-none"
            items={filterOption.paymentMethod}
          />
        </div>
      </div>
      <ZojaButton
        onClick={onClick}
        text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
        extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin tw-mb-4"
      />
    </div>
  );
};

export default IncomeReportFilter;
