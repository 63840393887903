import { TableTag, TD, TH, TR, TableContainer, AccountUpdateHistoryTableContainer } from './style';
import { Dictionary } from '../../../types';

export interface AccountUpdateHistoryIProps {
  id: number;
  time: string;
  action?: string;
  outcome?: string;
  oldContact?: string;
  newContact?: string;
  adminValidator?: string;
}

export interface TableIPropsIProps {
  type?: string;
  data?: AccountUpdateHistoryIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData3?: Dictionary;
}

function AccountUpdateHistoryTable({ data, headerData3, type }: TableIPropsIProps) {
  return (
    <AccountUpdateHistoryTableContainer>
      <TableTag>
        <thead>
          <tr>
            <TH>{headerData3?.time}</TH>
            <TH>{headerData3?.action}</TH>
            <TH>{headerData3?.outcome}</TH>
            <TH>{headerData3?.oldContact}</TH>
            <TH>{headerData3?.newContact}</TH>
            <TH>{headerData3?.adminValidator}</TH>
          </tr>
        </thead>

        <tbody>
          {data?.map((item: AccountUpdateHistoryIProps) => (
            <TR key={item?.id}>
              <TD>{item?.time}</TD>
              <TD>{item?.action}</TD>
              <TD
                style={{
                  color:
                    item?.outcome === 'accepted'
                      ? '#4dab4dde' // Green
                      : item?.outcome === 'processed'
                      ? 'orange' // Orange
                      : '#fd0000', // Red
                }}>
                {item?.outcome}
              </TD>

              {/* <TD style={{ color: item?.outcome === 'accepted' ? '#4dab4dde' : '#fd0000' }}>{item?.outcome}</TD> */}
              <TD>{item?.oldContact}</TD>
              <TD>{item?.newContact}</TD>
              <TD>{item?.adminValidator}</TD>
            </TR>
          ))}
        </tbody>
      </TableTag>
    </AccountUpdateHistoryTableContainer>
  );
}

export default AccountUpdateHistoryTable;
