import React from 'react';
import { ZojaButton, ZojaModal } from '../tailwind';
import { images } from '../../utils';
import { ReactComponent as YesNoIcon } from '../../assets/svg/yesno.svg';

interface IProps {
  title: string;
  content: string;
  type?: string;
  show: boolean;
  handleClose: () => void;
  contentRef: HTMLDivElement | any;
  closeOnClickOutside?: boolean;
  handleShowSaveReport?: () => void;
}

const OpenDisputeModal = ({
  title,
  content,
  show,
  contentRef,
  handleClose,
  handleShowSaveReport,
  type,
  closeOnClickOutside = false,
}: IProps) => {
  return (
    <ZojaModal
      show={show}
      height="auto"
      position="center"
      borderRadius="12px"
      handleClose={handleClose}
      closeOnClickOutside={closeOnClickOutside}
      extraClass="md:tw-w-[33.5rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      contentRef={contentRef}
      children={
        <div className="rounded-lg tw-py-8" ref={contentRef}>
          <h2 className="tw-text-center tw-text-[18px] md:tw-text-xl tw-text-gray-700 tw-font-medium">{title}</h2>
          {/* <img src={images.yesno} className="tw-w-[30%] tw-mx-auto" alt="download-icon" /> */}
          <div className=" tw-grid tw-justify-center">
            <YesNoIcon />
          </div>
          <p className="tw-text-center tw-my-6 tw-w-[316px] tw-mx-auto tw-text-gray-700 tw-text-sm tw-font-light">
            {content}
          </p>
          <div className="tw-flex tw-justify-center tw-mb-8">
            <ZojaButton
              text="Continue, Send Email"
              onClick={handleClose}
              extraClass="tw-p-2 tw-bg-isPrimary tw-cursor-pointer tw-text-white tw-px-7 tw-rounded-[10px] tw-text-sm tw-font-medium"
            />
          </div>
        </div>
      }
    />
  );
};

export default OpenDisputeModal;
