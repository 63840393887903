import React, { useEffect, useState } from 'react';
import { Dictionary } from '../../../types';
import { ZojaTable } from '../..';
import { TD, TR } from '../../tailwind/table/tableElements';
import { dateFormat, dateTimeFormat3 } from '../../../utils/dateFormat';
import { FaChevronRight } from 'react-icons/fa';
import { useAppDispatch } from '../../../redux/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { currencyFormat, routesPath } from '../../../utils';
import moment from 'moment';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
const { KYCCUSTOMERINFORMATION } = routesPath;

interface IProps {
  data: Dictionary[];
  level?: string;
  tab?: string;
  sorting?: any;
  order?: string;
  tableColumns?: any;
  dropdownList?: any;
  setSelectedItem?: any;
  setOpenMore: (item: any) => void;
  setSelectedTableItem?: any;
  handleMoreIconOptions: (item: any) => void;
}

const tableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];
const pendingTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Created',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];

const rejectedTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Rejection Reason',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Rejected',
    hasSortIcon: false,
  },
  // {
  //     id: 7,
  //     title: '',
  // },
];

const verifiedUsers: string = 'approved';
const pendingUsers: string = 'pending';
const rejectedUsers: string = 'rejected';

const ReconciliationTable = ({
  data,
  sorting,
  order,
  tableColumns,
  setOpenMore,
  dropdownList,
  handleMoreIconOptions,
  setSelectedItem,
  setSelectedTableItem,
}: IProps) => {
  const [settlementTableColumns, setSettlementTableColumns] = useState(tableColumns);

  const handleOnSelect = (item: any) => {
    console.log(item);
    setOpenMore(!true);
    setSelectedTableItem(item);
  };

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <ZojaTable column={tableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.name}</TD>
            <TD>{item.tid}</TD>
            <TD>
              <>{currencyFormat(item.amount, true)} </>
            </TD>
            <TD> {item.type}</TD>
            <TD
              extraClass={`${
                item.status === 'success'
                  ? 'tw-text-green-500'
                  : item.status === 'failed'
                  ? 'tw-text-red-500'
                  : 'tw-text-black'
              }`}
              value={item.status === 'success' ? 'Successful' : item.status === 'failed' ? 'Failed' : 'Pending'}
            />
            <TD value={moment(item.time).format('DD MMMM, YYYY - hh:mm a')} />
            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  handleClose={() => setOpenMore(false)}
                  dropdownList={dropdownList}
                  onActionClick={item => handleMoreIconOptions(item)}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
};

export default ReconciliationTable;
