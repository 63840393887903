import { GoUpload } from 'react-icons/go';
import { FaRegSave } from 'react-icons/fa';
import { colors, formatFileSize } from '../../utils';
// import { formatFileSize } from '../../utils';
// import { ReactComponent as DocIcon } from '../../assets/images/doc_image.svg';
// import { FiUpload } from 'react-icons/fi';
import { useRef } from 'react';
import { ZojaButton } from '../tailwind';

interface IProps {
  title?: string;
  titleExtraClass?: string;
  extraClass?: string;
  fileNameExtraClass?: string;
  fileSizeExtraClass?: string;
  value: FileList | null;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  resetValue: () => void;
  accept?: string;
  multiple?: boolean;
  disabled?: boolean;
  displayFileNamesAfterChange?: boolean;
}

function FilePicker({
  titleExtraClass,
  extraClass,
  fileNameExtraClass,
  fileSizeExtraClass,
  value,
  onChange,
  resetValue,
  accept = `media_type`,
  multiple = true,
  disabled = false,
  title = multiple ? `Add Files` : `Add File`,
  displayFileNamesAfterChange = true,
}: IProps) {
  const fileInputRef = useRef<any>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div className={`${extraClass} tw-flex tw-flex-col`}>
        {displayFileNamesAfterChange &&
          value &&
          value?.length > 0 &&
          Array.from(value).map(item => {
            return (
              <div className="tw-flex tw-space-x-2 tw-items-center tw-mb-2" key={item.name + item.type}>
                <FaRegSave size={22} color={colors.primary} className="" />
                <h6 className={`${fileNameExtraClass} tw-m-0 tw-p-0 tw-font-semibold tw-text-md tw-text-isPrimary`}>
                  {item.name}
                </h6>
                <h6 className={`${fileSizeExtraClass} tw-m-0 tw-p-0 tw-font-light tw-text-sm`}>
                  {formatFileSize(item.size)}
                </h6>
              </div>
            );
          })}

        <div className="tw-flex tw-space-x-4 tw-justify-start tw-items-center tw-mt-5">
          <div className="tw-flex tw-items-center tw-justify-center tw-bg-gray-100">
            <div className="tw-relative tw-inline-block">
              <button
                type="button"
                onClick={handleButtonClick}
                className="tw-font-bold tw-py-2 tw-px-4 tw-rounded tw-flex tw-items-center tw-space-x-4 tw-border-2 tw-border-isBlack tw-relative tw-w-fit tw-border-dashed tw-cursor-pointer">
                <GoUpload size={20} color={colors.black} className="tw-cursor-pointer" />
                <h6 className={`${titleExtraClass}tw-m-0 tw-p-0 tw-font-light tw-text-sm tw-cursor-pointer tw-mb-0`}>
                  {title}
                </h6>
              </button>
              <input
                type="file"
                className="tw-hidden"
                onChange={onChange}
                accept={accept}
                disabled={disabled}
                multiple={multiple}
                ref={fileInputRef}
              />
            </div>
          </div>

          {value && value?.length > 0 && (
            <ZojaButton
              text="Reset"
              extraClass={`tw-px-4 tw-py-2 tw-w-fit tw-mt-0 tw-font-normal tw-bg-isRed tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
              type="button"
              disabled={!value}
              onClick={resetValue}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default FilePicker;
