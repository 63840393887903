import { TableTag, TD, TR, TableContainer } from './style';
import { TH as TableHeader } from '../../tailwind/table/tableElements';
// import { TransactionCard } from '../..';
import { RxCaretRight } from 'react-icons/rx';
import { colors } from '../../../utils';
// import { useNavigate } from 'react-router-dom';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
// import { Dictionary } from '@reduxjs/toolkit';
import { CustomerTableHeader } from '../../../types';
import moment from 'moment';
import EmptyState from '../../emptyState/emptyState';
import { ReactComponent as EmptyStateImage } from '../../../assets/svg/emptyStateNote.svg';

// const { USERDETAILS } = routesPath;

export interface userDataIProps {
  id: number;
  name: string;
  userId: string;
  walletNo: string;
  email: string;
  phone: string;
  subAgents?: number;
  lastSeen?: string;
  date?: string;
  validationType?: string;
}

export interface TableIPropsIProps {
  type: string;
  data: userDataIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData: CustomerTableHeader;
  onClick: (item: any) => void;
  sorting?: any;
  order?: string;
}

const emptyListCenterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function CustomersUsersTable({ data, headerData, type, onClick, sorting, order }: TableIPropsIProps) {
  return (
    <div>
      {data?.length >= 1 ? (
        <TableContainer>
          <TableTag>
            <thead>
              <tr>
                {/* <TH></TH>
                <TH>{headerData.id}</TH>
                <TH>{headerData.name}</TH>
                <TH>{headerData.email}</TH>
                <TH>{headerData.walletNo}</TH>
                <TH>{headerData.phone}</TH>
                <TH>
                  {type === 'inactive' ? headerData.lastSeen : type === 'subagents' ? headerData.subAgents : null}
                </TH> */}

                {headerData.map(header => (
                  <TableHeader
                    key={header.id}
                    value={header.title}
                    headerValue={header.value}
                    hasSortIcon={header.hasSortIcon}
                    padding="2"
                    sorting={sorting}
                    order={order}
                    extraClass={`tw-text-[#162082]`}
                  />
                ))}
              </tr>
            </thead>
            <tbody>
              {type === 'active' &&
                data?.map((item: any) => (
                  <TR key={item.id}>
                    <TD>{item.id}</TD>
                    <TD>{item.name}</TD>
                    <TD>{item.walletNo}</TD>
                    <TD>{item.phone}</TD>
                    <TD>{item.kycLevel}</TD>
                    <TD>{item.lastSeen}</TD>
                    <TD>{item.dateOnboarded}</TD>
                    <TD>
                      <RxCaretRight
                        size={20}
                        color={colors.grey}
                        onClick={() => onClick(item)}
                        className="tw-cursor-pointer"
                      />
                    </TD>
                  </TR>
                ))}
              {type === 'subagents' &&
                data?.map((item: any) => (
                  <TR key={item.id}>
                    <TD>{item.id}</TD>
                    <TD>{item.name}</TD>
                    <TD>{item.walletNo}</TD>
                    <TD>{item.phone}</TD>
                    <TD>{item.subAgents}</TD>
                    <TD>{item.lastSeen}</TD>
                    <TD>{item.dateOnboarded}</TD>
                    <TD>
                      <RxCaretRight
                        size={20}
                        color={colors.grey}
                        onClick={() => onClick(item)}
                        className="tw-cursor-pointer"
                      />
                    </TD>
                  </TR>
                ))}
              {type === 'inactive' &&
                data?.map((item: any) => (
                  <TR key={item.id}>
                    <TD>{item.id}</TD>
                    <TD>{item.name}</TD>
                    <TD>{item.walletNo}</TD>
                    <TD>{item.phone}</TD>
                    <TD>{item.kycLevel}</TD>
                    <TD>{item.dateDeactivated}</TD>
                    <TD>
                      <RxCaretRight
                        size={20}
                        color={colors.grey}
                        onClick={() => onClick(item)}
                        className="tw-cursor-pointer"
                      />
                    </TD>
                  </TR>
                ))}
              {type === 'pending' &&
                data?.map((item: any) => (
                  <TR key={item.id}>
                    <TD>{item.id}</TD>
                    <TD>{item.name}</TD>
                    <TD>{item.verificationType}</TD>
                    <TD>{item.phone}</TD>
                    <TD>{item.email}</TD>
                    <TD>{moment(item?.createdAt).format('DD MMMM, YYYY - hh:mm a')}</TD>
                    <TD>
                      <RxCaretRight
                        size={20}
                        color={colors.grey}
                        onClick={() => onClick(item)}
                        className="tw-cursor-pointer"
                      />
                    </TD>
                  </TR>
                ))}
            </tbody>
          </TableTag>
        </TableContainer>
      ) : (
        <div style={emptyListCenterStyle}>
          <div className="tw-mt-6 tw-py-9">
            <EmptyState
              img={<EmptyStateImage width={200} height={200} />}
              description="No Record Found"
              btnText="Reset Data"
              btnBackground="tw-bg-isPrimary"
              showBtn={false}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CustomersUsersTable;
