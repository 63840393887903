import React, { useEffect, useState } from 'react';
import { CustomerProfile } from '../../components';
import { CustomerProfileIProps } from '../../components/customerProfile';
import { AppContainer } from '../../atoms';
import { Link, useNavigate } from 'react-router-dom';
import { routesPath } from '../../utils';
const { EVENTCASHREQUESTSETTINGS } = routesPath;

const agentStats = [
  {
    id: 1,
    value: '1,500,000',
    title: 'Total Requests Value',
  },
  {
    id: 2,
    value: '300,000',
    title: 'Total Commission Paid',
  },
  {
    id: 3,
    value: '50',
    title: 'Total Requests completed',
  },
  {
    id: 4,
    value: '12',
    title: 'Total Requests Failed',
  },
];
const AgentProfile = () => {
  const [customerDetails, setCustomerDetails] = useState<CustomerProfileIProps[]>([]);
  const [appActivity, setAppActivity] = useState<CustomerProfileIProps[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    let customerDetailsResult: CustomerProfileIProps[];
    let appActivityResult: CustomerProfileIProps[];
    customerDetailsResult = [
      {
        id: 1,
        helper: 'Full Name',
        text: 'Wade Warren Chukwuma',
      },
      {
        id: 2,
        helper: 'Email',
        text: 'wadewarren@gmail.com',
      },
      {
        id: 3,
        helper: 'Phone Number',
        text: '08122345678',
      },
      {
        id: 4,
        helper: 'BVN',
        text: '22349688790',
      },
      {
        id: 5,
        helper: 'Date of birth',
        text: 'Jul 19 1995',
      },
      {
        id: 6,
        helper: 'Profile Level',
        text: 'Level 1',
      },
      {
        id: 7,
        helper: 'Success Rate',
        text: '50%',
      },
      {
        id: 8,
        helper: 'Default Address',
        text: '3995 Valley View Ln undefined Red Oak, Nigeria',
      },
      {
        id: 9,
        helper: 'Account Number',
        text: '77123456781',
      },
    ];
    appActivityResult = [
      {
        id: 1,
        helper: 'Date Registered',
        text: 'Jul 12, 2021',
      },
      {
        id: 2,
        helper: 'Last Login',
        text: 'Jan 8, 2022',
      },
      {
        id: 3,
        helper: 'Last Device Login',
        text: 'Iphone 14 pro max',
      },
      {
        id: 4,
        helper: 'Profile Status',
        text: 'Deactivated',
      },
      {
        id: 5,
        helper: 'Deactivation Comment',
        text: 'EFCC Order',
      },
    ];

    setCustomerDetails(customerDetailsResult);
    setAppActivity(appActivityResult);
  }, [customerDetails]);

  return (
    <AppContainer
      goBack={() => navigate(EVENTCASHREQUESTSETTINGS)}
      navTitle={`Back`}
      navHelper={
        <div className="tw-flex tw-gap-x-3 tw-items-center">
          <span className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-[.6rem] md:tw-text-[1rem]">
            Agent Profile
          </span>
        </div>
      }>
      <div className="lg:tw-flex tw-gap-y-4 tw-justify-between tw-gap-x-14 tw-px-8 tw-pt-7">
        <div className=" tw-basis-[90%] tw-mb-16">
          <CustomerProfile data={customerDetails} title="Agent`s Details" />
          <CustomerProfile data={appActivity} title="App Activity" />
        </div>
        <div className="">
          <h2 className="tw-text-gray-700 tw-text-lg tw-font-medium">Stats</h2>
          {agentStats.map(item => (
            <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex">
              <div
                className={`${
                  item.title === 'Total Requests Failed' ? 'tw-text-red-600' : 'tw-text-emerald-600'
                } tw-text-[40px] tw-font-normal`}>
                {item.value}
              </div>
              <div className="tw-text-zinc-600 tw-text-xs tw-font-normal">{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </AppContainer>
  );
};

export default AgentProfile;
