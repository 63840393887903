import React, { useEffect, useState } from 'react';
import { Dictionary } from '../../../types';
import { ZojaTable } from '../..';
import { TD, TR } from '../../tailwind/table/tableElements';
import { dateTimeFormat3 } from '../../../utils/dateFormat';
import { FaChevronRight } from 'react-icons/fa';
import { useAppDispatch } from '../../../redux/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../../utils';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import { GoDotFill } from 'react-icons/go';
import moment from 'moment';

const { KYCCUSTOMERINFORMATION } = routesPath;

interface IProps {
  data: Dictionary[];
  sorting?: any;
  order?: string;
  type?: string;
  columns?: any;
  setOpenMore: (item: any) => void;
  dropdownList?: any;
  setSelectedItem?: any;
  handleMoreIconOptions: (item: any) => void;
  setSelectedTableItem?: any;
}

const FreezeTable = ({
  data,
  columns,
  sorting,
  order,
  setOpenMore,
  dropdownList,
  handleMoreIconOptions,
  setSelectedItem,
  setSelectedTableItem,
  type,
}: IProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnSelect = (item: any) => {
    setOpenMore(!true);
    setSelectedTableItem(item);
  };

  return (
    <div>
      <ZojaTable column={columns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item?.id}>
            <TD>{item.id ?? 'N/A'}</TD>
            <TD>{item?.fullName ?? 'N/A'}</TD>
            <TD>{item?.accountNumber ?? 'N/A'}</TD>
            <TD
              value={
                item?.kycLevel === 'level zero'
                  ? 'Level 0'
                  : item?.kycLevel === 'level one'
                  ? 'Level 1'
                  : item?.kycLevel === 'level two'
                  ? 'Level 2'
                  : item?.kycLevel === 'level three'
                  ? 'Level 3'
                  : 'Agency'
              }></TD>
            <TD>{item?.reasonFor}</TD>
            {type === 'rejected' ? (
              <TD extraClass={`tw-flex tw-gap-x-2 tw-items-center`}>{item?.rejectionReason ?? 'N/A'}</TD>
            ) : (
              type !== 'initiator' && (
                <TD
                  extraClass={`tw-flex tw-items-center ${
                    item?.status === 'approved'
                      ? 'tw-text-[#2DBD9B]'
                      : item?.status === 'pending' || item?.status === 'Awaiting Approval'
                      ? 'tw-text-[#FFAF56]'
                      : item?.status === 'rejected'
                      ? 'tw-text-[#FD0000CC]'
                      : 'tw-text-[tw-text-isPrimary]'
                  }`}>
                  <GoDotFill
                    color={
                      item?.status === 'approved'
                        ? '#2DBD9B'
                        : item?.status === 'pending' || item?.status === 'Awaiting Approval'
                        ? '#FFAF56'
                        : item?.status === 'rejected'
                        ? '#FD0000CC'
                        : ''
                    }
                  />
                  {item?.status ?? 'N/A'}
                </TD>
              )
            )}
            <TD value={moment(item?.createdAt).format('DD MMMM, YYYY - hh:mm a') ?? 'N/A'}></TD>
            {type !== 'rejected' && (
              <TD
                onClick={() => {
                  // tab !== 'rejected_verifications' && handleRowClicked(item.detailsId);
                }}>
                <div className="tw-ml-4" onClick={() => handleOnSelect(item)}>
                  <TDMORE
                    handleClose={() => setOpenMore(false)}
                    dropdownList={dropdownList}
                    onActionClick={item => handleMoreIconOptions(item)}
                    setSelectedItem={setSelectedItem}
                  />
                </div>
              </TD>
            )}
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
};

export default FreezeTable;
