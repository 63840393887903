import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
};

const cashRequestSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getCashRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getCashRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getCashRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getCashRequest = createAsyncThunk('getAllCashRequest', async (payload: Dictionary, { dispatch }) => {
  const { min_amount, max_amount, reference, sort_by, status, order_by, page, per_page } = payload;
  const url = `/admin/cash-requests?reference=${reference}&sort_by=${sort_by}&min_amount=${min_amount}&max_amount=${max_amount}&status=${status}&order_by=${order_by}&per_page=${per_page}&page=${page}`;

  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const cashRequestReset = cashRequestSlice.actions.reset;
export const cashRequestSliceReducer = cashRequestSlice.reducer;
