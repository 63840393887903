import React from 'react';
import { Oval } from 'react-loader-spinner';
import { cn } from '../../../../lib/utils';
interface ButtonProps {
  id?: string;
  name?: string;
  text: string | any;
  onClick?: (e: any) => void;
  extraClass?: string;
  labelExtras?: string;
  marginTop?: string;
  disabled?: boolean;
  type?: 'button' | 'submit';
  onBlur?: (e: any) => void;
  error?: any;
  icon?: any;
  loading?: boolean;
  loadingText?: string;
  loadingClass?: string;
  disabledExtraClass?: string;
}

const ZojaButton = ({
  extraClass,
  text,
  onClick,
  marginTop,
  name,
  id,
  disabled,
  icon,
  type = 'submit',
  loading,
  loadingText,
  loadingClass,
  disabledExtraClass,
}: ButtonProps) => {
  return (
    <div className={marginTop}>
      <button
        id={id}
        name={name}
        disabled={disabled}
        className={`${
          icon && 'tw-flex tw-items-center tw-justify-center tw-gap-x-2'
        } tw-p-1.5 tw-rounded-[0.4rem] tw-text-md tw-transition-all tw-duration-500 tw-ease-out disabled:tw-cursor-not-allowed disabled:tw-bg-isPrimary/70 ${extraClass} ${disabledExtraClass}`}
        onClick={onClick}
        type={type}>
        {loading ? (
          <div className={cn('tw-flex tw-items-center tw-gap-1', loadingClass)}>
            <Oval
              height="20"
              width="20"
              color="#ffffff"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
            {loadingText && loadingText}
          </div>
        ) : (
          <>
            {icon} {text}
          </>
        )}
      </button>
    </div>
  );
};

export default ZojaButton;
