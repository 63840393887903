const dateToTimestamp = (dateString: string): number => {
    return new Date(dateString).setHours(0, 0, 0, 0);
  };
  
  export const daysDifference = (startDate: string, endDate: string): number => {
    const millisecondsPerDay: number = 1000 * 60 * 60 * 24;
    const startTimestamp: number = dateToTimestamp(startDate);
    const endTimestamp: number = dateToTimestamp(endDate);
    const differenceInMilliseconds: number = endTimestamp - startTimestamp;
    return Math.round(differenceInMilliseconds / millisecondsPerDay);
  };