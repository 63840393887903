import React, { ReactElement } from 'react';

interface InputProps {
  id?: string;
  icon?: ReactElement;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  extraClass?: string;
  labelExtraClass?: string;
  style?: React.CSSProperties;
  name?: string;
  marginTop?: string;
  onBlur?: (e: any) => void;
  error?: any;
  touched?: any;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  resize?: 'resize' | 'none' | 'y' | 'x';
  disabled?: boolean;
  required?: boolean;
}

const ZojaTextArea = ({
  id,
  name = '',
  icon,
  marginTop,
  label,
  value,
  onChange,
  onBlur,
  error,
  touched = {},
  placeholder,
  extraClass,
  iconPosition,
  iconColor,
  labelExtraClass,
  resize = 'resize',
  disabled,
  required = false,
}: InputProps) => {
  return (
    <div className={`${marginTop}`}>
      {label && (
        <label htmlFor={name} className={`tw-text-[12px] tw-mb-1 tw-block ${labelExtraClass}`}>
          {label}
        </label>
      )}
      <div className="relative">
        <textarea
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          className={`${extraClass} tw-border tw-border-isGreyVariantTwo tw-w-full tw-block tw-bg-[#FFFFFF08] tw-p-1.5 tw-px-2 tw-rounded-[4px] tw-placeholder:text-xs tw-focus:outline-none tw-placeholder:text-[#FFFFFF40] tw-appearance-none ${
            touched[name] && error ? 'tw-border-red-400' : ''
          } resize-none`}
          placeholder={placeholder}
          disabled={disabled}
          required={required}
        />
        {touched[name] && error && <span className="tw-text-xs tw-text-red-400">{error}</span>}
        {icon && (
          <span
            className={`tw-text-${iconColor} tw-absolute tw-top-3 ${
              iconPosition === 'left' ? 'tw-left-2.5' : 'tw-right-2.5'
            }`}>
            {' '}
            {icon}{' '}
          </span>
        )}
      </div>
    </div>
  );
};

export default ZojaTextArea;
