import React, { ReactElement } from 'react';
import { FaChevronDown } from 'react-icons/fa';
interface SelectProps {
  id?: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  extraClass?: string;
  labelExtraClass?: string;
  setSelectedOption: (value: any) => void;
  showArrown?: boolean;
  centralPlacedArrow?: boolean;
  arrowDownClass?: string;
  centralPlacedArrowClass?: string;
  marginTop?: string;
  label?: string;
  disabled?: boolean;
  options: Array<{ label: string; value: string | number }>;
  icon?: ReactElement;
  onBlur?: (e: any) => void;
  error?: any;
  touched?: any;
  loading?: boolean;
}

const ZojaSelect = ({
  id,
  name = '',
  value,
  options,
  touched = {},
  disabled,
  showArrown = false,
  centralPlacedArrow = false,
  arrowDownClass,
  centralPlacedArrowClass,
  label,
  error,
  marginTop,
  extraClass,
  onChange,
  icon,
  labelExtraClass,
  setSelectedOption,
  loading = false,
}: SelectProps) => {
  return (
    <div className={marginTop}>
      {label && (
        <label htmlFor="" className={`tw-w-full tw-text-md tw-mb-1 ${labelExtraClass}`}>
          {label}
        </label>
      )}
      <div className="tw-relative tw-w-full">
        {loading ? (
          <div
            className={`${extraClass} tw-border tw-text-gray-900 tw-text-sm tw-rounded-lg tw-cursor-pointer tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-p-2.5
                focus:tw-outline-none tw-w-full tw-appearance-none tw-flex tw-items-center ${
                  touched[name] && error ? 'tw-border-red-400' : ''
                } ${disabled && 'tw-bg-gray-300'}`}>
            <h5 className="tw-font-light tw-text-sm tw-p-0 tw-m-0">Loading...</h5>
          </div>
        ) : (
          <select
            disabled={disabled}
            name={name}
            onChange={e => setSelectedOption(e.target.value)}
            className={`${extraClass} tw-border tw-text-gray-900 tw-text-sm tw-rounded-lg tw-cursor-pointer tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-block tw-p-2.5
                focus:tw-outline-none tw-w-full tw-appearance-none ${
                  touched[name] && error ? 'tw-border-red-400' : ''
                } ${disabled && 'tw-bg-gray-300'}`}>
            {options?.map((option, index) => (
              <option
                key={index}
                value={option.value}
                className=""
                selected={value?.toString()?.toLowerCase() === option?.value?.toString().toLowerCase()}>
                {option.label}
              </option>
            ))}
          </select>
        )}

        {showArrown && !loading && (
          <span className={`${arrowDownClass} tw-absolute tw-text-isGreyVariantFour tw-text-xs tw-right-4`}>
            <FaChevronDown />
          </span>
        )}
        {centralPlacedArrow && !loading && (
          <div className="tw-absolute tw-right-4 tw-top-0 tw-flex tw-items-center tw-h-full tw-cursor-pointer">
            <span className={`${centralPlacedArrowClass} tw-text-isGreyVariantFour tw-text-xs`}>
              <FaChevronDown />
            </span>
          </div>
        )}
      </div>
      {touched[name] && error && <span className="tw-text-xs tw-text-red-400">{error}</span>}
    </div>
  );
};

export default ZojaSelect;
