import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { useEffect, useState } from "react";

interface CustomEditorProps {
    editorState: any,
    setEditorState: (event: EditorState) => void
    handleClick?: () => void,
    error?: string,
    required?: boolean,
    minLength?: number,
    touched?: boolean
}

// Get the plain text message from editor
const getPlainTextMessage = (editorState: any) => editorState.getCurrentContent().getPlainText()

// Get the raw data from editor (JSON)
const getRawData = (editorState: any) => convertToRaw(editorState.getCurrentContent())

export default function CustomEditor({ editorState, setEditorState, handleClick, error, minLength, required, touched}: CustomEditorProps) {
    // const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());

    // const content  = {
    //     "blocks": [
    //         {
    //             "key": "637gr",
    //             "text": "Initialized from content state. Thank",
    //             "type": "unstyled",
    //             "depth": 0,
    //             "inlineStyleRanges": [],
    //             "entityRanges": [],
    //             "data": {}
    //         }
    //     ],
    //     "entityMap": {}
    // }

    const handleEditorStateChange = async (newEditorState: any) => {
        await setEditorState(newEditorState);
        const data = convertToRaw(editorState.getCurrentContent());
        // console.log(data);
  };

    const toolbarOptions = {
        options: ['inline', 'list', 'textAlign'],
        inline: {
          options: ['bold', 'italic', 'underline'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
    };

    // set initial editor state
    // useEffect(() => {
    //     setEditorState(EditorState.createWithContent(convertFromRaw(content)));
    // },[])
    // console.log(error)

    return (
        <>
        <div className='tw-flex tw-flex-col-reverse tw-mt-4 tw-border tw-rounded-md'>
            <Editor
                editorState={editorState}
                onEditorStateChange={(event) => handleEditorStateChange(event)}
                editorStyle={{ height: '250px', paddingLeft: '20px', borderRadius: "10px" }}
                toolbar={toolbarOptions}
                // toolbarClassName="toolbarClassName"
                // wrapperClassName="wrapperClassName"
                // editorClassName="editorClassName"
                toolbarStyle={{ marginleft: '2px', borderradius: '5px', display: 'flex'  }}
                wrapperStyle={{ display: 'flex', flexDirection: 'column-reverse' }}
                placeholder="Enter message..."
                editorClassName={`${
                    error && touched && "tw-border tw-border-red-400"}`}
            />
            {/* <Editor /> */}
        </div>
          { error && touched && <span className="tw-text-red-400 tw-text-sm">{error}</span>}
        </>

    )
}
