import styled from 'styled-components';
import { spacing } from '../../utils';

export const DepartmentContainer = styled.div`
  margin-top: ${spacing.xlarge};
`;

export const DepartmentTop = styled.div`
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  margin-bottom: ${spacing.large};
`;
