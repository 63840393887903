import React, { ReactElement } from 'react';
import { Dictionary } from '../../../types';
import { TBODY, TH, THEAD, TR } from './tableElements';
interface TableProps {
  column: Dictionary[];
  data?: Dictionary[];
  children: ReactElement | ReactElement[];
  hasActionButton?: boolean;
  filteredTable?: boolean;
  visibleColumns?: number[];
}

const ReportTable = ({
  column,
  data,
  children,
  filteredTable = false,
  visibleColumns,
  hasActionButton = false,
}: TableProps) => {
  return (
    <div className="tw-relative hide_scrollbar">
      <table className="tw-table-auto tw-w-full tw-text-[14px] tw-text-left tw-text-zinc-600 tw-border-separate tw-border-spacing-y-0">
        <THEAD>
          <TR>
            <>
              {filteredTable === true &&
                column
                  .filter(column => visibleColumns?.includes(column.id))
                  .map(column => <TH key={column.id} value={column.title}></TH>)}
              {filteredTable === false && column.map(column => <TH key={column.id} value={column.title} />)}
              {hasActionButton && <TH />}
            </>
          </TR>
        </THEAD>
        <TBODY>{children}</TBODY>
      </table>
      {/* <hr className="tw-w-[100vw]" /> */}
    </div>
  );
};

export default ReportTable;
