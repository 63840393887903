import { useNavigate, useParams } from 'react-router-dom';
import { AppContainer } from '../../../atoms';
import { colors, images } from '../../../utils';
import { ZojaButton, ZojaSelect } from '../../../components/tailwind';
import {
  createSendRestrictionForApprovalRequest,
  createRestrictionInitiationRequest,
  getBanReasonsRequest,
  getBanRequestByIdRequest,
  createRestrictionInitiationReset,
} from '../../../redux/slice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux-hooks';
import { Dictionary } from '../../../types';
import { FilePicker, RejectBanRequestModal, ShowApproveBanSuccessModal, TextArea } from '../../../components';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import { MoveLeft } from 'lucide-react';

const UnrestrictAccount = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const banRequestState = useAppSelector(state => state.getBanRequestById);
  const { status: banRequestStatus } = banRequestState;

  const banReasonsState = useAppSelector(state => state.getBanReasons);
  const { status: banReasonsStatus } = banReasonsState;

  const createRestrictionInitiationState = useAppSelector(state => state.createRestrictionInitiation);
  const { status: createRestrictionInitiationStatus } = createRestrictionInitiationState;

  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [banFile, setBanFile] = useState<any>([]);
  const [showUnrestrictModal, setShowUnrestrictModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<Dictionary>();
  const [reasonsForBan, setReasonsForBan] = useState<any>([]);
  const [approveType, setApproveType] = useState<any>('');
  const [selectedOption, setSelectedOption] = useState<any>('');

  useEffect(() => {
    dispatch(getBanRequestByIdRequest({ restrictionId: id }));
  }, []);

  useEffect(() => {
    dispatch(getBanReasonsRequest());
  }, []);

  useEffect(() => {
    setUserData(banRequestState.data);
  }, [banRequestState.data]);

  useEffect(() => {
    if (banReasonsStatus === 'succeeded' && banReasonsState?.data?.length > 0) {
      const updatedList: any[] = [];

      banReasonsState?.data?.forEach((item: Dictionary, index: number) => {
        updatedList.push({ id: index + 1, label: item, value: item });
      });

      setReasonsForBan([
        { id: updatedList.length + 1, label: 'Select Reason for unrestrict', value: '' },
        ...updatedList,
      ]);
    }
  }, [banReasonsState?.data, banReasonsStatus]);

  const handleRejectban = (payload: any) => {
    setApproveType('reject');

    if (payload.reason === 'others') {
      if (payload.description.length < 2) {
        toast.error('Please add a reason');
      } else {
        dispatch(
          createSendRestrictionForApprovalRequest({ id: id, status: 'rejected', description: payload.description }),
        );
      }
    } else {
      dispatch(createSendRestrictionForApprovalRequest({ id: id, status: 'rejected', reason: payload.reason }));
    }
  };

  const handleInitiateUnrestrict = (payload: { furtherReasons: string; reason: any }) => {
    const formData = new FormData();
    formData.append('account_id', userData?.attributes?.account_id);
    formData.append('reason_for', payload.reason);
    formData.append('description_of_reason', payload.furtherReasons);
    formData.append('restriction_type', 'unrestrict');
    // formData.append('proofs', banFile || []);

    banFile.forEach((file: string | Blob, index: any) => {
      formData.append(`proofs[${index}]`, file);
    });

    const formDataObj: any = {};
    for (const [key, value] of formData.entries()) {
      formDataObj[key] = value;
    }

    dispatch(createRestrictionInitiationRequest(formData));
  };

  useEffect(() => {
    if (createRestrictionInitiationStatus === 'succeeded') {
      setShowUnrestrictModal(true);
      dispatch(createRestrictionInitiationReset());
    }
  }, [createRestrictionInitiationStatus]);

  const onChangeFilePicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const selectedFiles = Array.from(e.target.files);
      setBanFile(selectedFiles);
    }
  };

  const resetFilePicker = () => {
    setBanFile(null);
  };

  return (
    <AppContainer
      navTitle={
        <div className="tw-flex tw-gap- tw-items-center">
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div
              onClick={() => navigate(-1)}
              className="tw-flex tw-gap-1 tw-text-[#5E6366] tw-cursor-pointer tw-no-underline tw-text-[1rem] tw-font-normal hover:tw-text-gray-500">
              <span className="tw-hidden md:tw-block">
                {' '}
                <MoveLeft />
              </span>
              <span className="tw-text-[#5E6366] tw-no-underline tw-text-[1rem] tw-font-normal">
                Account Restriction
              </span>
            </div>
            <span className="tw-border-gray-600 tw-font-bold tw-block tw-border tw-h-6"></span>
          </div>
          <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">REMOVE RESTRICTION</span>
        </div>
      }>
      {banRequestStatus === 'loading' ? (
        <div className="tw-h-screen tw-mx-auto tw-flex tw-justify-center tw-items-center">
          <Oval
            height="80"
            width="80"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="tw-max-w-[701px]">
          <>
            <div className="tw-flex">
              <h2 className="tw-mt-14 tw-text-xl tw-text-[#323348] tw-font-medium tw-mb-7">
                Remove Account Restriction
              </h2>
            </div>
            <div className="tw-w-[70%]">
              <div className="tw-flex tw-justify-between tw-items-center tw-mr-10">
                <div className=" tw-flex tw-items-center tw-gap-x-3">
                  <img src={images.user} alt="" width={30} height={30} className=" tw-rounded-full" />
                  <p className=" tw-text-isPrimary tw-text-lg m-0">{userData?.attributes?.name}</p>
                </div>
                <p className="m-0 tw-font-semibold tw-text-isPrimary tw-capitalize">
                  KYC: <span className="tw-font-normal">{userData?.attributes?.kyc_level}</span>
                </p>
              </div>
            </div>
            <hr className="tw-mt-[5px] tw-border tw-border-[#BEBEBE] tw-mb-14" />
            <Formik
              initialValues={{
                reason: '',
                furtherReasons: '',
              }}
              enableReinitialize={true}
              // validationSchema={schema}
              onSubmit={async (values, { setSubmitting }) => {
                const { furtherReasons } = values ?? '';
                const payload = {
                  furtherReasons: furtherReasons,
                  reason: selectedOption,
                };
                handleInitiateUnrestrict(payload);
                setSubmitting(false);
              }}>
              {formikProps => {
                const { handleChange, values, handleSubmit } = formikProps;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="tw-grid tw-gap-y-6">
                      <ZojaSelect
                        options={reasonsForBan}
                        setSelectedOption={setSelectedOption}
                        extraClass="!tw-w-full tw-border tw-border-[#BEBEBE] focus:tw-border-[#222B88]"
                        name="reason"
                        showArrown={true}
                        arrowDownClass="tw-top-[40%]"
                      />
                      <TextArea
                        name="furtherReasons"
                        onChange={handleChange}
                        value={values.furtherReasons}
                        label="Further Reasons"
                        placeholder="Start Typing"
                        borderColor={colors.greyVariantTwelve}
                        marginBottom={'0px'}
                      />
                      <FilePicker
                        value={banFile}
                        onChange={onChangeFilePicker}
                        extraClass="tw-mb-6"
                        resetValue={resetFilePicker}
                      />
                      <div className="tw-w-max">
                        <ZojaButton
                          type="submit"
                          text={'Initiate Restriction Removal'}
                          loading={createRestrictionInitiationStatus === 'loading'}
                          disabled={selectedOption.length < 1 || createRestrictionInitiationStatus === 'loading'}
                          loadingText="Initiate Unrestrict"
                          extraClass="tw-bg-isPrimary tw-text-white tw-w-full tw-py-[14px] tw-px-10 disabled:!tw-text-[#B6B6B6] disabled:!tw-bg-[#E7E7E7] tw-text-sm tw-rounded-[4px]"
                          loadingClass="tw-justify-center"
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>

          <RejectBanRequestModal
            show={showRejectModal}
            handleRejectBanRequest={handleRejectban}
            handleClose={() => setShowRejectModal(false)}
            reasonsForBan={reasonsForBan}
            title="Unrestrict"
            loadingReasons={banReasonsStatus === 'loading'}
            loading={createRestrictionInitiationStatus === 'loading'}
          />

          <ShowApproveBanSuccessModal
            show={showUnrestrictModal}
            handleClose={() => setShowUnrestrictModal(false)}
            title={'Unrestrict Request Sent'}
            description="The request has been sent for review"
            buttoText="Close"
            handleFinish={() => navigate(-1)}
            image={images.downloadSuccessIcon}
          />
        </div>
      )}
    </AppContainer>
  );
};

export default UnrestrictAccount;
