import { useNavigate } from 'react-router-dom';
import { AppContainer, UserRegReport } from '../../atoms';
import {
  getEverySelectedOptionValue,
  getSelectedOptionValue,
  replaceCommasInQueryParam,
  routesPath,
  useDebounce,
} from '../../utils';
import { DownloadSuccessModal } from '../../components';
import { userRegReportColumn } from './dummyData';
import { Dictionary } from '../../types';
import { useEffect, useRef, useState } from 'react';
import {
  saveReportRequest,
  searchUserAccountRequest,
  saveReportReset,
  getAllUserRegReportRequest,
  downloadUserRegReportRequest,
  setUserRegFilterOptions,
  resetUserRegFilterOptions,
  downloadUserRegReportReset,
  getLocationsRequest,
} from '../../redux/slice';
import {
  accountVerification,
  activityType,
  channel,
  chargebacks,
  dateOptions,
  deviceType,
  figureRangeOptions,
  genderOptions,
  kycLevelOptions,
  manageColumnOptions,
  methodOptions,
  reconciliationStatus,
  refundStatus,
  securityEvents,
  sessionDuration,
  settlementDestination,
  settlementMethod,
  settlementType,
  sortByOtions,
  sourceOfReg,
  timeStamp,
  transactionStatusOptions,
  transactionTypeOptions,
  userFeedback,
  userLevelReg,
  userStatusOptions,
} from './filterOptions';
import { useAppSelector, useAppDispatch } from '../../redux/redux-hooks';
import SaveReportModal from '../../components/modalTypes/SaveReportModal';
const { REPORT } = routesPath;

export interface userRegistrationReportColumn {
  id: number;
  title: string;
  ref: string;
}

export interface userRegistrationReportReportRow {
  id: number;
  sn: number;
  date: string;
  location: string;
  phoneNumber: number;
  accountNumber: number;
  accountName: string;
  deviceType: string;
  userStatus: string;
  agency: string;
  sourceOfReg: string;
  gender: string;
  age: string;
  userLevel: string;
  customerChannel: string;
  accountVerification: string;
  userFeedback: string;
}

const UserRegistrationReport = () => {
  // REDUX STATES

  const searchedUserAccount = useAppSelector(state => state.searchUserAccount);
  const { status: saveReportStatus } = useAppSelector(state => state.saveReportSlice);
  const allUserRegReportState = useAppSelector(state => state.getAllUserRegReport);
  const getLocationsState = useAppSelector(state => state.getLocations);
  const { status: getAllUserRegReportStatus, data: getAllUserRegReportData } = allUserRegReportState;
  const userRegFilterOptions = useAppSelector(state => state.userRegFilterOptions);
  const { status: downloadUserRegReportStatus } = useAppSelector(state => state.downloadUserRegReport);
  const { status: getLocationsStatus, data: getLocationsData } = getLocationsState;

  // ROUTER HOOKS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REFS
  const successModalRef = useRef<any>(null);
  const saveReportModalRef = useRef<any>(null);

  //   REACT STATES
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [search, setSearch] = useState<any>();
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(allUserRegReportState?.data?.meta?.current_page || 1);
  const [perPage, setPerPage] = useState();
  const [userRegReportData, setUserRegReportData] = useState<userRegistrationReportReportRow[]>([]);
  const [selectedPageOption, setSelectedPageOption] = useState<'10' | '20' | '30'>();
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [showSuccessSavedModal, setShowSuccessSavedModal] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportQueries, setReportQueries] = useState('');
  const [searchLocationsResults, setSearchLocationsResults] = useState<any>([]);

  //  DEBOUNCE HOOK
  const debouncedSearch = useDebounce(search, 300);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    ageRange: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
    transactionDate: false,
    transactionAmount: false,
    transactionType: false,
    transactionStatus: false,
    feesRange: false,
    paymentMethod: false,
    channel: false,
    refundStatus: false,
    reconciliationStatus: false,
    timeStamp: false,
    settlementType: false,
    chargebacks: false,
    settlementDestination: false,
    settlementMethod: false,
    settlementBatchID: false,
    bankStatRef: false,
    bankAccount: false,
    deviceType: false,
    sourceOfReg: false,
    userStatus: false,
    userLevel: false,
    userFeedback: false,
    accountVerification: false,
    activityType: false,
    sessionDuration: false,
    securityEvents: false,
    transactionFee: false,
    transactionCode: false,
  });

  const [showOptionsSmallScreen, setShowOptionsSmallScreen] = useState(false);

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState({
    sortBy: sortByOtions.aphabetically,
    orderBy: sortByOtions.orderBy,
    gender: genderOptions,
    accountInfo: [] as any[],
    transactionDate: dateOptions,
    dateOnboarded: dateOptions,
    lastLogin: dateOptions,
    transactionType: transactionTypeOptions,
    transactionStatus: transactionStatusOptions,
    location: [] as any[],
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    feesRange: figureRangeOptions,
    reportFilterType: {
      title: 'Filter Type',
      id: 1,
      value: 'initial',
    },
    manageColumn: manageColumnOptions.userActivities,
    paymentMethod: methodOptions,
    channel: channel,
    refundStatus: refundStatus,
    reconciliationStatus: reconciliationStatus,
    timeStamp: timeStamp,
    settlementType: settlementType,
    settlementMethod: settlementMethod,
    chargebacks: chargebacks,
    settlementDestination: settlementDestination,
    deviceType: deviceType,
    sourceOfReg: sourceOfReg,
    userStatus: userStatusOptions,
    userLevel: userLevelReg,
    userFeedback: userFeedback,
    accountVerification: accountVerification,
    activityType: activityType,
    sessionDuration: sessionDuration,
    securityEvents: securityEvents,
  });

  // CHECKS IF THE DATE SELECTED IS CUSTOM
  const dateIsCustom = filterOption.transactionDate?.some(
    (item: { value: string; isChecked: any }) => item.value === 'custom' && item.isChecked,
  );

  const initialValues: Dictionary = {
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [manageColumn, setManageColumn] = useState(manageColumnOptions.userActivities);

  const [visibleColumns, setVisibleColumns] = useState(userRegReportColumn.map(column => column.id));
  const [filterOptions, setFilterOptions] = useState(initialValues);

  const handleSubmitFilter = () => {
    dispatch(getAllUserRegReportRequest(userRegFilterOptions));
    setShowOptions({ ...showOptions, sortBy: false });
  };

  // close filter on state change
  useEffect(() => {
    setShowOptions({ ...showOptions, sortBy: false });
  }, [getAllUserRegReportStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    dispatch(downloadUserRegReportRequest({ ...userRegFilterOptions, exp: type, per_page: selectedPageOption || 10 }));
    setShowOptions({ ...showOptions, exportOptions: false });
  };

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setUserRegFilterOptions({ ...userRegFilterOptions, [name]: value }));
  };

  // this processes strings to be used as saved reports
  useEffect(() => {
    const urlLink = getAllUserRegReportData?.links?.last;

    // Find the index of the first "?" and the last "&"
    const firstQuestionMarkIndex = urlLink?.indexOf('?');
    const lastAmpersandIndex = urlLink?.lastIndexOf('&');

    // Extract the substring between the first "?" and the last "&"
    const extractedParams =
      firstQuestionMarkIndex !== -1 ? urlLink?.substring(firstQuestionMarkIndex + 1, lastAmpersandIndex) : '';

    if (extractedParams?.startsWith('https://')) {
      // Set expectedParams to an empty string if it starts with "https://"
      setReportQueries('');
    } else {
      setReportQueries(replaceCommasInQueryParam(extractedParams));
    }
  }, [downloadUserRegReportStatus, getAllUserRegReportData?.links?.last, reportQueries]);

  // THIS EFFECT UPDATES THE FILTER OPTIONS FOR UserReg
  useEffect(() => {
    dispatch(
      setUserRegFilterOptions({
        ...userRegFilterOptions,
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
        per_page: selectedPageOption,
        page: currentPage,
        start_date: filterOption.dateRange.start_date,
        end_date: filterOption.dateRange.end_date,
        device_type: getEverySelectedOptionValue(filterOption.deviceType),
        source_of_reg: getEverySelectedOptionValue(filterOption.sourceOfReg),
        user_status: getEverySelectedOptionValue(filterOption.userStatus),
        user_level: getEverySelectedOptionValue(filterOption.userLevel),
        user_feedback: getEverySelectedOptionValue(filterOption.userFeedback),
        account_verification_status: getEverySelectedOptionValue(filterOption.accountVerification),
        gender: getEverySelectedOptionValue(filterOption.gender),
        location: getEverySelectedOptionValue(filterOption.location),
      }),
    );
  }, [dispatch, filterOption, selectedPageOption, dateIsCustom, currentPage]);

  // THIS EFFECT CALLS GET USER ENDPOINT WHEN selectedPageOption CHANGES
  useEffect(() => {
    if (selectedPageOption) {
      dispatch(getAllUserRegReportRequest({ ...userRegFilterOptions, per_page: selectedPageOption }));
    }
  }, [selectedPageOption]);

  useEffect(() => {
    if (currentPage) {
      dispatch(getAllUserRegReportRequest({ ...userRegFilterOptions, page: currentPage }));
    }
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(resetUserRegFilterOptions()); // Reset the filter options when the component is unmounted
    };
  }, [dispatch]);

  // THIS EFFECT CALLS SEARCH ENDPOINT WHEN THE DEBOUNCED SEARCH VALUE CHANGES
  useEffect(() => {
    if (search) {
      dispatch(searchUserAccountRequest({ search: debouncedSearch }));
    }
  }, [dispatch, debouncedSearch]);

  useEffect(() => {
    if (downloadUserRegReportStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadUserRegReportStatus]);

  useEffect(() => {
    dispatch(getAllUserRegReportRequest(userRegFilterOptions));
  }, [dispatch, filterOptions]);

  //  // THIS EFFECT CALLS GET LOCATION ENDPOINT
  useEffect(() => {
    dispatch(getLocationsRequest());
  }, []);

  //THIS EFFECT STORES THE LOCATION IN A NEW MODIFIED ARRAY
  useEffect(() => {
    if (getLocationsStatus === 'succeeded') {
      let updatedList: any[] = [];

      getLocationsData?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          name: item.location,
          value: item.location,
          isChecked: false,
        });
      });

      setFilterOption({ ...filterOption, location: updatedList });
      setSearchLocationsResults(updatedList);
    }
  }, [getLocationsData, getLocationsStatus]);

  // THIS EFFECT UPDATES THE UserReg REPORT DATA
  useEffect(() => {
    if (getAllUserRegReportStatus === 'succeeded') {
      let updatedList: userRegistrationReportReportRow[] = [];

      allUserRegReportState?.data?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1 || 0,
          sn: index + 1 || 0,
          date: item?.date || '-',
          phoneNumber: item?.phone_number || '-',
          accountNumber: item?.account_number || '-',
          accountName: item?.account_name || '-',
          location: item?.location || '-',
          deviceType: item?.device_type || '-',
          agency: item?.agency || '-',
          userStatus: item?.user_status || '-',
          sourceOfReg: item?.source_of_registration || '-',
          gender: item?.gender || '-',
          userLevel: item?.kyc_level || '-',
          age: item?.age || '-',
          customerChannel: item?.customer_channel || '-',
          accountVerification: item?.account_verification_status || '-',
          userFeedback: item?.user_feedback || '-',
        });
      });

      const {
        meta: { per_page, total },
      } = allUserRegReportState?.data;
      setTotalItems(total);
      setPerPage(per_page);

      setUserRegReportData(updatedList);
    }
  }, [getAllUserRegReportStatus, allUserRegReportState]);

  // CALLS API WHEN THE APPLY FILTER BUTTON IS CLICKED
  const handleApplyFilter = () => {
    dispatch(getAllUserRegReportRequest(userRegFilterOptions));
  };

  // THIS EFFECT UPDATES THE USERLIST DATA
  useEffect(() => {
    if (searchedUserAccount.status === 'succeeded') {
      let updatedList: any[] = [];

      searchedUserAccount?.data?.users?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          isChecked: false,
          accountNumber: item.account_number,
          name: item.name,
          value: item.name,
          telephone: item.telephone,
        });
      });

      setFilterOption({ ...filterOption, accountInfo: updatedList });
    }
  }, [searchedUserAccount?.data.user, searchedUserAccount?.data?.users, searchedUserAccount.status]);

  const handleShowSaveReport = () => {
    setShowSaveReportModal(true);
    setShowSuccessDownloadModal(false);
  };

  // SAVES REPORT WHEN BUTTON IS CLICKED
  const handleReportSave = () => {
    const payload = { query: reportQueries, name: reportName, type: 'userReg' };
    dispatch(saveReportRequest(payload));
  };

  //SHOWS SAVE REPORT MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSaveReportModal(false);
    }
  }, [saveReportStatus]);

  //SHOWS SAVED SUCCESS MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSuccessSavedModal(true);
    }
  }, [saveReportStatus]);

  // input changer handler for saved report
  const handleSavedReportInputChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setReportName(value);
  };

  // FUNCTION TO CHANGE PAGE
  const onChangePagination = (selectedPage: any) => {
    setCurrentPage(selectedPage);
    let currentPage = selectedPage;

    let filterBy = { ...userRegFilterOptions, page: currentPage };
    dispatch(getAllUserRegReportRequest(filterBy));
  };

  // THIS FUNCTION SEARCH LOCALLY FOR ITEMS
  const handleLocationSearch = (e: { target: { value: any } }) => {
    const query = e.target.value;
    // setSearchQuery(query);
    const filteredResults = filterOption.location.filter((item: { name: string }) =>
      item.name.toLowerCase().includes(query.toLowerCase()),
    );
    setSearchLocationsResults(filteredResults);
  };

  return (
    <AppContainer
      navTitle="Reports"
      goBack={() => navigate(REPORT)}
      navHelper={
        <>
          <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">Reports</h4>
        </>
      }>
      <div>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            setShowSuccessDownloadModal(false);
            dispatch(downloadUserRegReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          handleShowSaveReport={() => {
            handleShowSaveReport();
            dispatch(downloadUserRegReportReset());
          }}
          content="Your document has been downloaded to your device"
          type="saveDownloadModal"
        />
        <DownloadSuccessModal
          show={showSuccessSavedModal}
          handleClose={() => {
            setShowSuccessSavedModal(false);
            dispatch(saveReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Report Saved"
          content="Your report has been saved successfully"
        />
        <SaveReportModal
          show={showSaveReportModal}
          handleClose={() => setShowSaveReportModal(false)}
          closeOnClickOutside={false}
          contentRef={saveReportModalRef}
          onSave={handleReportSave}
          onHandleChange={handleSavedReportInputChange}
          title="Save your Report"
          value={reportName}
          saveReportStatus={saveReportStatus}
          content="Give your report a name and see it on your report homepage to reprint anytime, and would not be automatically deleted with rest of your history"
        />

        <h1 className="tw-text-gray-800 tw-text-2xl tw-font-medium tw-my-7">User Registration and Growth Report</h1>
        <div className="">
          <UserRegReport
            onSubmitSort={() => handleSubmitFilter()}
            onApplyFilter={() => handleApplyFilter()}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            column={userRegReportColumn}
            tableData={userRegReportData}
            onExport={handleExportFile}
            manageColumnOptions={manageColumnOptions}
            filterOption={filterOption}
            setManageColumn={setManageColumn}
            manageColumn={manageColumn}
            setFilterOption={setFilterOption}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            showManageColumn={showManageColumn}
            setShowManageColumn={setShowManageColumn}
            setSearch={setSearch}
            searchedUsers={searchedUserAccount.data.users}
            handleInputChange={handleInputChange}
            setSelectedPageOption={setSelectedPageOption}
            itemsPerPage={perPage}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            transactionState={allUserRegReportState}
            visibleColumns={visibleColumns}
            transactionReportStatus={getAllUserRegReportStatus}
            setVisibleColumns={setVisibleColumns}
            downloadStatus={downloadUserRegReportStatus}
            onChangePagination={onChangePagination}
            showOptionsSmallScreen={showOptionsSmallScreen}
            userRegFilterOptions={userRegFilterOptions}
            handleClientSideSearch={handleLocationSearch}
            locations={searchLocationsResults}
            setShowOptionsSmallScreen={setShowOptionsSmallScreen}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default UserRegistrationReport;
