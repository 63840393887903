import moment from 'moment';
import { useEffect, useState } from 'react';
import { ZojaButton, ZojaModal } from '../../components/tailwind';
import { cn } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { initiateSinglePaymentRequest, initiateSinglePaymentReset, reviewSinglePaymentUploadRequest, reviewSinglePaymentUploadReset } from '../../redux/slice';
import { approveSinglePaymentRequest, approveSinglePaymentReset } from '../../redux/slice/approveSinglePaymentSlice';
import { Dictionary } from '../../types';
import { currencyFormat, icons, images } from '../../utils';
import RejectSinglePayment from './RejectSinglePayment';

interface PaymentDetailsProps {
    show: boolean,
    action_type?: string,
    tab?: string,
    handleClose: () => void,
    contentRef?: any,
    allowed_permissions: any,
    payment_details: Dictionary,
    setShowPaymentDetails?: (arg: any) => void,
    setIsInititiatedPayment?: (arg: any) => void,
    resetForm?: () => void
}


export default function PaymentDetails({ show, handleClose, setShowPaymentDetails, allowed_permissions, payment_details, setIsInititiatedPayment, resetForm, action_type, tab }: PaymentDetailsProps) {
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showReviewSuccessModal, setShowReviewSuccessModal] = useState(false)
    const [showRejectPaymentModal, setShowRejectPaymentModal] = useState(false)
    const [showApproveSuccessModal, setShowApproveSuccessModal] = useState(false)
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false)
    const loginState = useAppSelector(state => state.login);
    let CURRENT_USER_PERMISSION = loginState?.data?.user?.permissions?.map((d: Dictionary) => d.name)

    const initiateSinglePaymentState = useAppSelector((state) => state.initiateSinglePayment)

    const dispatch = useAppDispatch()

    const handleIntitiatePayment = () => {
        dispatch(initiateSinglePaymentRequest(payment_details))
    }

    const reviewSinglePaymentUploadState = useAppSelector((state) => state.reviewSinglePaymentUpload)
    const { status: reviewSinglePaymentUploadStatus } = reviewSinglePaymentUploadState

    const approveSinglePaymentState = useAppSelector((state) => state.approveSinglePayment)
    const { status: approveSinglePaymentStatus } = approveSinglePaymentState

    const handleApprove = () => {
        // console.log(values, tab)
        tab === 'Pending Reviews' && dispatch(reviewSinglePaymentUploadRequest({ transactionId: payment_details.id, data: { status: 'APPROVED' } }))
        tab === 'Pending Approval' && dispatch(approveSinglePaymentRequest({ transactionId: payment_details.id, data: { status: 'APPROVED' } }))
    }

    useEffect(() => {
        if (
            reviewSinglePaymentUploadStatus === "succeeded"
            || reviewSinglePaymentUploadStatus === "failed"
            || approveSinglePaymentStatus === "succeeded"
            || approveSinglePaymentStatus === "failed"
        ) {
            handleClose()
        }
    }, [reviewSinglePaymentUploadStatus, approveSinglePaymentStatus])

    useEffect(() => {
        if (initiateSinglePaymentState.status === 'succeeded') {
            setShowSuccessModal(true)
            setIsInititiatedPayment && setIsInititiatedPayment(true)
            handleClose()
            dispatch(initiateSinglePaymentReset())
            resetForm && resetForm()
        }
    }, [initiateSinglePaymentState])

    useEffect(() => {
        if (reviewSinglePaymentUploadState.status === 'succeeded' || approveSinglePaymentState.status === 'succeeded') {
            setShowPaymentDetails && setShowPaymentDetails(false)
            setShowConfirmPaymentModal(false)
        }
    }, [reviewSinglePaymentUploadState, approveSinglePaymentState])

    useEffect(() => {
        if (!show && reviewSinglePaymentUploadState.status === 'succeeded') {
            setShowReviewSuccessModal(true)
            dispatch(reviewSinglePaymentUploadReset())
        }
    }, [show])

    useEffect(() => {
        if (showConfirmPaymentModal && approveSinglePaymentState.status === 'succeeded') {
            setShowApproveSuccessModal(true)
            dispatch(approveSinglePaymentReset())
        }
    }, [showConfirmPaymentModal, approveSinglePaymentState])

    return (
        <>
            <RejectSinglePayment
                show={showRejectPaymentModal}
                handleClose={() => setShowRejectPaymentModal(false)}
                tab={tab}
                selectedPaymentItemId={payment_details?.id}
            />

            <ZojaModal
                show={showConfirmPaymentModal}
                handleClose={() => setShowConfirmPaymentModal(false)}
                height='auto'
                position='center'
                extraClass="md:tw-w-[32rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius='8px'
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-2 tw-p-4'>
                    <h3 className='tw-text-[.95rem] tw-text-[#263238] tw-mb-1'>{tab}</h3>
                    {/* <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to proceed with debiting <span className='tw-font-semibold'>#amount</span> from <span className='tw-font-semibold'>Boluwatife's</span> account</span> */}
                    {tab === 'Pending Reviews' && <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to proceed with approving this review</span>}
                    {tab === 'Pending Approval' && <span className='tw-block tw-mt-5 tw-text-[.9rem] tw-text-[#263238]'>Confirm that you want to approve this payment</span>}
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => setShowConfirmPaymentModal(false)}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-1 tw-flex tw-justify-end tw-gap-x-3'>
                        <ZojaButton
                            onClick={() => setShowConfirmPaymentModal(false)}
                            text="Cancel"
                            // loading
                            extraClass='tw-bg-transparent tw-grid tw-place-content-center tw-text-red-600 tw-font-semibold tw-text-[.75rem] tw-rounded-[4px] tw-px-5 tw-w-full tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-red-600'
                            type='button'
                        />
                        <ZojaButton
                            onClick={handleApprove}
                            text="Yes, Continue"
                            loading={reviewSinglePaymentUploadStatus === 'loading' || approveSinglePaymentStatus === "loading"}
                            loadingText='Yes, Continue'
                            disabled={reviewSinglePaymentUploadStatus === 'loading' || approveSinglePaymentStatus === "loading"}
                            extraClass='tw-bg-isPrimary tw-border tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-[.75rem] tw-rounded-[4px] tw-w-full tw-px-5 tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-isPrimary'
                            type='button'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ZojaModal
                show={showApproveSuccessModal}
                handleClose={() => {
                    setShowApproveSuccessModal(false)
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>{`${payment_details?.type?.charAt(0)?.toUpperCase() + payment_details?.type?.slice(1)} Payment Successful`}</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">Customer account has been {`${payment_details?.type}ed`} with the amount specified </span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            setShowApproveSuccessModal(false)
                        }}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={() => {
                                setShowApproveSuccessModal(false)
                            }}
                            text="Close"
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ZojaModal
                show={showReviewSuccessModal}
                handleClose={() => {
                    setShowReviewSuccessModal(false)
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>{`${payment_details?.type?.charAt(0)?.toUpperCase() + payment_details?.type?.slice(1)} Payment Reviewed Successfully`}</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">Payment has been sent for approval</span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            setShowReviewSuccessModal(false)
                        }}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={() => {
                                setShowReviewSuccessModal(false)
                            }}
                            text="Close"
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ZojaModal
                show={showSuccessModal}
                handleClose={() => {
                    setShowSuccessModal(false)
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>{`${payment_details?.type?.charAt(0)?.toUpperCase() + payment_details?.type?.slice(1)} Payment Initiatied`}</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">Payment is currently being reviewed</span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            setShowSuccessModal(false)
                        }}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={() => {
                                setShowSuccessModal(false)
                            }}
                            text="Close"
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-6 tw-p-4'>
                    <h3 className='tw-text-[.9rem] tw-text-[#263238] tw-mb-0'>Payment Details</h3>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <span className='tw-text-xs'>{payment_details?.date ? moment(payment_details?.date).format('L') : ''}</span>
                    <div className='tw-mt-3'>
                        <span className='tw-text-xs'>{payment_details?.type === 'credit' ? 'Credit To' : 'Debit From'}</span>
                        <div className="tw-flex tw-gap-8">
                            <span className='tw-text-xs tw-font-semibold'>{payment_details?.name}</span>
                            <span className='tw-text-xs'>{payment_details?.account_number}</span>
                        </div>
                    </div>
                    <h2 className={cn(
                        'tw-mt-3 tw-text-[#4EA78A] tw-text-[1.3rem]',
                        payment_details?.type === 'debit' && '!tw-text-red-500'
                    )}>{currencyFormat(Number(payment_details?.amount))}</h2>
                    
                    <div className='tw-mt-3 tw-pb-8'>
                        <span className='tw-text-xs tw-text-[#263238] tw-font-semibold'>Narration</span>
                        <div className="tw-text-xs">
                            {payment_details?.narration ? payment_details?.narration : 'N/A'}
                        </div>

                    </div>

                    {payment_details?.approval_rejection_reason && <div className='tw-mt-3'>
                        <span className='tw-text-xs tw-text-[#263238] tw-font-semibold'>Rejection reason</span>
                        <div className="tw-text-xs">
                           {payment_details?.approval_rejection_reason ? payment_details?.approval_rejection_reason : 'N/A'}
                        </div>
                    </div>}

                    {payment_details?.approval_rejection_message && <div className='tw-mt-3'>
                        <span className='tw-text-xs tw-text-[#263238] tw-font-semibold'>Rejection message</span>
                        <div className="tw-text-xs">
                           {payment_details?.approval_rejection_message ? payment_details?.approval_rejection_message : 'N/A'}
                        </div>
                    </div>}

                    {payment_details?.review_rejection_reason && <div className='tw-mt-3'>
                        <span className='tw-text-xs tw-text-[#263238] tw-font-semibold'>Rejection reason</span>
                        <div className="tw-text-xs">
                           {payment_details?.review_rejection_reason ? payment_details?.review_rejection_reason : 'N/A'}
                        </div>
                    </div>}

                    {payment_details?.review_rejection_message && <div className='tw-mt-3'>
                        <span className='tw-text-xs tw-text-[#263238] tw-font-semibold'>Rejection message</span>
                        <div className="tw-text-xs">
                           {payment_details?.review_rejection_message ? payment_details?.review_rejection_message : 'N/A'}
                        </div>
                    </div>}

                    {
                            (payment_details?.approval_status === 'APPROVED' || payment_details?.review_status === 'APPROVED') && (
                                <span className='tw-inline-block tw-mt-8 tw-bg-green-600 tw-text-white tw-p-1.5 tw-px-6'>APPROVED</span>
                            )
                        }
                        {
                            (payment_details?.approval_status === 'REJECTED' || payment_details?.review_status === 'REJECTED') && (
                                <span className='tw-inline-block tw-mt-8 tw-bg-red-600 tw-text-white tw-p-1.5 tw-px-6'>REJECTED</span>
                            )
                        }

                    {CURRENT_USER_PERMISSION.includes(allowed_permissions[0]) && action_type !== 'view' && <div className='my-3 mt-4 tw-flex tw-gap-4'>
                        <ZojaButton
                            onClick={handleIntitiatePayment}
                            text="Confirm"
                            loading={initiateSinglePaymentState.status === 'loading'}
                            disabled={initiateSinglePaymentState.status === 'loading'}
                            loadingText='Confirm'
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-1 tw-px-12 hover:tw-bg-isPrimary/90'
                        />
                    </div>}

                    {(CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) || CURRENT_USER_PERMISSION.includes(allowed_permissions[2])) && action_type !== 'view' &&
                        <div className='my-3 mt-4 tw-flex tw-gap-4'>
                            {
                                CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && (
                                    <ZojaButton
                                        onClick={handleApprove}
                                        loading={reviewSinglePaymentUploadStatus === 'loading'}
                                        disabled={reviewSinglePaymentUploadStatus === 'loading'}
                                        loadingText='Send for Approval'
                                        text="Send for Approval"
                                        extraClass='tw-border tw-border-isPrimary tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-xs tw-rounded-[4px] tw-w-full tw-p-1 tw-px-6 hover:tw-bg-isPrimary/90'
                                    />
                                )
                            }
                            {CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && <ZojaButton
                                onClick={() => {
                                    handleClose()
                                    setShowConfirmPaymentModal(true)
                                }}
                                text="Approve Payment"
                                // approval_status
                                extraClass={cn(
                                    'tw-border tw-border-isPrimary tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-xs tw-rounded-[4px] tw-w-full tw-p-1 tw-px-6 hover:tw-bg-isPrimary/90',
                                    (payment_details?.approval_status === 'APPROVED' || payment_details?.approval_status === 'REJECTED') && 'tw-hidden'
                                )}
                            />}
                            <ZojaButton
                                onClick={() => {
                                    handleClose()
                                    setShowRejectPaymentModal(true)
                                }}
                                text="Reject Payment"
                                extraClass={cn(
                                    'tw-border tw-border-red-500 tw-grid tw-place-content-center tw-text-red-500 tw-font-thin tw-text-xs tw-rounded-[4px] tw-w-full tw-p-1 tw-px-6 hover:tw-bg-red-600 hover:tw-text-white',
                                    CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) &&
                                    (payment_details?.approval_status === 'APPROVED' || payment_details?.approval_status === 'REJECTED') && 'tw-hidden'
                                )}
                            />
                        </div>
                    }
                </div>
            </ZojaModal>
        </>
    )
}
