import { Dictionary } from '../../types';

interface PageIndicatorProps {
  tabData: {
    id: string | number;
    url: string;
    title: string;
  }[];
  selected: string;
  setSelected: (arg: any) => void;
}

const PageTabIndicator = ({ tabData, selected, setSelected }: PageIndicatorProps) => {
  return (
    <div>
      <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-x-auto md:tw-gap-x-8">
        {tabData.map((items: any, idx: number) => (
          <div
            key={items.id}
            className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
              selected === items.url
                ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                : 'tw-text-[#6A616F]'
            }`}
            onClick={() => setSelected(tabData[idx].url)}>
            {items.title}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageTabIndicator;
