import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../redux/redux-hooks"
import { getSinglePaymentListRequest } from "../../redux/slice"
import { StatusLoader } from "../../atoms"
import PaymentEmptyState from "./paymentEmtyState"
import { Dictionary } from "../../types"
import { LucideChevronLeft, UploadIcon } from "lucide-react"
import { currencyFormat } from "../../utils"
import { cn } from "../../lib/utils"
import { ZojaButton } from "../tailwind"

interface completedPaymentTableListProps {
    selectedPaymentItemId: string | number,
    setActiveTab: (event: any) => void
}

const PAYMENT_TABS = ['Successful', 'Failed']

export default function CompletedPaymentTableList({ selectedPaymentItemId, setActiveTab }: completedPaymentTableListProps) {
    const [currentTabs, setCurrentTabs] = useState(PAYMENT_TABS)
    const [selectedTab, setSelectedTab] = useState('Successful')
    const getSinglePaymentListState = useAppSelector((state) => state.getSinglePaymentList)
    const { status: getSinglePaymentListStatus } = getSinglePaymentListState
    const dispatch = useAppDispatch()

    console.log(getSinglePaymentListState)

    useEffect(() => {
        dispatch(getSinglePaymentListRequest({ bulkImportId: selectedPaymentItemId }))
    }, [selectedPaymentItemId])
    return (
        <>
            <section>
                {
                    getSinglePaymentListStatus === "loading" ?
                        <StatusLoader status={getSinglePaymentListStatus} width={'80'} height={'80'} wrapperHeight={'tw-h-[60vh]'} showEmptyState={false} /> :
                        getSinglePaymentListStatus === "succeeded" && getSinglePaymentListState?.data?.bulk_import?.imported_customers?.length > 0 ?
                            <div className="tw-bg-whit tw-h-[80vh] tw-pb-4 tw-overflow-auto">
                                <div className="tw-mt-4 tw-flex tw-cursor-pointer">
                                    <span
                                        onClick={() => setActiveTab("")}
                                        className="tw-pr-3"
                                    > <LucideChevronLeft width={15} height={15} /> </span>
                                    <span className="tw-text-[.95rem]"> {getSinglePaymentListState?.data?.bulk_import?.name}</span>
                                </div>
                                <div className="tw-mt-4 tw-flex tw-justify-between tw-flex-wra tw-items-center tw-gap-8 tw-pb-1 tw-w-fi">
                                    <div className="tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-gap-8 tw-border-b tw-pb-1 tw-w-fit">
                                        
                                        {   getSinglePaymentListState?.data?.bulk_import?.processing_status === 'SUCCESS' ?
                                            currentTabs.map((tab, idx) => (
                                                <span key={idx} className={cn(
                                                    "tw-text-sm tw-cursor-pointer text-[#7E7E96] tw-relative",
                                                    selectedTab === tab && "tw-font-bold tw-text-isPrimary"
                                                )}
                                                    onClick={() => {
                                                        setSelectedTab(tab)
                                                        // setActiveTab("")
                                                    }}
                                                >{tab}
                                                    {selectedTab === tab && <span className="tw-h-[.15rem] tw-w-full tw-absolute tw-bg-isPrimary tw-left-0 -tw-bottom-1.5 tw-rounded-md"></span>}
                                                </span>
                                            )) : <span className="tw-font-medium tw-text-red-700">FAILED TRANSACTIONS</span>
                                        }
                                    </div>
                                    {/* <ZojaButton 
                                        text="Export Record"
                                        onClick={() => null}
                                        extraClass="tw-text-white tw-bg-isPrimary tw-p-1.5 tw-px-3.5 tw-rounded-[.25rem] tw-font-thin"
                                        icon={<UploadIcon width={15} height={15}/>}
                                        // loadingText="Export Record"
                                        // loading
                                        // disabled
                                    /> */}
                                </div>
                                <table className="tw-mt-6 tw-px-2 tw-table-auto tw-border-separat tw-border-spacing-y- tw-w-full">
                                    <thead>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">S/N</th>
                                        {/* <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Wallet Name</th> */}
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Account Name</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Account Number</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal">Amount</th>
                                        <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal tw-border-r">Description</th>
                                        {
                                            selectedTab === 'Failed' &&
                                            <th className="tw-whitespace-nowrap tw-border-l tw-border-t tw-border-b tw-text-center tw-p-2 tw-text-[##323348] tw-text-[.9rem] tw-font-normal tw-border-r">Reason</th>
                                        }
                                    </thead>
                                    <tbody>
                                        {selectedTab === 'Successful' &&
                                            getSinglePaymentListState?.data?.bulk_import?.imported_customers?.map((item: Dictionary, idx: number) => (
                                                <tr key={idx} className="odd:tw-bg-white eimport spacing from '../../utils/spacing';ven:tw-bg-gray-50 last-of-type:tw-border-b">
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{idx + 1}</td>
                                                    {/* <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">Wallet Name</td> */}
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item?.name}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item?.account?.number}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{`${currencyFormat(Number(item?.amount))}`}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem] tw-border-r">{item?.narration}</td>
                                                </tr>
                                            ))
                                        }
                                        {selectedTab === 'Failed' &&
                                            getSinglePaymentListState?.data?.bulk_import?.rejected_batch?.map((item: Dictionary, idx: number) => (
                                                <tr key={idx} className="odd:tw-bg-white eimport spacing from '../../utils/spacing';ven:tw-bg-gray-50 last-of-type:tw-border-b">
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{idx + 1}</td>
                                                    {/* <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">Wallet Name</td> */}
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item?.name}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{item?.account?.number}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem]">{`${currencyFormat(Number(item?.amount))}`}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem] tw-border-r">{item?.narration}</td>
                                                    <td className="tw-p-2 tw-text-center tw-border-l lg:tw-text-[.9rem] tw-border-r">{item?.reason}</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            <PaymentEmptyState
                                text="There is currently no data for you to view"
                            />

                }
                {/* <span className="tw-mt-4 tw-flex tw-cursor-pointer"
                    onClick={() => setActiveTab("")}
                > <LucideChevronLeft width={15} height={15} /> <span>Go Back</span></span> */}
            </section>
        </>
    )
}
