import { TD, TR } from '../../tailwind/table/tableElements';

import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { ZojaTable } from '../..';
import { Dictionary } from '../../../types';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import { cn } from '../../../lib/utils';
import { toast } from 'react-toastify';

export interface InternalUsersTableIPropsIProps {
  id: number;
  name: string;
  email: string;
  role: string;
  status: boolean;
  lastSeen: string;
  dateEnrolled: string;
}

export interface InternalUsersTableIProps {
  data: InternalUsersTableIPropsIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData?: any;
  setIsSort?: any;
  setSortHeader?: any;
  setOrder?: any;
  setIsNextPage?: any;
  onClick: (item: Dictionary) => any;
}

const dropdownList = [
  {
    id: 1,
    title: 'Edit',
    value: 'edit',
  },
  {
    id: 2,
    title: 'Deactivate',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate',
    value: 'reactivate',
  },
  // {
  //   id: 4,
  //   title: 'Reset Password',
  //   value: 'resetPassword',
  // },
];

function InternaUsersTable({
  data,
  setSelectedItem,
  sorting,
  order,
  tableColumns,
  setOpenMore,
  handleMoreIconOptions,
  selectedItem,
  setUserAccountStatus,
  setIsSort,
  setSortHeader,
  setOrder,
  setIsNextPage,
}: any) {
  const [currentDropdownList, setCurrentDropdownList] = useState<any>([]);

  const handleOnSelect = useCallback(
    (item: any) => {
      setSelectedItem(item);
    },
    [setSelectedItem],
  );

  useEffect(() => {
    if (selectedItem) {
      let newState = [...dropdownList];
      if (selectedItem?.status === 'active') {
        const filteredList = newState.filter(f => f.id !== 3);
        setCurrentDropdownList(filteredList);
      }
      if (selectedItem?.status === 'inactive') {
        const filteredList = newState.filter(f => f.id !== 2);
        setCurrentDropdownList(filteredList);
      }
    }
  }, [selectedItem, handleOnSelect]);

  return (
    <div>
      <ZojaTable
        column={tableColumns}
        sorting={sorting}
        order={order}
        setIsSort={setIsSort}
        setOrder={setOrder}
        setIsNextPage={setIsNextPage}
        setSortHeader={setSortHeader}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.name}</TD>
            <TD>{item.email}</TD>
            <TD> {item.role}</TD>
            <TD
              extraClass={cn(
                item.status === 'active' && 'tw-text-green-500', 
                item.status === 'inactive' && 'tw-text-red-500',
                item.status === 'pending' && 'tw-text-orange-500',
              )}
              value={
                item.status === 'active' ? 'Active' :
                item.status === 'inactive' ? 'Inactive' :
                item.status === 'pending' ? 'Pending' : 'N/A'

              }
            />

            <TD value={moment(item.lastSeen).format('DD-MMM-YYYY - hh:mm a')} />
            <TD value={moment(item.dateEnrolled).format('DD-MMM-YYYY - hh:mm a')} />
            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  handleClose={() => setOpenMore(false)}
                  dropdownList={currentDropdownList}
                  onActionClick={(item: any) => {
                    if (selectedItem?.status === "pending") {
                      toast.info("This account is pending review")
                      return
                    }
                    handleMoreIconOptions(item)
                  }}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
}

export default InternaUsersTable;
