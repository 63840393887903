import { MdOutlineEditCalendar } from 'react-icons/md';
import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../../components/tailwind';
import { icons } from '../../utils';
import DatePicker from 'react-multi-date-picker';
import { useRef, useState } from 'react';
import ZojaConfirmSuccess from './ConfirmSuccess';


interface AddNewEventProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
}

const eventCentreOptions = [
    {
        label: "select area",
        value: ""
    },
    {
        label: "Gbagada",
        value: "Gbagada"
    },
    {
        label: "Ikoyi",
        value: "Ikoyi"
    },
    {
        label: "Maryland",
        value: "Maryland"
    },
]


export default function AddNewEvent({ show, handleClose }: AddNewEventProps) {
    const [value, setValue] = useState(new Date());

    const eventDateRef = useRef<any>(null)

    return (
        <>
            <ZojaConfirmSuccess
                title='New Vendor Added'
                subTitle='Vendor has been added successfully, see details in table.'
                show={false}
                handleClose={() => null}
                onClick={() => null}
                buttonText='Complete'
            />
            <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Add New Event</h3>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <form>
                        <div className='mt-4'>
                            <ZojaInput
                                label='Event title'
                                placeholder='Enter event title'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className="mt-1">
                            <ZojaSelect options={eventCentreOptions} setSelectedOption={(value) => {
                                console.log(value);
                            }} extraClass={`tw-rounded-[5px] tw-p-1 tw-text-[11px] tw-text-gray-700 tw-border-[#E7E7E7] tw-w-full tw-appearance-none tw-bg-gray-100/40 ${false && 'tw-text-gray-600 tw-font-normal'}`} showArrown={true} arrowDownClass='tw-top-4 tw-text-gray-300 tw-text-xs'
                                label='Location(Event center)'
                                labelExtraClass='tw-text-[.83rem]'
                            />
                        </div>
                        <div className='mt-2'>
                            <ZojaInput
                                label='Expected Attendees'
                                placeholder='100'
                                type='number'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className="mt-2">
                            <span className='tw-text-xs'>Event date</span>
                            <div className='tw-border tw-text-[11.3px] tw-text-gray-400 tw-p-[9px] tw-rounded-[4px] tw-flex tw-gap-5 tw-relative'>
                                <span className='tw-text-lg tw-text-isPrimary tw-cursor-pointer tw-absolute tw-right-3 tw-top-3' onClick={() => eventDateRef.current.openCalendar()}><MdOutlineEditCalendar /> </span>
                                <DatePicker
                                    value={value}
                                    ref={eventDateRef}
                                    onOpenPickNewDate={false}
                                    inputClass="tw-bg-inherit tw-outline-non tw-hidde tw-border-none tw-p-1 focus:tw-outline-none"
                                    format='D-MM-YYYY'
                                    onChange={(date: any) => {
                                        setValue(date)
                                        // values.start_date = formatRMDatePicker(startDate)
                                    }}
                                />
                            </div>
                        </div>
                        <div className='my-3 mt-4'>
                            <ZojaButton
                                onClick={() => null}
                                text="Confirm"
                                // loading
                                extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90'
                            />
                        </div>
                    </form>
                </div>
            </ZojaModal>
        </>
    )
}
