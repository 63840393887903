import { MdClose } from 'react-icons/md'
import { ZojaButton, ZojaModal } from '../../components/tailwind'
import { images } from '../../utils'

interface EventCenterDetailModalProps {
    show: boolean,
    handleClose: () => void,
}

export default function EventCenterDetailModal({ show, handleClose }: EventCenterDetailModalProps) {
    return (
        <ZojaModal
            show={show}
            handleClose={handleClose}
            height='auto'
            position='center'
            extraClass="md:tw-w-[42rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            borderRadius='12px'
        >
            <div className='tw-relative tw-mb-12 tw-text-center tw-grid tw-place-content-center'>
                <span className='tw-absolute tw-top-2 tw-right-3 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer'
                onClick={handleClose}
                ><MdClose /></span>
                <h1 className='tw-text-xl tw-pt-8 md:tw-text-2xl'>Whitestone Event Centre</h1>
                <p className='tw-pt-4 tw-px-3 md:tw-px-16'>Tier 1</p>
                <div className="tw-grid tw-grid-cols-3 tw-gap-1 md:tw-gap-8 lg:tw-gap-x-16">
                    <div>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Total Amount Generated</p>
                        <h1 className='tw-text-isOrange tw-text-sm md:tw-text-lg lg:tw-text-2xl'>XXXXXX</h1>
                    </div>
                    <div>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Total Amount Generated</p>
                        <h1 className='tw-text-isOrange tw-text-sm md:tw-text-lg lg:tw-text-2xl'>XXXXXX</h1>
                    </div>
                    <div>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Total Amount Generated</p>
                        <h1 className='tw-text-isOrange tw-text-sm md:tw-text-lg lg:tw-text-2xl'>XXXXXX</h1>
                    </div>
                </div>
                <div className="tw-mt-4 tw-gap-6 tw-p-3 tw-px-6 tw-grid tw-grid-cols-3 tw-justify-evenly md:tw-justify-between">
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Email</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>Wade@mail</h1>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Phone Number</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>09030570703</h1>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>%Earned</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>3%</h1>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>%Agent commission</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>15%</h1>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Date Added</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>Jan-10-2022</h1>
                    </div>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                        <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Phone Number</p>
                        <h1 className='tw-text-gray-500 tw-text-sm tw-font-normal'>09030570703</h1>
                    </div>
                </div>

                <div className='tw-mt-6 tw-px-6 tw-flex tw-justify-between tw-items-center'>
                    <div className='tw-flex tw-flex-col tw-items-start'>
                            <p className='tw-text-isPrimary tw-text-xs tw-leading-4 tw-mb-[.4rem] md:tw-text-[.9rem]'>Status</p>
                            <h1 className='tw-bg-[#1DC64233] tw-text-[#16B45E] tw-font-medium tw-p-2 tw-px-12 tw-rounded-md tw-text-sm'>Active</h1>
                        </div>

                    <ZojaButton
                        text="Delete Vendor"
                        extraClass='tw-bg-[#FD0000B2] tw-rounded-lg tw-font-light tw-p-2.5 tw-px-8 tw-text-white tw-text-xs'
                    />
                </div>
            </div>
        </ZojaModal>
    )
}
