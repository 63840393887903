import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getAdminRequestDetails, reviewAdminRequest } from '../../redux/slice';
import { Dictionary } from '../../types';
import { ZojaButton, ZojaInput, ZojaModal } from '../tailwind';
import AllowedModules from '../permissionDefault/AllowedModules';
import { moveDataToFront } from '../../utils';
import RolePermissions from '../permissionDefault/RolePermissions';


interface DepartmentModalProps {
  show: boolean;
  handleClose: () => void;
  roleDetails: Dictionary;
  setShowRejectRequestModal: (arg: boolean) => void
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  hod_name: yup.string().required('Hod Name name is required'),
  description: yup.string().required('Description name is required'),
});

export default function ReviewDepartmentModal({
  show,
  handleClose,
  roleDetails,
  setShowRejectRequestModal
}: DepartmentModalProps) {

  const [availableModules, setAvailableModules] = useState<any>({});

  const dispatch = useAppDispatch()

  const reviewAdminRequestState = useAppSelector((state) => state.reviewAdminRequest)

  const adminRequestDetails = useAppSelector((state) => state.getAdminRequestDetails)
  const { data: { request_detail }, status: adminRequestDetailsStatus } = adminRequestDetails

  const handleFormSubmit = (data: Dictionary) => {
    dispatch(reviewAdminRequest({ id: roleDetails?.id, data: { status: 'approved' } }))
  }

  useEffect(() => {
    if (show) {
      dispatch(getAdminRequestDetails({ id: roleDetails?.id }))
    }
  }, [show])

  useEffect(() => {
    if (reviewAdminRequestState.status === 'succeeded') {
      handleClose()
    }
  }, [reviewAdminRequestState.status])

  useEffect(() => {
    if (request_detail?.admin_modules?.length) {
      const modules = request_detail?.admin_modules?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          title: item.name,
          isChecked: true,
          permissions: item.permissions
        }
      })

      const updatedModules = moveDataToFront(modules, 'name', 'Dashboard')
      const MODULES = { modules: updatedModules }
      setAvailableModules(MODULES)
    }
  }, [request_detail]);

  return (
    <>
      <ZojaModal
        show={show}
        handleClose={handleClose}
        height="auto"
        position="center"
        extraClass="md:tw-w-[50rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12 tw-pt-7 tw-pb-10 tw-px-8"
        borderRadius="8px">
        <div className="">
          <h2 className="tw-font-medium tw-text-xl tw-mb-8">Role Details</h2>

          <span
            className="tw-absolute tw-top-6 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
            onClick={() => {
              handleClose();
            }}>
            <MdClose />
          </span>

          {adminRequestDetailsStatus === 'loading' ? (
            <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="60"
                width="60"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>) : (
            <div className="">
              <Formik
                initialValues={{
                  name: request_detail?.name,
                  role_function: request_detail?.role_function
                }}
                enableReinitialize={true}
                // validationSchema={schema}
                onSubmit={async (values, { setSubmitting }) => {
                  const { name, role_function } = values ?? '';

                  const payload = {
                    role: name,
                    role_function: role_function,
                  };
                  handleFormSubmit(payload);
                  setSubmitting(false);
                }}>
                {formikProps => {
                  const { handleChange, values, handleSubmit, errors, touched } = formikProps;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="tw-mt-4 tw-mb-8">
                        <h3 className="tw-text-base tw-font-medium ">Role Information</h3>
                        <div className="tw-flex tw-items-center tw-gap-x-12">
                          <div className="tw-w-1/2">
                            <ZojaInput
                              label="Role Name"
                              placeholder="Role name"
                              type="text"
                              name="role"
                              touched={touched}
                              value={values.name}
                              onChange={handleChange}
                              error={errors.name}
                              disabled
                            />
                          </div>
                          <div className="tw-w-full">
                            <ZojaInput
                              label="Role Function"
                              placeholder="Enter short description"
                              type="text"
                              name="role_function"
                              touched={touched}
                              value={values.role_function}
                              onChange={handleChange}
                              error={errors.role_function}
                              disabled
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <h3 className="tw-text-base tw-font-medium ">Selected Modules</h3>
                        <p className='tw-text-xs tw-text-[#5E6366]'>Select below the modules that will be visible to the users in this role</p>

                        <div className='tw-mb-8'>
                          <AllowedModules
                            selected={availableModules}
                            setSelected={setAvailableModules}
                            available_modules={availableModules?.modules}
                            itemName={'modules'}
                            className="tw-border tw-border-[#E7E7E7]"
                            showCheckbox={false}
                          />
                        </div>
                        <RolePermissions 
                          module_permissions={availableModules?.modules?.filter((f: any) => (f.isChecked || f.title === 'Dashboard'))} 
                          permissionClass='tw-h-[10rem] tw-overflow-y-scroll'
                          />
                      </div>
                      {
                          roleDetails?.status === "pending" ?
                            <div className="tw-flex tw-items-center tw-gap-x-6 tw-mt-6">
                              <ZojaButton
                                type="submit"
                                text={'Approve'}
                                loading={reviewAdminRequestState.status === "loading"}
                                disabled={reviewAdminRequestState.status === "loading"}
                                loadingText="Approve"
                                // extraClass={`${loading || !isValid ? '!tw-bg-[#E7E7E7] tw-text-[#5E6366]' : 'tw-bg-isPrimary tw-text-white'
                                //   } tw-py-[14px] tw-px-8 tw-text-sm`}
                                extraClass={`tw-bg-isPrimary tw-text-white tw-py-[10px] tw-px-10 tw-text-sm`}
                                loadingClass="tw-justify-center"
                              />
                              <ZojaButton
                                type="button"
                                text={'Reject'}
                                onClick={() => {
                                  setShowRejectRequestModal(true)
                                  handleClose();
                                }}
                                extraClass="tw-bg-red-600 tw-text-white tw-py-[10px] tw-px-10  tw-font-medium tw-text-sm"
                              />
                            </div> : (
                              <div className='tw-mt-6'>
                                {roleDetails?.status === 'approved' && <span className='tw-text-xs tw-p-2 tw-rounded-sm tw-text-white tw-bg-green-700 tw-px-6'>Approved</span>}
                                {roleDetails?.status === 'rejected' && <span className='tw-text-xs tw-p-2 tw-rounded-sm tw-text-white tw-bg-red-700 tw-px-6'>Rejected</span>}
                              </div>
                            )
                        }
                    </form>
                  );
                }}
              </Formik>
            </div>
          )
          }


        </div>
      </ZojaModal>
    </>
  );
}

