const routesPath = {
  REMEMBERUSER: 'rememberUser',
  TOKEN: 'token',
  LOGIN: '/',
  CLAIMACCOUNT: '/admin/claim-account',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  PASSWORDRESET: '/password/reset',
  DASHBOARD: '/dashboard',
  TRANSACTIONINFORMATION: '/transactioninformation',
  KYC: '/kyc',
  VERIFIEDKYC: '/kyc/verified-users',
  PENDINGKYC: '/kyc/pending-verifications',
  REJECTEDKYC: '/kyc/rejected-verifications',
  SUPPORT: '/support',
  SETTLEMENTS: '/settlements',
  RECONCILIATION: '/reconciliation',
  RECONCILIATIONUSERDETAILS: '/reconciliation/',
  USERS: '/users',
  ACCOUNTMANAGEMENT: '/account-management',
  BANUNBAN: '/ban-and-unban',
  BAN: '/ban-and-unban',
  UNBANACCOUNT: '/ban-and-unban/unban-account/',
  UNBANREQUESTS: '/ban-and-unban/unban-requests',
  RESTRICTIONS: '/account-restriction',
  RESTRICTIONACCOUNTS: '/account-restriction/restricted-accounts',
  RESTRICTIONREQUESTS: '/account-restriction/restricted-requests',
  RESTRICTIONREQUESTSINITIATOR: '/account-restriction/restricted-requests-initiator',
  REJECTEDRESTRICTIONREQUESTS: '/account-restriction/rejected-restricted-requests',
  UNRESTRICTIONREQUESTS: '/account-restriction/unrestricted-requests',
  UNRESTRICTACCOUNT: '/account-restriction/unrestrict-account/',
  APPROVERESTRICTREQUEST: '/account-restriction/approve-restrict-account/',
  APPROVEUNRESTRICTREQUEST: '/account-restriction/approve-unrestrict-account/',
  REVIEWRESTRICTREQUEST: '/account-restriction/review-restrict-account/',
  REVIEWUNBANREQUESTS: '/ban-and-unban/review-unban-requests',
  REJECTEDBANREQUESTS: '/ban-and-unban/rejected-ban-requests',
  FREEZEREQUESTS: '/freeze-and-unfreeze/freeze-requests',
  UNFREEZEREQUESTS: '/freeze-and-unfreeze/unfreeze-requests',
  APPROVEBANREQUEST: '/freeze-and-unfreeze/approve-ban-request/',
  APPROVEUNBANREQUEST: '/freeze-and-unfreeze/approve-unban-request/',
  APPROVEFREEZEREQUEST: '/freeze-and-unfreeze/approve-freeze-request/',
  APPROVEUNFREEZEREQUEST: '/freeze-and-unfreeze/approve-unfreeze-request/',
  UNFREEZEACCOUNT: '/freeze-and-unfreeze/unfreeze-account/',
  MYFREEZEREQUEST: '/freeze-and-unfreeze/my-freeze-requests/',
  REJECTEDREQUEST: '/freeze-and-unfreeze/rejected-requests/',
  SETTINGS: '/settings',
  KYCDOC: '/kyc/',
  // I think I have to delete this UserDetails Route permanently, but I am leaving it here for now.
  //  Route has been changed to CUSTOMERDETAILS
  // USERDETAILS: '/user/profile/',
  TRANSACTIONS: '/transactions',
  PAYMENTS: '/payments',

  BULK_PAYMENTS: '/payments/bulk-payment',
  INITIATE_BULK_PAYMENTS: '/payments/bulk-payment/initiate',
  VIEW_UPLOADED_BULK_PAYMENTS: '/payments/bulk-payment/view-uploads',
  PENDING_BULK_PAYMENTS: '/payments/bulk-payment/pending',
  COMPLETED_BULK_PAYMENTS: '/payments/bulk-payment/completed',

  SINGLE_PAYMENTS: '/payments/single-payment',
  INITIATE_SINGLE_PAYMENTS: '/payments/single-payment/initiate',
  VIEW_UPLOADED_SINGLE_PAYMENTS: '/payments/single-payment/view-uploads',
  PENDING_SINGLE_PAYMENTS: '/payments/single-payment/pending',
  COMPLETED_SINGLE_PAYMENTS: '/payments/single-payment/completed',

  NEWAPPNOTIFICATION: '/settings/newappnotification',
  APPNOTIFICATIONUPDATE: '/settings/appnotificationupdate/',
  EMAILNOTIFICATION: '/settings/emailnotification',
  EMAILNOTIFICATIONUPDATE: '/settings/emailnotificationupdate/',
  NEWARTICLE: '/settings/newarticle',
  ARTICLEUPDATE: '/settings/articleupdate/',
  NEWFAQ: '/settings/newfaq',
  FAQUPDATE: '/settings/faq/',
  USERROLES: '/users/roles/',
  CREATEUSERROLES: '/users/roles/new',
  ROLESANDPERMISSIONS: '/roles-permissions',
  DEPARTMENT: '/departments',
  ADMIN_REQUEST: '/admin-request',
  CASHREQUESTINFORMATION: '/cash-request',
  EVENTCASHREQUESTINFORMATION: '/event-cash-request',
  EVENTCASHREQUESTSETTINGS: '/event-cash-request-settings',
  KYCCUSTOMERINFORMATION: '/kyc-information/',
  REPORT: '/report',
  GENERATEDREPORT: '/generatedreport',
  VOLUMEREPORT: '/volumereport',
  USERREPORT: '/userreport',
  INCOMEREPORT: '/incomereport',
  TRANSACTIONREPORT: '/transactionreport',
  DAILYREPORT: '/generalreport/dailyreport',
  WEEKLYREPORT: '/generalreport/weeklyreport',
  MONTHLYREPORT: '/generalreport/monthlyreport',
  RECONCILIATIONREPORT: '/reconciliationreport',
  SETTLEMENTREPORT: '/generalreport/settlementreport',
  USERREGREPORT: '/userregistrationreport',
  USERACTTIVITYREPORT: '/useractivitesreport',
  FINANCEREPORT: '/financereport',
  AGENTPROFILE: '/agent-profile/',
  CUSTOMERS: '/customers',
  CUSTOMERDETAILS: '/customers/profile/',
};

export default routesPath;
