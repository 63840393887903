import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaModal } from '../tailwind';
import { images } from '../../utils';
import { Dictionary } from '../../types';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import { ReactComponent as EmptyStateImage } from '../../assets/svg/emptyStateNote.svg';
import EmptyState from '../emptyState/emptyState';

interface BanHistoryModalProps {
  show: boolean;
  handleClose: () => void;
  userName: string;
  kycLevel: string;
  banHistorData: any;
  requestStatus?: string;
  banButtonText?: string;
  onClick?: () => void;
}

export default function BanHistory({
  show,
  handleClose,
  userName,
  kycLevel,
  banHistorData,
  requestStatus,
  onClick,
  banButtonText,
}: BanHistoryModalProps) {
  return (
    <ZojaModal
      show={show}
      handleClose={handleClose}
      height="auto"
      position="center"
      extraClass="md:tw-w-[33rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      borderRadius="8px">
      <div className=" tw-px-12 tw-py-10">
        <span
          className="tw-absolute tw-top-11 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
          onClick={handleClose}>
          <MdClose />
        </span>
        {requestStatus === 'loading' ? (
          <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : banHistorData?.length > 0 ? (
          <div className="">
            <div className="tw-flex">
              <h2 className=" tw-text-2xl tw-font-medium tw-mb-8">Ban History</h2>
            </div>
            <div className="tw-mb-6">
              <div className="tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between md:tw-items-center tw-mr-10 tw-gap-y-2">
                <div className=" tw-flex tw-items-center tw-gap-x-3">
                  <img src={images.user} alt="" width={40} height={40} className=" tw-rounded-full" />
                  <p className=" tw-text-isPrimary tw-text-lg m-0">{userName}</p>
                </div>
                <p className="m-0 tw-font-semibold tw-text-isPrimary">
                  KYC: <span className="tw-font-normal">{kycLevel}</span>
                </p>
              </div>
              <hr className="tw-mt-[5px] tw-border-[#BEBEBE]" />
            </div>
            <div className="tw-max-h-[50vh] tw-overflow-y-scroll">
              {banHistorData.length > 0 &&
                banHistorData.map((item: any, idx: any) => (
                  <div className="tw-mb-6" key={idx}>
                    <p className="tw-text-sm tw-mb-1">{moment(item?.attributes?.created_at).format('DD/MM/YYYY')}</p>
                    <h3 className="!tw-font-medium !text-[#3d3c3c] tw-text-sm tw-mb-2 tw-capitalize">
                      {item?.attributes?.reason_for}
                    </h3>
                    <p className=" tw-text-isGreyVariantEight tw-text-sm">{item?.attributes?.description_of_reason}</p>
                  </div>
                ))}
            </div>
            <ZojaButton
              text={banButtonText}
              onClick={onClick}
              extraClass="tw-bg-isPrimary tw-text-white tw-w-full tw-py-3 tw-mt-3 tw-text-sm tw-font-medium"
            />
          </div>
        ) : (
          <EmptyState
            img={<EmptyStateImage width={200} height={200} />}
            description="No Record Found"
            btnText="Reset Data"
            btnBackground="tw-bg-isPrimary"
            showBtn={false}
          />
        )}
      </div>
    </ZojaModal>
  );
}
