import ReactSwitch from 'react-switch';

interface IProps {
  title?: string;
  description?: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  custom?: boolean;
  borderRadius?: boolean;
  paddingVertical?: number | string;
  backgroundColor?: string;
  borderColor?: string;
  paddingLeft?: number | string;
  paddingRight?: number | string;
  marginBottom?: number | string;
  labelColor?: string;
}

const SwitchWithDescription = ({ onChange, checked, title, description }: IProps) => {
  return (
    <div className="tw-flex tw-items-end tw-justify-between">
      <div className="tw-grid tw-gap-y-3">
        <h3 className="tw-text-gray-700 tw-text-lg tw-font-medium">{title}</h3>
        <p className="tw-text-zinc-600 tw-text-sm tw-font-normal">{description}</p>
      </div>
      <div>
        <ReactSwitch
          onChange={onChange}
          checked={checked}
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#dbdbdb"
          onHandleColor="#162082"
        />
      </div>
    </div>
  );
};

export default SwitchWithDescription;
