import { useNavigate } from 'react-router-dom';
import { ReactComponent as EmptyReport } from '../../assets/svg/empty.svg';
import { images } from '../../utils';
import { Oval } from 'react-loader-spinner';
interface SavedReportProps {
  title: string;
  type: string;
  link: string;
  getAllSavedReportStatus?: string;
  data: any[];
  onHandleQuery: (e: any, type: string) => void;
}

const SavedReport = ({ title, data, onHandleQuery, type, link, getAllSavedReportStatus }: SavedReportProps) => {
  const navigate = useNavigate();

  return (
    <div className="">
      <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal tw-mb-4">{title}</h3>
      {getAllSavedReportStatus === 'loading' ? (
        <div className=" tw-w-full tw-mx-auto tw-flex tw-justify-center tw-items-center tw-flex-col tw-px-3 tw-h-[286px] tw-opacity-50 tw-bg-zinc-100 tw-rounded tw-shadow-inner">
          <Oval
            height="40"
            width="40"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : data?.length >= 1 ? (
        <div className="tw-flex tw-flex-col tw-items-start tw-gap-y-4">
          {data.map(item => (
            <button
              key={item?.id}
              onClick={() => {
                onHandleQuery(item?.query, type);
                navigate(link);
              }}
              className="tw-text-blue-900 tw-text-sm tw-font-normal">
              {item?.name}
            </button>
          ))}
        </div>
      ) : (
        <div className=" tw-flex-col tw-flex tw-items-center tw-justify-center tw-w-max tw-px-3 tw-h-[286px] tw-opacity-50 tw-bg-zinc-100 tw-rounded tw-shadow-inner">
          <EmptyReport />
          <p className="tw-text-black tw-text-sm tw-font-normal ">No saved data yet</p>
        </div>
      )}
    </div>
  );
};

export default SavedReport;
