import { TableTag, TH, TableContainer } from './style';
import { TD, TR } from '../../tailwind/table/tableElements';

import { MoreIcon, ZojaTable } from '../..';
import { colors, currencyFormat, dateFormat, capitalizeFirstLetter } from '../../../utils';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
import { Dictionary } from '../../../types';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';

export interface DepartmentTableIPropsIProps {
  id: number;
  departmentName: string;
  teamCount: string;
  assignedAdmin: string;
  departmentStatus: string;
  dateCreated: string;
}

export interface DepartmentTableIProps {
  data: DepartmentTableIPropsIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData?: any;
  setIsSort?: any;
  setSortHeader?: any;
  setOrder?: any;
  setIsNextPage?: any;
  onClick: (item: Dictionary) => any;
}

const dropdownList = [
  {
    id: 1,
    title: 'Modify',
    value: 'modify',
  },
  {
    id: 2,
    title: 'Deactivate Dept',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate Dept',
    value: 'reactivate',
  },
  // {
  //   id: 4,
  //   title: 'Delete Dept',
  //   value: 'delete',
  // },
];

function DepartmentTable({
  data,
  setSelectedItem,
  sorting,
  order,
  tableColumns,
  setOpenMore,
  handleMoreIconOptions,
  selectedItem,
  setDepartmentAccountStatus,
  setIsSort,
  setSortHeader,
  setOrder,
  setIsNextPage,
}: any) {
  const [currentDropdownList, setCurrentDropdownList] = useState<any>([]);

  const handleOnSelect = useCallback(
    (item: any) => {
      setSelectedItem(item);
    },
    [setSelectedItem],
  );

  useEffect(() => {
    if (selectedItem) {
      let newState = [...dropdownList];
      if (selectedItem?.status === 'active' || selectedItem?.status === 'pending') {
        const filteredList = newState.filter(f => f.id !== 3);
        setCurrentDropdownList(filteredList);
      }
      if (selectedItem?.status === 'inactive') {
        const filteredList = newState.filter(f => f.id !== 2);
        setCurrentDropdownList(filteredList);
      }
    }
  }, [selectedItem, handleOnSelect]);

  return (
    <div>
      <ZojaTable
        column={tableColumns}
        sorting={sorting}
        order={order}
        setIsSort={setIsSort}
        setOrder={setOrder}
        setIsNextPage={setIsNextPage}
        setSortHeader={setSortHeader}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.departmentName}</TD>
            <TD>{item.teamCount}</TD>
            <TD> {item.hod_name || 'N/A'}</TD>
            <TD
              extraClass={`${
                item.status === 'active'
                  ? 'tw-text-green-500'
                  : item.status === 'pending'
                  ? 'tw-text-[#ffaf56]'
                  : 'tw-text-red-500'
              }`}
              value={item.status}
            />

            <TD value={moment(item.dateCreated).format('DD-MMM-YYYY - hh:mm a')} />
            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  handleClose={() => setOpenMore(false)}
                  dropdownList={currentDropdownList}
                  onActionClick={item => handleMoreIconOptions(item)}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
}

export default DepartmentTable;
