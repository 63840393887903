import { memo } from 'react';
import { Container } from './style';
import { H4 } from '../../styles';
import { colors } from '../../utils';

export interface TabIProps {
  id?: number;
  paddingRight?: boolean;
  isSelected: boolean;
  text: string;
  type?: string;
  bottom?: string;
  tabPaddingBottom?: string;
  borderBottomWidth?: string;
  fontSize?: string;
  fontWeight?: string;
  tabViewSelectedIndex?: number;
  onClick?: () => void;
}

function Tab({
  text,
  isSelected,
  onClick,
  paddingRight,
  type,
  tabViewSelectedIndex,
  bottom,
  tabPaddingBottom,
  borderBottomWidth,
  fontSize,
  fontWeight,
}: TabIProps) {
  const colorUser =
    isSelected && tabViewSelectedIndex === 1
      ? colors.green
      : isSelected && tabViewSelectedIndex === 2
      ? colors.red
      : colors.grey;

  const colorSettle = isSelected ? colors.primary : colors.grey;

  return (
    <Container paddingRight={paddingRight} onClick={onClick}>
      <H4
        style={{
          borderBottomColor: isSelected ? colors.primary : 'transparent',
          borderBottomStyle: isSelected ? 'solid' : 'none',
          borderBottomWidth: isSelected ? borderBottomWidth || 2 : 0,
          position: 'relative',
          bottom: bottom ? bottom : '0',
          paddingBottom: tabPaddingBottom ? tabPaddingBottom : '0',
          fontSize: fontSize && fontSize,
          fontWeight: fontWeight && fontWeight,
        }}
        semiBold
        color={type === 'user' ? colorUser : colorSettle}>
        {text}
      </H4>
    </Container>
  );
}

export default memo(Tab);
