export const dateRangeOptions: any = [
    {
      id: 1,
      value: 'one_week',
      title: 'One week',
      isChecked: false,
    },
    {
      id: 2,
      value: 'thirty_days',
      title: '30 Days',
      isChecked: false,
    },
    {
      id: 3,
      value: 'six_months',
      title: '6 Months',
      isChecked: false,
    },
    {
      id: 4,
      value: 'custom',
      title: 'Custom',
      isChecked: false,
    },
  ];
  
  export const dateRangeOptioned: any = [
    {
      id: 1,
      value: 'one_day_ago',
      title: 'Yesterday',
      isChecked: false,
    },
    {
      id: 2,
      value: 'one_week_ago',
      title: 'One Week',
      isChecked: false,
    },
    {
      id: 3,
      value: 'one_month_ago',
      title: '1 Months ago',
      isChecked: false,
    },
  ];

    export const dateRangeSelect: any = [
      {
        id: 1,
        value: 'today',
        title: 'Today',
        isChecked: false,
      },
      {
        id: 2,
        value: 'yesterday',
        title: 'Yesterday',
        isChecked: false,
      },
      {
        id: 3,
        value: 'one_week',
        title: 'One week',
        isChecked: false,
      },
      {
        id: 3,
        value: 'thirthy_days',
        title: '30 days',
        isChecked: false,
      },
    ];