import React from 'react';
import { ZojaButton, ZojaModal } from '../tailwind';
import { images } from '../../utils';
import { IoClose } from 'react-icons/io5';

interface IProps {
  title: string;
  type?: string;
  show: boolean;
  handleClose: () => void;
  contentRef: HTMLDivElement | any;
  closeOnClickOutside?: boolean;
  handleShowSaveReport?: () => void;
}

const FeedbacksModal = ({
  title,
  show,
  contentRef,
  handleClose,
  handleShowSaveReport,
  type,
  closeOnClickOutside = false,
}: IProps) => {
  return (
    <ZojaModal
      show={show}
      height="auto"
      position="center"
      borderRadius="12px"
      handleClose={handleClose}
      closeOnClickOutside={closeOnClickOutside}
      extraClass="md:tw-w-[676px] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      contentRef={contentRef}
      children={
        <div className="rounded-lg tw-p-8 " ref={contentRef}>
          <h2 className=" tw-text-center tw-text-violet-900 tw-text-xl tw-font-normal tw-mb-10">Feedbacks</h2>

          <button
            onClick={() => handleClose()}
            className="tw-absolute tw-right-10 tw-top-8 tw-bg-isPrimary tw-rounded-md tw-p-1">
            <IoClose color="#fff" />
          </button>

          <div className="tw-flex tw-flex-wrap tw-gap-y-3 tw-items-center tw-gap-x-4">
            <div className="tw-p-4 tw-bg-neutral-200 tw-rounded-full tw-justify-center tw-items-center tw-gap-2.5 tw-flex">
              <button className="tw-text-neutral-500 tw-text-[15px] tw-font-normal tw-pb-0">This week</button>
            </div>

            <div className="tw-px-6 tw-py-4 tw-bg-neutral-200 tw-rounded-[25px] tw-justify-start tw-items-start tw-gap-2.5 tw-flex">
              <button className="tw-text-neutral-500 tw-text-[15px] tw-font-normal tw-pb-0">Month</button>
            </div>

            <div className="tw-px-6 tw-py-4 tw-bg-neutral-200 tw-rounded-[25px] tw-justify-start tw-items-start tw-gap-2.5 tw-flex">
              <button className="tw-text-neutral-500 tw-text-[15px] tw-font-normal tw-pb-0">Year</button>
            </div>

            <div className="tw-px-6 tw-py-4 tw-bg-neutral-200 tw-rounded-[25px] tw-justify-start tw-items-start tw-gap-2.5 tw-flex">
              <button className="tw-text-neutral-500 tw-text-[15px] tw-font-normal tw-pb-0">All</button>
            </div>
          </div>
          <div className="tw-w-full tw-h-[0px] tw-border tw-border-neutral-500 tw-border-opacity-20 tw-mt-14 tw-mb-6"></div>
          <div>
            <div className="tw-flex tw-items-start tw-justify-between">
              <div className="">
                <h3 className="tw-text-neutral-500 tw-text-xl tw-font-bold tw-mb-6">Oladele Oluwadare</h3>
                <p className="tw-text-neutral-500 tw-text-xl tw-font-light">
                  Please help approve this material requisition as we <br />
                  currently have a low stock
                </p>
              </div>
              <div>
                <p className="tw-text-center tw-text-neutral-500 tw-text-[13px] tw-font-light">09:14am 22/12/21</p>
              </div>
            </div>
          </div>
          <div className="tw-w-full tw-h-[0px] tw-border tw-border-neutral-500 tw-border-opacity-20 tw-mt-14 tw-mb-6"></div>
          <div>
            <div className="tw-flex tw-items-start tw-justify-between">
              <div className="">
                <h3 className="tw-text-neutral-500 tw-text-xl tw-font-bold tw-mb-6">Oladele Oluwadare</h3>
                <p className="tw-text-neutral-500 tw-text-xl tw-font-light">
                  Please help approve this material requisition as we <br />
                  currently have a low stock
                </p>
              </div>
              <div>
                <p className="tw-text-center tw-text-neutral-500 tw-text-[13px] tw-font-light">09:14am 22/12/21</p>
              </div>
            </div>
          </div>
          <div className="tw-w-full tw-h-[0px] tw-border tw-border-neutral-500 tw-border-opacity-20 tw-mt-14 tw-mb-6"></div>
          <div>
            <div className="tw-flex tw-items-start tw-justify-between">
              <div className="">
                <h3 className="tw-text-neutral-500 tw-text-xl tw-font-bold tw-mb-6">Oladele Oluwadare</h3>
                <p className="tw-text-neutral-500 tw-text-xl tw-font-light">
                  Please help approve this material requisition as we <br />
                  currently have a low stock
                </p>
              </div>
              <div>
                <p className="tw-text-center tw-text-neutral-500 tw-text-[13px] tw-font-light">09:14am 22/12/21</p>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default FeedbacksModal;
