import { CiCircleCheck } from "react-icons/ci";
import { TbCircleX } from "react-icons/tb";
import { cn } from "../../lib/utils";

interface PasswordCheckerProps {
    title: string,
    isValid: boolean
}

export default function PasswordChecker({title, isValid = false}: PasswordCheckerProps) {
    return (
        <div className={cn(
            "tw-flex tw-gap-x-1 ",
            isValid ? "tw-text-green-600" : "tw-text-red-600"
        )}>
            {isValid ? <CiCircleCheck width={15} height={15} /> : <TbCircleX  width={15} height={15} />}
             <span className='tw-text-xs'>{title}</span>
        </div>
    )
}
