import React, { useRef, useState } from 'react';
import { AppContainer } from '../../atoms';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { colors, routesPath, spacing } from '../../utils';
import { Dictionary } from '../../types';
import { icons } from '../../utils';
import { Oval } from 'react-loader-spinner';
import {
  DeactivateModal,
  ExportLabel,
  FeedbacksModal,
  FilterCard,
  FilterLabel,
  OpenDisputeModal,
  SingleCountCard,
  Switch,
  SwitchWithDescription,
  TextArea,
  ZojaTable,
} from '../../components';
import { TD, TR } from '../../components/tailwind/table/tableElements';
import { useAppSelector } from '../../redux/redux-hooks';
import { ZojaButton, ZojaInput } from '../../components/tailwind';
import { dateTimeFormat3 } from '../../utils/dateFormat';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { TDMORE } from '../../components/tailwind/table/tableElementMoreView';
const { DASHBOARD, CASHREQUESTINFORMATION, EVENTCASHREQUESTINFORMATION, AGENTPROFILE } = routesPath;

const cashRequestTabs: Dictionary = [
  {
    id: 1,
    title: 'General',
    url: 'general',
  },
  {
    id: 2,
    title: 'Commissions',
    url: 'commissions',
  },
  {
    id: 3,
    title: 'Vendor Management',
    url: 'vendor_management',
  },
];

const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'View Profile',
    value: 'viewProfile',
  },
  {
    id: 2,
    title: 'Review Feedback',
    value: 'reviewFeedback',
  },
  {
    id: 3,
    title: 'Open Dispute',
    value: 'openDispute',
  },
  {
    id: 4,
    title: 'Deactivate Agent',
    value: 'deactivateAgent',
  },
];

const registeredAgentsTable = {
  columns: [
    {
      id: 1,
      title: '#',
    },
    {
      id: 2,
      title: 'Agent Name',
    },
    {
      id: 3,
      title: 'Phone Number',
    },
    {
      id: 4,
      title: 'Email',
    },
    {
      id: 5,
      title: 'Success Rate',
    },
    {
      id: 6,
      title: 'Date Registered',
    },
    {
      id: 7,
      title: '',
    },
  ],
  data: [
    {
      id: 1,
      agentName: 'Caleb Adeshimawa',
      phoneNumber: '07012345678',
      email: 'binhan628@gmail.com',
      successRate: '70%',
      dateRegistered: '11/11/2023-12:24',
    },
    {
      id: 2,
      agentName: 'Caleb Adeshimawa',
      phoneNumber: '07012345678',
      email: 'binhan628@gmail.com',
      successRate: '70%',
      dateRegistered: '11/11/2023-12:24',
    },
    {
      id: 3,
      agentName: 'Caleb Adeshimawa',
      phoneNumber: '07012345678',
      email: 'binhan628@gmail.com',
      successRate: '70%',
      dateRegistered: '11/11/2023-12:24',
    },
    {
      id: 4,
      agentName: 'Caleb Adeshimawa',
      phoneNumber: '07012345678',
      email: 'binhan628@gmail.com',
      successRate: '70%',
      dateRegistered: '11/11/2023-12:24',
    },
    {
      id: 5,
      agentName: 'Caleb Adeshimawa',
      phoneNumber: '07012345678',
      email: 'binhan628@gmail.com',
      successRate: '70%',
      dateRegistered: '11/11/2023-12:24',
    },
  ],
};

const EventCashRequestSettings = () => {
  const kycsAnalyticsState = useAppSelector(state => state.getKycsAnalytics);
  const { status: kycsAnalyticsStatus } = kycsAnalyticsState;

  const successModalRef = useRef<any>(null);

  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [activePageTab, setActivePageTab] = useState('general');
  const [selectedAgent, setSelectedAgent] = useState<any>({});
  const [showFeedbacksModal, setShowFeedbacksModal] = useState(false);
  const [showOpenDisputeModal, setShowOpenDisputeModal] = useState(false);
  const [showDeactivateModal, setShowDeactivateModal] = useState(false);

  const [activeTab, setActiveTab] = useState('');
  const [openMore, setOpenMore] = useState(false);
  const [toggleSettings, setToggleSettings] = useState({
    notification: false,
    reportGeneration: false,
    disputeSettlement: false,
  });

  const viewProfile = 'View Profile';
  const reviewFeedback = 'Review Feedback';
  const openDispute = 'Open Dispute';
  const deactivateAgent = 'Deactivate Agent';

  const navigate = useNavigate();
  const location = useLocation();

  // handle different more actions
  const handleMoreIconOptions = async (item: any) => {
    if (item.hasOwnProperty('title') && item?.title === viewProfile) {
      navigate(`${AGENTPROFILE}1`);
    }
    if (item.hasOwnProperty('title') && item?.title === reviewFeedback) {
      setShowFeedbacksModal(true);
    }
    if (item.hasOwnProperty('title') && item?.title === openDispute) {
      setShowOpenDisputeModal(true);
    }
    if (item.hasOwnProperty('title') && item?.title === deactivateAgent) {
      setShowDeactivateModal(true);
    }
  };

  return (
    <div>
      <AppContainer
        goBack={() => navigate(DASHBOARD)}
        navTitle={`DASHBOARD`}
        navHelper={
          <div className="tw-flex tw-gap-x-3 tw-items-center">
            <Link
              to={CASHREQUESTINFORMATION}
              className="tw-hidden tw-uppercase tw-my-0 tw-text-gray-300 tw-no-underline tw-text-[.8rem] tw-font-normal md:tw-text-[.9rem] tw-pr-3 tw-border-r-2 tw-border-r-gray-600 hover:tw-text-gray-400 md:tw-block">
              All cash request
            </Link>
            <span className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-[.6rem] md:tw-text-[1rem]">
              Event Requests Settings
            </span>
          </div>
        }
        navBarContentRight={
          <div className="tw-flex tw-items-center tw-gap-x-3 md:tw-gap-x-10 md:tw-mr-6">
            {/* <NotificationIcon onClick={() => setShowNotificationModal(true)} /> */}
          </div>
        }>
        <>
          <section className="tw-mb-4 md:tw-p-0 md:tw-pt-8 md:tw-px-4 md:tw-mb-8">
            <div className="tw-mb-28 tw-mt-4">
              <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-scroll md:tw-gap-x-8">
                {cashRequestTabs.map((items: any, idx: number) => (
                  <div
                    key={items.id}
                    className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
                      activePageTab === items.url
                        ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                        : 'tw-text-[#6A616F]'
                    }`}
                    onClick={() => {
                      if (kycsAnalyticsStatus !== 'loading') {
                        setActiveTab('');
                        setActivePageTab(cashRequestTabs[idx].url);
                      }
                    }}>
                    {items.title}
                  </div>
                ))}
              </div>

              <div>
                {activePageTab === 'general' && (
                  <div className="tw-flex tw-mt-9 tw-p-[30px] tw-bg-white tw-rounded-2xl tw-flex-col tw-justify-start tw-items-start tw-gap-[51px] ">
                    <div className=" tw-w-full tw-grid tw-gap-y-12">
                      <SwitchWithDescription
                        checked={toggleSettings.notification}
                        title="Notifications"
                        description="Set the percentage of agent transaction charge that is paid to zojapay for every transaction they perform"
                        onChange={() => {
                          setToggleSettings({ ...toggleSettings, notification: !toggleSettings.notification });
                          // setCurrentAccess(canViewDashboard);
                        }}
                      />
                      <SwitchWithDescription
                        checked={toggleSettings.reportGeneration}
                        title="Allow Report Generation"
                        description="Set the percentage of agent transaction charge that is paid to zojapay for every transaction they perform"
                        onChange={() => {
                          setToggleSettings({ ...toggleSettings, reportGeneration: !toggleSettings.reportGeneration });
                          // setCurrentAccess(canViewDashboard);
                        }}
                      />
                      <SwitchWithDescription
                        checked={toggleSettings.disputeSettlement}
                        title="Allow dispute settlement"
                        description="Set the percentage of agent transaction charge that is paid to zojapay for every transaction they perform"
                        onChange={() => {
                          setToggleSettings({
                            ...toggleSettings,
                            disputeSettlement: !toggleSettings.disputeSettlement,
                          });
                          // setCurrentAccess(canViewDashboard);
                        }}
                      />
                    </div>

                    <ZojaButton
                      text={'Save Changes'}
                      type={'button'}
                      extraClass={
                        'tw-px-4 tw-py-2.5 tw-bg-blue-900 tw-rounded shadow tw-justify-start tw-items-center tw-gap-2 tw-flex tw-text-neutral-50 tw-text-sm tw-font-medium'
                      }
                    />
                  </div>
                )}

                {activePageTab === 'commissions' && (
                  <div className="tw-flex tw-mt-9 tw-p-[30px] tw-bg-white tw-rounded-2xl tw-flex-col tw-justify-start tw-items-start tw-gap-[51px] ">
                    <div>
                      <h3 className="tw-text-gray-700 tw-text-lg tw-font-medium">Commission Value(%)</h3>
                      <p className="tw-text-zinc-600 tw-text-sm tw-font-normal">
                        Set the percentage of agent transaction charge that is paid to zojapay for every transaction
                        they perform
                      </p>
                    </div>

                    <div className="tw-px-5 tw-pb-8 tw-pt-4 tw-bg-white tw-rounded-lg tw-shadow tw-justify-between tw-w-full tw-items-center tw-flex tw-flex-col lg:tw-flex-row tw-gap-y-5">
                      <div>
                        <h4 className="tw-text-emerald-600 tw-text-base tw-font-medium tw-pb-5">Tier 1(New Cat)</h4>
                        <div className="tw-flex tw-gap-x-5">
                          <div>
                            <ZojaInput label="Agent Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                          <div>
                            <ZojaInput label="Event centre Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="tw-text-emerald-600 tw-text-base tw-font-medium tw-pb-5">Tier 2(Popular)</h4>
                        <div className="tw-flex tw-gap-x-5">
                          <div>
                            <ZojaInput label="Agent Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                          <div>
                            <ZojaInput label="Event centre Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="tw-text-emerald-600 tw-text-base tw-font-medium tw-pb-5">
                          Tier 3(Industry Leader)
                        </h4>
                        <div className="tw-flex tw-gap-x-5">
                          <div>
                            <ZojaInput label="Agent Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                          <div>
                            <ZojaInput label="Event centre Commission" extraClass="tw-w-24 tw-border-isPrimary" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <h3 className="tw-text-gray-700 tw-text-lg tw-font-medium">Terms & Conditions Agreement</h3>
                      <p className="tw-text-zinc-600 tw-text-sm tw-font-normal">
                        view and edit terms and conditions below, commission value above is automatically updated in the
                        first line of text for the user based on event centre
                      </p>

                      <TextArea name="terms" onChange={() => {}} value="" />
                    </div>
                    <ZojaButton
                      text={'Save Changes'}
                      type={'button'}
                      extraClass={
                        'tw-px-4 tw-py-2.5 tw-bg-blue-900 tw-rounded shadow tw-justify-start tw-items-center tw-gap-2 tw-flex tw-text-neutral-50 tw-text-sm tw-font-medium'
                      }
                    />
                  </div>
                )}

                {activePageTab === 'vendor_management' && (
                  <div className="">
                    <div className="tw-grid tw-gap-4 tw-grid-cols-2 xl:tw-grid-cols-3 md:tw-gap-6 tw-text-6xl tw-mt-9">
                      <SingleCountCard
                        // key={id}
                        // id={id}
                        title={'Tier 1 Vendors'}
                        total={'5'}
                        subtitle={''}
                        // active={activeTab === helper ? true : false}
                        onClick={() => {
                          // if (kycsAnalyticsStatus !== 'loading' && kycsStatus !== 'loading') {
                          // setActiveTabHandler(helper);
                          // }
                        }}
                        titleClass="tw-text-6xl"
                        // loading={kycsAnalyticsStatus === 'loading'}
                      />
                      <SingleCountCard
                        // key={id}
                        // id={id}
                        title={'Tier 2 Vendors'}
                        total={'23'}
                        subtitle={''}
                        // active={activeTab === helper ? true : false}
                        onClick={() => {
                          // if (kycsAnalyticsStatus !== 'loading' && kycsStatus !== 'loading') {
                          // setActiveTabHandler(helper);
                          // }
                        }}
                        titleClass="tw-text-6xl"
                        // loading={kycsAnalyticsStatus === 'loading'}
                      />
                      <SingleCountCard
                        // key={id}
                        // id={id}
                        title={'Tier 3 Vendors'}
                        total={'5'}
                        subtitle={''}
                        // active={activeTab === helper ? true : false}
                        onClick={() => {
                          // if (kycsAnalyticsStatus !== 'loading' && kycsStatus !== 'loading') {
                          // setActiveTabHandler(helper);
                          // }
                        }}
                        titleClass="tw-text-6xl"
                        // loading={kycsAnalyticsStatus === 'loading'}
                      />
                    </div>
                    <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28 tw-mt-16">
                      <h2 className="tw-text-zinc-600 tw-text-2xl tw-font-normal tw-mb-4">Registered Agents</h2>
                      <ZojaTable column={registeredAgentsTable.columns}>
                        {registeredAgentsTable.data?.map((data: Dictionary, idx: number) => (
                          <TR key={data.id}>
                            <TD value={idx + 1} />
                            <TD value={data.agentName} extraClass="tw-font-thin" />
                            <TD value={data.phoneNumber} extraClass="tw-font-thin" />
                            <TD value={data.email} extraClass="tw-font-thin" />
                            <TD
                              value={data.successRate}
                              extraClass={`${
                                data.status === 'pending'
                                  ? 'tw-font-thin tw-text-orange-400'
                                  : data.status === 'processing'
                                  ? 'tw-font-thin tw-text-green-500'
                                  : 'tw-font-thin'
                              }`}
                            />
                            <TD
                              value={data.dateRegistered || '-'}
                              extraClass={`${!data.cash_provider && 'tw-text-center tw-font-thin'}`}
                            />
                            <TD>
                              <div onClick={() => setOpenMore(!true)}>
                                {/* <BsThreeDotsVertical /> */}
                                <TDMORE
                                  handleClose={() => setOpenMore(false)}
                                  dropdownList={dropdownList}
                                  onActionClick={item => handleMoreIconOptions(item)}
                                  setSelectedItem={setSelectedAgent}
                                />
                              </div>
                            </TD>

                            {openMore ? <div className="tw-bg-white">dropdown</div> : <></>}
                          </TR>
                        ))}
                      </ZojaTable>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <FeedbacksModal
              show={showFeedbacksModal}
              handleClose={() => setShowFeedbacksModal(false)}
              closeOnClickOutside={false}
              contentRef={successModalRef}
              title="Feedbacks"
            />
            <OpenDisputeModal
              show={showOpenDisputeModal}
              handleClose={() => setShowOpenDisputeModal(false)}
              closeOnClickOutside={false}
              contentRef={successModalRef}
              title="Open a dispute with this agent?"
              content="Opening a dispute will send an email to the agent with preset dispute information"
            />
            <DeactivateModal
              show={showDeactivateModal}
              handleClose={() => setShowDeactivateModal(false)}
              closeOnClickOutside={false}
              contentRef={successModalRef}
              content="Are you sure want to delete this event centre"
            />
          </section>
        </>
      </AppContainer>
    </div>
  );
};

export default EventCashRequestSettings;
