import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllVolumeReportRequest = createAsyncThunk(
  'getAllVolumeReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      model_type,
      time_range,
      min_count,
      max_count,
      min_total_value,
      max_total_value,
      min_total_revenue,
      max_total_revenue,
      payment_method,
      sort_by,
      order_by,
      page,
      per_page,
    } = payload;
    const url = `admin/report/volume`;

    try {
      const response = await api.get(`${url}`, {
        params: {
          model_type,
          time_range,
          min_count,
          max_count,
          min_total_value,
          max_total_value,
          min_total_revenue,
          max_total_revenue,
          payment_method,
          sort_by,
          order_by,
          page: page,
          per_page,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllVolumeReportSlice = createSlice({
  name: 'getAllVolumeReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllVolumeReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllVolumeReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllVolumeReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllVolumeReportReset = getAllVolumeReportSlice.actions.reset;
export const getAllVolumeReportSliceReducer = getAllVolumeReportSlice.reducer;
