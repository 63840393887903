import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import * as yup from "yup";
import { ConfirmPayment, PaymentContainer, RejectPaymentFileUpload } from "../../../atoms";
import PaymentDetails from "../../../atoms/modals/PaymentDetails";
import { CompletedSinglePaymentTable, PaymentEmptyState, PaymentPageTitle, PaymentTableList, SinglePaymentTable } from "../../../components";
import { ZojaButton, ZojaInput, ZojaModal } from "../../../components/tailwind";
import { cn } from "../../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { getReconciliationAccountRequest, getReconciliationAccountReset } from "../../../redux/slice";
import { Dictionary } from "../../../types";
import { images } from "../../../utils";
import icons from '../../../utils/icons';

type ACTIVE_TABS_TYPE = 'upload' | 'view' | 'pending' | 'approved' | ""

const INITIATOR_TABS = ['Initiate Payment', 'View Uploads']
const REVIEWER_TABS = ['Pending Reviews', 'Completed Payments']
const APPROVAL_TABS = ['Pending Approval', 'Completed Payments']
const ACTIVE_USER_PERMISSIONS = ['initiate customer upload', 'review transaction', 'approve transaction']

export default function SingleCreditCustomer() {
    const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false)
    const [showRejectUploadModal, setShowRejectUploadModal] = useState(false)
    const [activeTab, setActiveTab] = useState<ACTIVE_TABS_TYPE>('upload')
    const [selectedPaymentItemId, setSelectedPaymentItemId] = useState('')
    const [selectedTab, setSelectedTab] = useState('')
    const [currentTabs, setCurrentTabs] = useState(APPROVAL_TABS)
    const [searchParams, setSearchParams] = useSearchParams()
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)
    const [isInititiatedPayment, setIsInititiatedPayment] = useState(false)
    const loginState = useAppSelector(state => state.login);
    const {
        data: { name },
    } = useAppSelector(state => state.auth);
    const uploadPaymentListState = useAppSelector(state => state.uploadPaymentList)
    const { status: uploadPaymentStatus } = uploadPaymentListState;

    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const { status: reviewPaymentUploadStatus } = reviewPaymentUploadState

    const approveSinglePaymentState = useAppSelector((state) => state.approveSinglePayment)
    const { status: approveSinglePaymentStatus } = approveSinglePaymentState


    const getAccountDetailsState = useAppSelector(state => state.getReconciliationAccount);
    const { status: getAccountDetailsStatus } = getAccountDetailsState;

    let CURRENT_USER_PERMISSION = loginState?.data?.user?.permissions?.map((d: Dictionary) => d.name)

    const dispatch = useAppDispatch()

    const PAYMENT_TYPE = searchParams.get('type') ? searchParams.get('type') : 'credit'

    const handleGetUserDetails = (acountNumber: any) => {
        dispatch(getReconciliationAccountRequest({ search: acountNumber }))
    }

    const handleFormSubmit = (values: any) => {
        setShowPaymentDetails(true)
    }

    const { values, errors, handleSubmit, handleChange, touched, setFieldValue, resetForm, handleBlur } = useFormik({
        initialValues: {
            account_number: '',
            name: '',
            amount: '',
            narration: '',
            type: PAYMENT_TYPE,
        },
        validationSchema: yup.object().shape({
            account_number: yup.string().required('Enter account number'),
            amount: yup.string().required('Enter amount'),
            narration: yup.string().required('Enter narration'),
        }),
        onSubmit: handleFormSubmit,
        enableReinitialize: true
    })

    useEffect(() => {
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[0])) {
            setCurrentTabs(INITIATOR_TABS)
        }
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1])) {
            setCurrentTabs(REVIEWER_TABS)
        }
        if (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[2])) {
            setCurrentTabs(APPROVAL_TABS)
        }
        dispatch(getReconciliationAccountReset())
        resetForm()
        // setSearchParams({active_tab: ACTIVE_TAB ? ACTIVE_TAB : ''})
    }, [])

    useEffect(() => {
        if (values.account_number && values.account_number.length === 10) {
            handleGetUserDetails(values.account_number)
        }
        setFieldValue('name', '')
    }, [values.account_number])

    useEffect(() => {
        if (getAccountDetailsStatus === 'succeeded') {
            setFieldValue('name', getAccountDetailsState?.data?.user?.name)
        }
    }, [getAccountDetailsStatus, getAccountDetailsState])

    useEffect(() => {
        if (isInititiatedPayment) {
            resetForm()
        }
    },[isInititiatedPayment])

    useEffect(() => {
        if (reviewPaymentUploadStatus === 'succeeded' || approveSinglePaymentStatus === 'succeeded') {
            setActiveTab && setActiveTab('')
        }
    }, [reviewPaymentUploadStatus, approveSinglePaymentStatus])

    return (
        <>
            {/* Upload Success Modal */}
            <ZojaModal
                show={uploadPaymentStatus === "succeeded"}
                handleClose={() => {
                    // setSelectedFile("")
                    // dispatch(uploadPaymentListReset())
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    {/* <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Success!</h3> */}
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>File Upload successful</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">Data is currently being reviewed</span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            // setSelectedFile("")
                            // dispatch(uploadPaymentListReset())
                        }}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={() => {
                                // setSelectedFile("")
                                // dispatch(uploadPaymentListReset())
                            }}
                            text="Close"
                            // loading
                            // disabled
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>

            <ConfirmPayment
                show={showConfirmPaymentModal}
                handleClose={() => setShowConfirmPaymentModal(false)}
                tab={selectedTab}
                selectedPaymentItemId={selectedPaymentItemId}
            />
            <RejectPaymentFileUpload
                show={showRejectUploadModal}
                handleClose={() => setShowRejectUploadModal(false)}
                tab={selectedTab}
                selectedPaymentItemId={selectedPaymentItemId}
            />
            <PaymentDetails
                show={showPaymentDetails}
                handleClose={() => setShowPaymentDetails(false)}
                allowed_permissions={ACTIVE_USER_PERMISSIONS}
                payment_details={{...values, date: new Date()}}
                setIsInititiatedPayment={setIsInititiatedPayment}
                resetForm={resetForm}
            />
            <PaymentContainer
                title="Note: Payments are processed from the Zojapay settlement account, to create a payment proceed below"
            >
                <>
                    <div className="tw-mt-4 tw-flex tw-flex-wrap tw-items-center tw-gap-8 tw-border-b tw-pb-1 tw-w-fit">
                        {
                            currentTabs.map((tab, idx) => (
                                <span key={idx} className={cn(
                                    "tw-text-sm tw-cursor-pointer text-[#7E7E96] tw-relative",
                                    selectedTab === tab && "tw-font-bold tw-text-isPrimary"
                                )}
                                    onClick={() => {
                                        setSelectedTab(tab)
                                        setActiveTab("")
                                    }}
                                >{tab}
                                    {selectedTab === tab && <span className="tw-h-[.15rem] tw-w-full tw-absolute tw-bg-isPrimary tw-left-0 -tw-bottom-1.5 tw-rounded-md"></span>}
                                </span>
                            ))
                        }
                    </div>

                    <div className="tw-mt-4 tw-text-xs">
                        <PaymentPageTitle
                            title={""}
                            contentRight={<>
                                {activeTab === "view" && selectedPaymentItemId &&
                                    (CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1]) || CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[2])) &&
                                    <div className="tw-flex tw-items-center tw-gap-2">
                                        <ZojaButton
                                            text={
                                                CURRENT_USER_PERMISSION.includes(ACTIVE_USER_PERMISSIONS[1]) ? "Send for Approval" :
                                                    'Approve'
                                            }
                                            extraClass="tw-p-[.3rem] tw-px-6 tw-text-[.7rem] tw-font-thin tw-bg-isPrimary tw-text-white tw-rounded-lg"
                                            onClick={() => setShowConfirmPaymentModal(true)}
                                        />
                                        <ZojaButton
                                            text="Reject Upload"
                                            extraClass="tw-p-[.3rem] tw-px-8 tw-text-[.7rem] tw-font-normal tw-bg-transparent tw-text-red-600 tw-rounded-lg hover:tw-bg-red-500 hover:tw-text-white"
                                            onClick={() => setShowRejectUploadModal(true)}
                                        />
                                    </div>}
                            </>}
                        />

                        {selectedTab === "Initiate Payment"
                            && <>
                                <section className="md:tw-w-2/5">
                                    <form onSubmit={handleSubmit}>
                                        <div className="tw-relative">
                                            <ZojaInput
                                                placeholder="Input Account Number"
                                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#c2c2c2] focus:tw-outline-none focus:tw-border-blue-800'
                                                name="account_number"
                                                value={values.account_number}
                                                onChange={handleChange}
                                                touched={touched}
                                                error={errors.account_number}
                                            />
                                            {getAccountDetailsStatus === 'loading' && <span className="tw-absolute tw-right-4 tw-top-3 tw-animate tw-animate-spin tw-text-gray-400"><FaSpinner /></span>}
                                        </div>
                                        <ZojaInput
                                            placeholder="User Account Name"
                                            extraClass='tw-mt-5 placeholder:tw-pl-[1px] tw-pl-3 tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#c2c2c2] focus:tw-outline-none focus:tw-border-blue-800'
                                            value={values.name}
                                            disabled
                                        />
                                        <ZojaInput
                                            placeholder="Input Amount"
                                            extraClass='tw-mt-5 placeholder:tw-pl-[1px] tw-pl-3 tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#c2c2c2] focus:tw-outline-none focus:tw-border-blue-800'
                                            name="amount"
                                            value={values.amount}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            error={errors.amount}
                                        />
                                        <textarea
                                            id=""
                                            cols={5}
                                            rows={6}
                                            className={cn(
                                                'tw-w-full tw-mt-5 placeholder:tw-pl-[1px] tw-pl-3 tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-border tw-text-gray-700 tw-border-[#c2c2c2] focus:tw-outline-none focus:tw-border-blue-800',
                                                touched.narration && errors.narration && '!tw-border-red-500'
                                            )}
                                            placeholder="Enter narration here"
                                            name="narration"
                                            value={values.narration}
                                            onChange={handleChange}
                                        >
                                        </textarea>
                                       {errors.narration && touched.narration && <span className="tw-text-red-500 tw-text-xs"> {errors.narration} </span>}
                                        <ZojaButton
                                            text='Confirm' 
                                            // onClick={() => setShowPaymentDetails(true)}
                                             extraClass={cn(
                                                'tw-mt-4 tw-font-thin tw-border w-border-blue-900 tw-text-[#B6B6B6] tw-bg-[#E7E7E7] w-bg-blue-900 tw-px-10 tw-text-sm tw-rounded-[0.2rem] disabled:tw-bg-[#E7E7E7]',
                                                values.name && values.account_number && values.account_number.length === 10 && "!tw-bg-isPrimary !tw-border-[#DDDFF7] !tw-text-white"
                                            )}
                                            type="submit"
                                            disabled={values.name && values.account_number && values.account_number.length === 10 ? false : true}
                                        />
                                    </form>
                                </section>
                                {/* <CustomFileUploader
                                    selectedFile={selectedFile}
                                    setSelectedFile={handleSetFile}
                                    // handleDragOver={handleSetFile}
                                    handleDrop={handleDrop}
                                    error={fileError}
                                    handleUpload={handleFileUpload}
                                    loading={uploadPaymentStatus === 'loading'}
                                /> */}
                            </>
                        }

                        {selectedTab === "" && <PaymentEmptyState
                            text="There is currently no data for you to review"
                        />}

                        {
                            (selectedTab === "Pending Reviews"
                                || selectedTab === "Pending Approval"
                                || selectedTab === "View Uploads"
                            )
                            && activeTab !== "view" &&
                            <SinglePaymentTable
                                tab={selectedTab}
                                setActiveTab={setActiveTab}
                                handleItemClick={(data) => {
                                    setSelectedPaymentItemId(data)
                                    // setActiveTab("view")
                                }}
                                allowed_permissions={ACTIVE_USER_PERMISSIONS}
                                payment_type={PAYMENT_TYPE as string}
                            />
                        }

{
                            (selectedTab === "Completed Payments"
                            )
                            && activeTab !== "view" &&
                            <CompletedSinglePaymentTable
                                tab={selectedTab}
                                setActiveTab={setActiveTab}
                                handleItemClick={(data) => {
                                    setSelectedPaymentItemId(data)
                                    setActiveTab("view")
                                }}
                                allowed_permissions={ACTIVE_USER_PERMISSIONS}
                                payment_type={PAYMENT_TYPE as string}
                            />
                        }

                        {activeTab === "view" && selectedPaymentItemId &&
                            <PaymentTableList
                                selectedPaymentItemId={selectedPaymentItemId}
                                setActiveTab={setActiveTab}
                            />}
                    </div>
                </>
            </PaymentContainer>
        </>
    )
}
