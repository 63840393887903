import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadVolumeReportRequest = createAsyncThunk(
  'downloadVolumeReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      time_range,
      min_count,
      max_count,
      min_total_value,
      max_total_value,
      min_total_revenue,
      max_total_revenue,
      payment_method,
      exp,
      sort_by,
      order_by,
    } = payload;
    const url = `/admin/report/volume?time_range=${time_range}&min_count=${
      min_count === undefined ? null : min_count
    }&max_count=${max_count === undefined ? null : max_count}&min_total_value=${
      min_total_value === undefined ? null : min_total_value
    }&max_total_value=${max_total_value === undefined ? null : max_total_value}&min_total_revenue=${
      min_total_revenue === undefined ? null : min_total_revenue
    }&max_total_revenue=${
      max_total_revenue === undefined ? null : max_total_revenue
    }&payment_method=${payment_method}&export=${exp}&sort_by=${sort_by}&order_by=${order_by}`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `volumereport.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadVolumeReportSlice = createSlice({
  name: 'downloadVolumeReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadVolumeReportRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadVolumeReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadVolumeReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadVolumeReportReset = downloadVolumeReportSlice.actions.reset;
export const downloadVolumeReportSliceReducer = downloadVolumeReportSlice.reducer;
