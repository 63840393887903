import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaModal } from '../tailwind';

import { Oval } from 'react-loader-spinner';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getPhoneVerificationByIdRequest } from '../../redux/slice';

interface ImageUploadModalProps {
  show: boolean;
  handleClose: () => void;
  handleApprove: (item: any) => void;
  buttoText?: string;
  bvnImage?: string;
  uploadImage?: string;
  selectedItem?: any;
  loading: boolean;
  approveValidationStatus?: string;
}

export interface IPropsInitialValues {
  id: string | number;
  name: string;
  reason_for_change: string;
  status: string;
  telephone: string;
  verification_type: string;
  approved_by: string;
  bvn_image: string;
  verification_image: string;
}

export default function ImageUploadModal({
  show,
  handleClose,
  buttoText,
  uploadImage,
  bvnImage,
  handleApprove = () => '',
  selectedItem,
  loading,
  approveValidationStatus,
}: ImageUploadModalProps) {
  const dispatch = useAppDispatch();

  const getPhoneVerificationByIdState = useAppSelector(state => state.getPhoneVerificationById);
  const { status: getPhoneVerificationByIdStatus, data: getPhoneVerificationByIdData } = getPhoneVerificationByIdState;

  const [phoneVerificationDataList, setPhoneVerificationDataList] = useState<IPropsInitialValues>();

  useEffect(() => {
    if (selectedItem?.userId) {
      dispatch(getPhoneVerificationByIdRequest({ phoneVerificationId: selectedItem?.userId }));
    }
  }, [dispatch, selectedItem?.userId]);

  useEffect(() => {
    if (getPhoneVerificationByIdStatus === 'succeeded' && getPhoneVerificationByIdData) {
      const {
        pending_verification: { id, name, reason_for_change, status, telephone, verification_type, approved_by },
        media: { bvn_image, verification_image },
      } = getPhoneVerificationByIdData || {};

      const updatedList: IPropsInitialValues = {
        id,
        name,
        reason_for_change,
        status,
        telephone,
        verification_type,
        approved_by,
        bvn_image,
        verification_image,
      };
      setPhoneVerificationDataList(updatedList);
    }
  }, [getPhoneVerificationByIdData, getPhoneVerificationByIdData?.data, getPhoneVerificationByIdStatus]);

  return (
    <ZojaModal
      show={show}
      handleClose={handleClose}
      height="auto"
      position="center"
      extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      borderRadius="8px">
      {getPhoneVerificationByIdStatus === 'loading' ? (
        <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
          <Oval
            height="80"
            width="80"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className=" tw-px-10 tw-py-10">
          <div className="tw-flex tw-items-center tw-justify-between">
            <h2 className="tw-text-[#6A616F] tw-text-2xl tw-m-0">Image Upload</h2>
            <span
              className=" tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
              onClick={handleClose}>
              <MdClose />
            </span>
          </div>
          <div className="tw-w-fit tw-mt-8">
            <div className="tw-grid tw-grid-cols-2 tw-gap-x-3 tw-items-center">
              <div>
                <p className="tw-text-xs tw-text-isPrimary tw-m-0 tw-mb-3">BVN Picture</p>
                <img src={phoneVerificationDataList?.bvn_image} alt="success" className="tw-w-fit" />
              </div>
              <div>
                <p className="tw-text-xs tw-text-isRed tw-m-0 tw-mb-3">Uploaded Image</p>
                <img src={phoneVerificationDataList?.verification_image} alt="success" className="tw-w-fit " />
              </div>
            </div>
            <div className="tw-flex tw-mt-8 tw-gap-x-4">
              <ZojaButton
                text={'Approve'}
                onClick={() => handleApprove({ selectedItem, status: 'approved' })}
                type="button"
                loading={loading && approveValidationStatus === 'approved'}
                loadingText="Approve"
                extraClass="tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]"
              />
              <ZojaButton
                text={'Reject'}
                onClick={() => handleApprove({ selectedItem, status: 'declined' })}
                type="button"
                loading={loading && approveValidationStatus === 'declined'}
                loadingText="Reject"
                extraClass="tw-bg-isRed tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]"
              />
            </div>
          </div>
        </div>
      )}
    </ZojaModal>
  );
}
