import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { Formik } from 'formik';
import { FiFilter } from 'react-icons/fi';
import { H3 } from '../../styles';
import {
  CountInfoCard,
  DatePicker,
  BorderedText,
  Pagination,
  SearchInput,
  Modal,
  Picker,
  Input,
  TextArea,
  Button,
  FilterCard,
  ZojaCheckbox,
  FilterLabel,
  FilterColapsible,
  CustomDatePicker,
  TransactionTable,
  SingleCountCard,
  SettlementTable,
} from '../../components';
import {
  SettlementBarChart,
  TabView,
  MoreIconView,
  AppContainer,
  SuccessModalWithCopy,
  TransactionDetailsModal,
} from '../../atoms';
import {
  colors,
  currencyFormat,
  spacing,
  yearDateFormat,
  dateFormat,
  timeFormat,
  showMessage,
  icons,
  capitalizeFirstLetter,
  useSortTable,
  useWindowResize,
  handleSelectedOption,
  formatStartAndEndDateWithDelimeter,
  replaceStringWithBackslach,
  useDelayedFunction,
} from '../../utils';
import {
  AllTransactionContainer,
  AllTransactionContent,
  DateContent,
  InfoCountContainer,
  InfoCountContent,
  TabViewContainer,
  TabContentTwo,
  EscalateFormContainer,
  EscalateBtnContainer,
  CustomerNameContainer,
  DatePickerContainer,
} from './style';

import {
  getTransactionsRequest,
  getEscalationAgentsRequest,
  createEscalationTicketRequest,
  createEscalationTicketReset,
  getTransactionByIdRequest,
  exportTransactionByIdToMailReset,
  settlementAnalyticsRequest,
  downloadTransactionByIdRequest,
} from '../../redux/slice';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { Audio, Oval } from 'react-loader-spinner';
import { ZojaSelect, ZojaInput } from '../../components/tailwind';
type Dictionary = {
  [key: string]: any;
};

const tabViewData = [
  { id: 1, isSelected: true, text: 'Transactions History' },
  // { id: 2, isSelected: false, text: "Bills History" },
  // { id: 3, isSelected: false, text: "Cash Request History" },
];

const transactionDataHeader = [
  {
    id: 1,
    title: '',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Customer',
    value: 'name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Transaction ID',
    value: 'tid',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Amount',
    value: 'amount',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Transaction Type',
    value: 'type',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date',
    value: 'time',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Transaction Details',
    value: 'transactionDetails',
  },
  {
    id: 2,
    title: 'Escalate',
    value: 'escalate',
  },
];

const transactionDateOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];
const initialDate = '2022-01-01';
const currentDate = new Date().toDateString();
// const inflowData = [1000, 90, 100, 800, 500, 100, 900, 70, 80, 100, 800, 700];
// const outflowData = [100, 10, 20, 80, 100, 800, 700, 800, 90, 100, 800, 500];
// const profitData = [90, 50, 100, 91, 68, 100, 45, 70, 80, 30, 800, 50];
const emptyData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
function Settlements() {
  const dispatch = useAppDispatch();
  const [transactionStartDate, setTransactionStartDate] = useState(initialDate);
  const [transactionEndDate, setTransactionEndDate] = useState(currentDate);
  const [startDisplayRecordDate, setStartDisplayRecordDate] = useState('');
  const [endDisplayRecordDate, setEndDisplayRecordDate] = useState('');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [openMore, setOpenMore] = useState(false);
  const [perPage, setPerPage] = useState<any>('10');
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [tTypes] = useState(['Transaction Type', 'Transaction Type']);
  const [tStatus] = useState(['Transaction Status', 'Transaction Status']);
  const [transactionFilterParams, setTransactionFilterParams] = useState({
    type: '',
    status: '',
    start_date: '',
    end_date: '',
    search: '',
  });
  const initialValues: Dictionary = {
    model_type: tTypes[0] !== 'Transaction Type' ? replaceStringWithBackslach(tTypes[0]) : '',
    status: tStatus[0] !== 'Transaction Status' ? tStatus[0] : '',
    per_page: pageSize,
    page: currentPage,
    transaction_date: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
    search: '',
  };

  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [transactionDataList, setTransactionDataList] = useState<any[]>([]);
  const [tabViewSelectedIndex, setTabViewSelectedIndex] = useState<any[number]>(1);
  const [barChartSelectedText, setBarChartSelectedText] = useState('All Data');
  const [totalPages, setTotalPages] = useState(5);
  const [isSearchingUsers, setIsSearchingUsers] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedFailedTransaction, setSelectedFailedTransaction] = useState<any>({});
  const [moreIsVisible, setMoreIsVisible] = useState(false);
  const [showAmountRange, setShowAmountRange] = useState(true);
  const [transactionDate, setTransactionDate] = useState(transactionDateOptions);
  const [showTransactionDate, setShowTransactionDate] = useState(false);
  const [escalateModalVisible, setEscalateModalVisible] = useState(false);
  const [escalateSuccessfulModalVisible, setEscalateSuccessfulModalVisible] = useState(false);
  const [transactionDetailsModalVisible, setTransactionDetailsModalVisible] = useState(false);
  const [selectedTransactionActionText, setSelectedTransactionActionText] = useState('');
  const [escalateSuccessfulData, setEscalateSuccessfulData] = useState<Dictionary>({});
  const [escalationAgentsList, setEscalationAgentsList] = useState<any[]>([]);
  const [transactionByIdData, setTransactionByIdData] = useState<Dictionary>({});
  const transactionDetails = 'Transaction Details';
  const escalate = 'Escalate';
  const moreIconOption = [transactionDetails, escalate];
  const [transactionDateRange, setTransactionDateRange] = useState({ start_date: '', end_date: '' });
  const [selectedEscalateTo, setSelectedEscalateTo] = useState('');
  const [order, setOrder] = useState('ASC');
  const [selectedPriorityLevel, setSelectedPriorityLevel] = useState('');
  const [inflowData, setInflowData] = useState<any[]>([]);
  const [outflowData, setOutflowData] = useState<any[]>([]);
  const [profitData, setProfitData] = useState<any[]>([]);
  const [analyticsSummaryData, setAnalyticsSummaryData] = useState<any[]>([]);
  const [filterDate, setFilterDate] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>({});

  // redux state
  const transactionState = useAppSelector(state => state.getTransactions);
  const { status: getTransactionsStatus } = transactionState;

  const getTransactionByIdState = useAppSelector(state => state.getTransactionById);

  const { status: getTransactionByIdStatus } = getTransactionByIdState;

  const getEscalationAgentsState = useAppSelector(state => state.getEscalationAgents);
  const { status: getEscalationAgentsStatus } = getEscalationAgentsState;

  const createEscalationTicketState = useAppSelector(state => state.createEscalationTicket);
  const { status: createEscalationTicketStatus } = createEscalationTicketState;

  const exportTransactionByIdToMailState = useAppSelector(state => state.exportTransactionByIdToMail);
  const { status: exportTransactionByIdToMailStatus } = exportTransactionByIdToMailState;

  const settlementAnalyticsState = useAppSelector(state => state.settlementAnalytics);
  const { status: settlementAnalyticsStatus } = settlementAnalyticsState;

  const downloadTransactionByIdState = useAppSelector(state => state.downloadTransactionById);
  const { status: downloadTransactionByIdStatus } = downloadTransactionByIdState;

  const escalateCchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    description: yup.string().required('Description is required'),
    escalateTo: selectedEscalateTo.length < 2 ? yup.string().required('To who is required') : yup.string(),
    priorityLevel:
      selectedPriorityLevel.length < 2 ? yup.string().required('Priority level is required') : yup.string(),
  });

  useEffect(() => {
    if (getTransactionByIdStatus === 'succeeded') {
      const {
        amount,
        status,
        currency,
        transfer_purpose,
        charge,
        channel,
        created_at,
        external_account_name,
        source,
        user: { name, email, telephone },
      } = getTransactionByIdState.data.transaction;

      const result = {
        amount,
        status,
        currency,
        data: [
          {
            id: 1,
            text: capitalizeFirstLetter(transfer_purpose),
            helper: 'Transaction Type',
          },
          {
            id: 2,
            text: name,
            helper: 'Wallet Name',
          },
          {
            id: 3,
            text: email,
            helper: 'Email',
          },
          {
            id: 4,
            text: telephone,
            helper: 'Phone Number',
          },
          {
            id: 5,
            text: `N${charge}`,
            helper: 'Charges',
          },
          {
            id: 6,
            text: source !== null ? source?.name : external_account_name,
            helper: 'Sender Name',
          },
          {
            id: 7,
            text: channel,
            helper: 'Channel',
          },
          {
            id: 8,
            text: timeFormat(created_at, true),
            helper: 'Time',
          },
          {
            id: 9,
            text: dateFormat(created_at),
            helper: 'Date',
          },
        ],
      };

      setTransactionByIdData(result);
    }
  }, [getTransactionByIdState]);

  const handleCloseEscalateModal = () => {
    setEscalateModalVisible(false);
    setSelectedTransactionActionText('');
    setSelectedFailedTransaction({});
  };

  // api analytics

  useEffect(() => {
    const payload = {
      startDate: yearDateFormat(transactionStartDate),
      endDate: yearDateFormat(transactionEndDate),
    };
    dispatch(settlementAnalyticsRequest(payload));
  }, [filterDate]);

  useEffect(() => {
    if (settlementAnalyticsStatus === 'succeeded') {
      let inflowResult: any[] = [];
      let outflowResult: any[] = [];
      let profitResult: any[] = [];
      settlementAnalyticsState?.data?.transaction_analytics?.forEach((item: any) => {
        inflowResult.push(parseFloat(item?.data?.in_flow));
        outflowResult.push(parseFloat(item?.data?.out_flow));
        profitResult.push(parseFloat(item?.data?.profit));
      });

      setInflowData(inflowResult);
      setOutflowData(outflowResult);
      setProfitData(profitResult);

      setAnalyticsSummaryData([
        {
          id: 1,
          amount: parseFloat(settlementAnalyticsState?.data?.inflow),
          title: 'Inflow',
          helper: 'Total Income',
          color: colors.blueVariantOne,
        },
        {
          id: 2,
          amount: parseFloat(settlementAnalyticsState?.data?.outflow),
          title: 'Outflow',
          helper: 'Total Withdrawals',
          color: colors.orange,
        },
        {
          id: 3,
          amount: parseFloat(settlementAnalyticsState?.data?.profit),
          title: 'Profit',
          helper: 'Sharing Percentage on Transactions',
          color: colors.greenVariantOne,
        },
      ]);
    }
  }, [settlementAnalyticsState]);

  // api getTransactions
  useEffect(() => {
    dispatch(
      getTransactionsRequest({
        ...transactionFilterParams,
        per_page: filterOptions.per_page,
        page: currentPage,
      }),
    );
  }, [transactionFilterParams, currentPage]);

  useEffect(() => {
    if (getTransactionsStatus === 'succeeded') {
      let updatedList: any[] = [];

      transactionState?.data?.transactions?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          name: item.user.name,
          tid: item.transaction_reference,
          amount: parseFloat(item.amount),
          type: item.type,
          status: item.status,
          icon: true,
          time: item.created_at,
          currency: item.currency,
          phoneNumber: item.user.telephone,
          transId: item.id,
          email: item.user.email,
        });
      });

      const {
        meta: { last_page, per_page },
      } = transactionState?.data?.transactions;

      setTotalPages(last_page);
      setFilterOptions({ ...filterOptions, per_page });
      setTransactionDataList(updatedList);
    }
  }, [transactionState]);

  useEffect(() => {
    // fetch escalation agents on when escalation is clicked from options
    if (selectedTransactionActionText === escalate) {
      dispatch(getEscalationAgentsRequest({ id: 'user' }));
    }
  }, [selectedTransactionActionText]);

  useEffect(() => {
    if (getEscalationAgentsStatus === 'succeeded') {
      let result: any[] = [];
      getEscalationAgentsState.data.internal_users.forEach((item: any) => {
        result.push({
          value: item.id,
          label: item.name,
        });
      });
      setEscalationAgentsList(result);
    }
  }, [getEscalationAgentsState]);

  useEffect(() => {
    if (exportTransactionByIdToMailStatus === 'succeeded') {
      setTransactionDetailsModalVisible(false);
      showMessage({
        type: 'success',
        message: exportTransactionByIdToMailState?.data?.message,
      });
      dispatch(exportTransactionByIdToMailReset());
    }
  }, [exportTransactionByIdToMailState]);

  useEffect(() => {
    if (createEscalationTicketStatus === 'succeeded') {
      setEscalateSuccessfulData(createEscalationTicketState.data.ticket);
      setEscalateSuccessfulModalVisible(true);
      setEscalateSuccessfulData({});
    }
  }, [createEscalationTicketState]);

  const handleCloseEscalateSuccessfulModal = () => {
    setEscalateSuccessfulModalVisible(false);
    setEscalateSuccessfulData({});
    dispatch(createEscalationTicketReset());
    handleCloseEscalateModal();
  };

  const handleTransactionFilter = () => {
    setTransactionFilterParams({
      type: '',
      status: '',
      start_date: startDisplayRecordDate.length < 2 ? '' : yearDateFormat(startDisplayRecordDate),
      end_date: endDisplayRecordDate.length < 2 ? '' : yearDateFormat(endDisplayRecordDate),
      search: searchValue,
    });
  };

  // handle different excalation modules
  const handleMoreIconOptions = async (item: any) => {
    if (selectedFailedTransaction.hasOwnProperty('name') && item.title === escalate) {
      setMoreIsVisible(false);
      setEscalateModalVisible(true);
    }
    if (selectedFailedTransaction.hasOwnProperty('name') && item.title === transactionDetails) {
      setMoreIsVisible(false);
      setTransactionDetailsModalVisible(true);
      dispatch(
        getTransactionByIdRequest({
          transId: selectedFailedTransaction.transId,
        }),
      );
    }
  };

  const handleSetFilterDateToday = () => {
    setTransactionStartDate(initialDate);
    setTransactionEndDate(currentDate);

    setFilterDate(!filterDate);
  };

  // handle search
  const handleOnchangeInternalUser = (value: any) => {
    setSearchValue(value);
  };

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  // filter table handler
  const handleSubmitFilter = () => {
    const { transaction_date: transactionDateValue, ...restPayload } = filterOptions;
    const transaction_date_range = formatStartAndEndDateWithDelimeter(
      transactionDateRange.start_date,
      transactionDateRange.end_date,
    );

    const payload =
      transactionDateValue === 'custom'
        ? { ...restPayload, transaction_date: transaction_date_range }
        : { ...restPayload, transaction_date: transactionDateValue };

    dispatch(getTransactionsRequest(payload));
  };

  // Custom Hooks
  const { handleSortItems } = useSortTable({
    order: order,
    setOrder,
    data: transactionDataList,
    setData: setTransactionDataList,
  });

  const { width } = useWindowResize();

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    setFilterOptions({ ...filterOptions, per_page: pageNumber });
    setPerPage(pageNumber);

    dispatch(
      getTransactionsRequest({
        ...filterOptions,
        per_page: pageNumber,
      }),
    );
  };

  const { delayedFunction } = useDelayedFunction(2000);

  useEffect(() => {
    delayedFunction(handleTransactionFilter);
  }, [searchValue]);

  return (
    <AppContainer navTitle="SETTLEMENTS">
      <div>
        {getTransactionsStatus !== 'loading' && (
          <AllTransactionContainer>
            <H3 color={colors.primary}>All Transactions</H3>
            {showFilterOptions && (
              <div
                className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
                onClick={() => {
                  setShowFilterOptions(false);
                }}></div>
            )}
            <AllTransactionContent>
              <DateContent>
                <div className=" flex tw-flex-col">
                  <p className="tw-text-[#7E7E96] md:tw-text-[0.7rem] my-1">Start Date</p>
                  <DatePicker selectedDate={setTransactionStartDate} />
                </div>
                <div
                  style={{
                    marginLeft: spacing.xsmall,
                    marginRight: spacing.xsmall,
                  }}></div>
                <div className=" flex flex-col ">
                  <div className="tw-text-[#7E7E96] md:tw-text-[0.7rem] my-1 ">End Date</div>
                  <DatePicker selectedDate={setTransactionEndDate} />
                </div>
              </DateContent>

              <BorderedText
                onClick={handleSetFilterDateToday}
                backgroundColor={colors.white}
                color={colors.grey}
                text={'Today'}
              />
              <BorderedText
                onClick={() => {
                  setFilterDate(!filterDate);
                }}
                backgroundColor={colors.primary}
                color={colors.white}
                text={'Filter Page'}
              />
            </AllTransactionContent>
          </AllTransactionContainer>
        )}

        {settlementAnalyticsStatus === 'succeeded' && (
          <InfoCountContainer>
            {analyticsSummaryData.map((item: any) => (
              <InfoCountContent key={item.id}>
                <CountInfoCard
                  title={item.title}
                  helper={item.helper}
                  color={item.color}
                  count={currencyFormat(item.amount)}
                />
              </InfoCountContent>
            ))}
          </InfoCountContainer>
        )}

        {settlementAnalyticsStatus === 'loading' && (
          <section className="tw-my-5 md:tw-mt-8">
            <div className="tw-grid tw-gap-4 tw-grid-cols-2 xl:tw-grid-cols-3 md:tw-gap-6">
              {analyticsSummaryData.map(({ id, helper, title, amount }) => (
                <SingleCountCard
                  key={id}
                  id={id}
                  title={title}
                  total={amount}
                  subtitle={helper}
                  active={false}
                  onClick={() => {}}
                  loading={settlementAnalyticsStatus === 'loading'}
                />
              ))}
            </div>
          </section>
        )}

        {settlementAnalyticsStatus !== 'failed' && (
          <div>
            {settlementAnalyticsStatus === 'succeeded' ? (
              <SettlementBarChart
                setBarChartSelectedText={setBarChartSelectedText}
                inflowData={
                  barChartSelectedText === 'All Data' || barChartSelectedText === 'Inflow' ? inflowData : emptyData
                }
                outflowData={
                  barChartSelectedText === 'All Data' || barChartSelectedText === 'Outflow' ? outflowData : emptyData
                }
                profitData={
                  barChartSelectedText === 'All Data' || barChartSelectedText === 'Profit' ? profitData : emptyData
                }
              />
            ) : (
              getTransactionsStatus !== 'loading' && (
                <div className="tw-w-full tw-bg-white/60 tw-h-[20rem] tw-grid tw-place-content-center">
                  <Audio
                    height="50"
                    width="50"
                    color="#e5e8eb"
                    ariaLabel="audio-loading"
                    wrapperStyle={{}}
                    wrapperClass="wrapper-class"
                    visible={true}
                  />
                </div>
              )
            )}
          </div>
        )}

        {getTransactionsStatus === 'succeeded' && (
          <>
            <TabViewContainer>
              <TabView data={tabViewData} setSelectedIndex={setTabViewSelectedIndex} />
              {tabViewSelectedIndex === 1 && (
                <TabContentTwo>
                  <SearchInput
                    backgroundColor={'transparent'}
                    name="SearchValue"
                    value={searchValue}
                    onChange={(e: any) => handleOnchangeInternalUser(e.target.value)}
                    placeholder="By Name, Transaction ID"
                  />
                  <DatePickerContainer>
                    <div className=" flex flex-col ">
                      <div className="tw-text-[#7E7E96] md:tw-text-[0.7rem] ">Start Date</div>
                      <DatePicker selectedDate={setStartDisplayRecordDate} />{' '}
                    </div>

                    <div className=" flex flex-col ">
                      <div className="tw-text-[#7E7E96] md:tw-text-[0.7rem] ">End Date</div>
                      <DatePicker selectedDate={setEndDisplayRecordDate} />{' '}
                    </div>
                  </DatePickerContainer>

                  <BorderedText
                    onClick={handleTransactionFilter}
                    backgroundColor={colors.primary}
                    color={colors.white}
                    icon={<FiFilter color={colors.white} size={15} />}
                    text="Filter"
                  />

                  <FilterLabel
                    title="Filter Options"
                    icon={<icons.BsFilter />}
                    onClick={() => setShowFilterOptions(true)}>
                    <FilterCard
                      showFilter={showFilterOptions}
                      title="Filter Options"
                      onClick={() => handleSubmitFilter()}
                      extraClass="tw-w-[13rem]">
                      <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden tw-z-[1]">
                        <ZojaSelect
                          options={[
                            { value: '', label: 'Transaction Type' },
                            { value: 'credit', label: 'Credit' },
                            { value: 'debit', label: 'Debit' },
                          ]}
                          name="transactionType"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, type: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />
                        <ZojaSelect
                          options={[
                            { value: '', label: 'Transaction Status' },
                            { value: 'success', label: 'Success' },
                            { value: 'pending', label: 'Pending' },
                            { value: 'failed', label: 'Failed' },
                          ]}
                          name="transactionStatus"
                          showArrown={true}
                          setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                          extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                          arrowDownClass="tw-right-lg tw-top-3.5"
                        />
                        <FilterColapsible
                          title="Amount"
                          show={showAmountRange}
                          onClick={() => setShowAmountRange(!showAmountRange)}>
                          <>
                            <ZojaInput
                              label="Min Amount"
                              id="min_amount"
                              type="number"
                              extraClass="tw-text-xs tw-p-2 tw-mb-2"
                              name="min_amount"
                              value={filterOptions.min_amount}
                              onChange={handleInputChange}
                            />
                            <ZojaInput
                              label="Max Amount"
                              id="max_amount"
                              name="max_amount"
                              type="number"
                              extraClass="tw-text-xs tw-p-2 tw-mb-2"
                              value={filterOptions.max_amount}
                              onChange={handleInputChange}
                            />
                          </>
                        </FilterColapsible>
                        {/* <FilterColapsible
                          title="Transaction Date"
                          show={showTransactionDate}
                          onClick={() => setShowTransactionDate(!showTransactionDate)}
                          extraClass="tw-shadow-none">
                          <>
                            <div className="">
                              {transactionDate.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, transactionDate, setTransactionDate)}
                                  />
                                </div>
                              ))}
                            </div>

                            <div
                              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                                filterOptions.transaction_date === 'custom' && 'tw-max-h-fit'
                              }`}>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="State Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setTransactionDateRange({ ...transactionDateRange, start_date: value })
                                  }
                                />
                              </div>
                              <div className="tw-mb-2">
                                <CustomDatePicker
                                  label="End Date"
                                  value={new Date()}
                                  setValue={value =>
                                    setTransactionDateRange({ ...transactionDateRange, end_date: value })
                                  }
                                />
                              </div>
                            </div>
                          </>
                        </FilterColapsible> */}
                      </div>
                    </FilterCard>
                  </FilterLabel>
                </TabContentTwo>
              )}
            </TabViewContainer>
            {tabViewSelectedIndex === 1 && (
              <div>
                <SettlementTable
                  data={transactionDataList}
                  tableColumns={transactionDataHeader}
                  setSelectedTableItem={setSelectedFailedTransaction}
                  tab={'verified_users'}
                  setOpenMore={setOpenMore}
                  dropdownList={dropdownList}
                  sorting={handleSortItems}
                  order={order}
                  handleMoreIconOptions={handleMoreIconOptions}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            )}
          </>
        )}

        <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
          <Pagination
            isLoading={getTransactionsStatus === 'loading' ? true : false}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={selectedPage => {
              setCurrentPage(selectedPage);
            }}
          />
          {getTransactionsStatus !== 'loading' ? (
            <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
              <span className="tw-text-xs tw-w-16">Per page</span>
              <ZojaSelect
                options={[
                  { label: '10', value: '10' },
                  { label: '20', value: '20' },
                  { label: '50', value: '50' },
                  { label: '100', value: '100' },
                  { label: '200', value: '200' },
                ]}
                value={perPage}
                // value={customerAccountStatement?.data?.transactions?.per_page}
                setSelectedOption={selected => handlePageNumberChange(selected)}
                extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <Modal isModalVisible={escalateModalVisible} closeModal={handleCloseEscalateModal}>
          <Formik
            initialValues={{
              title: '',
              description: '',
              escalateTo: '',
              priorityLevel: '',
            }}
            enableReinitialize={true}
            validationSchema={escalateCchema}
            onSubmit={async (values, { setSubmitting }) => {
              const { title, description } = values;

              await dispatch(
                createEscalationTicketRequest({
                  title,
                  description,
                  internal_user_id: selectedEscalateTo,
                  priority_level: selectedPriorityLevel,
                  customer_telephone: selectedFailedTransaction?.phoneNumber,
                }),
              );

              setSubmitting(false);
            }}>
            {formikProps => {
              const { handleChange, values, handleSubmit, errors } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <EscalateFormContainer>
                    <CustomerNameContainer>
                      <Input
                        label="Customer Name"
                        backgroundColor={colors.white}
                        borderColor={colors.grey}
                        placeholder="Enter title"
                        type="text"
                        value={selectedFailedTransaction?.name}
                        name={'name'}
                        onChange={() => {}}
                      />
                    </CustomerNameContainer>

                    <Input
                      label="Title"
                      backgroundColor={colors.white}
                      borderColor={colors.grey}
                      placeholder="Enter title"
                      type="text"
                      value={values.title}
                      name={'title'}
                      onChange={handleChange}
                      error={errors.title}
                    />

                    <TextArea
                      label="Title"
                      backgroundColor={colors.white}
                      borderColor={colors.grey}
                      placeholder="Type here..."
                      value={values.description}
                      name={'description'}
                      onChange={handleChange}
                      error={errors.description}
                    />

                    <Picker
                      error={errors.escalateTo}
                      label="Escalate to"
                      selectedValue={setSelectedEscalateTo}
                      placeholder="Select Agent"
                      options={escalationAgentsList}
                    />

                    <Picker
                      error={errors.priorityLevel}
                      label="Priority Level"
                      selectedValue={setSelectedPriorityLevel}
                      placeholder="Select Priority"
                      options={[
                        { label: 'Low', value: 'low' },
                        { label: 'Medium', value: 'medium' },
                        { label: 'High', value: 'high' },
                      ]}
                    />
                    <EscalateBtnContainer>
                      <Button
                        type="submit"
                        text="Escalate"
                        disabled={createEscalationTicketStatus === 'loading' ? true : false}
                      />
                      <Button
                        onClick={handleCloseEscalateModal}
                        text="Cancel"
                        disabled={false}
                        secondary
                        borderColor="transparent"
                        color={colors.primary}
                      />
                    </EscalateBtnContainer>
                  </EscalateFormContainer>
                </form>
              );
            }}
          </Formik>
        </Modal>

        {/* Escalation successful modal */}
        <SuccessModalWithCopy
          isModalVisible={escalateSuccessfulModalVisible}
          closeModal={handleCloseEscalateSuccessfulModal}
          text={'Complaint has been escalated with Ticket Id:'}
          copyIconText={'Copy Ticket:Id'}
          title={escalateSuccessfulData?.ticket_reference}
          iconType="sent"
        />

        <TransactionDetailsModal
          status={transactionByIdData?.status}
          amount={transactionByIdData?.amount}
          currency={transactionByIdData?.currency}
          isModalVisible={transactionDetailsModalVisible}
          closeModal={() => setTransactionDetailsModalVisible(false)}
          onClickExportBtn={() =>
            dispatch(
              downloadTransactionByIdRequest({
                transId: selectedFailedTransaction.transId,
              }),
            )
          }
          actionBtnText="Download"
          exportBtnDisabled={downloadTransactionByIdStatus === 'loading' ? true : false}
          data={transactionByIdData?.data}
          isLoading={getTransactionByIdState.status === 'loading' ? true : false}
        />

        <MoreIconView
          setSelectedText={setSelectedTransactionActionText}
          isModalVisible={moreIsVisible}
          closeModal={() => setMoreIsVisible(false)}
          options={moreIconOption}
          onClick={item => handleMoreIconOptions(item)}
        />
        {getTransactionsStatus === 'loading' && (
          <>
            <div className="tw-h-[70vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="80"
                width="80"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          </>
        )}
      </div>
    </AppContainer>
  );
}

export default Settlements;
