import { memo } from 'react';
import { Container } from './style';
import { H4 } from '../../styles';
import { colors } from '../../utils';
import { Bottom } from '../cards/countInfoCardBorderText/style';

export interface TabIProps {
  id?: number;
  paddingRight?: boolean;
  isSelected: boolean;
  text: string;
  type?: string;
  tabViewSelectedIndex?: number;
  onClick?: () => void;
}

function TabButton({ text, isSelected, onClick, paddingRight, type, tabViewSelectedIndex }: TabIProps) {
  const color = isSelected ? colors.primary : 'black';
  const Buttomcolor = isSelected ? colors.primary : 'transparent';

  return (
    <Container style={{}} isSelected paddingRight={paddingRight} onClick={onClick}>
      <H4
        style={{
          borderBottom: `2px solid ${Buttomcolor}`,
          padding: '0rem 2rem',
          marginRight: '0.3rem',
          fontWeight: `${isSelected ? 'bold' : 'unset'} `,
          marginTop: '1rem',
        }}
        color={color}>
        {text}
      </H4>
    </Container>
  );
}

export default memo(TabButton);
