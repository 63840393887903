import { useState } from 'react';
import { Container, BtnContainer } from './style';
import { Modal, LoginHistoryTable, EmptyState } from '../../components';
import { H2, H3, H5 } from '../../styles';
import { TabViewLoginHistory } from '../../atoms/';
import { colors, spacing } from '../../utils';
import { LoginHistoryIProps } from '../../components/tables/loginHistoryTable';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as EmptyStateNote } from '../../assets/svg/emptyStateNote.svg';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  title?: string;
  data: LoginHistoryIProps[];
  data2: LoginHistoryIProps[];
  headerData1?: any;
  headerData2?: any;
  actionClick: () => void;
  isLoading: boolean;
  loginAttemptsData?: any;
}

const tabViewData = [
  { id: 1, isSelected: true, text: 'User`s History' },
  { id: 2, isSelected: false, text: 'Admin View History' },
];
function LoginHistoryModal({
  isModalVisible,
  closeModal,
  title,
  data,
  data2,
  headerData1,
  headerData2,
  isLoading,
  loginAttemptsData,
}: IProps) {
  const [tabViewLoginHistorySelectedIndex, setTabViewLoginHistorySelectedIndex] = useState<any[number]>(1);

  // THIS FUNCTION RESETS FILTERS
  const handleReset = () => {
    window.location.reload();
  };
  console.log(data);

  return (
    <Modal title={title} isModalVisible={isModalVisible} closeModal={closeModal}>
      {isLoading ? (
        <Container style={{ display: 'flex' }}>
          <Oval
            height="500"
            width="80"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </Container>
      ) : (
        <Container>
          <TabViewLoginHistory
            data={tabViewData}
            loginAttemptsData={loginAttemptsData}
            setSelectedIndex={setTabViewLoginHistorySelectedIndex}
            tabViewSelectedIndex={tabViewLoginHistorySelectedIndex}
          />
          {tabViewLoginHistorySelectedIndex === 1 && data?.length >= 1 && (
            <LoginHistoryTable data={data} headerData={headerData1} />
          )}
          {tabViewLoginHistorySelectedIndex === 2 && data2?.length >= 1 && (
            <LoginHistoryTable data={data2} headerData={headerData2} />
          )}

          {tabViewLoginHistorySelectedIndex === 1 && data?.length < 1 && (
            <H2
              center
              semiBold
              color={colors.grey}
              style={{
                marginTop: spacing.small,
                marginBottom: spacing.small,
              }}>
              <EmptyState
                img={<EmptyStateNote width={200} height={200} />}
                description="No Record Found"
                btnText="Reset Data"
                btnBackground="tw-bg-isPrimary"
                onClick={() => handleReset()}
              />
            </H2>
          )}
          {tabViewLoginHistorySelectedIndex === 2 && data2?.length < 1 && (
            <H2
              center
              semiBold
              color={colors.grey}
              style={{
                marginTop: spacing.small,
                marginBottom: spacing.small,
                height: '55vh',
                display: 'grid',
                placeContent: 'center',
              }}>
              <EmptyState
                img={<EmptyStateNote width={200} height={200} />}
                description="No Record Found"
                btnText="Reset Data"
                btnBackground="tw-bg-isPrimary"
                onClick={() => handleReset()}
              />
            </H2>
          )}
          <div
            style={{
              width: '100%',
              marginTop: spacing.xsmall,
            }}>
            {
              // <BtnContainer style={{ marginBottom: '20px' }}>
              //   <H3 semiBold onClick={closeModal} color={colors.blueVariantThree} style={{ cursor: 'pointer' }}>
              //     Close
              //   </H3>
              // </BtnContainer>
            }
          </div>
        </Container>
      )}
    </Modal>
  );
}

export default LoginHistoryModal;
