// import { TableTag, TD, TH, TR, TableContainer } from './style';
import { TransactionCard, ZojaTable } from '../..';
import { TD, TR, CUSTOMTD } from '../../tailwind/table/tableElements';
import { currencyFormat } from '../../../utils';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
import moment from 'moment';
import { Dictionary } from '../../../types';
import { FaChevronRight } from 'react-icons/fa';

export interface TableIPropsIProps {
  data: Dictionary[];
  type: string;
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData?: any;
  sorting?: any;
  order?: string;
  tableColumns?: any;
  onClick: any;
}

const emptyListCenterStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

function TransactionTable({ data, setSelectedItem, type, onClick, sorting, order, tableColumns }: TableIPropsIProps) {
  const handleOnSelect = (item: any) => {
    setSelectedItem(item);
    onClick(item);
    // setMoreIsVisible(true);
  };

  return (
    <div>
      {data?.length >= 1 ? (
        <>
          {type === 'transactions' ? (
            <ZojaTable column={tableColumns} sorting={sorting} order={order}>
              {data?.map((item: Dictionary, idx: number) => (
                <TR key={idx}>
                  <TD value={idx + 1} />
                  <TD value={moment(item.time).format('DD MMMM, YYYY - hh:mm a')} />
                  <TD>{item.name}</TD>
                  <TD> {item.type}</TD>
                  <TD>{item.app_version || 'N/A'}</TD>
                  <TD>
                    <>{currencyFormat(item.amount, true)}</>
                  </TD>
                  <TD
                    extraClass={`${
                      item.status === 'success'
                        ? 'tw-text-green-500'
                        : item.status === 'failed'
                        ? 'tw-text-red-500'
                        : item.status === 'pending'
                        ? 'tw-text-isOrange'
                        : 'tw-text-black'
                    }`}
                    value={item.status === 'success' ? 'Successful' : item.status === 'failed' ? 'Failed' : 'Pending'}
                  />
                  <TD> {item.details}</TD>
                  <TD extraClass="tw-cursor-pointer">
                    <FaChevronRight onClick={() => handleOnSelect(item)} />
                  </TD>
                </TR>
              ))}
            </ZojaTable>
          ) : type === 'mainTransactions' ? (
            <ZojaTable column={tableColumns} sorting={sorting} order={order}>
              {data?.map((item: Dictionary, idx: number) => (
                <TR key={idx}>
                  <TD value={idx + 1} />
                  <TD value={moment(item.time).format('DD MMMM, YYYY - hh:mm a')} />
                  <TD>{item.name}</TD>
                  <TD> {item.type}</TD>
                  <TD>{item.app_version || 'N/A'}</TD>
                  <TD>
                    <>{currencyFormat(item.amount, true)}</>
                  </TD>
                  <TD
                    extraClass={`${
                      item.status === 'success'
                        ? 'tw-text-green-500'
                        : item.status === 'failed'
                        ? 'tw-text-red-500'
                        : item.status === 'pending'
                        ? 'tw-text-isOrange'
                        : 'tw-text-black'
                    }`}
                    value={item.status === 'success' ? 'Successful' : item.status === 'failed' ? 'Failed' : 'Pending'}
                  />

                  <CUSTOMTD value={item.transactionDetails} />

                  <TD extraClass="tw-cursor-pointer">
                    <FaChevronRight onClick={() => handleOnSelect(item)} />
                  </TD>
                </TR>
              ))}
            </ZojaTable>
          ) : type === 'billHistory' ? (
            <div>
              {data.map((item: any) => (
                <TransactionCard
                  cardType={type}
                  key={item.id}
                  onClick={() => handleOnSelect(item)}
                  id={item.id}
                  tid={item.tid}
                  name={item.name}
                  amount={item.name}
                  status={item.status}
                  type={item.type}
                  time={item.type}
                />
              ))}
            </div>
          ) : (
            <div style={emptyListCenterStyle}>
              <EmptySearchIcon />
            </div>
          )}
        </>
      ) : (
        <div style={emptyListCenterStyle}>
          <EmptySearchIcon />
        </div>
      )}
    </div>
  );
}

export default TransactionTable;
