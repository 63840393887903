import styled from 'styled-components';
import { spacing } from '../../utils';

interface StyleProps {
  backgroundColor?: string;
  type?: string;
}

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-start;
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
