import { AppContainer } from '../../atoms';

export default function BanAndUnban() {
  return (
    <AppContainer
      navTitle={
        <span className="tw-ml-3 tw-text-[#5E6366] tw-text-xs tw-font-medium md:tw-text-[1.2rem]">Ban/Unban</span>
      }>
      <div className="tw-p-2 tw-mb-28 tw-mt-4 md:tw-mt-7">Ban and Unban</div>
    </AppContainer>
  );
}
