import { useNavigate } from 'react-router-dom';
import { AppContainer } from '../../atoms';
import {
  dateFormat,
  formatStartAndEndDateWithDelimeter,
  getEverySelectedOptionValue,
  getSelectedOptionValue,
  replaceCommasInQueryParam,
  routesPath,
  useDebounce,
} from '../../utils';

import { userReportColumn } from './dummyData';
import { Dictionary } from '../../types';
import { useEffect, useRef, useState } from 'react';
import {
  downloadUserReportRequest,
  downloadUserReportReset,
  getAllUserReportRequest,
  getLocationsRequest,
  resetUserFilterOptions,
  saveReportRequest,
  saveReportReset,
  searchUserAccountRequest,
  setUserFilterOptions,
} from '../../redux/slice';

import UserGeneratedReport from '../../atoms/generatedReports/userGeneratedReport';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  dateOptions,
  dateOptionsLogin,
  dateOptionsOnboard,
  genderOptions,
  kycLevelOptions,
  manageColumnOptions,
  sortByOtionsVolUser,
} from './filterOptions';
import { DownloadSuccessModal } from '../../components';
import SaveReportModal from '../../components/modalTypes/SaveReportModal';
import moment from 'moment';
const { REPORT } = routesPath;

export interface userReportRow {
  id: number;
  sn: number;
  lastLoginTime: string;
  accountName: string;
  accountNumber: number;
  phoneNumber: string;
  accountBalance: string;
  status: string;
  kyc: string;
  gender: string;
  age: string;
  totalTransactions: string;
  location: string;
  dateOnboarded: string;
}

const UserReport = () => {
  // REFS
  const successModalRef = useRef<any>(null);
  const saveReportModalRef = useRef<any>(null);

  // REDUX STATES
  const allUserReportState = useAppSelector(state => state.getAllUserReport);
  const getLocationsState = useAppSelector(state => state.getLocations);

  const [selectedPageOption, setSelectedPageOption] = useState<'10' | '20' | '30'>();

  const { status: getAllUserReportStatus, data: getAllUserReportData } = allUserReportState;
  const { status: getLocationsStatus, data: getLocationsData } = getLocationsState;

  const userFilterOptions = useAppSelector(state => state.userFilterOptions);

  const searchedUserAccount = useAppSelector(state => state.searchUserAccount);

  const { status: downloadUserReportStatus } = useAppSelector(state => state.downloadUserReport);

  const { status: saveReportStatus } = useAppSelector(state => state.saveReportSlice);

  // ROUTER HOOKS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //   REACT STATES
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(allUserReportState?.data?.meta?.current_page || 1);
  const [perPage, setPerPage] = useState();
  const [userReportData, setUserReportData] = useState<userReportRow[]>([]);
  const [search, setSearch] = useState<any>();
  const [visibleColumns, setVisibleColumns] = useState(userReportColumn.map(column => column.id));
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [showSuccessSavedModal, setShowSuccessSavedModal] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportQueries, setReportQueries] = useState('');
  const [searchLocationsResults, setSearchLocationsResults] = useState<any>([]);

  useEffect(() => {
    const urlLink = getAllUserReportData?.links?.last;

    // Find the index of the first "?" and the last "&"
    const firstQuestionMarkIndex = urlLink?.indexOf('?');
    const lastAmpersandIndex = urlLink?.lastIndexOf('&');

    // Extract the substring between the first "?" and the last "&"
    const extractedParams =
      firstQuestionMarkIndex !== -1 ? urlLink?.substring(firstQuestionMarkIndex + 1, lastAmpersandIndex) : '';

    if (extractedParams?.startsWith('https://')) {
      // Set expectedParams to an empty string if it starts with "https://"
      setReportQueries('');
    } else {
      setReportQueries(replaceCommasInQueryParam(extractedParams));
    }
  }, [downloadUserReportStatus, getAllUserReportData?.links?.last, reportQueries]);

  //   DEBOUNCE HOOK
  const debouncedSearch = useDebounce(search, 300);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    ageRange: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
  });

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState({
    sortBy: sortByOtionsVolUser.aphabetically,
    orderBy: sortByOtionsVolUser.orderBy,
    gender: genderOptions,
    accountInfo: [] as any[],
    transactionDate: dateOptions,
    dateOnboarded: dateOptionsOnboard,
    lastLogin: dateOptionsLogin,
    location: [] as any[],
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    manageColumn: manageColumnOptions.user,
    reportFilterType: { title: 'Filter Type', id: 1, value: 'initial' },
  });

  // CHECKS IF THE DATE SELECTED IS CUSTOM
  const isCustomInDateOptions = (dateOptions: any[]) => {
    return dateOptions?.some(item => item.value === 'custom' && item.isChecked);
  };
  const isCustomInDateOnboarded = isCustomInDateOptions(filterOption.dateOnboarded);
  const isCustomInLastLogin = isCustomInDateOptions(filterOption.lastLogin);

  const initialValues: Dictionary = {
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [filterOptions, setFilterOptions] = useState(initialValues);

  const handleSubmitFilter = () => {
    dispatch(getAllUserReportRequest(userFilterOptions));
  };

  // close filter on state change
  useEffect(() => {
    setShowOptions({ ...showOptions, sortBy: false });
  }, [getAllUserReportStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    dispatch(downloadUserReportRequest({ ...userFilterOptions, exp: type, per_page: selectedPageOption || 10 }));
    setShowOptions({ ...showOptions, exportOptions: false });
  };

  //SHOWS DOWNLOAD SUCCESS MODAL
  useEffect(() => {
    if (downloadUserReportStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadUserReportStatus]);

  //SHOWS SAVED SUCCESS MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSuccessSavedModal(true);
    }
  }, [saveReportStatus]);

  //SHOWS SAVE REPORT MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSaveReportModal(false);
    }
  }, [saveReportStatus]);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setUserFilterOptions({ ...userFilterOptions, [name]: value }));
  };

  // THIS EFFECT UPDATES THE FILTER OPTIONS FOR USERS
  useEffect(() => {
    dispatch(
      setUserFilterOptions({
        ...userFilterOptions,
        date_onboarded: isCustomInDateOnboarded
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.dateOnboarded),
        last_seen: isCustomInLastLogin
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.lastLogin),

        gender: getEverySelectedOptionValue(filterOption.gender),
        filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
        level: getEverySelectedOptionValue(filterOption.kycLevel),
        location: getEverySelectedOptionValue(filterOption.location),
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
        per_page: selectedPageOption,
        page: currentPage,
      }),
    );
  }, [dispatch, filterOption, selectedPageOption, currentPage]);

  // THIS EFFECT CALLS GET USER ENDPOINT WHEN selectedPageOption CHANGES
  useEffect(() => {
    if (selectedPageOption) {
      dispatch(getAllUserReportRequest({ userFilterOptions, per_page: selectedPageOption }));
    }
  }, [selectedPageOption]);

  useEffect(() => {
    return () => {
      dispatch(resetUserFilterOptions()); // Reset the filter options when the component is unmounted
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentPage) {
      dispatch(getAllUserReportRequest({ ...userFilterOptions, page: currentPage }));
    }
  }, [currentPage]);

  // THIS EFFECT CALLS SEARCH ENDPOINT WHEN THE DEBOUNCED SEARCH VALUE CHANGES
  useEffect(() => {
    if (search) {
      dispatch(searchUserAccountRequest({ search: debouncedSearch }));
    }
  }, [dispatch, debouncedSearch]);

  useEffect(() => {
    dispatch(getAllUserReportRequest(userFilterOptions));
  }, [dispatch, filterOptions]);

  //  // THIS EFFECT CALLS GET LOCATION ENDPOINT
  useEffect(() => {
    dispatch(getLocationsRequest());
  }, []);

  //THIS EFFECT STORES THE LOCATION IN A NEW MODIFIED ARRAY
  useEffect(() => {
    if (getLocationsStatus === 'succeeded') {
      let updatedList: any[] = [];

      getLocationsData?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          name: item.location,
          value: item.location,
          isChecked: false,
        });
      });

      setFilterOption({ ...filterOption, location: updatedList });
      setSearchLocationsResults(updatedList);
    }
  }, [getLocationsData, getLocationsStatus]);

  // THIS EFFECT UPDATES THE USER REPORT DATA
  useEffect(() => {
    if (getAllUserReportStatus === 'succeeded') {
      let updatedList: userReportRow[] = [];

      allUserReportState?.data?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          sn: index + 1,
          lastLoginTime:
            item?.last_login === null
              ? '-'
              : `${dateFormat(item?.last_login)}, ${moment(item?.last_login).format('HH:mm')}`,
          accountName: item?.account?.name || '-',
          accountNumber: item?.account?.number || '-',
          phoneNumber: item?.telephone || '-',
          accountBalance: item?.account?.balance || '-',
          status: item?.status || '-',
          kyc: item?.kyc_level || '-',
          gender: item?.gender || '-',
          age: item?.age || '-',
          totalTransactions: item?.total_transactions === 0 ? 0 : item?.total_transactions,
          location: item?.location || '-',
          dateOnboarded: dateFormat(item?.created_at),
        });
      });

      const {
        meta: { per_page, total },
      } = allUserReportState?.data;
      setTotalItems(total);
      setPerPage(per_page);

      setUserReportData(updatedList);
      // setUserReportDataList(updatedList);
    }
  }, [getAllUserReportStatus, allUserReportState]);

  // CALLS API WHEN THE APPLY FILTER BUTTON IS CLICKED
  const handleApplyFilter = () => {
    dispatch(getAllUserReportRequest(userFilterOptions));
  };

  // THIS EFFECT UPDATES THE USERLIST DATA
  useEffect(() => {
    if (searchedUserAccount.status === 'succeeded') {
      let updatedList: any[] = [];

      searchedUserAccount?.data?.users?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          isChecked: false,
          accountNumber: item.account_number,
          name: item.name,
          value: item.name,
          telephone: item.telephone,
        });
      });

      setFilterOption({ ...filterOption, accountInfo: updatedList });
    }
  }, [searchedUserAccount?.data.user, searchedUserAccount?.data?.users, searchedUserAccount.status]);

  const handleShowSaveReport = () => {
    setShowSaveReportModal(true);
    setShowSuccessDownloadModal(false);
  };

  // input changer handler for saved report
  const handleSavedReportInputChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setReportName(value);
  };

  // SAVES REPORT WHEN BUTTON IS CLICKED
  const handleReportSave = () => {
    const payload = { query: reportQueries, name: reportName, type: 'user' };
    dispatch(saveReportRequest(payload));
  };

  // THIS FUNCTION SEARCH LOCALLY FOR ITEMS
  const handleLocationSearch = (e: { target: { value: any } }) => {
    const query = e.target.value;
    // setSearchQuery(query);
    const filteredResults = filterOption.location.filter((item: { name: string }) =>
      item.name.toLowerCase().includes(query.toLowerCase()),
    );
    setSearchLocationsResults(filteredResults);
  };

  return (
    <AppContainer
      navTitle="Reports"
      goBack={() => navigate(REPORT)}
      navHelper={
        <>
          <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">Reports</h4>
        </>
      }>
      <div>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            setShowSuccessDownloadModal(false);
            dispatch(downloadUserReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
          handleShowSaveReport={() => {
            handleShowSaveReport();
            dispatch(downloadUserReportReset());
          }}
          type="saveDownloadModal"
        />
        <DownloadSuccessModal
          show={showSuccessSavedModal}
          handleClose={() => {
            setShowSuccessSavedModal(false);
            dispatch(saveReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Report Saved"
          content="Your report has been saved successfully"
        />
        <SaveReportModal
          show={showSaveReportModal}
          handleClose={() => setShowSaveReportModal(false)}
          closeOnClickOutside={false}
          contentRef={saveReportModalRef}
          onHandleChange={handleSavedReportInputChange}
          title="Save your Report"
          value={reportName}
          onSave={handleReportSave}
          saveReportStatus={saveReportStatus}
          content="Give your report a name and see it on your report homepage to reprint anytime, and would not be automatically deleted with rest of your history"
        />
        <h1 className="tw-text-gray-800 tw-text-2xl tw-font-medium tw-my-7">User Report</h1>
        <div className="">
          <UserGeneratedReport
            onSubmitFilter={() => handleSubmitFilter()}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            column={userReportColumn}
            tableData={userReportData}
            onExport={handleExportFile}
            onApplyFilter={() => handleApplyFilter()}
            manageColumnOptions={manageColumnOptions}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            showManageColumn={showManageColumn}
            setShowManageColumn={setShowManageColumn}
            setSearch={setSearch}
            searchedUsers={searchedUserAccount.data.users}
            handleInputChange={handleInputChange}
            setSelectedPageOption={setSelectedPageOption}
            itemsPerPage={perPage}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            userState={allUserReportState}
            visibleColumns={visibleColumns}
            // handleShowSaveReport={handleShowSaveReport}
            handleClientSideSearch={handleLocationSearch}
            setVisibleColumns={setVisibleColumns}
            downloadStatus={downloadUserReportStatus}
            locations={searchLocationsResults}
            userFilterOptions={userFilterOptions}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default UserReport;
