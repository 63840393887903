import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {}
} as InitState;

export const getCompletedSinglePaymentRequest = createAsyncThunk('getCompletedSinglePayment', async (payload: Dictionary, { dispatch }) => {
  const { page, per_page, type } = payload;
  const url = `admin/single-transaction/completed?type=${type}&page=${page}&per_page=${per_page}`;
  // &page=${page}&per_page=${per_page}
  try {
    const response = await api.get(url);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

const getCompletedSinglePaymentSlice = createSlice({
  name: 'getCompletedSinglePayment',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
    setBatchPaymentListFilters: (state, action) => {
      state.filters = action.payload
    }
  },
  extraReducers: builder => {
    builder.addCase(getCompletedSinglePaymentRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getCompletedSinglePaymentRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getCompletedSinglePaymentRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getCompletedSinglePaymentReset = getCompletedSinglePaymentSlice.actions.reset;
export const getCompletedSinglePaymentSliceReducer = getCompletedSinglePaymentSlice.reducer;
