import React, { ReactElement } from 'react';
import { colors } from '../../../../utils';

interface InputProps {
  id?: string;
  icon?: ReactElement;
  label?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: string;
  extraClass?: string;
  labelExtraClass?: string;
  style?: React.CSSProperties;
  name?: string;
  marginTop?: string;
  onBlur?: (e: any) => void;
  error?: any;
  touched?: any;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  disabled?: boolean;
  hasClearIcon?: boolean;
  clearIcon?: ReactElement;
  onClear?: (e: any) => void;
}

const ZojaInput = ({
  id,
  name = '',
  icon,
  marginTop,
  label,
  value,
  onChange,
  onBlur,
  error,
  touched = {},
  placeholder,
  type = 'text',
  extraClass,
  iconPosition,
  iconColor = colors.primary,
  labelExtraClass,
  disabled = false,
  hasClearIcon = false,
  clearIcon,
  onClear,
}: InputProps) => {
  return (
    <div className={`${marginTop} tw-w-full`}>
      {label && (
        <label htmlFor={name} className={`${labelExtraClass} tw-text-[12px] tw-mb-1 tw-block`}>
          {label}
        </label>
      )}
      <div className="tw-relative tw-w-full ">
        <input
          id={id}
          name={name}
          value={value}
          onChange={onChange}
          type={type}
          onBlur={onBlur}
          className={`${extraClass} tw-border tw-w-full tw-block tw-bg-[#FFFFFF08] tw-rounded-md tw-font-medium tw-placeholder:text-xs tw-focus:outline-none tw-placeholder:text-[#FFFFFF40] tw-appearance-none
          placeholder:tw-pl-[1px tw-p-2.5 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-text-gray-600 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-isPrimary
          ${
            touched[name] && error ? 'tw-border-red-400' : ''
          }`}
          placeholder={placeholder}
          disabled={disabled}
        />

        {touched[name] && error && <span className="tw-text-xs tw-text-red-400">{error}</span>}

        {hasClearIcon && value && (
          <button
            className="tw-absolute tw-top-3.5 tw-right-4 tw-border-none tw-bg-none tw-cursor-pointer"
            onClick={onClear}
            type="button">
            <span className="">{clearIcon}</span>
          </button>
        )}

        {icon && (
          <span
            className={`tw-text-${iconColor} tw-absolute tw-top-3.5 ${
              iconPosition === 'left' ? 'tw-left-2.5' : 'tw-right-2.5'
            } tw-font-light`}>
            {icon}
          </span>
        )}
      </div>
    </div>
  );
};

export default ZojaInput;
