import { ZojaButton } from '../tailwind';

const EmptyState = ({ img, description, btnText, btnBackground = 'tw-bg-isPrimary', onClick, showBtn }: any) => {
  return (
    <div className="tw-flex tw-justify-center tw-flex-col tw-items-center">
      <div className=" tw-h-max">{img}</div>
      <div className=" tw-mb-5">{description}</div>
      {showBtn && (
        <ZojaButton
          text={btnText}
          onClick={onClick}
          extraClass={`${btnBackground} tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
        />
      )}
    </div>
  );
};

export default EmptyState;
