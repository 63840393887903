import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaInput, ZojaModal, ZojaTextArea } from '../tailwind';
import { Formik } from 'formik';
import * as yup from 'yup';

interface DefaultValuesIProps {
  defaultDepartmentName?: string;
  defaultHodName?: string;
  defaultDepartmentDescription?: string;
}

interface CreateDepartmentModalProps {
  show: boolean;
  handleClose: () => void;
  onSubmit: (item: any) => void;
  loading: boolean;
  type: string;
  defaultValues?: DefaultValuesIProps;
  actionStatus?: any;
}

interface CreateDepartmentProps {
  show: boolean;
  handleClose: () => void;
  onSubmit: (item: any) => void;
  loading: boolean;
  defaultValues?: DefaultValuesIProps;
  actionStatus?: any;
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  description: yup.string().required('Description name is required'),
});

export default function CreateDepartmentModal({
  show,
  handleClose,
  onSubmit,
  loading,
  type,
  defaultValues,
  actionStatus,
}: CreateDepartmentModalProps) {
  return (
    <ZojaModal
      show={show}
      handleClose={handleClose}
      height="auto"
      position="center"
      extraClass="md:tw-w-[35rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12 tw-pt-7 tw-pb-10 tw-px-8"
      borderRadius="8px">
      {type === 'create' ? (
        <CreateDepartmentForm
          show={show}
          handleClose={handleClose}
          onSubmit={onSubmit}
          loading={loading}
          actionStatus={actionStatus}
        />
      ) : (
        <UpdateDepartmentForm
          show={show}
          handleClose={handleClose}
          onSubmit={onSubmit}
          loading={loading}
          defaultValues={defaultValues}
        />
      )}
    </ZojaModal>
  );
}

const CreateDepartmentForm = ({ show, handleClose, onSubmit, loading }: CreateDepartmentProps) => {
  return (
    <div className="">
      <h2 className="tw-font-medium tw-text-2xl tw-mb-8">Add New Department</h2>
      <span
        className="tw-absolute tw-top-6 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
        onClick={() => {
          handleClose();
        }}>
        <MdClose />
      </span>
      <div className="">
        <Formik
          initialValues={{
            hod_name: '',
            description: '',
            name: '',
          }}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            const { description, name, hod_name } = values ?? '';

            const payload = {
              description: description,
              hod_name: hod_name,
              name: name,
            };

            onSubmit(payload);
            setSubmitting(false);
          }}>
          {formikProps => {
            const { handleChange, values, handleSubmit, errors, touched, isValid } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <div className="tw-grid tw-gap-y-8">
                  <ZojaInput
                    label="Name of Department"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    id={`name`}
                    extraClass={`tw-border-[#E7E7E7] !tw-text-sm !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                    name={`name`}
                    value={values.name}
                    placeholder="Enter Name of Department"
                    onChange={handleChange}
                  />
                  <ZojaTextArea
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    id="description"
                    placeholder="Enter description"
                    extraClass={`tw-border-[#E7E7E7] tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40 tw-rounded-[10px]`}
                    label="Department Description"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    resize="none"
                  />
                  <ZojaInput
                    label="Name of HOD (optional)"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    id={`hod_name`}
                    extraClass={`tw-border-[#E7E7E7] !tw-text-sm !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                    name={`hod_name`}
                    value={values.hod_name}
                    placeholder="Enter Name of HOD"
                    onChange={handleChange}
                  />
                  <div className="tw-flex tw-gap-x-6 tw-mt-6">
                    <ZojaButton
                      type="submit"
                      text={'Create Department'}
                      loading={loading}
                      disabled={loading || !isValid}
                      loadingText="Create Department"
                      extraClass={`${
                        loading || !isValid ? '!tw-bg-[#E7E7E7] tw-text-[#5E6366]' : 'tw-bg-isPrimary tw-text-white'
                      } tw-py-[14px] tw-px-8 tw-text-sm`}
                      loadingClass="tw-justify-center"
                    />
                    <ZojaButton
                      type="button"
                      text={'Cancel'}
                      onClick={() => {
                        handleClose();
                      }}
                      extraClass="tw-bg-transparent tw-text-[#5E6366] tw-py-[18px] tw-font-medium tw-text-sm"
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

const UpdateDepartmentForm = ({ show, handleClose, onSubmit, loading, defaultValues }: CreateDepartmentProps) => {
  return (
    <div className="">
      <h2 className="tw-font-medium tw-text-2xl tw-mb-8">Update Department</h2>

      <span
        className="tw-absolute tw-top-6 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
        onClick={() => {
          handleClose();
        }}>
        <MdClose />
      </span>

      <div className="">
        <Formik
          initialValues={{
            name: defaultValues?.defaultDepartmentName,
            hod_name: defaultValues?.defaultHodName,
            description: defaultValues?.defaultDepartmentDescription,
          }}
          enableReinitialize={true}
          validationSchema={schema}
          validateOnMount={true}
          onSubmit={async (values, { setSubmitting }) => {
            const { description, name, hod_name } = values ?? '';

            const payload = {
              description: description,
              hod_name: hod_name,
              name: name,
            };
            onSubmit(payload);
            setSubmitting(false);
          }}>
          {formikProps => {
            const { handleChange, values, handleSubmit, errors, touched, isValid } = formikProps;
            return (
              <form onSubmit={handleSubmit}>
                <div className="tw-grid tw-gap-y-8">
                  <ZojaInput
                    label="Name of Department"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    id={`name`}
                    extraClass={`tw-border-[#E7E7E7] !tw-text-sm !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                    name={`name`}
                    value={values.name}
                    placeholder="Enter Name of Department"
                    onChange={handleChange}
                  />
                  <ZojaTextArea
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    id="description"
                    placeholder="Enter description"
                    extraClass={`tw-border-[#E7E7E7] tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40 tw-rounded-[10px]`}
                    label="Department Description"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    resize="none"
                  />
                  <ZojaInput
                    label="Name of HOD (optional)"
                    labelExtraClass="tw-font-normal tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                    id={`hod_name`}
                    extraClass={`tw-border-[#E7E7E7] !tw-text-sm !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                    name={`hod_name`}
                    value={values.hod_name}
                    placeholder="Enter Name of HOD"
                    onChange={handleChange}
                  />
                  <div className="tw-flex tw-gap-x-6 tw-mt-6">
                    <ZojaButton
                      type="submit"
                      text={'Update Department'}
                      loading={loading}
                      disabled={loading || !isValid}
                      loadingText="Update Department"
                      extraClass={`${
                        loading || !isValid ? '!tw-bg-[#E7E7E7] tw-text-[#5E6366]' : 'tw-bg-isPrimary tw-text-white'
                      } tw-py-[14px] tw-px-8 tw-text-sm`}
                      loadingClass="tw-justify-center"
                    />
                    <ZojaButton
                      type="button"
                      text={'Cancel'}
                      onClick={() => {
                        handleClose();
                      }}
                      extraClass="tw-bg-transparent tw-text-[#5E6366] tw-py-[18px] tw-font-medium tw-text-sm"
                    />
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
