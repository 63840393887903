import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ActivityActionModal, AppContainer } from '../../atoms';
import { images, moveDataToFront, routesPath } from '../../utils';

import { Formik } from 'formik';
import { Oval } from 'react-loader-spinner';
import { AllowedModules, RolePermissions } from '../../components';
import Switch from '../../components/switch';
import { ZojaButton, ZojaInput } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { createRoleRequest, createRoleReset, getAllModulesRequest } from '../../redux/slice';
import { Dictionary } from '../../types';

const { ROLESANDPERMISSIONS } = routesPath;


function CreateRole() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // redux state
  const createRoleState = useAppSelector(state => state.createRole);
  const { status: createRoleStatus } = createRoleState;

  const getAllModules = useAppSelector(state => state.getAllModules);
  const { status: getAllModulesStatus, data: AVAILABLE_MODULES } = getAllModules

  const [canSeeAllModule, setCanSeeAllModule] = useState(false);
  const [createRoleSuccessModalVisible, setCreateRoleSuccessModalVisible] = useState<boolean>(false);
  const [availableModules, setAvailableModules] = useState<any>({});

  const schema = yup.object().shape({
    role: yup.string().required('Role is required'),
    role_function: yup.string().required('Role function is required'),
  });


  const handleCreateRole = (payload: Dictionary) => {
    const selected_modules = availableModules?.modules?.filter((f: any) => f.isChecked)?.map((d: any) => d.id)
    const dashboard_moduleID = availableModules?.modules?.filter((f: any) => f.name === 'Dashboard')?.[0]?.id

    dispatch(createRoleRequest({ ...payload, modules: [...selected_modules, dashboard_moduleID] }));

  };

  const handleCloseCreateRoleModal = () => {
    setCreateRoleSuccessModalVisible(false);
    dispatch(createRoleReset());
    navigate(ROLESANDPERMISSIONS);
  };

  useEffect(() => {
    dispatch(getAllModulesRequest({}));
  }, []);

  useEffect(() => {
    if (createRoleStatus === 'succeeded') {
      setCreateRoleSuccessModalVisible(true);
    }
  }, [createRoleStatus]);

  useEffect(() => {
    if (getAllModulesStatus === 'succeeded') {
      const modules = AVAILABLE_MODULES?.modules?.map((item: any) => {
        return {
          id: item.id,
          name: item.name,
          title: item.name,
          isChecked: false,
          permissions: item.permissions
        }
      })

      const updatedModules = moveDataToFront(modules, 'name', 'Dashboard')
      const MODULES = { modules: updatedModules }
      setAvailableModules(MODULES)
    }
  }, [getAllModulesStatus]);

  useEffect(() => {
    if (availableModules?.modules?.length) {
      const modules = [...availableModules?.modules]
      const updatedModules = modules.map((item: any) => {
        return {
          ...item,
          isChecked: canSeeAllModule
        }
      })
      const new_modules = { modules: updatedModules }
      setAvailableModules(new_modules)
    }
  }, [canSeeAllModule])

  return (
    <AppContainer goBack={() => navigate(ROLESANDPERMISSIONS)} navTitle={`USER ROLES`} navHelper="ROLE DETAILS">
      <div className="tw-mt-7 tw-pb-10 tw-w-[85%]">
        {getAllModulesStatus === 'loading' ? (
          <div className="tw-h-[70vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <Formik
            initialValues={{
              role: '',
              role_function: ''
            }}
            enableReinitialize={true}
            validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              const { role, role_function } = values ?? '';

              const payload = {
                role: role,
                role_function: role_function,
              };
              handleCreateRole(payload);
              setSubmitting(false);
            }}>
            {formikProps => {
              const { handleChange, values, handleSubmit, errors, touched } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <div className=" tw-flex tw-items-center tw-justify-between">
                    <h2 className="tw-text-xl tw-font-medium">Add New Role</h2>
                    <div className="tw-flex tw-justify-end tw-pt-6">
                      <ZojaButton
                        extraClass="tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]"
                        text={'Create Role'}
                        loading={createRoleStatus === 'loading'}
                        loadingText="Create Role"
                        disabled={createRoleStatus === 'loading'}
                      />
                    </div>
                  </div>
                  <div className="tw-mt-4 tw-mb-8">
                    <h3 className="tw-text-base tw-font-medium ">Role Information</h3>
                    <div className="tw-flex tw-items-center tw-gap-x-12">
                      <div className="tw-w-1/2">
                        <ZojaInput
                          label="Role Name"
                          placeholder="Enter role name"
                          type="text"
                          name="role"
                          touched={touched}
                          value={values.role}
                          onChange={handleChange}
                          error={errors.role}
                        />
                      </div>
                      <div className="tw-w-full">
                        <ZojaInput
                          label="Role Function"
                          placeholder="Enter short description"
                          type="text"
                          name="role_function"
                          touched={touched}
                          value={values.role_function}
                          onChange={handleChange}
                          error={errors.role_function}
                        />
                      </div>
                    </div>
                  </div>

                  <div>
                    <h3 className="tw-text-base tw-font-medium ">Available Modules</h3>
                    <p className='tw-text-xs tw-text-[#5E6366]'>Select below the modules that will be visible to the users in this role</p>

                    <div className='tw-mb-8'>
                      <AllowedModules
                        selected={availableModules}
                        setSelected={setAvailableModules}
                        available_modules={availableModules?.modules}
                        itemName={'modules'}
                        className="tw-border tw-border-[#E7E7E7]"
                      />
                      <span className='tw-block tw-my-6'>
                        <Switch
                          onChange={() => setCanSeeAllModule(!canSeeAllModule)}
                          checked={canSeeAllModule}
                          onColor="#222B88"
                          height={20}
                          width={48}
                          className="!tw-w-[22px]"
                          // custom
                          label='Users can see all Modules'
                          labelClass='!tw-text-gray-500 !tw-text-xs'
                        />
                      </span>
                    </div>
                    <RolePermissions module_permissions={availableModules?.modules?.filter((f: any) => (f.isChecked || f.title === 'Dashboard'))} />
                  </div>
                </form>
              );
            }}
          </Formik>
        )}

        <ActivityActionModal
          isModalVisible={createRoleSuccessModalVisible}
          closeModal={handleCloseCreateRoleModal}
          title={'Role Created Successfully'}
          text={''}
          actionText="Close"
          image={images.check}
          actionClick={handleCloseCreateRoleModal}
          isLoading={false}
        />
      </div>
    </AppContainer>
  );
}

export default CreateRole;
