import { SetStateAction, useEffect, useRef, useState } from 'react';
import { AppContainer } from '../../../atoms';
import { FreezeTable, EmptyState, Pagination } from '../../../components';
import { useNavigate } from 'react-router-dom';
import { formatString, routesPath, useSortTable, useWindowResize } from '../../../utils';
import { Dictionary } from '../../../types';
import { unFreezeRequestsHeaderData } from '../data';
import BanHistory from '../../../components/modalTypes/BanHistory';
import { useAppDispatch, useAppSelector } from '../../../redux/redux-hooks';
import { getBanHistoryRequest, getRestrictions } from '../../../redux/slice';
import { Oval } from 'react-loader-spinner';
import { ZojaSelect } from '../../../components/tailwind';
import { ReactComponent as EmptyStateImage } from '../../../assets/svg/emptyStateNote.svg';
const { UNFREEZEREQUESTS, FREEZEREQUESTS, APPROVEUNFREEZEREQUEST, REJECTEDREQUEST } = routesPath;

const BanPageTabs: Dictionary = [
  {
    id: 1,
    title: 'Freeze Requests',
    url: 'freeze_requests',
    path: FREEZEREQUESTS,
  },
  {
    id: 2,
    title: 'Unfreeze Requests',
    url: 'unfreeze_requests',
    path: UNFREEZEREQUESTS,
  },
  {
    id: 3,
    title: 'Rejected Requests',
    url: 'rejected_requests',
    path: REJECTEDREQUEST,
  },
];

const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'View Freeze History',
    value: 'viewFreezeHistory',
  },
  {
    id: 2,
    title: 'Review Request',
    value: 'reviewRequest',
  },
];

const UnFreeze = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowResize();

  const firstRender = useRef('');

  //   redux state
  const restrictionsState = useAppSelector(state => state.getRestrictions);
  const { status: restrictionsStatus } = restrictionsState;

  const banHistoryState = useAppSelector(state => state.getBanHistory);
  const { status: banHistoryStatus } = banHistoryState;

  const auth = useAppSelector(state => state.auth);

  const isApproveAccountRestrictionIncluded = auth?.data?.permissions?.some(
    (action: { name: string }) => action.name === 'approve account restriction',
  );

  const [activePageTab, setActivePageTab] = useState('unfreeze_requests');
  const [activeTab, setActiveTab] = useState('');
  const [freezeData, setFreezeData] = useState<any[]>([]);
  const [order, setOrder] = useState('ASC');
  const [openMore, setOpenMore] = useState(false);
  const [selectedTableRow, setSelectedTableRow] = useState<any>({});
  const [selectedItem, setSelectedItem] = useState<any>({});
  const [moreIsVisible, setMoreIsVisible] = useState(false);
  const [showBanHistory, setShowBanHistory] = useState(false);
  const [filter, setFilter] = useState({
    restriction_type: 'unfreeze',
    status: isApproveAccountRestrictionIncluded ? 'awaiting_approval' : 'pending',
  });
  const [totalPages, setTotalPages] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState<any>('10');

  const viewFreezeHistory = 'View Freeze History';
  const reviewRequest = 'Review Request';

  const { handleSortItems } = useSortTable({ order: order, setOrder, data: freezeData, setData: setFreezeData });

  // handle different table actions modules
  const handleMoreIconOptions = async (item: any) => {
    if (selectedTableRow.hasOwnProperty('fullName') && item.title === viewFreezeHistory) {
      setMoreIsVisible(false);
      setShowBanHistory(true);
      dispatch(getBanHistoryRequest({ account_number: selectedTableRow?.accountNumber }));
    }
    if (selectedTableRow.hasOwnProperty('fullName') && item.title === reviewRequest) {
      setMoreIsVisible(false);
      navigate(`${APPROVEUNFREEZEREQUEST}${selectedTableRow?.freezeId}`);
    }
  };

  useEffect(() => {
    if (firstRender.current === '') {
      dispatch(getRestrictions(filter));
    }
    return () => {
      firstRender.current = 'false';
    };
  }, []);

  useEffect(() => {
    if (restrictionsStatus === 'succeeded' && restrictionsState?.data?.data?.length > 0) {
      const updatedList: any[] = [];

      restrictionsState?.data?.data.forEach((item: Dictionary, index: number) => {
        updatedList.push({
          id: index + 1,
          accountNumber: item?.attributes?.account_number,
          createdAt: item?.attributes?.created_at,
          fullName: item?.attributes?.name,
          freezeId: item?.id,
          kycLevel: item.attributes?.kyc_level,
          reasonFor: item?.attributes?.reason_for,
          status: formatString(item?.attributes?.status),
        });
      });

      const { last_page } = restrictionsState?.data?.meta;

      setTotalPages(last_page);
      setFreezeData(updatedList);
    } else {
      setFreezeData([]);
    }
  }, [restrictionsState]);

  // FUNCTION TO CHANGE PAGE
  const onChangePagination = (selectedPage: SetStateAction<number>) => {
    // SET CURRENT PAGE TO STATE
    setCurrentPage(selectedPage);
    let currentPage = selectedPage;

    // ADD TO PAYLOAD
    const payload = { ...filter, page: currentPage };

    // DISPATCH FUNCTION
    dispatch(getRestrictions(payload));
  };

  // handle page number change
  const handlePageNumberChange = (pageNumber: any) => {
    setPerPage(pageNumber);

    dispatch(
      getRestrictions({
        ...filter,
        per_page: pageNumber,
      }),
    );
  };

  return (
    <AppContainer
      navTitle={
        <div className="tw-flex tw-gap- tw-items-center">
          <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">FREEZE/UNFREEZE</span>
        </div>
      }>
      <div className="tw-p-2 tw-mb-28 tw-mt-4 md:tw-mt-14">
        <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-scroll md:tw-overflow-hidden md:tw-gap-x-8 tw-mb-10">
          {BanPageTabs.map((items: any, idx: number) => (
            <div
              key={items.id}
              className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
                activePageTab === items.url
                  ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                  : 'tw-text-[#6A616F]'
              }`}
              onClick={() => {
                if (restrictionsStatus !== 'loading' && UNFREEZEREQUESTS !== items.path) {
                  setActiveTab('');
                  setActivePageTab(BanPageTabs[idx].url);
                  navigate(items.path);
                }
              }}>
              {items.title}
            </div>
          ))}
        </div>
        {restrictionsStatus === 'loading' ? (
          <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : freezeData?.length !== 0 ? (
          <FreezeTable
            data={freezeData}
            columns={unFreezeRequestsHeaderData}
            sorting={handleSortItems}
            order={order}
            handleMoreIconOptions={handleMoreIconOptions}
            setOpenMore={setOpenMore}
            setSelectedTableItem={setSelectedTableRow}
            dropdownList={dropdownList}
            setSelectedItem={setSelectedItem}
            type="unfreeze"
          />
        ) : (
          <div className="tw-bg-white tw-shadow-sm tw-mt-6 tw-py-9">
            <EmptyState
              img={<EmptyStateImage width={200} height={200} />}
              description="No Record Found"
              btnText="Reset Data"
              btnBackground="tw-bg-isPrimary"
              showBtn={false}
            />
          </div>
        )}

        <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
          {freezeData.length >= 1 && (
            <>
              <Pagination
                isLoading={restrictionsStatus === 'loading' ? true : false}
                currentPage={currentPage}
                totalPages={totalPages}
                maxWidth={width < 500 ? 350 : 500}
                onPageChange={selectedPage => {
                  onChangePagination(selectedPage);
                }}
              />
              {restrictionsStatus !== 'loading' ? (
                <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                  <span className="tw-text-xs tw-w-16">Per page</span>
                  <ZojaSelect
                    options={[
                      { label: '10', value: '10' },
                      { label: '20', value: '20' },
                      { label: '50', value: '50' },
                      { label: '100', value: '100' },
                      { label: '200', value: '200' },
                    ]}
                    value={perPage}
                    setSelectedOption={selected => handlePageNumberChange(selected)}
                    extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                  />
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
        <BanHistory
          show={showBanHistory}
          handleClose={() => setShowBanHistory(false)}
          banButtonText={isApproveAccountRestrictionIncluded ? 'Approve Unfreeze Request' : 'Review Unfreeze Request'}
          onClick={() => navigate(`${APPROVEUNFREEZEREQUEST}${selectedTableRow?.freezeId}`)}
          userName={selectedTableRow?.fullName}
          kycLevel={selectedTableRow?.kycLevel}
          banHistorData={banHistoryState?.data}
          requestStatus={banHistoryStatus}
        />
      </div>
    </AppContainer>
  );
};

export default UnFreeze;
