export const dateFormat = (text: string) => {
  let d = new Date(text);

  let datestring = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear() + ' ';

  return datestring;
};

export const yearDateFormat = (text: string) => {
  let d = new Date(text);

  let datestring = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + ' ';

  return datestring;
};

export const dateTimeFormat = (text: string) => {
  let d = new Date(text);

  let datestring = d.getFullYear() + '/' + (d.getMonth() + 1) + '/' + d.getDate() + ' ';

  return datestring;
};

export const dateTimeFormat2 = (text: string) => {
  let d = new Date(text);

  let datestring = `${d.getFullYear()}-${
    d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`
  }-${d.getDate()} `;

  return datestring;
};

export function timeFormat(date: string, pmAm: boolean = false) {
  const d = new Date(date);
  let hours = d.getHours();
  let minutes: any = d.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours %= 12;
  hours = hours || 12;
  minutes = minutes < 10 ? `0${minutes}` : minutes;

  const strTime = `${hours}:${minutes} ${ampm}`;
  const noAmPmstrTime = `${hours}:${minutes}`;

  return pmAm ? strTime : noAmPmstrTime;
}

export const formatRMDatePicker = (date: any) => {
  // Format the date as 'YYYY-MM-DD'
  const day = date?.day?.toString()?.padStart(2, '0');
  const month = date?.month?.number?.toString()?.padStart(2, '0');
  const year = date?.year;

  return `${year}-${month}-${day}`;
};

export const formatRMTimePicker = (date: any) => {
  // Format the time as 'HH:mm'
  const hour = date?.hour?.toString()?.padStart(2, '0');
  const minute = date?.minute?.toString()?.padStart(2, '0');

  return `${hour}:${minute}`;
};

export const dateTimeFormat3 = (inputDate: any) => {
  const options: any = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(inputDate);
  const formattedDate = date.toLocaleString('en-US', options);

  return formattedDate;
};

export const formatDate2 = (text: string) => {
  const d = new Date(text);

  const day = d.getDate().toString().padStart(2, '0');

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const month = monthNames[d.getMonth()];

  const year = d.getFullYear().toString().slice(-4);

  const datestring = `${day}/${month}/${year}`;

  return datestring;
};

export const formatTime = (text: string) => {
  const d = new Date(text);

  let hours = d.getHours();
  const minutes = d.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  const timestring = `${hours.toString().padStart(2, '0')}:${minutes}${ampm}`;

  return timestring;
};

// export default dateFormat;
