import { useState, useLayoutEffect } from 'react';
import { Container } from './style';
import { Tab } from '../../components';
import { TabIProps } from '../../components/tab';

export interface TabViewIPropsIProps {
  data: TabIProps[];
  setSelectedIndex: any[number];
  backgroundColor?: string;
  type?: string;
  tabViewSelectedIndex?: number;
  borderBottom?: string;
  paddingBottom?: boolean;
  bottom?: string;
  tabPaddingBottom?: string;
  borderBottomWidth?: string;
  fontSize?: string;
  fontWeight?: string;
  gap?: string;
}

function TabView({
  data,
  backgroundColor,
  setSelectedIndex,
  tabViewSelectedIndex,
  type,
  paddingBottom,
  borderBottom,
  bottom,
  tabPaddingBottom,
  borderBottomWidth,
  fontSize,
  fontWeight,
  gap,
}: TabViewIPropsIProps) {
  const [dataList, setDataList] = useState<any[]>([]);
  const handleOnSelect = (item: TabIProps) => {
    const itemToEdit = item;
    const updatedData: TabIProps[] = [...dataList].map((el: TabIProps) => {
      return {
        ...el,
        isSelected: el.id === itemToEdit.id,
      };
    });
    setDataList(updatedData);
    setSelectedIndex(itemToEdit.id);
  };

  useLayoutEffect(() => {
    setDataList(data);
  }, [data]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Container
          backgroundColor={backgroundColor}
          paddingBottom={paddingBottom}
          borderBottom={borderBottom}
          gap={gap}>
          {dataList.map((item: TabIProps) => (
            <Tab
              onClick={() => handleOnSelect(item)}
              key={item.id}
              isSelected={item.isSelected}
              text={item.text}
              paddingRight={dataList.length === item.id ? false : true}
              type={type}
              tabViewSelectedIndex={tabViewSelectedIndex}
              bottom={bottom}
              tabPaddingBottom={tabPaddingBottom}
              borderBottomWidth={borderBottomWidth}
              fontSize={fontSize}
              fontWeight={fontWeight}
            />
          ))}
        </Container>
      </div>
    </>
  );
}

export default TabView;
