import Modal from 'react-modal';
import { colors, boxShadow, images, capitalizeFirstLetter, dateAndTimeFormatString } from '../../utils';
import { ModalTabIndicator } from '../../components';
import { useEffect, useState } from 'react';
import { modalTabType } from '../../components/activityIndicator/modalTabIndicator';
import { H3, H4, H5 } from '../../styles';
import { FiX } from 'react-icons/fi';
import { ZojaSelect, ZojaTextArea, ZojaButton } from '../../components/tailwind';
import { FilePicker } from '../../components';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getBanReasonsList, getCustomerBanHistory } from '../../redux/slice';
import { Oval } from 'react-loader-spinner';
import moment from 'moment';
import { ProhibitionFormat } from '../../types';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  userToProhibitData: {
    account_name: string;
    account_number: string;
    user_kyc_level: string;
    passport: string;
  };
  restrictType: ProhibitionFormat;
  setRestrictType: React.Dispatch<React.SetStateAction<ProhibitionFormat>>;
  setConfirmProhibitionModalVisible: React.Dispatch<React.SetStateAction<boolean>>;

  //details for prohibition controls (freeze)
  setReasonForFreeze?: React.Dispatch<React.SetStateAction<string | number>>;
  freezeDescription?: string;
  setFreezeDescription?: React.Dispatch<React.SetStateAction<string>>;
  freezeFile?: FileList | null;
  setFreezeFile?: React.Dispatch<React.SetStateAction<FileList | null>>;

  //details for prohibition controls (restrict)
  setRestrictionDuration?: React.Dispatch<React.SetStateAction<string>>;
  setReasonForRestriction?: React.Dispatch<React.SetStateAction<string | number>>;
  restrictionDescription?: string;
  setRestrictionDescription?: React.Dispatch<React.SetStateAction<string>>;
  restrictionFile?: FileList | null;
  setRestrictionFile?: React.Dispatch<React.SetStateAction<FileList | null>>;

  //details for prohibition controls (unfreeze)
  setReasonForUnfreeze?: React.Dispatch<React.SetStateAction<string | number>>;
  unfreezeDescription?: string;
  setUnfreezeDescription?: React.Dispatch<React.SetStateAction<string>>;

  //details for prohibition controls (unrestrict)
  setReasonForUnrestrict?: React.Dispatch<React.SetStateAction<string | number>>;
  unrestrictDescription?: string;
  setUnrestrictDescription?: React.Dispatch<React.SetStateAction<string>>;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: `1px solid ${colors.smokeWhite}`,
    boxShadow: boxShadow.light,
  },
  overlay: { backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999 },
};

const modalTabsForFreeze: modalTabType[] = [
  {
    id: 1,
    title: 'Freeze Details',
  },
  {
    id: 2,
    title: 'History',
  },
];

const modalTabsForRestrict: modalTabType[] = [
  {
    id: 1,
    title: 'Restriction Details',
  },
  {
    id: 2,
    title: 'History',
  },
];

const modalTabsForUnfreeze: modalTabType[] = [
  {
    id: 1,
    title: 'Unfreeze Details',
  },
  {
    id: 2,
    title: 'History',
  },
];

const modalTabsForUnRestrict: modalTabType[] = [
  {
    id: 1,
    title: 'Unrestrict Details',
  },
  {
    id: 2,
    title: 'History',
  },
];

const restrictionDurationOptions = [
  {
    value: '1hour',
    label: '1 hour',
  },
  {
    value: '2hour',
    label: '2 hours',
  },
  {
    value: '5hour',
    label: '5 hours',
  },
  {
    value: '12hour',
    label: '12 hours',
  },
  {
    value: '24hour',
    label: '24 hours',
  },
  {
    value: '48hour',
    label: '48 hours',
  },
  {
    value: 'indefinite',
    label: 'indefinitely',
  },
];

function InitiateProhibitionModal({
  isModalVisible,
  closeModal,
  userToProhibitData,
  restrictType,
  setRestrictType,
  setConfirmProhibitionModalVisible,
  setReasonForFreeze,
  freezeDescription,
  setFreezeDescription,
  freezeFile,
  setFreezeFile,
  setRestrictionDuration,
  setReasonForRestriction,
  restrictionDescription,
  setRestrictionDescription,
  restrictionFile,
  setRestrictionFile,
  setReasonForUnfreeze,
  unfreezeDescription,
  setUnfreezeDescription,
  setReasonForUnrestrict,
  unrestrictDescription,
  setUnrestrictDescription,
}: IProps) {
  const dispatch = useAppDispatch();

  const banReasons = useAppSelector(state => state.banReasonsList);
  const banHistory = useAppSelector(state => state.customerBanHistory);

  const { account_name, user_kyc_level, passport, account_number } = userToProhibitData;

  const [selectedTab, setSelectedTab] = useState(
    restrictType === 'freeze'
      ? modalTabsForFreeze[0]
      : restrictType === 'restrict'
      ? modalTabsForRestrict[0]
      : restrictType === 'unfreeze'
      ? modalTabsForUnfreeze[0]
      : modalTabsForUnRestrict[0],
  );

  const onChangeFilePicker = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && restrictType === 'freeze' && setFreezeFile) {
      setFreezeFile(e.target.files);
      return;
    }

    if (e.target.files && restrictType === 'restrict' && setRestrictionFile) {
      setRestrictionFile(e.target.files);
      return;
    }
  };

  const resetFilePicker = () => {
    if (restrictType === 'freeze' && setFreezeFile) {
      setFreezeFile(null);
      return;
    }
    if (restrictType === 'restrict' && setRestrictionFile) {
      setRestrictionFile(null);
      return;
    }
  };

  const createBanOptionsArray = () => {
    if (!banReasons.data || banReasons.status === 'failed') return [];

    const array: {
      label: string;
      value: string | number;
    }[] = [];

    banReasons.data.forEach((reason: string, index: number) => {
      const object = {
        id: index,
        label: reason ? capitalizeFirstLetter(reason) : '',
        value: reason,
      };

      array.push(object);
    });

    return array;
  };

  useEffect(() => {
    if (isModalVisible) dispatch(getBanReasonsList({}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    if (isModalVisible) dispatch(getCustomerBanHistory({ account_number }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalVisible]);

  useEffect(() => {
    if (restrictType === 'freeze') setSelectedTab(modalTabsForFreeze[0]);
    if (restrictType === 'restrict') setSelectedTab(modalTabsForRestrict[0]);
    if (restrictType === 'unrestrict') setSelectedTab(modalTabsForUnRestrict[0]);
    if (restrictType === 'unfreeze') setSelectedTab(modalTabsForUnfreeze[0]);
  }, [restrictType]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={!!isModalVisible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Ban and Unban Modal">
        <div className="tw-w-[60vh] tw-p-6">
          <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
            <ModalTabIndicator
              tabList={
                restrictType === 'freeze'
                  ? modalTabsForFreeze
                  : restrictType === 'restrict'
                  ? modalTabsForRestrict
                  : restrictType === 'unfreeze'
                  ? modalTabsForUnfreeze
                  : modalTabsForUnRestrict
              }
              selected={selectedTab}
              setSelected={setSelectedTab}
            />
            <div className="tw-cursor-pointer" onClick={closeModal}>
              <FiX color={colors.red} size={28} />
            </div>
          </div>

          <div className="tw-w-full tw-my-5">
            <div className="tw-w-fit tw-flex tw-items-center tw-space-x-6 tw-pb-2 tw-pr-8">
              <img
                src={passport?.length > 0 ? passport : images.userProfileImage}
                alt=""
                className={`tw-w-[32px] tw-h-[32px] tw-rounded-full`}
              />
              <H3 left semiLight color={colors.primary}>
                {account_name?.length > 0 ? account_name : 'No username'}
              </H3>
              <H3 left bold color={colors.primary}>
                {`KYC: ${' '}`}
                <span className="tw-font-normal">{user_kyc_level ? capitalizeFirstLetter(user_kyc_level) : 'N/A'}</span>
              </H3>
            </div>
            <div className="tw-w-full tw-border tw-border-t-0 tw-border-r-0 tw-border-l-0 tw-border-b-[#cbc5cd]"></div>
          </div>

          {/* Initiate Prohibition Tab */}
          {/* I seperated each tab due to pressure and complications from the Product team always changing the requirements. */}
          {/* Probably when the requirements have been clarified by the Product team, This flow can be combined to aviod code duplication. */}
          {selectedTab.id === 1 && (
            <>
              {/* freeze flow */}
              {restrictType === 'freeze' && setReasonForFreeze && setFreezeDescription && (
                <>
                  <ZojaSelect
                    options={banReasons.status === 'succeeded' ? createBanOptionsArray() : []}
                    setSelectedOption={setReasonForFreeze}
                    label="Reason for freeze"
                    labelExtraClass="tw-font-normal tw-text-[#100317]"
                    centralPlacedArrow={true}
                    centralPlacedArrowClass="tw-text-isPrimary"
                    extraClass="tw-text-isPrimary"
                    loading={banReasons.status === 'loading'}
                  />

                  <div className="tw-w-full">
                    <ZojaTextArea
                      value={freezeDescription}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFreezeDescription(e.target.value)}
                      name="freezeDescription"
                      placeholder="Enter freeze description"
                      extraClass={`tw-border-gray-300 tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40`}
                      label="Description of Reason (Compulsory)"
                      labelExtraClass="tw-font-normal tw-text-[15px] tw-text-[#100317]"
                      marginTop="tw-mt-4"
                      resize="none"
                      required={true}
                    />
                  </div>

                  <FilePicker
                    title="Upload Proof"
                    titleExtraClass=""
                    extraClass="tw-mt-4"
                    value={freezeFile as FileList | null}
                    onChange={onChangeFilePicker}
                    resetValue={resetFilePicker}
                    multiple={true}
                  />

                  <ZojaButton
                    text="Initiate Freeze"
                    extraClass={`tw-p-3 tw-px-8 tw-w-full tw-mt-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
                    type="button"
                    disabled={banReasons.status === 'loading' || !freezeDescription || freezeDescription?.length < 1}
                    onClick={() => {
                      setRestrictType('freeze');
                      setConfirmProhibitionModalVisible(true);
                      closeModal();
                    }}
                  />
                </>
              )}

              {/* restrict flow */}
              {restrictType === 'restrict' &&
                setRestrictionDuration &&
                setReasonForRestriction &&
                setRestrictionDescription && (
                  <>
                    <div className="tw-mb-4">
                      <ZojaSelect
                        options={restrictionDurationOptions}
                        setSelectedOption={setRestrictionDuration}
                        label="Assign Lock Duration"
                        labelExtraClass="tw-font-normal tw-text-[#100317]"
                        centralPlacedArrow={true}
                        centralPlacedArrowClass="tw-text-isPrimary"
                        extraClass="tw-text-isPrimary"
                      />
                    </div>

                    <ZojaSelect
                      options={banReasons.status === 'succeeded' ? createBanOptionsArray() : []}
                      setSelectedOption={setReasonForRestriction}
                      label="Reason for lock"
                      labelExtraClass="tw-font-normal tw-text-[#100317]"
                      centralPlacedArrow={true}
                      centralPlacedArrowClass="tw-text-isPrimary"
                      extraClass="tw-text-isPrimary"
                      loading={banReasons.status === 'loading'}
                    />

                    <div className="tw-w-full">
                      <ZojaTextArea
                        value={restrictionDescription}
                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                          setRestrictionDescription(e.target.value)
                        }
                        name="restrictionDescription"
                        placeholder="Enter restriction description"
                        extraClass={`tw-border-gray-300 tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40`}
                        label="Description of Reason (Compulsory)"
                        labelExtraClass="tw-font-normal tw-text-[15px] tw-text-[#100317]"
                        marginTop="tw-mt-4"
                        resize="none"
                        required={true}
                      />
                    </div>

                    <FilePicker
                      title="Upload Proof"
                      titleExtraClass=""
                      extraClass="tw-mt-4"
                      value={restrictionFile as FileList | null}
                      onChange={onChangeFilePicker}
                      resetValue={resetFilePicker}
                      multiple={true}
                    />

                    <ZojaButton
                      text="Initiate Account Restriction"
                      extraClass={`tw-p-3 tw-px-8 tw-w-full tw-mt-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
                      type="button"
                      disabled={
                        banReasons.status === 'loading' || !restrictionDescription || restrictionDescription?.length < 1
                      }
                      onClick={() => {
                        setRestrictType('restrict');
                        setConfirmProhibitionModalVisible(true);
                        closeModal();
                      }}
                    />
                  </>
                )}

              {/* unfreeze flow */}
              {restrictType === 'unfreeze' && setReasonForUnfreeze && setUnfreezeDescription && (
                <>
                  <ZojaSelect
                    options={banReasons.status === 'succeeded' ? createBanOptionsArray() : []}
                    setSelectedOption={setReasonForUnfreeze}
                    label="Reason for unfreeze"
                    labelExtraClass="tw-font-normal tw-text-[#100317]"
                    centralPlacedArrow={true}
                    centralPlacedArrowClass="tw-text-isPrimary"
                    extraClass="tw-text-isPrimary"
                    loading={banReasons.status === 'loading'}
                  />

                  <div className="tw-w-full">
                    <ZojaTextArea
                      value={unfreezeDescription}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUnfreezeDescription(e.target.value)}
                      name="unfreezeDescription"
                      placeholder="Enter unfreeze description"
                      extraClass={`tw-border-gray-300 tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40`}
                      label="Description of Reason (Compulsory)"
                      labelExtraClass="tw-font-normal tw-text-[15px] tw-text-[#100317]"
                      marginTop="tw-mt-4"
                      resize="none"
                      required={true}
                    />
                  </div>

                  <ZojaButton
                    text="Initiate Unfreeze"
                    extraClass={`tw-p-3 tw-px-8 tw-w-full tw-mt-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
                    type="button"
                    disabled={
                      banReasons.status === 'loading' || !unfreezeDescription || unfreezeDescription?.length < 1
                    }
                    onClick={() => {
                      setRestrictType('unfreeze');
                      setConfirmProhibitionModalVisible(true);
                      closeModal();
                    }}
                  />
                </>
              )}

              {/* unrestrict flow */}
              {restrictType === 'unrestrict' && setReasonForUnrestrict && setUnrestrictDescription && (
                <>
                  <ZojaSelect
                    options={banReasons.status === 'succeeded' ? createBanOptionsArray() : []}
                    setSelectedOption={setReasonForUnrestrict}
                    label="Reason for unrestrict"
                    labelExtraClass="tw-font-normal tw-text-[#100317]"
                    centralPlacedArrow={true}
                    centralPlacedArrowClass="tw-text-isPrimary"
                    extraClass="tw-text-isPrimary"
                    loading={banReasons.status === 'loading'}
                  />

                  <div className="tw-w-full">
                    <ZojaTextArea
                      value={unrestrictDescription}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setUnrestrictDescription(e.target.value)}
                      name="unrestrictDescription"
                      placeholder="Enter unrestrict description"
                      extraClass={`tw-border-gray-300 tw-px-0 tw-p-0 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40`}
                      label="Description of Reason (Compulsory)"
                      labelExtraClass="tw-font-normal tw-text-[15px] tw-text-[#100317]"
                      marginTop="tw-mt-4"
                      resize="none"
                      required={true}
                    />
                  </div>

                  <ZojaButton
                    text="Initiate Unrestrict"
                    extraClass={`tw-p-3 tw-px-8 tw-w-full tw-mt-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
                    type="button"
                    disabled={
                      banReasons.status === 'loading' || !unrestrictDescription || unrestrictDescription?.length < 1
                    }
                    onClick={() => {
                      setRestrictType('unrestrict');
                      setConfirmProhibitionModalVisible(true);
                      closeModal();
                    }}
                  />
                </>
              )}
            </>
          )}

          {/*History Tab shows all prohibitions (both freeze/unfreeze/restrict/unrestrict*/}
          {selectedTab.id === 2 && (
            <>
              {banHistory.status === 'loading' ? (
                <>
                  <div className="tw-h-[30vh] tw-w-full tw-flex tw-items-center tw-justify-center">
                    <Oval
                      height="40"
                      width="40"
                      color={colors.primary}
                      ariaLabel="tail-spin-loading"
                      secondaryColor={colors.blueVariantOne}
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  </div>
                </>
              ) : banHistory.status === 'failed' ? (
                <div className="tw-w-full tw-h-[50vh] tw-flex tw-justify-start tw-items-start">
                  <H4 left semiLight color={colors.black}>
                    Fetching account ban history failed.
                  </H4>
                </div>
              ) : banHistory.status === 'succeeded' && banHistory?.data?.length < 1 ? (
                <>
                  <div className="tw-w-full tw-h-[50vh] tw-flex tw-flex-col tw-justify-center tw-items-center">
                    <img src={images.fileExcelEmpty} alt="Empty container" className="tw-w-[50%] tw-h-auto" />
                    <H4 left semiLight color={colors.black}>
                      User has no freeze history.
                    </H4>
                  </div>
                </>
              ) : banHistory.status === 'succeeded' && banHistory?.data?.length > 0 ? (
                <div className="tw-mb-8 tw-max-h-[60vh] tw-overflow-y-scroll">
                  {banHistory?.data?.map((item: any) => (
                    <div className="tw-mb-4" key={item?.attributes?.created_at}>
                      <H5 left semiLight color={colors.black}>
                        {item?.attributes?.created_at
                          ? moment(item?.attributes?.created_at).format(dateAndTimeFormatString)
                          : `N/A`}
                      </H5>
                      <H4 left bold color={colors.black}>
                        Prohibition Type:{' '}
                        {item?.type ? capitalizeFirstLetter(item?.type) : `No Prohibition type specified`}
                      </H4>
                      <H4 left bold color={colors.black}>
                        {item?.attributes?.reason_for ? capitalizeFirstLetter(item?.attributes?.reason_for) : ``}
                      </H4>
                      <H5 left semiLight color={colors.greyVariantSeven}>
                        {item?.attributes?.description_of_reason
                          ? item?.attributes?.description_of_reason
                          : `No Prohinition Description given`}
                      </H5>
                      <H5 left semiLight color={colors.greyVariantSeven}>
                        Initiated By:{' '}
                        {item?.attributes?.initiated_by?.name
                          ? item?.attributes?.initiated_by?.name
                          : `No Initiator specified`}
                      </H5>
                      <H5 left semiLight color={colors.greyVariantSeven}>
                        Initiated By:{' '}
                        {item?.attributes?.reviewed_by?.name ? item?.attributes?.reviewed_by?.name : `Pending Review`}
                      </H5>
                      <H5 left semiLight color={colors.greyVariantSeven}>
                        Initiated By:{' '}
                        {item?.attributes?.approved_by?.name ? item?.attributes?.approved_by?.name : `Pending Approval`}
                      </H5>
                    </div>
                  ))}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default InitiateProhibitionModal;
