import { FaSearch } from 'react-icons/fa';
import { useAppSelector } from '../../redux/redux-hooks';
import ZojaCheckbox from '../checkbox';
import CustomDatePickerSeven from '../customDatePicker/customDatePickerSeven';
import CustomDatePicker from '../customDatePicker/';
import FilterColapsible from '../filterColapsible';
import { ZojaInput, ZojaSelect } from '../tailwind';
import { Oval } from 'react-loader-spinner';
import yearRange from '../../utils/yearRange';
import { useState } from 'react';
import { Dictionary } from '../../types';

const ReusableFilter = ({
  title,
  items,
  show,
  setShow,
  handleSelectedOption,
  extraClass,
  setItem,
  setChecked,
  itemName,
  filterOptions,
  type,
  label,
  id,
  inputType,
  name,
  onHandleInputChange,
  setSearch,
  searchedUsers,
  extraClassChildren,
  searchedUserAccountState,
  handleClientSideSearch,
  setEndDate,
  setStartDate,
  endDate,
  startDate,
  filterType,
  filterParams,
  userType,
  textType,
  placeholder,
}: any) => {
  const transactionFilterOptions = useAppSelector(state => state.filterOptions);
  const generalTransactionFilterOptions = useAppSelector(state => state.generalTransactionFilterOptions);

  // if (filterType === 'timeBased') {
  //   setTextTypeValue(generalTransactionFilterOptions)
  // }

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const monthOptions = Object.values(
    months.map((month: any) => {
      return {
        label: month,
        value: month,
      };
    }),
  );

  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();
  const yearsFrom = yearRange(currentYear, 2022, -1);

  const yearOptions = Object.values(
    yearsFrom.map((year: any) => {
      return {
        label: year,
        value: year,
      };
    }),
  );

  const secondarySelector: any = {
    Daily: [
      {
        label: 'Select Week',
        value: '',
      },
      {
        label: 'Week 1',
        value: 'Week 1',
      },
      {
        label: 'Week 2',
        value: 'Week 2',
      },
      {
        label: 'Week 3',
        value: 'Week 3',
      },
      {
        label: 'Week 4',
        value: 'Week 4',
      },
      {
        label: 'Week 5',
        value: 'Week 5',
      },
    ],
    Weekly: [
      {
        label: 'Select Month',
        value: '',
      },
      ...monthOptions,
    ],
    Monthly: [
      {
        label: 'Select Year',
        value: '',
      },
      ...yearOptions,
    ],
  };
  const [filterBy, setFilterBy] = useState<Dictionary>({
    frequency: 'daily',
    week: 1,
    month: currentMonth,
    year: currentYear,
  });

  // filter input change handler
  const handleFilterChange = (data: any) => {
    const { key, value } = data;
    if (key === 'year') {
      setFilterBy({ ...filterBy, year: value });
    }
    if (key === 'month') {
      let monthValue = months.findIndex(m => m === value) + 1;
      // console.log(monthValue)
      setFilterBy({ ...filterBy, month: monthValue });
    }
    if (key === 'week') {
      let weekValue = secondarySelector.Daily.findIndex((d: any) => d.value === value);
      setFilterBy({ ...filterBy, week: weekValue });
    }
  };

  // filter customer growth insight handler
  const handleFilterCustomerGrowthInsight = () => {
    // if (filterBy.frequency === 'daily') {
    // dispatch(getCustomerGrowthInsight(filterBy))
    // // }
    // setFilterBy({ ...filterBy, frequency: 'daily' })
    // setLoading(true)
    // setShowCustomerGrowthInsight(false)
  };

  if (type === 'text') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          {textType === 'single' ? (
            <div className="tw-p-2 md:tw-p-0 tw-mt-2 tw-z-20">
              <ZojaInput
                label={label}
                id={id}
                type={inputType}
                extraClass="tw-text-xs tw-p-2 tw-mb-2"
                name={name}
                value={filterParams[`${`${name}`}`]}
                onChange={onHandleInputChange}
                placeholder={placeholder}
              />
            </div>
          ) : (
            <>
              <div className="tw-p-2 md:tw-p-0 tw-mt-2 tw-z-20">
                <ZojaInput
                  label={`Min ${label}`}
                  id={`min_${id}`}
                  type={inputType}
                  extraClass="tw-text-xs tw-p-2 tw-mb-2"
                  name={`min_${name}`}
                  value={filterParams[`${`min_${name}`}`]}
                  onChange={onHandleInputChange}
                />
                <ZojaInput
                  label={`Max ${label}`}
                  id={`max_${id}`}
                  type={inputType}
                  name={`max_${name}`}
                  extraClass="tw-text-xs tw-p-2 tw-mb-2"
                  value={filterParams[`${`max_${name}`}`]}
                  onChange={onHandleInputChange}
                />
              </div>
            </>
          )}
        </FilterColapsible>
      </>
    );
  }

  if (type === 'inputCheck') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div className="tw-p-2 md:tw-p-0 tw-mt-2">
              <div className="tw-relative">
                {itemName === 'location' ? (
                  <ZojaInput placeholder="Search" icon={<FaSearch />} onChange={handleClientSideSearch} />
                ) : (
                  <ZojaInput placeholder="Search" icon={<FaSearch />} onChange={e => setSearch(e.target.value)} />
                )}
              </div>
              {searchedUserAccountState?.status === 'loading' ? (
                <div className="tw-h-[10vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="50"
                    width="50"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              ) : items.length < 1 ? (
                <h6 className="tw-text-[12px] tw-p-2">Search for a user</h6>
              ) : (
                <div className="tw-h-32 tw-overflow-y-scroll hide_scrollbar">
                  {items?.map((item: { telephone: string; id: number; name: string; isChecked: boolean }) => (
                    <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                      <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">
                        {userType === 'number' ? item.telephone : item.name}
                      </span>
                      <ZojaCheckbox
                        isChecked={item.isChecked}
                        onClick={() => setChecked(item.id, filterOptions, itemName, setItem)}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }

  if (type === 'checkDate') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div className="tw-p-2 md:tw-p-0 tw-mt-2">
              {items?.map((item: { id: number; title: string; isChecked: boolean }) => (
                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                  <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                  <ZojaCheckbox
                    isChecked={item.isChecked}
                    onClick={() => setChecked(item.id, filterOptions, itemName, setItem)}
                  />
                </div>
              ))}
            </div>

            <div
              className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 
  
                    ${
                      items.some(
                        (item: { value: string; isChecked: any }) => item.value === 'custom' && item.isChecked,
                      ) === true && 'tw-max-h-fit'
                    }
                    
                    `}>
              <div className="tw-mb-2">
                <CustomDatePicker
                  label="Start Date"
                  value={new Date()}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          start_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
              <div className="tw-mb-2">
                <CustomDatePicker
                  label="End Date"
                  value={new Date()}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          end_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }

  if (type === 'customDate') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div className={`tw-mt-2`}>
              <div className="tw-mb-2">
                <CustomDatePicker
                  label="Start Date"
                  value={new Date()}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          start_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
              <div className="tw-mb-2">
                <CustomDatePicker
                  label="End Date"
                  value={new Date()}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          end_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }
  if (type === 'date') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div className={`tw-mt-2`}>
              <div className="tw-mb-2">
                <CustomDatePicker
                  value={new Date()}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          start_date: value,
                        },
                      };
                    })
                  }
                  onOpenPickNewDate={false}
                />
              </div>
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }

  if (type === 'sevenDays') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div className={`tw-mt-2`}>
              <div className="tw-mb-2">
                <CustomDatePickerSeven
                  value={new Date()}
                  placeholder="start date"
                  selectedDate={startDate}
                  setSelectedDate={setStartDate}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          start_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className={`tw-mt-2`}>
              <div className="tw-mb-2">
                <CustomDatePickerSeven
                  value={new Date()}
                  placeholder="end date"
                  selectedDate={endDate}
                  // error={}
                  disabled={!startDate ? true : false}
                  setSelectedDate={setEndDate}
                  setValue={value =>
                    setItem((prevFilterOption: { dateRange: any }) => {
                      return {
                        ...prevFilterOption,
                        dateRange: {
                          ...prevFilterOption.dateRange,
                          end_date: value,
                        },
                      };
                    })
                  }
                />
              </div>
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }

  if (type === 'select') {
    return (
      <>
        <FilterColapsible
          title={title}
          show={show}
          onClick={setShow}
          extraClass={extraClass}
          extraClassChildren={extraClassChildren}>
          <>
            <div
              className={`tw-shadow-zojaShadowFive tw-h-full tw-p-3 tw-bg-white tw-top-0 tw-right-0 tw-flex tw-flex-col tw-gap-x-3 tw-items-cente tw-flex-wra tw-justify-en tw-gap-y-4 tw-transition-all tw-duration-300 tw-ease-in-out `}>
              <>
                <div className={``}>
                  <div className="tw-mb-2">
                    <CustomDatePickerSeven
                      value={new Date()}
                      placeholder="start date"
                      selectedDate={startDate}
                      setSelectedDate={setStartDate}
                      setValue={value =>
                        setItem((prevFilterOption: { dateRange: any }) => {
                          return {
                            ...prevFilterOption,
                            dateRange: {
                              ...prevFilterOption.dateRange,
                              start_date: value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                </div>
                <div className={`x`}>
                  <div className="tw-mb-2">
                    <CustomDatePickerSeven
                      value={new Date()}
                      placeholder="end date"
                      selectedDate={endDate}
                      // error={}
                      disabled={!startDate ? true : false}
                      setSelectedDate={setEndDate}
                      setValue={value =>
                        setItem((prevFilterOption: { dateRange: any }) => {
                          return {
                            ...prevFilterOption,
                            dateRange: {
                              ...prevFilterOption.dateRange,
                              end_date: value,
                            },
                          };
                        })
                      }
                    />
                  </div>
                </div>
              </>
              {/* <ZojaSelect
                options={secondarySelector['Weekly']}
                extraClass={`tw-appearance-none tw-p-1 tw-text-xs tw-px- tw-w-32 tw-pr-10 tw-py-1.5 tw-rounded-[3px] tw-text-[#222B88] tw-border-0 tw-cursor-pointer tw-font-normal tw-shadow-zojaShadowFive`}
                disabled={filterBy.frequency === 'monthly' && true}
                showArrown={true}
                arrowDownClass="tw-top-2.5 tw-right-2 tw-text-[#222B88]"
                setSelectedOption={data => handleFilterChange({ key: 'month', value: data })}
              />
              <ZojaSelect
                options={secondarySelector['Monthly']}
                extraClass="tw-appearance-none tw-p-1 tw-text-xs tw-px- tw-w-32 tw-pr-10 tw-py-1.5 tw-rounded-[3px] tw-bg-white tw-text-[#222B88] tw-border-0 tw-cursor-pointer tw-font-normal tw-shadow-zojaShadowFive"
                showArrown={true}
                arrowDownClass="tw-top-2.5 tw-right-2 tw-text-[#222B88]"
                setSelectedOption={data => handleFilterChange({ key: 'year', value: data })}
              /> */}
              {/* <button
                className="tw-bg-isPrimary tw-text-white tw-p-1.5 tw-px-5 tw-text-xs tw-rounded-lg"
                onClick={handleFilterCustomerGrowthInsight}>
                Filter
              </button> */}
            </div>
          </>
        </FilterColapsible>
      </>
    );
  }

  return (
    <FilterColapsible
      title={title}
      show={show}
      onClick={setShow}
      extraClass={extraClass}
      extraClassChildren={extraClassChildren}>
      <>
        <div className="tw-p-2 md:tw-p-0">
          {items?.map((item: { id: number; title: string; isChecked: boolean }) => (
            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
              <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
              <ZojaCheckbox
                isChecked={item.isChecked}
                // onClick={() => handleSelectedOption(item, 'accountInfo', setItem)}
                onClick={() => setChecked(item.id, filterOptions, itemName, setItem)}
              />
            </div>
          ))}
        </div>
      </>
    </FilterColapsible>
  );
};

export default ReusableFilter;
