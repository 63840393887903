import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';
type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  search_by: ''
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getKycsRequest = createAsyncThunk('getKycs', async (payload: Dictionary, { dispatch }) => {
  const url = `admin/kycs`;
  let filters = payload?.filter ? { ...payload.filter } : {};
  try {
    const response = await api.get(`${url}${payload.kycLevel}`, {
      params: {
        per_page: payload.per_page,
        page: payload.page,
        ...filters,
      },
    });
    return response?.data;
  } catch (err) {
    throw err;
  }
});

const getKycsSlice = createSlice({
  name: 'getKycs',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
    setKycSearchParam: (state, action) => {
      state.search_by = action.payload
    },
    resetKycSearchParam: (state, action) => {
      state.search_by = ""
    },
  },
  extraReducers: builder => {
    builder.addCase(getKycsRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getKycsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getKycsRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getKycsReset = getKycsSlice.actions.reset;
export const { setKycSearchParam, resetKycSearchParam } = getKycsSlice.actions;
export const getKycsSliceReducer = getKycsSlice.reducer;
