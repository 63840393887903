import { icons } from '../../utils';

interface IProps {
  title?: string;
  show: boolean;
  children: React.ReactElement;
  onClick: () => void;
  submitButtonText?: string;
  extraClass?: string;
  extraClassTitle?: string;
  childClass?: string;
}

const FilterColapsibleSingle = ({
  show,
  title,
  onClick,
  submitButtonText,
  extraClass,
  children,
  childClass,
  extraClassTitle,
}: IProps) => {
  return (
    <div className={`${show && 'tw-shadow-zojaShadowFive'}`}>
      <span
        className={`${extraClass} tw-flex tw-items-center tw-justify-between tw-text-xs tw-p-3.5 tw-bg-white tw-border-isPrimary ${
          show ? 'tw-rounded-tl-md tw-rounded-tr-md tw-border-0' : ' tw-rounded-md tw-border'
        } tw-cursor-pointer`}
        onClick={onClick}>
        <span className={`${extraClassTitle} tw-font-normal tw-text-[#222B88]`}>{title}</span>
        <span
          className={`tw-text-isGreyVariantFour tw-transition-transform tw-duration-500 tw-rotate-0 ${
            show && 'tw-rotate-180'
          }`}>
          {/* {show ? <icons.FaChevronUp /> : <icons.FaChevronDown />} */}
          <icons.FaChevronDown />
        </span>
      </span>
      {show && <div className=" tw-bg-[#6a616f24] tw-w-full tw-h-[1px]"></div>}
      <div
        className={`${childClass} tw-bg-white tw-overflow-hidden tw-max-h-0 ${
          show && 'tw-max-h-fit tw-overflow-y-scroll tw-px-3 tw-absolute tw-shadow-zojaShadowFive'
        }`}>
        {children}
      </div>
    </div>
  );
};

export default FilterColapsibleSingle;
