import { ZojaButton } from '../tailwind';

interface IProps {
  title?: string;
  showFilter: boolean;
  children: React.ReactElement;
  onClick: (e: any) => void;
  onCancel: (e: any) => void;
  submitButtonText?: string;
  showSubmitButton?: boolean;
  extraClass?: string;
}

const index = ({
  showFilter,
  title,
  onClick,
  submitButtonText,
  showSubmitButton = true,
  extraClass,
  onCancel,
  children,
}: IProps) => {
  return showFilter ? (
    <div
      className={`${extraClass} tw-bg-white tw-shadow-zojaShadowFour tw-absolute tw-top-0 -tw-left-32 tw-rounded-md tw-p-5 tw-z-40`}>
      {children}
      {showSubmitButton ? (
        <div className="tw-flex tw-items-center tw-justify-between">
          <ZojaButton
            onClick={onCancel}
            text={`Cancel`}
            extraClass="tw-mt-6 tw-border tw-border-isPrimary tw-text-isPrimary tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin"
          />
          <ZojaButton
            onClick={onClick}
            text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
            extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin"
          />
        </div>
      ) : null}
    </div>
  ) : null;
};

export default index;
