export const transactionReportColumn = [
  {
    id: 1,
    title: 'S/N',
    ref: 'sn',
  },
  { id: 2, title: 'Transaction Date', ref: 'transactionDate' },
  { id: 3, title: 'Transaction Type', ref: 'transactionType' },
  { id: 4, title: 'Account Name', ref: 'accountName' },
  { id: 5, title: 'Amount(N)', ref: 'amount' },
  { id: 6, title: 'Timestamp', ref: 'timestamp' },
  { id: 7, title: 'Status', ref: 'status' },
  { id: 8, title: 'Account Number', ref: 'accountNumber' },
  { id: 9, title: 'Phone Number', ref: 'phoneNumber' },
  { id: 10, title: 'Reference ID', ref: 'referenceID' },
  { id: 11, title: 'KYC Level', ref: 'kyc' },
  { id: 12, title: 'Transaction Fee', ref: 'fee' },
];

export const userReportColumn = [
  {
    id: 1,
    title: 'S/N',
    ref: 'sn',
  },
  { id: 2, title: 'Last Login time', ref: 'lastLoginTime' },
  { id: 3, title: 'Account Name', ref: 'accountName' },
  { id: 4, title: 'Account Number', ref: 'accountNumber' },
  { id: 5, title: 'Phone Number', ref: 'phoneNumber' },
  { id: 6, title: 'Account Balance(N)', ref: 'accountBalance' },
  { id: 7, title: 'Status', ref: 'status' },
  { id: 8, title: 'KYC Level', ref: 'kyc' },
  { id: 9, title: 'Gender', ref: 'gender' },
  { id: 10, title: 'Age', ref: 'age' },
  { id: 11, title: 'Total Transactions', ref: 'totalTransactions' },
  { id: 12, title: 'Location', ref: 'location' },
  { id: 13, title: 'Date Onboarded', ref: 'dateOnboarded' },
];

export const incomeReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Payment Method', ref: 'paymentMethod' },
  { id: 3, title: 'Payment Date', ref: 'paymentDate' },
  { id: 4, title: 'Account Name', ref: 'accountName' },
  { id: 5, title: 'Account Number', ref: 'accountNumber' },
  { id: 6, title: 'Reference ID', ref: 'referenceID' },
  { id: 7, title: 'Payment Charge(N)', ref: 'paymentCharge' },
  { id: 8, title: 'Transaction Amount', ref: 'transactionAmount' },
  { id: 9, title: 'Expense(N)', ref: 'expense' },
  { id: 10, title: 'Revenue(N)', ref: 'revenue' },
  { id: 11, title: 'Profit Margin(%)', ref: 'profitMargin' },
  { id: 12, title: 'Timestamp', ref: 'timestamp' },
];

export const volumeReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Payment Method', ref: 'paymentMethod' },
  { id: 3, title: 'Payment Date', ref: 'paymentDate' },
  { id: 4, title: 'Transaction count', ref: 'transactionCount' },
  { id: 5, title: 'Total value(N)', ref: 'totalValue' },
  { id: 6, title: 'Total Revenue', ref: 'totalRevenue' },
  // { id: 9, title: 'Profit Margin(%)', ref: 'profitMargin' },
];

export const timebasedReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Transaction Date', ref: 'transactionDate' },
  { id: 3, title: 'Transaction Type', ref: 'transactionType' },
  { id: 4, title: 'Account ID', ref: 'accountID' },
  { id: 5, title: 'Account Name', ref: 'accountName' },
  { id: 6, title: 'Status', ref: 'status' },
  { id: 7, title: 'Amount(N)', ref: 'amount' },
  { id: 8, title: 'Payment Method', ref: 'paymentMethod' },
  { id: 9, title: 'Transaction Channel', ref: 'transactionChannel' },
  { id: 10, title: 'Refund Status', ref: 'refundStatus' },
  { id: 11, title: 'Transaction Code', ref: 'transactionCode' },
  { id: 12, title: 'Time Stamp', ref: 'timeStamp' },
  { id: 13, title: 'Transaction Fee', ref: 'transactionFee' },
  { id: 14, title: 'KYC Level', ref: 'kycLevel' },
];

export const reconciliationReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Transaction Date', ref: 'transactionDate' },
  { id: 3, title: 'Transaction Type', ref: 'transactionType' },
  { id: 4, title: 'Account Name', ref: 'accountName' },
  { id: 5, title: 'Account Number', ref: 'accountNumber' },
  { id: 6, title: 'Internal Ref Num', ref: 'internalRefNum' },
  { id: 7, title: 'Status', ref: 'status' },
  { id: 8, title: 'Amount (N)', ref: 'amount' },
  { id: 9, title: 'Reconciliation Status', ref: 'reconciliationStatus' },
  // { id: 10, title: 'Number of Reconciliation', ref: 'numberOfReconciliation' },
];

export const settlementReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Date', ref: 'date' },
  { id: 3, title: 'Settlement Type', ref: 'settlementType' },
  { id: 4, title: 'Bank Account', ref: 'bankAccount' },
  { id: 5, title: 'Settlement Status', ref: 'settlementStatus' },
  { id: 6, title: 'Amount(N)', ref: 'amount' },
  { id: 7, title: 'Settlement Method', ref: 'settlementMethod' },
  { id: 8, title: 'Bank Statement Ref', ref: 'bankStatementRef' },
  { id: 9, title: 'Settlement Batch ID', ref: 'settlementBatchID' },
  { id: 10, title: 'Approved Status', ref: 'approvedStatus' },
  { id: 11, title: 'Settlement Destination', ref: 'settlementDestination' },
  { id: 12, title: 'TimeStamp', ref: 'timeStamp' },
  { id: 13, title: 'Chargebacks', ref: 'chargebacks' },
];

export const userRegReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Date', ref: 'date' },
  { id: 3, title: 'Location', ref: 'location' },
  { id: 4, title: 'Phone Number', ref: 'phoneNumber' },
  { id: 5, title: 'Account Number', ref: 'accountNumber' },
  { id: 6, title: 'Account Name', ref: 'accountName' },
  { id: 7, title: 'Device Type', ref: 'deviceType' },
  { id: 8, title: 'User Status', ref: 'userStatus' },
  { id: 9, title: 'Agency', ref: 'agency' },
  { id: 10, title: 'Source of Reg', ref: 'sourceOfReg' },
  { id: 11, title: 'Gender', ref: 'gender' },
  { id: 12, title: 'Age', ref: 'age' },
  { id: 13, title: 'User Level', ref: 'userLevel' },
  { id: 14, title: 'Customer Channel', ref: 'customerChannel' },
  { id: 15, title: 'Account Verification', ref: 'accountVerification' },
  { id: 16, title: 'User Feedback', ref: 'userFeedback' },
];

export const userActivitiesColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Date', ref: 'date' },
  { id: 3, title: 'User ID', ref: 'userId' },
  { id: 4, title: 'Activity Type', ref: 'activityType' },
  { id: 5, title: 'Device Type', ref: 'deviceType' },
  { id: 6, title: 'Location', ref: 'location' },
  { id: 7, title: 'Security Events', ref: 'securityEvents' },
  { id: 8, title: 'Session Duration', ref: 'sessionDuration' },
  { id: 9, title: 'User Status', ref: 'userStatus' },
  { id: 10, title: 'Transaction Frequency', ref: 'transactionFrequency' },
  { id: 11, title: 'User Reg Date', ref: 'userRegDate' },
  { id: 12, title: 'User Level', ref: 'userLevel' },
];

export const financeReportColumn = [
  { id: 1, title: 'S/N', ref: 'sn' },
  { id: 2, title: 'Transaction Date', ref: 'transactionDate' },
  { id: 3, title: 'Transaction Type', ref: 'transactionType' },
  { id: 4, title: 'Account ID', ref: 'accountID' },
  { id: 5, title: 'Status', ref: 'status' },
  { id: 6, title: 'Amount', ref: 'amount' },
  { id: 7, title: 'Payment Method', ref: 'paymentMethod' },
  { id: 8, title: 'Transaction Fee', ref: 'transactionFee' },
  { id: 9, title: 'Transaction Code', ref: 'transactionCode' },
  { id: 10, title: 'User Level', ref: 'userLevel' },
];

export const transactionReport = [
  {
    id: 1,
    sn: 1,
    transactionDate: '30/08/2023',
    transactionType: 'Zojapay to Zojapay',
    accountName: 'John Doe',
    amount: 20000,
    timestamp: '30 minutes ago',
    status: 'Successful',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    referenceID: 12345678901234567890,
    kyc: 'Basic',
    fee: 0,
  },
  {
    sn: 2,
    transactionDate: '30/08/2023',
    transactionType: 'Zojapay to Zojapay',
    accountName: 'John Doe',
    amount: 20000,
    timestamp: '30 minutes ago',
    status: 'Successful',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    referenceID: 12345678901234567890,
    kyc: 'Basic',
    fee: 0,
  },
  {
    sn: 3,
    transactionDate: '30/08/2023',
    transactionType: 'Zojapay to Zojapay',
    accountName: 'John Doe',
    amount: 20000,
    timestamp: '30 minutes ago',
    status: 'Failed',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    referenceID: 12345678901234567890,
    kyc: 'Basic',
    fee: 0,
  },
  {
    sn: 4,
    transactionDate: '30/08/2023',
    transactionType: 'Zojapay to Zojapay',
    accountName: 'John Doe',
    amount: 20000,
    timestamp: '30 minutes ago',
    status: 'Pending',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    referenceID: 12345678901234567890,
    kyc: 'Basic',
    fee: 0,
  },
  {
    sn: 5,
    transactionDate: '30/08/2023',
    transactionType: 'Zojapay to Zojapay',
    accountName: 'John Doe',
    amount: 20000,
    timestamp: '30 minutes ago',
    status: 'Successful',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    referenceID: 12345678901234567890,
    kyc: 'Basic',
    fee: 0,
  },
];
export const incomeReport = [
  {
    sn: 1,
    PaymentMethod: 'Fund with card',
    paymentDate: '30/08/2023',
    AccountName: 'John Doe',
    accountNumber: '00000000000',
    referenceID: '12345678901234567890',
    paymentCharge: 25,
    expense: 28,
    revenue: '07013264261',
    profitMargin: '07013264261',
    timestamp: '12:00pm',
  },
  {
    sn: 2,
    PaymentMethod: 'Fund with card',
    paymentDate: '30/08/2023',
    AccountName: 'John Doe',
    accountNumber: '00000000000',
    referenceID: '12345678901234567890',
    paymentCharge: 25,
    expense: 28,
    revenue: '07013264261',
    profitMargin: '07013264261',
    timestamp: '12:00pm',
  },
  {
    sn: 3,
    PaymentMethod: 'Fund with card',
    paymentDate: '30/08/2023',
    AccountName: 'John Doe',
    accountNumber: '00000000000',
    referenceID: '12345678901234567890',
    paymentCharge: 25,
    expense: 28,
    revenue: '07013264261',
    profitMargin: '07013264261',
    timestamp: '12:00pm',
  },
  {
    sn: 4,
    PaymentMethod: 'Fund with card',
    paymentDate: '30/08/2023',
    AccountName: 'John Doe',
    accountNumber: '00000000000',
    referenceID: '12345678901234567890',
    paymentCharge: 25,
    expense: 28,
    revenue: '07013264261',
    profitMargin: '07013264261',
    timestamp: '12:00pm',
  },
  {
    sn: 5,
    PaymentMethod: 'Fund with card',
    paymentDate: '30/08/2023',
    AccountName: 'John Doe',
    accountNumber: '00000000000',
    referenceID: '12345678901234567890',
    paymentCharge: 25,
    expense: 28,
    revenue: '07013264261',
    profitMargin: '07013264261',
    timestamp: '12:00pm',
  },
];

export const userReport = [
  {
    sn: 1,
    lastLoginTime: 'Online',
    accountName: 'John Doe',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    accountBalance: 20000,
    status: 'Active',
    kyc: 'Basic',
    gender: 'Male',
    age: 'Male',
    totalTransactions: '07013264261',
    location: 'Lagos',
    dateOnboarded: '30/08/2023',
  },
  {
    sn: 2,
    lastLoginTime: 'Online',
    accountName: 'John Doe',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    accountBalance: 20000,
    status: 'Active',
    kyc: 'Basic',
    gender: 'Male',
    age: 'Male',
    totalTransactions: '07013264261',
    location: 'Lagos',
    dateOnboarded: '30/08/2023',
  },
  {
    sn: 3,
    lastLoginTime: 'Online',
    accountName: 'John Doe',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    accountBalance: 20000,
    status: 'Active',
    kyc: 'Basic',
    gender: 'Male',
    age: 'Male',
    totalTransactions: '07013264261',
    location: 'Lagos',
    dateOnboarded: '30/08/2023',
  },
  {
    sn: 4,
    lastLoginTime: 'Online',
    accountName: 'John Doe',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    accountBalance: 20000,
    status: 'Active',
    kyc: 'Basic',
    gender: 'Male',
    age: 'Male',
    totalTransactions: '07013264261',
    location: 'Lagos',
    dateOnboarded: '30/08/2023',
  },
  {
    sn: 5,
    lastLoginTime: 'Online',
    accountName: 'John Doe',
    accountNumber: '00000000000',
    phoneNumber: '07013264261',
    accountBalance: 20000,
    status: 'Active',
    kyc: 'Basic',
    gender: 'Male',
    age: 'Male',
    totalTransactions: '07013264261',
    location: 'Lagos',
    dateOnboarded: '30/08/2023',
  },
];

export const volumeReport = [
  {
    sn: 1,
    paymentMethod: 'Zojapay to zojapay',
    paymentDate: '30/08/2023',
    timestamp: 'Today',
    transactionCount: 'John Doe',
    totalValue: '00000000000',
    userType: 'KYC lvl 1-3',
    totalRevenue: 0,
    profitMargin: '07013264261',
  },
  {
    sn: 2,
    paymentMethod: 'Zojapay to zojapay',
    paymentDate: '30/08/2023',
    timestamp: 'Today',
    transactionCount: 'John Doe',
    totalValue: '00000000000',
    userType: 'KYC lvl 1-3',
    totalRevenue: 0,
    profitMargin: '07013264261',
  },
  {
    sn: 3,
    paymentMethod: 'Zojapay to zojapay',
    paymentDate: '30/08/2023',
    timestamp: 'Today',
    transactionCount: 'John Doe',
    totalValue: '00000000000',
    userType: 'KYC lvl 1-3',
    totalRevenue: 0,
    profitMargin: '07013264261',
  },
  {
    sn: 4,
    paymentMethod: 'Zojapay to zojapay',
    paymentDate: '30/08/2023',
    timestamp: 'Today',
    transactionCount: 'John Doe',
    totalValue: '00000000000',
    userType: 'KYC lvl 1-3',
    totalRevenue: 0,
    profitMargin: '07013264261',
  },
  {
    sn: 5,
    paymentMethod: 'Zojapay to zojapay',
    paymentDate: '30/08/2023',
    timestamp: 'Today',
    transactionCount: 'John Doe',
    totalValue: '00000000000',
    userType: 'KYC lvl 1-3',
    totalRevenue: 0,
    profitMargin: '07013264261',
  },
];
export const timebasedReport = [
  {
    sn: 1,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    accountName: 'John Doe',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Bank Transfer',
    transactionChannel: 'Web',
    refundStatus: 'Completed',
    transactionCode: '#00000',
    timeStamp: '30 Mins ago',
    transactionFee: '25 Naira',
  },
  {
    sn: 2,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    accountName: 'John Doe',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Bank Transfer',
    transactionChannel: 'Web',
    refundStatus: 'Completed',
    transactionCode: '#00000',
    timeStamp: '30 Mins ago',
    transactionFee: '25 Naira',
  },
  {
    sn: 3,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    accountName: 'John Doe',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Bank Transfer',
    transactionChannel: 'Web',
    refundStatus: 'Completed',
    transactionCode: '#00000',
    timeStamp: '30 Mins ago',
    transactionFee: '25 Naira',
  },
  {
    sn: 4,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    accountName: 'John Doe',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Bank Transfer',
    transactionChannel: 'Web',
    refundStatus: 'Completed',
    transactionCode: '#00000',
    timeStamp: '30 Mins ago',
    transactionFee: '25 Naira',
  },
  {
    sn: 5,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    accountName: 'John Doe',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Bank Transfer',
    transactionChannel: 'Web',
    refundStatus: 'Completed',
    transactionCode: '#00000',
    timeStamp: '30 Mins ago',
    transactionFee: '25 Naira',
  },
];

export const reconcialiationReport = [
  {
    sn: 1,
    transactionDate: '30/08/2023',
    transactionType: 'Withdrawals',
    accountName: 'John Doe',
    accountNumber: '000000000000',
    internalRefNum: 6421,
    status: 'Successful',
    amount: '20,000',
    reconciliationStatus: 'Unreconciled',
    numberOfReconciliation: 'Number of Reconciliation',
    transactionCode: 5,
  },
  {
    sn: 2,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountName: 'John Doe',
    accountNumber: '000000000000',
    internalRefNum: 6421,
    status: 'Successful',
    amount: '20,000',
    reconciliationStatus: 'Unreconciled',
    numberOfReconciliation: 'Number of Reconciliation',
    transactionCode: 5,
  },
  {
    sn: 3,
    transactionDate: '30/08/2023',
    transactionType: 'Withdrawals',
    accountName: 'John Doe',
    accountNumber: '000000000000',
    internalRefNum: 6421,
    status: 'Successful',
    amount: '20,000',
    reconciliationStatus: 'Unreconciled',
    numberOfReconciliation: 'Number of Reconciliation',
    transactionCode: 5,
  },
  {
    sn: 4,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountName: 'John Doe',
    accountNumber: '000000000000',
    internalRefNum: 6421,
    status: 'Successful',
    amount: '20,000',
    reconciliationStatus: 'Unreconciled',
    numberOfReconciliation: 'Number of Reconciliation',
    transactionCode: 5,
  },
  {
    sn: 5,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountName: 'John Doe',
    accountNumber: '000000000000',
    internalRefNum: 6421,
    status: 'Successful',
    amount: '20,000',
    reconciliationStatus: 'Unreconciled',
    numberOfReconciliation: 'Number of Reconciliation',
    transactionCode: 5,
  },
];

export const settlementReport = [
  {
    sn: 1,
    date: '30/08/2023',
    settlementType: 'Daily',
    bankAccount: '000000000000',
    settlementStatus: 'John Doe',
    amount: '20,000',
    settlementMethod: 'Wire Transfer',
    bankStatementRef: '14444',
    settlementBatchID: '#30069',
    approvedStatus: 'Not Approved',
    settlementDestination: 'Bank Account',
    timeStamp: '30 mins ago',
    chargebacks: 'Reversals',
  },
  {
    sn: 2,
    date: '30/08/2023',
    settlementType: 'Daily',
    bankAccount: '000000000000',
    settlementStatus: 'John Doe',
    amount: '20,000',
    settlementMethod: 'Wire Transfer',
    bankStatementRef: '14444',
    settlementBatchID: '#30069',
    approvedStatus: 'Not Approved',
    settlementDestination: 'Bank Account',
    timeStamp: '30 mins ago',
    chargebacks: 'Reversals',
  },
  {
    sn: 3,
    date: '30/08/2023',
    settlementType: 'Daily',
    bankAccount: '000000000000',
    settlementStatus: 'John Doe',
    amount: '20,000',
    settlementMethod: 'Wire Transfer',
    bankStatementRef: '14444',
    settlementBatchID: '#30069',
    approvedStatus: 'Not Approved',
    settlementDestination: 'Bank Account',
    timeStamp: '30 mins ago',
    chargebacks: 'Reversals',
  },
  {
    sn: 4,
    date: '30/08/2023',
    settlementType: 'Daily',
    bankAccount: '000000000000',
    settlementStatus: 'John Doe',
    amount: '20,000',
    settlementMethod: 'Wire Transfer',
    bankStatementRef: '14444',
    settlementBatchID: '#30069',
    approvedStatus: 'Not Approved',
    settlementDestination: 'Bank Account',
    timeStamp: '30 mins ago',
    chargebacks: 'Reversals',
  },
  {
    sn: 5,
    date: '30/08/2023',
    settlementType: 'Daily',
    bankAccount: '000000000000',
    settlementStatus: 'John Doe',
    amount: '20,000',
    settlementMethod: 'Wire Transfer',
    bankStatementRef: '14444',
    settlementBatchID: '#30069',
    approvedStatus: 'Not Approved',
    settlementDestination: 'Bank Account',
    timeStamp: '30 mins ago',
    chargebacks: 'Reversals',
  },
];

export const userRegistrationReport = [
  {
    sn: 1,
    date: '30/08/2023',
    location: 'Daily',
    phoneNumber: '07013264261',
    accountNumber: '00000000000',
    accountName: 'Joe Doe',
    deviceType: 'Desktop',
    userStatus: 'Active',
    agency: 'Super Agent',
    sourceOfReg: 'Web',
    gender: 'Female',
    age: '30 mins ago',
    userLevel: 'Level 0',
    customerChannel: 'Social Media',
    accountVerification: 'Approved',
    userFeedback: 'Good',
  },
  {
    sn: 2,
    date: '30/08/2023',
    location: 'Daily',
    phoneNumber: '07013264261',
    accountNumber: '00000000000',
    accountName: 'Joe Doe',
    deviceType: 'Desktop',
    userStatus: 'Active',
    agency: 'Super Agent',
    sourceOfReg: 'Web',
    gender: 'Female',
    age: '30 mins ago',
    userLevel: 'Level 0',
    customerChannel: 'Social Media',
    accountVerification: 'Unapproved',
    userFeedback: 'Very Good',
  },
  {
    sn: 3,
    date: '30/08/2023',
    location: 'Daily',
    phoneNumber: '07013264261',
    accountNumber: '00000000000',
    accountName: 'Joe Doe',
    deviceType: 'Desktop',
    userStatus: 'Active',
    agency: 'Super Agent',
    sourceOfReg: 'Mobile',
    gender: 'Female',
    age: '30 mins ago',
    userLevel: 'Level 0',
    customerChannel: 'Social Media',
    accountVerification: 'Approved',
    userFeedback: 'Bad',
  },
  {
    sn: 4,
    date: '30/08/2023',
    location: 'Daily',
    phoneNumber: '07013264261',
    accountNumber: '00000000000',
    accountName: 'Joe Doe',
    deviceType: 'Desktop',
    userStatus: 'Active',
    agency: 'Super Agent',
    sourceOfReg: 'Web',
    gender: 'Female',
    age: '30 mins ago',
    userLevel: 'Level 0',
    customerChannel: 'Social Media',
    accountVerification: 'Approved',
    userFeedback: 'Good',
  },
  {
    sn: 5,
    date: '30/08/2023',
    location: 'Daily',
    phoneNumber: '07013264261',
    accountNumber: '00000000000',
    accountName: 'Joe Doe',
    deviceType: 'Desktop',
    userStatus: 'Active',
    agency: 'Super Agent',
    sourceOfReg: 'Mobile',
    gender: 'Female',
    age: '30 mins ago',
    userLevel: 'Level 0',
    customerChannel: 'Social Media',
    accountVerification: 'Approved',
    userFeedback: 'Good',
  },
];

export const userActivitiesReport = [
  {
    sn: 1,
    date: '30/08/2023',
    userId: '00000000000',
    activityType: 'Log In',
    deviceType: 'Desktop',
    location: 'Lagos',
    securityEvents: 'Account Lockout',
    sessionDuration: '30 Mins',
    userStatus: 'Inactive',
    transactionFrequency: '0000000000',
    userRegDate: '28/11/2022',
    userLevel: 'Level 1',
  },
  {
    sn: 2,
    date: '30/08/2023',
    userId: '00000000000',
    activityType: 'Log In',
    deviceType: 'Desktop',
    location: 'Lagos',
    securityEvents: 'Account Lockout',
    sessionDuration: '30 Mins',
    userStatus: 'Inactive',
    transactionFrequency: '0000000000',
    userRegDate: '28/11/2022',
    userLevel: 'Level 0',
  },
  {
    sn: 3,
    date: '30/08/2023',
    userId: '00000000000',
    activityType: 'Log In',
    deviceType: 'Mobile',
    location: 'Abuja',
    securityEvents: 'Account Lockout',
    sessionDuration: '10 Mins',
    userStatus: 'Active',
    transactionFrequency: '000010000',
    userRegDate: '28/10/2022',
    userLevel: 'Level 2',
  },
  {
    sn: 4,
    date: '30/08/2023',
    userId: '00000000000',
    activityType: 'Log In',
    deviceType: 'Mobile',
    location: 'Abuja',
    securityEvents: 'Account Lockout',
    sessionDuration: '10 Mins',
    userStatus: 'Active',
    transactionFrequency: '000010000',
    userRegDate: '28/10/2022',
    userLevel: 'Level 1',
  },
  {
    sn: 5,
    date: '30/08/2023',
    userId: '00000000000',
    activityType: 'Log In',
    deviceType: 'Mobile',
    location: 'Abuja',
    securityEvents: 'Account Lockout',
    sessionDuration: '10 Mins',
    userStatus: 'Active',
    transactionFrequency: '000010000',
    userRegDate: '28/10/2022',
    userLevel: 'Level 3',
  },
];

export const financeReport = [
  {
    sn: 1,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Zojapay to Zojapay',
    transactionFee: '25 Naira',
    transactionCode: '#00000',
    userLevel: 'Level 1',
  },
  {
    sn: 2,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Zojapay to Zojapay',
    transactionFee: '25 Naira',
    transactionCode: '#00000',
    userLevel: 'Level 1',
  },
  {
    sn: 3,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Zojapay to Zojapay',
    transactionFee: '25 Naira',
    transactionCode: '#00000',
    userLevel: 'Level 1',
  },
  {
    sn: 4,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Zojapay to Zojapay',
    transactionFee: '25 Naira',
    transactionCode: '#00000',
    userLevel: 'Level 1',
  },
  {
    sn: 5,
    transactionDate: '30/08/2023',
    transactionType: 'Deposit',
    accountID: '0000000000',
    status: 'Successful',
    amount: '20,000',
    paymentMethod: 'Zojapay to Zojapay',
    transactionFee: '25 Naira',
    transactionCode: '#00000',
    userLevel: 'Level 1',
  },
];

export const reportTitle = {
  dailyreport: {
    id: 1,
    title: 'Daily Transaction Report',
    frequency: 'daily',
  },
  weeklyreport: {
    id: 2,
    title: 'Weekly Transaction Report',
    frequency: 'weekly',
  },
  monthlyreport: {
    id: 3,
    title: 'Monthly Transaction Report',
    frequency: 'monthly',
  },
  reconciliationreport: {
    id: 4,
    title: 'Reconciliation Report',
  },
  settlementreport: {
    id: 5,
    title: 'Settlement Report',
  },
  financereport: {
    id: 6,
    title: 'Finance Report',
  },
  userregistrationreport: {
    id: 7,
    title: 'User Registration and Growth Report',
  },
  useractivitesreport: {
    id: 8,
    title: 'User Activities and Access Logs Report',
  },
};
