import { Formik } from 'formik';
import { useEffect } from 'react';
import { MdClose } from 'react-icons/md';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getAdminRequestDetails, reviewAdminRequest } from '../../redux/slice';
import { Dictionary } from '../../types';
import { ZojaButton, ZojaInput, ZojaModal, ZojaTextArea } from '../tailwind';

interface DepartmentModalProps {
  show: boolean;
  handleClose: () => void;
  departmentDetails: Dictionary;
  setShowRejectRequestModal: (arg: boolean) => void
}

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  hod_name: yup.string().required('Hod Name name is required'),
  description: yup.string().required('Description name is required'),
});

export default function ReviewDepartmentModal({
  show,
  handleClose,
  departmentDetails,
  setShowRejectRequestModal
}: DepartmentModalProps) {

  const dispatch = useAppDispatch()

  const reviewAdminRequestState = useAppSelector((state) => state.reviewAdminRequest)

  const adminRequestDetails = useAppSelector((state) => state.getAdminRequestDetails)
  const { data: { request_detail }, status: adminRequestDetailsStatus } = adminRequestDetails

  const handleFormSubmit = (data: Dictionary) => {
    dispatch(reviewAdminRequest({id: departmentDetails?.id, data: {status: 'approved'}}))
  }

  useEffect(() => {
    if (show) {
      dispatch(getAdminRequestDetails({ id: departmentDetails?.id }))
    }
  }, [show])

  useEffect(() => {
    if (reviewAdminRequestState.status === 'succeeded') {
      handleClose()
    }
  },[reviewAdminRequestState.status])

  return (
    <>
      <ZojaModal
        show={show}
        handleClose={handleClose}
        height="auto"
        position="center"
        extraClass="md:tw-w-[35rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12 tw-pt-7 tw-pb-10 tw-px-8"
        borderRadius="8px">
        <div className="">
          <h2 className="tw-font-medium tw-text-xl tw-mb-8">Department Information</h2>

          <span
            className="tw-absolute tw-top-6 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
            onClick={() => {
              handleClose();
            }}>
            <MdClose />
          </span>

          {adminRequestDetailsStatus === 'loading' ? (
            <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="60"
                width="60"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>) : (
            <div className="">
              <Formik
                initialValues={{
                  name: request_detail?.name || 'N/A',
                  hod_name: request_detail?.hod_name || 'N/A',
                  description: request_detail?.description || 'N/A',
                }}
                enableReinitialize={true}
                validationSchema={schema}
                validateOnMount={true}
                onSubmit={async (values, { setSubmitting }) => {
                  const { description, name, hod_name } = values ?? '';

                  const payload = {
                    description: description,
                    hod_name: hod_name,
                    name: name,
                  };
                  handleFormSubmit(payload);
                  setSubmitting(false);
                }}>
                {formikProps => {
                  const { handleChange, values, handleSubmit, errors, touched, isValid } = formikProps;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="tw-grid tw-gap-y-8">
                        <ZojaInput
                          label="Name of Department"
                          labelExtraClass="tw-font-normal !tw-text-sm tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                          id={`name`}
                          extraClass={`tw-border-[#E7E7E7] !tw-text-[.8rem] !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                          name={`name`}
                          value={values.name}
                          placeholder="Enter Name of Department"
                          onChange={handleChange}
                          disabled
                        />
                        <ZojaTextArea
                          value={values.description}
                          onChange={handleChange}
                          name="description"
                          id="description"
                          placeholder="Enter description"
                          extraClass={`tw-border-[#E7E7E7] !tw-text-[.8rem] tw-px-0 tw-p-0 placeholder:tw-text-[.8rem] focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-h-40 tw-rounded-[10px]`}
                          label="Department Description"
                          labelExtraClass="tw-font-normal !tw-text-sm tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                          resize="none"
                          disabled
                        />
                        <ZojaInput
                          label="Name of HOD (optional)"
                          labelExtraClass="tw-font-normal !tw-text-sm tw-text-base tw-text-[#5E6366] tw-mb-4 !tw-font-light"
                          id={`hod_name`}
                          extraClass={`tw-border-[#E7E7E7] !tw-text-[.8rem] !tw-font-light focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-py-3 tw-rounded-[10px]`}
                          name={`hod_name`}
                          value={values.hod_name}
                          placeholder="Enter Name of HOD"
                          onChange={handleChange}
                          disabled
                        />
                        {
                          departmentDetails?.status === "pending" ?
                            <div className="tw-flex tw-items-center tw-gap-x-6 tw-mt-6">
                              <ZojaButton
                                type="submit"
                                text={'Approve'}
                                loading={reviewAdminRequestState.status === "loading"}
                                disabled={reviewAdminRequestState.status === "loading"}
                                loadingText="Approve"
                                // extraClass={`${loading || !isValid ? '!tw-bg-[#E7E7E7] tw-text-[#5E6366]' : 'tw-bg-isPrimary tw-text-white'
                                //   } tw-py-[14px] tw-px-8 tw-text-sm`}
                                extraClass={`tw-bg-isPrimary tw-text-white tw-py-[10px] tw-px-10 tw-text-sm`}
                                loadingClass="tw-justify-center"
                              />
                              <ZojaButton
                                type="button"
                                text={'Reject'}
                                onClick={() => {
                                  setShowRejectRequestModal(true)
                                  handleClose();
                                }}
                                extraClass="tw-bg-red-600 tw-text-white tw-py-[10px] tw-px-10  tw-font-medium tw-text-sm"
                              />
                            </div> : (
                              <div>
                                {departmentDetails?.status === 'approved' && <span className='tw-text-xs tw-p-2 tw-rounded-sm tw-text-white tw-bg-green-700 tw-px-6'>Approved</span>}
                                {departmentDetails?.status === 'rejected' && <span className='tw-text-xs tw-p-2 tw-rounded-sm tw-text-white tw-bg-red-700 tw-px-6'>Rejected</span>}
                              </div>
                            )
                        }
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          )
          }


        </div>
      </ZojaModal>
    </>
  );
}

