import { ReactComponent as EmptyStateNote } from '../../../assets/svg/emptyStateNote.svg';
import { Dictionary } from '../../../types';
import moment from 'moment';
import { currencyFormat, capitalizeFirstLetter, dateAndTimeFormatString } from '../../../utils';
import { TD, TR } from '../../tailwind/table/tableElements';
import { ZojaTable } from '../..';

export interface TableIPropsIProps {
  data: Dictionary[];
  headerData: Dictionary[];
  sorting?: any;
  order?: string;
}

function AccountManagementTransactionsTable({ data, headerData, sorting, order }: TableIPropsIProps) {
  return (
    <>
      {data?.length >= 1 ? (
        <ZojaTable column={headerData} sorting={sorting} order={order} thClass="tw-text-isBlack" tableHeight={`fit`}>
          <>
            {/* old table data */}
            {/* {data?.map((item: any, index) => (
              <TR key={`${item.narration}${index}AccountManagementTransactionsTable`} extraClass={`tw-cursor-default`}>
                <TD extraClass="tw-text-isBlack">{index + 1}</TD>
                <TD extraClass="tw-text-isBlack">
                  {item.transaction_type ? capitalizeFirstLetter(item.transaction_type) : `--`}
                </TD>
                <TD extraClass="tw-text-isBlack">{item.narration ? ellipsis(item.narration, 18) : ''}</TD>
                <TD extraClass="tw-text-isBlack">{item.amount ? currencyFormat(item.amount) : `--`}</TD>
                <TD
                  extraClass={`tw-font-medium ${
                    item.status === `success`
                      ? 'tw-text-isGreen'
                      : item.status === `failed`
                      ? 'tw-text-isRed'
                      : 'tw-text-isBlack'
                  }`}>
                  {item.status ? capitalizeFirstLetter(item.status) : `--`}
                </TD>
                <TD extraClass="tw-text-isBlack">
                  {item.old_balance
                    ? currencyFormat(item.old_balance)
                    : item.old_balance === 0
                    ? currencyFormat(0)
                    : `--`}
                </TD>
                <TD extraClass="tw-text-isBlack">
                  {item.balance ? currencyFormat(item.balance) : item.balance === 0 ? currencyFormat(0) : `--`}
                </TD>
                <TD extraClass="tw-text-isBlack">{moment(item.date).format(dateAndTimeFormatString)}</TD>
                <TD>
                  <RxCaretRight
                    size={20}
                    color={colors.black}
                    onClick={() => onClick(item)}
                    className="tw-cursor-pointer"
                  />
                </TD>
              </TR>
            ))} */}
            {data?.map((item: any, index) => (
              <TR
                key={`${item.narration}${index}AccountManagementTransactionsTable`}
                extraClass={`tw-cursor-default tw-min-h-[100px]`}>
                <TD extraClass="tw-text-isBlack ">{index + 1}</TD>
                <TD extraClass="tw-text-isBlack  tw-w-fit tw-whitespace-nowrap">
                  {moment(item.date).format(dateAndTimeFormatString)}
                </TD>
                <TD extraClass="tw-text-isBlack  tw-w-fit tw-whitespace-nowrap">
                  {item.reference ? item.reference : `NA`}
                </TD>
                <TD extraClass="tw-text-isBlack tw-w-fit tw-whitespace-nowrap">
                  {item.transaction_type === 'debit' ? `${currencyFormat(+item.amount)}` : `-`}
                </TD>
                <TD extraClass="tw-text-isBlack tw-w-fit tw-whitespace-nowrap">
                  {item.transaction_type === 'credit' ? `${currencyFormat(+item.amount)}` : `-`}
                </TD>
                <TD extraClass="tw-text-isBlack tw-w-fit tw-whitespace-nowrap">
                  {item.balance ? currencyFormat(+item.balance) : item.balance === 0 ? currencyFormat(0) : `--`}
                </TD>
                <TD
                  extraClass={`tw-font-medium ${
                    item.status === `success`
                      ? 'tw-text-[#2DBD9B]'
                      : item.status === `failed`
                      ? 'tw-text-[#FD0000]'
                      : item.status === `pending`
                      ? `tw-text-[#FFAF56]`
                      : 'tw-text-isBlack'
                  }`}>
                  {item.status ? capitalizeFirstLetter(item.status) : `--`}
                </TD>
                <TD extraClass="tw-text-isBlack tw-min-h-fit tw-whitespace-normal tw-text-xs">
                  {item.narration ? item.narration : ''}
                </TD>
              </TR>
            ))}
          </>
        </ZojaTable>
      ) : (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <EmptyStateNote />
          <p>No Record Found</p>
        </div>
      )}
    </>
  );
}

export default AccountManagementTransactionsTable;
