import moment from "moment";
import { useEffect, useState } from "react";
import { FaChevronRight } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { Pagination, PaymentEmptyState, ZojaTable } from "../..";
import { PaymentDetailsModal, StatusLoader } from "../../../atoms";
import { cn } from "../../../lib/utils";
import { useAppDispatch, useAppSelector } from "../../../redux/redux-hooks";
import { getCompletedBatchPaymentRequest, getCompletedSinglePaymentRequest } from "../../../redux/slice";
import { Dictionary } from "../../../types";
import { currencyFormat, useSortTable } from "../../../utils";
import { ZojaSelect } from "../../tailwind";
import { TD, TR } from "../../tailwind/table/tableElements";

interface CompletedSinglePaymentTableProps {
    tab: string,
    handleItemClick: (event: any) => void
    handleActionClick?: (event: any) => void,
    setActiveTab?: (event: any) => void,
    allowed_permissions: any,
    sorting?: any;
    order?: string;
    payment_type: string
}

const tableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Date & Time',
        value: 'created_at',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Uploaded Document',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Review Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Approval Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Processing Status',
        value: 'processing_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    }
]

const initiatorColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'File Name',
        value: 'name',
        hasSortIcon: true,
    },
    // {
    //     id: 3,
    //     title: 'Uploaded By',
    //     value: 'uploaded_by',
    //     hasSortIcon: true,
    // },
    {
        id: 4,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    },
    {
        id: 5,
        title: 'Status',
        value: 'review_status',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Date Uploaded',
        value: 'created_at',
        hasSortIcon: true,
    }
    // {
    //     id: 6,
    //     title: 'Approval Status',
    //     value: 'approval_status',
    //     hasSortIcon: true,
    // },
    // {
    //     id: 7,
    //     title: 'Processing Status',
    //     value: 'processing_status',
    //     hasSortIcon: true,
    // },
]
const reviewerTableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Account Name',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Account Number',
        value: 'account_number',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Amount',
        value: 'amount',
        hasSortIcon: true,
    },
     {
        id: 5,
        title: 'Initiated By',
        value: 'initiated_by',
        hasSortIcon: true,
    },
    {
        id: 6,
        title: 'Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Rejection Reason',
        value: 'review_rejection_message',
        hasSortIcon: true,
    },
    {
        id: 8,
        title: 'Date Uploaded',
        value: 'created_at',
        hasSortIcon: true,
    },
]
const approvalTableColumns = [
    {
        id: 1,
        title: '#',
    },
    {
        id: 2,
        title: 'Account Name',
        value: 'name',
        hasSortIcon: true,
    },
    {
        id: 3,
        title: 'Account Number',
        value: 'account_number',
        hasSortIcon: true,
    },
    {
        id: 4,
        title: 'Amount',
        value: 'amount',
        hasSortIcon: true,
    },
     {
        id: 5,
        title: 'Initiated By',
        value: 'initiated_by',
        hasSortIcon: true,
    },
     {
        id: 6,
        title: 'Reviewed By',
        value: 'reviewed_by',
        hasSortIcon: true,
    },
    {
        id: 7,
        title: 'Status',
        value: 'approval_status',
        hasSortIcon: true,
    },
    // {
    //     id: 8,
    //     title: 'Rejection Reason',
    //     value: 'review_rejection_message',
    //     hasSortIcon: true,
    // },
    {
        id: 9,
        title: 'Date Uploaded',
        value: 'created_at',
        hasSortIcon: true,
    },
]

const STATUS_COLOR: Dictionary = {
    PENDING: 'tw-text-yellow-500',
    APPROVED: 'tw-text-green-500',
    SUCCESS: '!tw-text-green-500',
    REJECTED: 'tw-text-red-500',
    FAILED: 'tw-text-red-500',
}

export default function CompletedSinglePaymentTable({ tab, handleItemClick, setActiveTab, allowed_permissions, payment_type }: CompletedSinglePaymentTableProps) {
    const [activeTableColumn, setActiveTableColumn] = useState<any>(tableColumns);
    const [batchPaymentData, setBatchPaymentData] = useState<any[]>([]);
    const [showPaymentDetails, setShowPaymentDetails] = useState(false)
    const [actionType, setActionType] = useState('')
    const [paymentDetails, setPaymentDetails] = useState({})
    const [order, setOrder] = useState('ASC');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(5);
    const [payload, setPayload] = useState({
        page: 1,
        start_date: '',
        end_date: '',
        time: '',
        per_page: 10,
        type: payment_type
    })

    const loginState = useAppSelector(state => state.login);
    let CURRENT_USER_PERMISSION = loginState?.data?.user?.permissions?.map((d: Dictionary) => d.name)

    const getCompletedSinglePaymentState = useAppSelector((state) => state.getCompletedSinglePayment)
    const { status: getSingleCompletedPaymentStatus } = getCompletedSinglePaymentState;

    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const { status: reviewPaymentUploadStatus } = reviewPaymentUploadState

    const [searchParams, setSearchParams] = useSearchParams()

    const dispatch = useAppDispatch()

    // handle page number change
    const handlePageNumberChange = (pageNumber: any) => {
        let filters = getCompletedSinglePaymentState.filters
        dispatch(
            getCompletedBatchPaymentRequest({
                ...payload,
                per_page: pageNumber,
                ...filters,
                page: getCompletedSinglePaymentState?.data?.transactions?.meta?.current_page,
            }),
        );
    }

    // Handle table sorting
    // Custom Hooks
    const { handleSortItems } = useSortTable({
        order: order,
        setOrder,
        data: batchPaymentData,
        setData: setBatchPaymentData,
    });

    useEffect(() => {
        if (getCompletedSinglePaymentState?.data?.transactions?.data?.length > 0) {
            let updateData: any[] = [];
            getCompletedSinglePaymentState?.data?.transactions?.data.forEach((item: Dictionary, index: number) => {
                updateData.push({
                    id: index + 1,
                    uuid: item.id,
                    created_at: item.created_at,
                    name: item.name,
                    account_number: item.account_number,
                    amount: item.amount,
                    narration: item.narration,
                    review_status: item?.review_status,
                    approval_status: item?.approval_status,
                    processing_status: item?.processing_status,
                    review_rejection_reason: item?.review_rejection_reason,
                    review_rejection_message: item?.review_rejection_message,
                    initiated_by: item?.initiated_by?.name,
                    uploaded_by: item?.uploaded_by?.name,
                    approved_by: item?.approved_by?.name,
                    reviewed_by: item?.reviewed_by?.name,
                });
            });
            setBatchPaymentData(updateData);
            const {
                last_page,
            } = getCompletedSinglePaymentState?.data?.transactions?.meta;
            setTotalPages(last_page);
            setPayload({ ...payload, page: currentPage });
        }
    }, [getCompletedSinglePaymentState?.data, currentPage]);

    useEffect(() => {
        dispatch(getCompletedSinglePaymentRequest({ type: payment_type, page: currentPage, per_page: 10 }))
    }, [reviewPaymentUploadStatus, searchParams])

    useEffect(() => {
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[0])) {
            setActiveTableColumn(initiatorColumns)
        }
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[1])) {
            setActiveTableColumn(reviewerTableColumns)
        }
        if (CURRENT_USER_PERMISSION.includes(allowed_permissions[2])) {
            setActiveTableColumn(approvalTableColumns)
        }
    }, [])

    return (
        <>
        <PaymentDetailsModal
                show={showPaymentDetails}
                setShowPaymentDetails={setShowPaymentDetails}
                handleClose={() => setShowPaymentDetails(false)}
                allowed_permissions={allowed_permissions}
                payment_details={paymentDetails}
                action_type={actionType}
                tab={tab}
            />
            {
                getSingleCompletedPaymentStatus === "loading" ?
                    <StatusLoader status={getSingleCompletedPaymentStatus} width={'80'} height={'80'} wrapperHeight={'tw-h-[60vh]'} showEmptyState={false} /> :
                    getSingleCompletedPaymentStatus === "succeeded" && getCompletedSinglePaymentState?.data?.transactions?.data?.length ?
                        <>
                            <div className={`tw-mt- tw-borde tw-rounded-m tw-bg-white tw-p-6 tw-shadow-zojaShadowNine`}>
                                <div>
                                <ZojaTable
                                        column={activeTableColumn}
                                        sorting={handleSortItems}
                                        order={order}
                                        thClass="tw-text-gray-600 tw-font-semibold"
                                    >
                                        {
                                            batchPaymentData?.map((data: Dictionary, idx: number) => {
                                                return (
                                                    <TR key={idx}>
                                                        <TD value={idx + 1} extraClass="tw-text-gray-500" />
                                                        <TD value={data.name} extraClass="tw-text-gray-500" />
                                                        <TD value={data.account_number} extraClass="tw-text-gray-500" />
                                                        <TD value={currencyFormat(Number(data.amount))} extraClass="tw-text-gray-500" />
                                                        <TD value={data?.initiated_by || 'N/A'} extraClass={cn(
                                                            "tw-text-gray-500 tw-text-center",
                                                            !CURRENT_USER_PERMISSION.includes(allowed_permissions[1]) && "tw-hidden"
                                                        )} />
                                                         <TD value={data?.initiated_by || 'N/A'} extraClass={cn(
                                                            "tw-text-gray-500 tw-text-center",
                                                            !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && "tw-hidden"
                                                        )} />
                                                        <TD value={
                                                            CURRENT_USER_PERMISSION.includes(allowed_permissions[0]) && data.review_status === 'APPROVED' ?
                                                                'Reviewed' : data.review_status.toLowerCase()
                                                        } extraClass={cn(
                                                            `tw-text-center tw-text-xs !tw-capitalize`,
                                                            data.review_status && `${STATUS_COLOR[data.review_status]}`,
                                                            CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && "tw-hidden"
                                                        )} />
                                                        {/* <TD value={data?.uploaded_by || 'N/A'} extraClass={cn(
                                                            "tw-text-gray-500 tw-text-center",
                                                            CURRENT_USER_PERMISSION.includes(allowed_permissions[0]) && "tw-hidden"
                                                        )} /> */}
                                                        <TD value={data?.reviewed_by || 'N/A'} extraClass={cn(
                                                            "tw-text-gray-500 tw-text-center",
                                                            !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && "tw-hidden"
                                                        )} />
                                                        <TD
                                                            value={data.review_rejection_message ? data.review_rejection_message : 'N/A'}
                                                            extraClass={cn(
                                                                "tw-text-center",
                                                                CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && "tw-hidden"
                                                            )}
                                                        />
                                                        <TD value={data.approval_status.toLowerCase()} extraClass={cn(
                                                            `tw-text-center tw-text-xs`,
                                                            data.approval_status && `${STATUS_COLOR[data.approval_status]}`,
                                                            (!CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden')
                                                        )} />
                                                        <TD value={moment(data.created_at).format('DD MMMM, YYYY - hh:mm a')} extraClass="tw-text-gray-500 tw-capitalize" />

                                                        {/* <TD value={data.processing_status.toLowerCase()} extraClass={cn(
                                                            "tw-text-isPrimary tw-text-center tw-text-xs",
                                                            data.processing_status && `${STATUS_COLOR[data.processing_status]}`,
                                                            !CURRENT_USER_PERMISSION.includes(allowed_permissions[2]) && 'tw-hidden'
                                                        )} /> */}
                                                        <TD
                                                            extraClass={cn(
                                                                'tw-text-gray-400',
                                                            )}
                                                            onClick={() => {
                                                                if (CURRENT_USER_PERMISSION.includes(allowed_permissions[1])
                                                                ) {
                                                                    setPaymentDetails({
                                                                        type: payment_type,
                                                                        name: data.name,
                                                                        amount: data.amount,
                                                                        narration: data.narration,
                                                                        account_number: data.account_number,
                                                                        date: data.created_at,
                                                                        id: data.uuid
                                                                    })
                                                                    setActionType('view')
                                                                    setShowPaymentDetails(true)
                                                                }
                                                                if (CURRENT_USER_PERMISSION.includes(allowed_permissions[2])
                                                                ) {
                                                                    setPaymentDetails({
                                                                        type: payment_type,
                                                                        name: data.name,
                                                                        amount: data.amount,
                                                                        narration: data.narration,
                                                                        account_number: data.account_number,
                                                                        date: data.created_at,
                                                                        id: data.uuid,
                                                                        approval_status: data.approval_status
                                                                    })
                                                                    setActionType('view')
                                                                    setShowPaymentDetails(true)
                                                                }
                                                                // handleItemClick(data.uuid)
                                                            }}
                                                        >
                                                            <span className="tw-text-xs">
                                                                {' '}
                                                                <FaChevronRight />
                                                            </span>
                                                        </TD>
                                                    </TR>
                                                )

                                            }
                                            )
                                        }
                                    </ZojaTable>
                                </div>
                                <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                                    <Pagination
                                        isLoading={false}
                                        currentPage={getCompletedSinglePaymentState?.data?.transactions?.meta?.current_page}
                                        totalPages={totalPages}
                                        onPageChange={selectedPage => {
                                            setCurrentPage(selectedPage);
                                            let currentPage = selectedPage;
                                            let filterBy = {
                                                ...payload,
                                                per_page: getCompletedSinglePaymentState?.data?.transactions?.meta?.per_page,
                                                page: currentPage,
                                                type: payment_type
                                            };
                                            dispatch(getCompletedSinglePaymentRequest(filterBy));
                                        }}
                                    />
                                    <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                                        <span className="tw-text-xs tw-w-16">Per page</span>
                                        <ZojaSelect
                                            options={[
                                                { label: '10', value: '10' },
                                                { label: '25', value: '25' },
                                                { label: '50', value: '50' },
                                            ]}
                                            value={getCompletedSinglePaymentState?.data?.transactions?.meta?.per_page}
                                            setSelectedOption={(selected) => handlePageNumberChange(selected)}
                                            extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-[2.5rem]"
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                        :
                        <PaymentEmptyState
                            text="There is currently no data for you to view"
                        />
            }
        </>
    )
}
