import moment from 'moment';
import { currencyFormat, colors, capitalizeFirstLetter, dateAndTimeFormatString } from '../../utils';
import { FiArrowLeft } from 'react-icons/fi';
import { H3, H5, H1 } from '../../styles';
import { ZojaButton } from '../../components/tailwind';
import { Oval } from 'react-loader-spinner';
import { useAppSelector } from '../../redux/redux-hooks';
import { images } from '../../utils';
import { ProhibitionFormat } from '../../types';

interface CustomerAccountInformationProps {
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setInitiateProhibitionModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  setRestrictType: React.Dispatch<React.SetStateAction<ProhibitionFormat>>;
}

export default function AccountManagementInformation({
  setShowDetails,
  setInitiateProhibitionModalVisible,
  setRestrictType,
}: CustomerAccountInformationProps) {
  const { data, status: searchCustomerAccountStatementStatus } = useAppSelector(
    state => state.searchCustomerAccountStatement,
  );

  const {
    account_name,
    account_number,
    email,
    account_created_at,
    last_ban_history,
    phone_number,
    user_kyc_level,
    available_balance,
    total_inflow,
    total_out_flow,
    provider_balance,
    account_status,
    prohibition_status,
  } = data;

  const typed_account_status: 'active' | 'Account Restricted' | 'Account Frozen' | 'Account Banned' = account_status;
  const typed_prohibition_status:
    | 'Nil'
    | 'Restriction awaiting Review'
    | 'Restriction awaiting Approval'
    | 'Restriction approved'
    | 'unrestrict awaiting Review'
    | 'unrestrict awaiting Approval'
    | 'unrestrict approved'
    | 'freeze awaiting Review'
    | 'freeze awaiting Approval'
    | 'freeze approved'
    | 'unfreeze awaiting Review'
    | 'unfreeze awaiting Approval'
    | 'unfreeze approved'
    | 'ban awaiting Review'
    | 'ban awaiting Approval'
    | 'ban approved'
    | 'unban awaiting Review'
    | 'unban awaiting Approval'
    | 'unban approved' = prohibition_status;

  const keyStyles = `tw-text-sm tw-font-normal`;
  const valueStyles = 'tw-text-sm tw-font-light tw-pl-2';
  const tableStyles = 'tw-border-separate tw-border-spacing-y-2 tw-mt-2';

  return (
    <div
      className={`
       tw-px-6 tw-w-full
        `}>
      <div
        onClick={() => {
          setShowDetails(false);
        }}
        className="tw-cursor-pointer tw-w-fit">
        <FiArrowLeft size={24} color={colors.black} />
      </div>

      <div className="tw-w-full tw-flex tw-items-center tw-justify-between tw-mt-4 tw-mb-6">
        <div>
          <H3 color={colors.greyVariantEleven} bold left>
            {account_name}
          </H3>
          <H5 color={colors.greyVariantSeven} light left>
            View selected user details
          </H5>
        </div>

        {searchCustomerAccountStatementStatus === 'succeeded' && (
          <div className="tw-flex tw-space-x-6">
            {/* ACTIVE ACCOUNT SHOWS BOTH RESTRICT AND FREEZE BUTTONS */}
            {typed_account_status === 'active' && (
              <>
                {/* restrict button */}
                <ZojaButton
                  text="Restrict Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#FFAF56] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={false}
                  icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                  onClick={() => {
                    setRestrictType('restrict');
                    setInitiateProhibitionModalVisible(true);
                  }}
                />
                {/* freeze button */}
                <ZojaButton
                  text="Freeze Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-isRed tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={false}
                  icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                  onClick={() => {
                    setRestrictType('freeze');
                    setInitiateProhibitionModalVisible(true);
                  }}
                />
              </>
            )}

            {/* FROZEN ACCOUNT SHOWS A DISABLED RESTRICT BUTTON BECAUSE FREEZE SUPERCEDES RESTRICT */}
            {typed_account_status === 'Account Frozen' && (
              <ZojaButton
                text="Restrict Account"
                extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#FFAF56] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                type="button"
                disabled={true}
                icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                onClick={() => {}}
              />
            )}

            {/* RESTRICT/UNRESTRICT BUTTON PERMUTATIONS */}
            <>
              {/* RESTRICTED ACCOUNT SHOWS RESTRICT BUTTON BUT DISABLED */}
              {typed_account_status === 'Account Restricted' &&
                (typed_prohibition_status === 'Restriction awaiting Review' ||
                  typed_prohibition_status === 'Restriction awaiting Approval') && (
                  <ZojaButton
                    text="Restrict Account"
                    extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#FFAF56] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                    disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                    type="button"
                    disabled={true}
                    icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                    onClick={() => {}}
                  />
                )}

              {/* RESTRICTED ACCOUNT SHOWS UNRESTRICT BUTTON BUT DISABLED */}
              {typed_account_status === 'Account Restricted' &&
                (typed_prohibition_status === 'unrestrict awaiting Review' ||
                  typed_prohibition_status === 'unrestrict awaiting Approval') && (
                  <ZojaButton
                    text="Unrestrict Account"
                    extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#4EA78A] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                    disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                    type="button"
                    disabled={true}
                    icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                    onClick={() => {}}
                  />
                )}

              {/* RESTRICTED ACCOUNT SHOWS UNRESTRICT BUTTON */}
              {typed_account_status === 'Account Restricted' && typed_prohibition_status === 'Restriction approved' && (
                <ZojaButton
                  text="Unrestrict Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#4EA78A] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={false}
                  icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                  onClick={() => {
                    setRestrictType('unrestrict');
                    setInitiateProhibitionModalVisible(true);
                  }}
                />
              )}
            </>

            {/* FREEZE/UNFREEZE BUTTON PERMUTATIONS */}
            <>
              {/* FROZEN ACCOUNT SHOWS FREEZE BUTTON BUT DISABLED */}
              {typed_account_status === 'Account Frozen' &&
                (typed_prohibition_status === 'freeze awaiting Review' ||
                  typed_prohibition_status === 'freeze awaiting Approval') && (
                  <ZojaButton
                    text="Freeze Account"
                    extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-isRed tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                    disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                    type="button"
                    disabled={true}
                    icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                    onClick={() => {}}
                  />
                )}

              {/* FROZEN ACCOUNT SHOWS UNFREEZE BUTTON BUT DISABLED */}
              {typed_account_status === 'Account Frozen' &&
                (typed_prohibition_status === 'unfreeze awaiting Review' ||
                  typed_prohibition_status === 'unfreeze awaiting Approval') && (
                  <ZojaButton
                    text="Unfreeze Account"
                    extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#89CFF0] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                    disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                    type="button"
                    disabled={true}
                    icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                    onClick={() => {}}
                  />
                )}

              {/* FROZEN ACCOUNT SHOWS UNFREEZE BUTTON */}
              {typed_account_status === 'Account Frozen' && typed_prohibition_status === 'freeze approved' && (
                <ZojaButton
                  text="Unfreeze Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#89CFF0] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={false}
                  icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                  onClick={() => {
                    setRestrictType('unfreeze');
                    setInitiateProhibitionModalVisible(true);
                  }}
                />
              )}
            </>

            {/* RESTRICTED ACCOUNT SHOWS A FREEZE BUTTON */}
            {typed_account_status === 'Account Restricted' && (
              <ZojaButton
                text="Freeze Account"
                extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-isRed tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                type="button"
                disabled={false}
                icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                onClick={() => {
                  setRestrictType('freeze');
                  setInitiateProhibitionModalVisible(true);
                }}
              />
            )}

            {/* BANNED ACCOUNT SHOULD SHOW DISABLED RESTRICT AND FREEZE BUTTONS */}
            {typed_account_status === 'Account Banned' && (
              <>
                {/* restrict button (disabled) */}
                <ZojaButton
                  text="Restrict Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-[#FFAF56] tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={true}
                  icon={<img src={images.restrictIcon} alt="restrict Icon" />}
                  onClick={() => {}}
                />
                {/* freeze button (disabled) */}
                <ZojaButton
                  text="Freeze Account"
                  extraClass={`tw-py-2.5 tw-px-3.5 tw-font-light tw-bg-isRed tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine `}
                  disabledExtraClass="disabled:tw-bg-[#B6B6B6]"
                  type="button"
                  disabled={true}
                  icon={<img src={images.freezeIcon} alt="freeze Icon" />}
                  onClick={() => {}}
                />
              </>
            )}
          </div>
        )}
      </div>

      <H1 left lightBold>
        Account Summary
      </H1>

      <div className="tw-border  tw-border-l-0 tw-border-r-0 tw-border-b-0 tw-border-t-[#B6B6B6] tw-mt-2 tw-flex tw-justify-between">
        {searchCustomerAccountStatementStatus === 'loading' ? (
          <div className="tw-flex tw-w-full tw-h-[20vh] tw-justify-center tw-items-center">
            <Oval
              height="60"
              width="60"
              color={colors.primary}
              ariaLabel="tail-spin-loading"
              secondaryColor={colors.secondary}
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : searchCustomerAccountStatementStatus === 'succeeded' ? (
          <>
            <table className={tableStyles}>
              <thead></thead>
              <tbody>
                <tr>
                  <td className={keyStyles}>Available Balance:</td>
                  <td className={valueStyles}>
                    {available_balance || available_balance === 0 ? currencyFormat(+available_balance) : ``}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Total Inflow:</td>
                  <td className={valueStyles}>
                    {total_inflow || total_inflow === 0 ? currencyFormat(+total_inflow) : ``}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Total Outflow:</td>
                  <td className={valueStyles}>
                    {total_out_flow || total_out_flow === 0 ? currencyFormat(+total_out_flow) : ``}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Provider Balance:</td>
                  <td className={valueStyles}>{provider_balance ? currencyFormat(+provider_balance) : ``}</td>
                </tr>
              </tbody>
            </table>
            <table className={tableStyles}>
              <thead></thead>
              <tbody>
                <tr>
                  <td className={keyStyles}>Account Number:</td>
                  <td className={valueStyles}>{account_number}</td>
                </tr>
                <tr>
                  <td className={keyStyles}>Email Address:</td>
                  <td className={valueStyles}>{email}</td>
                </tr>
                <tr>
                  <td className={keyStyles}>Phone Number:</td>
                  <td className={valueStyles}>{phone_number}</td>
                </tr>
                <tr>
                  <td className={keyStyles}>User KYC Level:</td>
                  <td className={valueStyles}>{user_kyc_level ? capitalizeFirstLetter(user_kyc_level) : ``}</td>
                </tr>
              </tbody>
            </table>
            <table className={tableStyles}>
              <thead></thead>
              <tbody>
                <tr>
                  <td className={keyStyles}>Account Created on:</td>
                  <td className={valueStyles}>
                    {account_created_at ? moment(account_created_at).format(dateAndTimeFormatString) : `N/A`}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Last Ban History:</td>
                  <td className={valueStyles}>
                    {last_ban_history?.length === 0
                      ? '--'
                      : last_ban_history
                      ? moment(last_ban_history).format(dateAndTimeFormatString)
                      : `N/A`}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Account Status:</td>
                  <td
                    className={`tw-text-sm tw-pl-2 tw-font-bold ${
                      typed_account_status === 'active' ? `tw-text-isGreen` : `tw-text-isRed`
                    }`}>
                    {typed_account_status === 'active'
                      ? `Active`
                      : typed_account_status === `Account Banned`
                      ? `Banned`
                      : typed_account_status === `Account Frozen`
                      ? `Frozen`
                      : typed_account_status === `Account Restricted`
                      ? `Restricted`
                      : `null`}
                  </td>
                </tr>
                <tr>
                  <td className={keyStyles}>Prohibition Status:</td>
                  <td className={`tw-text-sm tw-pl-2 tw-font-bold tw-text-isBlack`}>
                    {typed_prohibition_status ? capitalizeFirstLetter(typed_prohibition_status) : 'Nil'}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        ) : (
          <>
            <p></p>
          </>
        )}
      </div>
    </div>
  );
}
