import React, { useState, useLayoutEffect, useEffect } from 'react';
import { Button, CustomEditor, DatePicker, Input, Picker, RadioInput, RichText } from '../../components';
import { Formik } from 'formik';
import { colors, spacing } from '../../utils';
import { ButtonContainer, MeridianInput, MiniInput2, RadioStyle, SingleMiniInput, TimeWrapper } from './style';
import { H2, H3, H4, H5 } from '../../styles';
import CustomTimeInput from '../customTimeInput';
import * as yup from 'yup';
import { routesPath, showMessage } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Dictionary } from '../../types';
import { EditorState, convertToRaw } from 'draft-js';


const { SETTINGS } = routesPath;

export interface IPropsInitialValues {
  title: string;
  message: string;
  status: string;
  image: string;
  interval: string;
  delivery_date: string;
  delivery_time: string;
  delivery_meridiem: string;
  type: string;
  recipients: string;
}

export interface IPropsRadioData {
  id: number;
  label: string;
  value: string;
}

export interface IProps {
  onSubmit: (item: Dictionary) => any;
  initialValues?: IPropsInitialValues;
  requestStatus?: string;
  radioData?: IPropsRadioData[];
  type?: string;
}

const NewNotification = ({ radioData, requestStatus, initialValues, type, onSubmit }: IProps) => {
  const [editorState, setEditorState] = useState<any>(EditorState.createEmpty());
  const [deliverTime, setDeliverTime] = useState<any>();

  const [selectedNotificationInterval, setSelectedNotificationInterval] = useState<any>();

  const [specificUserValue, setSpecificUserValue] = useState('');

  const [selectedNotificationReceipients, setSelectedNotificationReceipients] = useState('');

  const [imageValue, setImageValue] = useState('');

  const [recipentFileValue, setRecipentFileValue] = useState('');

  const [deliveryMeridiem, setDeliveryMeridiem] = useState('am');

  const [deliverDate, setDeliverDate] = useState<any>('');

  const [messageValue, setMessageValue] = useState<any>('');

  const navigate = useNavigate();

  const schema = yup.object().shape({
    title: yup.string().required('Title is required'),
    message: messageValue.length < 2 ? yup.string().required('Content is required') : yup.string(),
    // specificUser:
    //   selectedNotificationReceipients === 'specific users'
    //     ? yup.string().required('Specific user is required')
    //     : yup.string(),
  });

  useLayoutEffect(() => {
    if (initialValues?.message) {
      setMessageValue(initialValues?.message);
    }
    if (initialValues?.delivery_date) {
      setDeliverDate(initialValues?.delivery_date);
    }
    if (initialValues?.interval) {
      setSelectedNotificationInterval(initialValues?.interval);
    }
    if (initialValues?.interval) {
      setSpecificUserValue(initialValues?.recipients);
    }
  }, []);

  // HANDLE CHANGE FOR MERIDAIN
  const handleMeridanChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setDeliveryMeridiem(event.target.value);
  };

  useEffect(() => {
    let content = editorState.getCurrentContent().getPlainText()
    if (content) {
      const data = convertToRaw(editorState.getCurrentContent())
      setMessageValue(content)
    }
  }, [editorState])
  

  return (
    <div>
      <Formik
        initialValues={{
          title: initialValues?.title || '',
          message: initialValues?.message || '',
          interval: initialValues?.interval || '',
          delivery_time: initialValues?.delivery_time || '',
          delivery_date: initialValues?.delivery_date || '',
          recipients: initialValues?.title || '',
          image: '',
          type: initialValues?.type || '',
          spreadsheet: '',
          specificUser: '',
          delivery_meridiem: initialValues?.delivery_meridiem || '',
        }}
        validationSchema={schema}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          const { title, message, specificUser } = values;
          
          const payload = {
            title,
            message: messageValue,
            interval: selectedNotificationInterval,
            delivery_time: deliverTime,
            // recipients: selectedNotificationReceipients,
            recipients: specificUserValue.length > 2 ? specificUserValue : selectedNotificationReceipients,
            image: imageValue,
            spreadsheet: recipentFileValue,
            delivery_date: deliverDate,
            delivery_meridiem: deliveryMeridiem || '',
          };
          // console.log(payload)

          if (deliverDate.length < 2) {
            showMessage({
              type: 'error',
              message: 'Select a delivery date',
            });
          } else {
            onSubmit(payload);
          }

          setSubmitting(false);
        }}>
        {formikProps => {
          const { handleChange, values, handleSubmit, errors, setFieldError, touched } = formikProps;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <H2 left color={colors?.greyVariantEight} semiBold style={{ marginBottom: spacing.small }}>
                  New App Notification
                </H2>
                <Input
                  label="Notification Title"
                  backgroundColor={colors.white}
                  borderColor={colors.greyVariantTen}
                  placeholder="Enter title"
                  type="text"
                  value={values?.title}
                  name={'title'}
                  onChange={handleChange}
                  error={errors.title}
                />

                {/* <RichText
                  selectedValue={setMessageValue}
                  error={errors.message}
                  placeholderText={initialValues?.message ? initialValues?.message : 'Enter message body'}
                  label={'Notification Message'}
                  defaultValue={initialValues?.message}
                /> */}
                <CustomEditor
                  editorState={editorState}
                  setEditorState={(content) => {
                    setEditorState(content)
                    setFieldError('message', '')
                  }}
                  touched={touched.message}
                  error={errors.message}
                />

                <H3 left style={{ fontWeight: '500', marginTop: spacing.small, marginBottom: spacing.small }}>
                  Notification Settings
                </H3>
                <MiniInput2>
                  <Picker
                    error={errors.interval}
                    label="Notification Interval (Optional)"
                    selectedValue={setSelectedNotificationInterval}
                    marginBottom="0"
                    placeholder={initialValues?.interval}
                    options={[
                      { label: 'One Time', value: 'one time' },
                      { label: 'Weekly', value: 'weekly' },
                      { label: 'Monthly', value: 'monthly' },
                    ]}
                    borderColor={colors.greyVariantTen}
                  />
                  <SingleMiniInput>
                    <H5 left semiBold color={colors.grey}>
                      Select Delivery Date
                    </H5>
                    <DatePicker selectedDate={setDeliverDate} />
                  </SingleMiniInput>
                  <SingleMiniInput>
                    <H5 left color={colors.grey} semiBold>
                      Enter Delivery Time
                    </H5>
                    <TimeWrapper>
                      <CustomTimeInput setTimeValue={setDeliverTime} error={errors.delivery_time} />
                      <div>
                        <MeridianInput name="delivery_meridiem" value={deliveryMeridiem} onChange={handleMeridanChange}>
                          <option>pm</option>
                          <option>am</option>
                        </MeridianInput>
                      </div>
                    </TimeWrapper>
                  </SingleMiniInput>
                </MiniInput2>
                <RadioStyle>
                  <H3 left style={{ fontWeight: '500', marginBottom: spacing.small }}>
                    Notification Settings
                  </H3>

                  <RadioInput data={radioData} selectedValue={setSelectedNotificationReceipients} />
                  {selectedNotificationReceipients === 'specific users' && (
                    // <CustomUpload
                    //   icon={<AiFillFile color={colors.grey} />}
                    //   inputMessage={'Import File'}
                    //   backgroundColor={colors.white}
                    //   setFileValue={setRecipentFileValue}
                    // />
                    <Picker
                      error={errors.specificUser}
                      label="Select user"
                      selectedValue={setSpecificUserValue}
                      marginBottom="0"
                      placeholder={'Select users'}
                      options={[
                        { label: 'Level 0 users', value: 'level zero' },
                        { label: 'Level 1 users', value: 'level one' },
                        { label: 'Level 2 users', value: 'level two' },
                        { label: 'Level 3 users', value: 'level three' },
                        { label: 'Super agent', value: 'super agent' },
                      ]}
                      borderColor={colors.greyVariantTen}
                    />
                  )}
                </RadioStyle>
                <ButtonContainer>
                  <Button
                    type="submit"
                    text={initialValues?.title ? 'Update Item' : 'Create Item'}
                    disabled={requestStatus === 'loading' ? true : false}
                  />
                  <Button
                    onClick={() => navigate(SETTINGS)}
                    text="Cancel"
                    disabled={false}
                    secondary
                    backgroundColor="red"
                    borderColor="transparent"
                    color={colors.primary}
                    boxShadow="none"
                  />
                </ButtonContainer>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewNotification;
