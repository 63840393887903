import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../tailwind';
import { colors, images } from '../../utils';
import { Dictionary } from '../../types';
import { Oval } from 'react-loader-spinner';
import { useState } from 'react';
import { Formik } from 'formik';
import TextArea from '../textArea';

interface RejectBanRequestProps {
  show: boolean;
  reasonsForBan: any[];
  handleClose: () => void;
  handleRejectBanRequest: (item: any) => void;
  loading?: any;
  loadingReasons?: any;
  title?: string;
}

export default function RejectBanRequestModal({
  show,
  handleClose,
  handleRejectBanRequest,
  reasonsForBan,
  loading,
  loadingReasons,
  title,
}: RejectBanRequestProps) {
  const [selectedOption, setSelectedOption] = useState<any>('');

  return (
    <ZojaModal
      show={show}
      handleClose={handleClose}
      height="auto"
      position="center"
      extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12 tw-py-28 "
      borderRadius="8px">
      <div className=" tw-px-20 tw-py-5">
        <span
          className="tw-absolute tw-top-11 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
          onClick={() => {
            handleClose();
            setSelectedOption('');
          }}>
          <MdClose />
        </span>

        <div className="">
          <h2 className="tw-font-medium tw-text-lg tw-mb-8">Reject {title}</h2>
          <Formik
            initialValues={{
              reason: '',
              description: '',
            }}
            enableReinitialize={true}
            // validationSchema={schema}
            onSubmit={async (values, { setSubmitting }) => {
              const { description } = values ?? '';

              const payload = {
                description: description,
                reason: selectedOption,
              };
              handleRejectBanRequest(payload);
              setSubmitting(false);
            }}>
            {formikProps => {
              const { handleChange, values, handleSubmit, errors, touched } = formikProps;
              return (
                <form onSubmit={handleSubmit}>
                  <div className="tw-grid tw-gap-y-8">
                    <ZojaSelect
                      options={reasonsForBan}
                      setSelectedOption={setSelectedOption}
                      extraClass="!tw-w-full tw-border hover:tw-border-[#222B88] tw-border-[#C9C5CA]"
                      name="reason"
                      loading={loadingReasons}
                    />
                    <TextArea
                      name="description"
                      onChange={handleChange}
                      value={values.description}
                      borderColor={colors.greyVariantThirteen}
                      label="Description (optional)"
                      disabled={selectedOption !== 'others'}
                    />
                    <div className="tw-w-full">
                      <ZojaButton
                        type="submit"
                        text={'Reject'}
                        loading={loading}
                        disabled={selectedOption.length < 1 || loading === 'loading'}
                        loadingText="Reject"
                        extraClass="tw-bg-[#FD0000CC] tw-text-white tw-w-full tw-py-[14px]"
                        loadingClass="tw-justify-center"
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </ZojaModal>
  );
}
