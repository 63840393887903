import React, { useState } from 'react';
import FilterColapsible from '../filterColapsible';
import ZojaCheckbox from '../checkbox';
import CustomDatePicker from '../customDatePicker';
import { ZojaButton, ZojaInput } from '../tailwind';
import { handleSelectedOption, toggleItemChecked, toggleItemCheckedOneItem } from '../../utils';
import ReusableFilter from '../reusableFilter';
import { Dictionary } from '../../types';
import { setFilterOptions } from '../../redux/slice';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';

const TransactionReportFilter = ({
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  submitButtonText,
  setTransactionFilterOption,
  onClick,
  setSearch,
  searchedUsers,
}: any) => {
  const dispatch = useAppDispatch();

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  const transactionFilterOptions = useAppSelector(state => state.filterOptions);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setTransactionFilterOption({ ...transactionFilterOptions, [name]: value }));
  };

  return (
    <div className="">
      <div className="tw-h-[40vh] hide_scrollbar">
        <div className="tw-mt-4 ">
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'transactionStatus'}
            filterOptions={filterOption}
            title="Transaction Status"
            show={showOptions.transactionStatus}
            setShow={() => setShowOptions({ ...showOptions, transactionStatus: !showOptions.transactionStatus })}
            extraClass="tw-shadow-none"
            items={filterOption.transactionStatus}
          />

          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'accountInfo'}
            filterOptions={filterOption}
            title="Account info"
            show={showOptions.accountInfo}
            setShow={() => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })}
            extraClass="tw-shadow-none"
            items={filterOption.accountInfo}
            type={'inputCheck'}
            setSearch={setSearch}
            searchedUsers={searchedUsers}
            searchedUserAccountState={searchedUserAccountState}
          />
          <ReusableFilter
            setChecked={toggleItemCheckedOneItem}
            setItem={setFilterOption}
            itemName={'transactionDate'}
            filterOptions={filterOption}
            title="Transaction Date"
            show={showOptions.transactionDate}
            setShow={() => setShowOptions({ ...showOptions, transactionDate: !showOptions.transactionDate })}
            extraClass="tw-shadow-none"
            items={filterOption.transactionDate}
            type={'checkDate'}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'kycLevel'}
            filterOptions={filterOption}
            title="KYC Level"
            show={showOptions.kycLevel}
            setShow={() => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })}
            extraClass="tw-shadow-none"
            items={filterOption.kycLevel}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'transactionAmount'}
            filterOptions={filterOption}
            title="Transaction Amount"
            show={showOptions.transactionAmount}
            setShow={() => setShowOptions({ ...showOptions, transactionAmount: !showOptions.transactionAmount })}
            extraClass="tw-shadow-none"
            items={filterOption.transactionAmount}
            type="text"
            label="Amount"
            id="amount"
            inputType="number"
            name="amount"
            onHandleInputChange={handleInputChange}
            filterParams={transactionFilterOptions}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'transactionType'}
            filterOptions={filterOption}
            title="Transaction Type"
            show={showOptions.transactionType}
            setShow={() => setShowOptions({ ...showOptions, transactionType: !showOptions.transactionType })}
            extraClass="tw-shadow-none"
            items={filterOption.transactionType}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'feesRange'}
            filterOptions={filterOption}
            title="Fees Range(N)"
            show={showOptions.feesRange}
            setShow={() => setShowOptions({ ...showOptions, feesRange: !showOptions.feesRange })}
            extraClass="tw-shadow-none"
            items={filterOption.feesRange}
          />
        </div>
      </div>

      <ZojaButton
        onClick={onClick}
        text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
        extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin tw-mb-4"
      />
    </div>
  );
};

export default TransactionReportFilter;
