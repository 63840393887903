import React, { useState } from 'react';
import FilterColapsible from '../filterColapsible';
import ZojaCheckbox from '../checkbox';
import CustomDatePicker from '../customDatePicker';
import { ZojaButton, ZojaInput } from '../tailwind';
import { handleSelectedOption, toggleItemChecked, toggleItemCheckedOneItem } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { Dictionary } from '../../types';
import ReusableFilter from '../reusableFilter';

const VolumeReportFilter = ({
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  submitButtonText,
  setVolumeFilterOption,
  onClick,
  setSearch,
  searchedUsers,
}: any) => {
  const dispatch = useAppDispatch();

  const volumeFilterOptions = useAppSelector(state => state.volumeFilterOptions);

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setVolumeFilterOption({ ...volumeFilterOptions, [name]: value }));
  };

  return (
    <div>
      <div className="tw-h-[40vh] hide_scrollbar">
        <div className="tw-mt-4">
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            filterOptions={filterOption}
            title="Total Vale(N)"
            show={showOptions.totalValue}
            setShow={() => setShowOptions({ ...showOptions, totalValue: !showOptions.totalValue })}
            extraClass="tw-shadow-none"
            type="text"
            label="Total Value"
            id="total_value"
            inputType="number"
            name="total_value"
            onHandleInputChange={handleInputChange}
            filterParams={volumeFilterOptions}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            filterOptions={filterOption}
            title="Total Revenue(N)"
            show={showOptions.totalRevenue}
            setShow={() => setShowOptions({ ...showOptions, totalRevenue: !showOptions.totalRevenue })}
            extraClass="tw-shadow-none"
            type="text"
            label="Revenue"
            id="total_revenue"
            inputType="number"
            name="total_revenue"
            onHandleInputChange={handleInputChange}
            filterParams={volumeFilterOptions}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'userType'}
            filterOptions={filterOption}
            title="User Type"
            show={showOptions.userType}
            setShow={() => setShowOptions({ ...showOptions, userType: !showOptions.userType })}
            extraClass="tw-shadow-none"
            items={filterOption.userType}
          />

          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'profitMargin'}
            filterOptions={filterOption}
            title="Profit Margin"
            show={showOptions.profitMargin}
            setShow={() => setShowOptions({ ...showOptions, profitMargin: !showOptions.profitMargin })}
            extraClass="tw-shadow-none"
            items={filterOption.profitMargin}
          />

          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'paymentMethod'}
            filterOptions={filterOption}
            title="Payment Method"
            show={showOptions.paymentMethod}
            setShow={() => setShowOptions({ ...showOptions, paymentMethod: !showOptions.paymentMethod })}
            extraClass="tw-shadow-none"
            items={filterOption.paymentMethod}
          />

          <ReusableFilter
            setChecked={toggleItemCheckedOneItem}
            setItem={setFilterOption}
            itemName={'timeRange'}
            filterOptions={filterOption}
            title="Time Range"
            show={showOptions.timeRange}
            setShow={() => setShowOptions({ ...showOptions, timeRange: !showOptions.timeRange })}
            extraClass="tw-shadow-none"
            items={filterOption.timeRange}
            type={'checkDate'}
          />

          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            filterOptions={filterOption}
            title="Transaction Count"
            show={showOptions.transactionCount}
            setShow={() => setShowOptions({ ...showOptions, transactionCount: !showOptions.transactionCount })}
            extraClass="tw-shadow-none"
            type="text"
            label="Count"
            id="count"
            inputType="number"
            name="count"
            onHandleInputChange={handleInputChange}
            filterParams={volumeFilterOptions}
          />
        </div>
      </div>
      <ZojaButton
        onClick={onClick}
        text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
        extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin tw-mb-4"
      />
    </div>
  );
};

export default VolumeReportFilter;
