interface IProps {
  title: string;
  icon?: React.ReactElement;
  children?: React.ReactElement;
  labelExtraclass?: string;
  titleClass?: string;
  labelStyle?: any;
  onClick: () => void;
}

const FilterLabelVariant2 = ({ title, icon, labelExtraclass, labelStyle, onClick, children }: IProps) => {
  return (
    <div
      className={`${labelExtraclass} tw-relative tw-flex tw-items-center tw-justify-center tw-gap-x-2 tw-bg-white tw-shadow-zojaShadowFive tw-p-2.5 tw-px-3 tw-rounded-sm tw-cursor-pointer`}
      onClick={onClick}
      style={labelStyle}>
      <span className="tw-hidden tw-text-[#222B88] tw-text-sm tw-font-[300] md:tw-block">{title}</span>
      <span className="tw-text-[#101828] tw-text-2xl tw-font-semibold">{icon}</span>
      {children}
    </div>
  );
};

export default FilterLabelVariant2;
