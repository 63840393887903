import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Dictionary } from "../../types";
import api from "../../api/api";

interface InitState {
    data: Dictionary;
    status: "idle" | "loading" | "succeeded" | "failed";
    error: any;
}
const initialState: InitState = {
    data: {},
    status: 'idle',
    error: null
}

const dashboardTransactionTrendSlice = createSlice({
    name: 'dashboard_transaction_trend',
    initialState,
    reducers: {
        reset: (state) => {
            Object.assign(state, initialState);
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getDashboardTransactionTrend.pending, (state) => {
            state.status = "loading";
        });
        builder.addCase(getDashboardTransactionTrend.fulfilled, (state, action) => {
            state.status = "succeeded";
            state.data = action.payload;
        });
        builder.addCase(getDashboardTransactionTrend.rejected, (state, action) => {
            state.status = "failed";
            state.error = action.payload;
        });
    },
})


export const getDashboardTransactionTrend = createAsyncThunk(
    'getDashboardTransactionTrend',
    async (payload: Dictionary, { dispatch }) => {
        const { year } = payload;
        const url = `admin/dashboard/transaction-trends?year=${year}`;

        try {
            const response = await api.get(`${url}`);
            return response?.data;
        } catch (err) {
            throw err;
        }
    },
);

export const dashboardTransactionTrendReset = dashboardTransactionTrendSlice.actions.reset
export const dashboardTransactionTrendSliceReducer = dashboardTransactionTrendSlice.reducer