import { useNavigate } from 'react-router-dom';
import { AppContainer } from '../../atoms';
import {
  formatStartAndEndDateWithDelimeter,
  getEverySelectedOptionValue,
  getSelectedOptionValue,
  replaceCommasInQueryParam,
  routesPath,
} from '../../utils';

import { volumeReportColumn } from './dummyData';
import { Dictionary } from '../../types';
import { useEffect, useRef, useState } from 'react';

import VolumeGeneratedReport from '../../atoms/generatedReports/volumeGeneratedReport';
import {
  dateOptions,
  figureRangeOptions,
  kycLevelOptions,
  locationOptions,
  manageColumnOptions,
  marginOptions,
  methodOptions,
  sortByOtionsVolUser,
  timeRangeOptions,
  transactionStatusOptions,
  transactionTypeOptions,
  userTypeOptions,
} from './filterOptions';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getAllVolumeReportRequest,
  resetVolumeFilterOptions,
  saveReportRequest,
  saveReportReset,
  setVolumeFilterOptions,
} from '../../redux/slice';
import { DownloadSuccessModal } from '../../components';
import SaveReportModal from '../../components/modalTypes/SaveReportModal';
import { downloadVolumeReportRequest, downloadVolumeReportReset } from '../../redux/slice/downloadVolumeReportSlice';
const { REPORT } = routesPath;

export interface TransactionReportColumn {
  id: number;
  title: string;
  ref: string;
}

export interface VolumeReportRow {
  id: number;
  sn: number;
  paymentMethod: string;
  paymentDate: string;
  transactionCount: string;
  totalValue: number;
  totalRevenue: number;
  profitMargin: string;
}

const VolumeReport = () => {
  // REFS
  const successModalRef = useRef<any>(null);
  const saveReportModalRef = useRef<any>(null);

  // REDUX STATES
  const allVolumeReportState = useAppSelector(state => state.getAllVolumeReport);

  // const { status: getAllVolumeReportStatus } = allVolumeReportState;
  const { status: getAllVolumeReportStatus, data: getAllVolumeReportData } = allVolumeReportState;
  const volumeFilterOptions = useAppSelector(state => state.volumeFilterOptions);
  const { status: downloadVolumeReportStatus } = useAppSelector(state => state.downloadVolumeReport);

  const { status: saveReportStatus } = useAppSelector(state => state.saveReportSlice);

  // ROUTER HOOKS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //   REACT STATES
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(allVolumeReportState?.data?.meta?.current_page || 1);
  const [perPage, setPerPage] = useState();
  const [volumeReportData, setVolumeReportData] = useState<VolumeReportRow[]>([]);
  // const [search, setSearch] = useState<any>();
  const [visibleColumns, setVisibleColumns] = useState(volumeReportColumn.map(column => column.id));
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [showSuccessSavedModal, setShowSuccessSavedModal] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportQueries, setReportQueries] = useState('');
  const [selectedPageOption, setSelectedPageOption] = useState<'10' | '20' | '30'>();

  useEffect(() => {
    const urlLink = getAllVolumeReportData?.links?.last;

    // Find the index of the first "?" and the last "&"
    const firstQuestionMarkIndex = urlLink?.indexOf('?');
    const lastAmpersandIndex = urlLink?.lastIndexOf('&');

    // Extract the substring between the first "?" and the last "&"
    const extractedParams =
      firstQuestionMarkIndex !== -1 ? urlLink?.substring(firstQuestionMarkIndex + 1, lastAmpersandIndex) : '';

    if (extractedParams?.startsWith('https://')) {
      // Set expectedParams to an empty string if it starts with "https://"
      setReportQueries('');
    } else {
      setReportQueries(replaceCommasInQueryParam(extractedParams));
    }
  }, [downloadVolumeReportStatus, getAllVolumeReportData?.links?.last, reportQueries]);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    transactionCount: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
    transactionDate: false,
    transactionAmount: false,
    transactionType: false,
    transactionStatus: false,
    feesRange: false,
    revenue: false,
    timeRange: false,
    paymentMethod: false,
    profitMargin: false,
    userType: false,
    totalRevenue: false,
    totalAmount: false,
  });

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState({
    sortBy: sortByOtionsVolUser.aphabetically,
    orderBy: sortByOtionsVolUser.orderBy,
    transactionDate: dateOptions,
    dateOnboarded: dateOptions,
    lastLogin: dateOptions,
    transactionType: transactionTypeOptions,
    transactionStatus: transactionStatusOptions,
    location: locationOptions,
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    timeRange: timeRangeOptions,
    reportFilterType: {
      title: 'Filter Type',
      id: 1,
      value: 'initial',
    },
    manageColumn: manageColumnOptions.volume,
    visibleColumns: volumeReportColumn.map(column => column.id),
    revenue: figureRangeOptions,
    profitMargin: marginOptions,
    paymentMethod: methodOptions,
    userType: userTypeOptions,
  });

  // CHECKS IF THE DATE SELECTED IS CUSTOM
  const isCustomInDateOptions = (dateOptions: any[]) => {
    return dateOptions?.some(item => item.value === 'custom' && item.isChecked);
  };
  const isCustomInTimeRange = isCustomInDateOptions(filterOption.timeRange);

  const initialValues: Dictionary = {
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [filterOptions, setFilterOptions] = useState(initialValues);

  const handleSubmitFilter = () => {
    dispatch(getAllVolumeReportRequest(volumeFilterOptions));
  };

  // close filter on state change
  useEffect(() => {
    setShowOptions({ ...showOptions, sortBy: false });
  }, [getAllVolumeReportStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    dispatch(downloadVolumeReportRequest({ ...volumeFilterOptions, exp: type, per_page: selectedPageOption || 10 }));
    setShowOptions({ ...showOptions, exportOptions: false });
  };
  //SHOWS DOWNLOAD SUCCESS MODAL
  useEffect(() => {
    if (downloadVolumeReportStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadVolumeReportStatus]);

  //SHOWS SAVED SUCCESS MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSuccessSavedModal(true);
    }
  }, [saveReportStatus]);

  //SHOWS SAVE REPORT MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSaveReportModal(false);
    }
  }, [saveReportStatus]);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setVolumeFilterOptions({ ...volumeFilterOptions, [name]: value }));
  };

  // THIS EFFECT UPDATES THE FILTER OPTIONS FOR USERS
  useEffect(() => {
    dispatch(
      setVolumeFilterOptions({
        ...volumeFilterOptions,
        time_range: isCustomInTimeRange
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.timeRange),
        payment_method: getEverySelectedOptionValue(filterOption.paymentMethod),
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
        per_page: selectedPageOption,
        page: currentPage,
      }),
    );
  }, [dispatch, filterOption, selectedPageOption, currentPage]);

  // THIS EFFECT CALLS GET USER ENDPOINT WHEN selectedPageOption CHANGES
  useEffect(() => {
    if (selectedPageOption) {
      dispatch(getAllVolumeReportRequest({ volumeFilterOptions, per_page: selectedPageOption }));
    }
  }, [selectedPageOption]);

  useEffect(() => {
    return () => {
      dispatch(resetVolumeFilterOptions()); // Reset the filter options when the component is unmounted
    };
  }, [dispatch]);

  useEffect(() => {
    if (currentPage) {
      dispatch(getAllVolumeReportRequest({ ...volumeFilterOptions, page: currentPage }));
    }
  }, [currentPage]);

  useEffect(() => {
    dispatch(getAllVolumeReportRequest(volumeFilterOptions));
  }, [dispatch, filterOptions]);

  // THIS EFFECT UPDATES THE USER REPORT DATA
  useEffect(() => {
    if (getAllVolumeReportStatus === 'succeeded') {
      let updatedList: VolumeReportRow[] = [];

      allVolumeReportState?.data?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          sn: index + 1,
          paymentDate: item?.date || '-',
          paymentMethod: item?.payment_method || '-',
          transactionCount: item?.transaction_count || '-',
          totalValue: item?.total_value || '-',
          totalRevenue: item?.total_revenue || '-',
          profitMargin: item?.profit_margin || '-',
        });
      });

      const {
        meta: { per_page, total },
      } = allVolumeReportState?.data;
      setTotalItems(total);
      setPerPage(per_page);

      setVolumeReportData(updatedList);
      // setUserReportDataList(updatedList);
    }
  }, [getAllVolumeReportStatus, allVolumeReportState]);

  // CALLS API WHEN THE APPLY FILTER BUTTON IS CLICKED
  const handleApplyFilter = () => {
    dispatch(getAllVolumeReportRequest(volumeFilterOptions));
  };

  const handleShowSaveReport = () => {
    setShowSaveReportModal(true);
    setShowSuccessDownloadModal(false);
  };

  // input changer handler for saved report
  const handleSavedReportInputChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setReportName(value);
  };

  // SAVES REPORT WHEN BUTTON IS CLICKED
  const handleReportSave = () => {
    const payload = { query: reportQueries, name: reportName, type: 'volume' };
    dispatch(saveReportRequest(payload));
  };

  return (
    <AppContainer
      navTitle="Reports"
      goBack={() => navigate(REPORT)}
      navHelper={
        <>
          <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">Reports</h4>
        </>
      }>
      <div>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            setShowSuccessDownloadModal(false);
            dispatch(downloadVolumeReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
          handleShowSaveReport={() => {
            handleShowSaveReport();
            dispatch(downloadVolumeReportReset());
          }}
          type="saveDownloadModal"
        />
        <DownloadSuccessModal
          show={showSuccessSavedModal}
          handleClose={() => {
            setShowSuccessSavedModal(false);
            dispatch(saveReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Report Saved"
          content="Your report has been saved successfully"
        />
        <SaveReportModal
          show={showSaveReportModal}
          handleClose={() => setShowSaveReportModal(false)}
          closeOnClickOutside={false}
          contentRef={saveReportModalRef}
          onHandleChange={handleSavedReportInputChange}
          title="Save your Report"
          value={reportName}
          onSave={handleReportSave}
          saveReportStatus={saveReportStatus}
          content="Give your report a name and see it on your report homepage to reprint anytime, and would not be automatically deleted with rest of your history"
        />
        <h1 className="tw-text-gray-800 tw-text-2xl tw-font-medium tw-my-7">Volume Report</h1>

        <div className="">
          <VolumeGeneratedReport
            onSubmitFilter={() => handleSubmitFilter()}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            column={volumeReportColumn}
            onExport={handleExportFile}
            tableData={volumeReportData}
            manageColumnOptions={manageColumnOptions}
            filterOption={filterOption}
            setFilterOption={setFilterOption}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            showManageColumn={showManageColumn}
            setShowManageColumn={setShowManageColumn}
            onApplyFilter={() => handleApplyFilter()}
            // setSearch={setSearch}
            handleInputChange={handleInputChange}
            setSelectedPageOption={setSelectedPageOption}
            itemsPerPage={perPage}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            volumeState={allVolumeReportState}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            downloadStatus={downloadVolumeReportStatus}
            volumeFilterOptions={volumeFilterOptions}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default VolumeReport;
