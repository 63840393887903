import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllUserAccessReportRequest = createAsyncThunk(
  'getAllUserAccessReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      transaction_date,
      activity_type,
      location,
      device_type,
      sort_by,
      order_by,
      exp,
      per_page,
      page,
      start_date,
      end_date,
      userId,
    } = payload;
    const url = `admin/report/general/user-access-log`;

    try {
      const response = await api.get(`${url}`, {
        params: {
          export: exp,
          per_page,
          'date[start_date]': start_date,
          'date[end_date]': end_date,
          page,
          date: transaction_date,
          telephone: userId,
          activity_type,
          location,
          device_type,
          sort_by,
          order_by,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllUserAccessReportSlice = createSlice({
  name: 'getAllUserAccessReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllUserAccessReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllUserAccessReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllUserAccessReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllUserAccessReportReset = getAllUserAccessReportSlice.actions.reset;
export const getAllUserAccessReportSliceReducer = getAllUserAccessReportSlice.reducer;
