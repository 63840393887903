import { cn } from "../../lib/utils"

interface RolePermissionsProps {
    module_permissions: Array<any>,
    permissionClass?: string
}

export default function RolePermissions({module_permissions, permissionClass}: RolePermissionsProps) {
    return (
        <div className='tw-bg-white tw-shadow-zojaShadowTwo tw-p-6'>
            <h3 className="tw-text-base tw-font-medium ">Permissions</h3>
            <p className='tw-text-xs tw-text-[#5E6366]'>See below the permissions attached to visible modules selected above</p>
            <div className={cn(
                "tw-py-3 tw-px-5 tw-rounded",
                permissionClass
            )}>
                {
                    module_permissions?.map((d: any) => {
                        return (
                            <div key={d.id} className='tw-mb-4 tw-border tw-border-isPrimary/10 tw-rounded-md tw-p-3'>
                                <h4 className="text-[#263238] tw-text-sm tw-font-normal tw-mb-3">
                                    {d.title}
                                </h4>
                                <div className="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-3">
                                    {
                                        d?.permissions?.map((d: any) => (
                                            <div className="tw-flex tw-items-center tw-gap-4 tw-gap-x-3" key={d.id}>
                                                <p className="tw-m-0 tw-text-[#758089] tw-text-[14px]">{d.display_name}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}
