import { ReactElement } from 'react';
import { Dictionary } from '../../../types';
import { TBODY, TH, THEAD, TR } from './tableElements';

interface TableProps {
  column: Dictionary[];
  data?: Dictionary[];
  children: ReactElement | ReactElement[];
  hasActionButton?: boolean;
  order?: string;
  sorting?: (col: string) => void;
  thClass?: string;
  ref?: any;
  setIsSort?: any;
  setSortHeader?: any;
  setOrder?: any;
  setIsNextPage?: any;
  tableHeight?: string;
}

const Table = ({
  column,
  data,
  children,
  hasActionButton = false,
  thClass,
  ref,
  sorting,
  order,
  setIsSort,
  setSortHeader,
  setOrder,
  setIsNextPage,
  tableHeight = `[60vh]`,
}: TableProps) => {
  return (
    <div className={`tw-relative tw-overflow-x-auto tw-h-${tableHeight}`}>
      <table
        className="tw-table-auto tw-w-full tw-text-[14px] tw-text-left tw-text-isPrimary tw-border-separate tw-border-spacing-y-1.5"
        ref={ref}>
        <THEAD>
          <TR extraClass="tw-bg-isPrimary">
            <>
              {column?.map(column => (
                <TH
                  key={column.id}
                  value={column.title}
                  headerValue={column.value}
                  hasSortIcon={column.hasSortIcon}
                  sorting={sorting}
                  order={order}
                  extraClass={`${thClass}`}
                  setIsSort={setIsSort}
                  setSortHeader={setSortHeader}
                  setOrder={setOrder}
                  setIsNextPage={setIsNextPage}
                />
              ))}
              {hasActionButton && (
                <TH
                  sorting={sorting}
                  setIsSort={setIsSort}
                  setSortHeader={setSortHeader}
                  setOrder={setOrder}
                  setIsNextPage={setIsNextPage}
                />
              )}
            </>
          </TR>
        </THEAD>
        <TBODY>{children}</TBODY>
      </table>
    </div>
  );
};

export default Table;
