import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import { GrantPermissions, Modal } from '../../components';
import { ZojaButton, ZojaInput, ZojaSelect } from '../../components/tailwind';
import { cn } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getDepartmentsDropDownRequest, getUserDetailsRequest } from '../../redux/slice';
import { Dictionary } from '../../types';

interface RoleOptionsIProps {
  value: string;
  label: string;
}

interface DefaultValuesIProps {
  defaultEmail: string;
  defaultName: string;
  defaultRole: string;
  defaultPermissions?: any[];
  defaultId?: string;
}

export interface IProps {
  isModalVisible: boolean;
  editing: boolean;
  closeModal: () => void;
  title: string;
  isLoading: boolean;
  onSubmit: (item: Dictionary) => any;
  isSubmittingInternalUser: boolean;
  isSuccessInternaluser: any;
  roleOption: RoleOptionsIProps[];
  rolesAndPermissions?: Dictionary[];
  actionBtnText: string;
  defaultValues: DefaultValuesIProps;
  permissionDefault?: any;
  setOpenPermissions?: any;
  setUserRole?: any;
  setPermissions?: any;
  setCurrentUserData?: any;
  openPermissions?: boolean;
  permissions?: any;
}

function CreateInternalUserModal({
  isModalVisible,
  editing,
  closeModal,
  title,
  isLoading,
  onSubmit,
  isSubmittingInternalUser,
  roleOption,
  actionBtnText,
  defaultValues,
  setOpenPermissions,
  openPermissions,
  setUserRole,
  permissions,
  isSuccessInternaluser,
}: IProps) {

  const { defaultEmail, defaultName, defaultRole } = defaultValues;
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedRole, setSelectedRole] = useState(defaultRole);
  const [currentPermissions, setCurrentPermissions] = useState<any>({});
  const [selectedPermissions, setSelectedPermissions] = useState<any>({});
  const [availablePermissions, setAvailablePermissions] = useState<any>([]);
  const [attachedPermissions, setAttachedPermissions] = useState<any>([]);

  const getDepartmentsDropDownState = useAppSelector((state) => state.getDepartmentsDropDown)
  const { departments } = getDepartmentsDropDownState?.data

  const getUserDetailsState = useAppSelector((state) => state.getUserDetails)
  const { data: userDetails } = getUserDetailsState?.data

  const dispatch = useAppDispatch()

  const schema = yup.object().shape({
    email: yup.string().email().required('Email is required'),
    role: selectedRole.length < 2 ? yup.string().required('Role is required') : yup.string(),
  });


  useEffect(() => {
    dispatch(
      getDepartmentsDropDownRequest({}),
    )
  }, [])

  useEffect(() => {
    setUserRole(selectedRole);
    setAttachedPermissions([])
  }, [selectedRole, setUserRole]);

  useEffect(() => {
    if (selectedRole && permissions) {
      const filteredPermissionsData = permissions?.filter(
        (f: any) => f.label.toLowerCase() === selectedRole.toLowerCase(),
      )[0]?.permissions_data;

      let checkTiles = filteredPermissionsData
      let modules = checkTiles?.map((item: any) => {
        return item.permissions.map((d: any) => d.name)
      })
      modules = modules?.reduce((acc: any, curr: any) => [...acc, ...curr], []);
      setAvailablePermissions(modules)

      setCurrentPermissions(filteredPermissionsData)
      setOpenPermissions(true)
    }
  }, [selectedRole, setUserRole, permissions])


  useEffect(() => {
    if (getUserDetailsState?.status === "succeeded") {
      const attacted_permissions = getUserDetailsState?.data?.user?.permissions?.map((d: any) => d.name)
      setAttachedPermissions(attacted_permissions)
      setSelectedDepartment(getUserDetailsState?.data?.user?.department?.id)
    }
  }, [getUserDetailsState?.status, selectedRole])

  useEffect(() => {
    if (editing) {
      dispatch(getUserDetailsRequest({ userId: defaultValues?.defaultId }))
      setSelectedRole(defaultRole);
    }
  }, [editing]);


  return (
    <Modal title={title} isModalVisible={isModalVisible} closeModal={closeModal}>
      {isLoading ? (
        <div className="tw-h-[50vh] tw-w-[20rem] sm:tw-w-[35rem] tw-mx-auto tw-flex tw-justify-center tw-items-center">
          <Oval
            height="60"
            width="60"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <section className='tw-w-[20rem] sm:tw-w-[35rem] tw-p-4'>
          <>
            <Formik
              initialValues={{
                email: defaultEmail,
                name: defaultName,
                role: defaultRole,
              }}
              enableReinitialize={true}
              validationSchema={schema}
              onSubmit={async (values, { setSubmitting }) => {
                const { email, role, name } = values ?? '';

                const payload = {
                  email,
                  name,
                  department_id: selectedDepartment,
                  role: selectedRole.length > 2 ? selectedRole : role,
                  permissions: attachedPermissions,
                };
                onSubmit(payload);
                setSubmitting(false);
                isSuccessInternaluser === true && setOpenPermissions(false);
              }}>
              {formikProps => {
                const { handleChange, values, handleSubmit, errors } = formikProps;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={cn(
                      "tw-flex tw-gap-4",
                      !editing && 'tw-hidden'
                    )}>
                      <div className='tw-mb-4 tw-w-full'>
                        <ZojaInput
                          label="Fullname"
                          labelExtraClass='!tw-text-[.9rem]'
                          type="text"
                          name="name"
                          placeholder="Full Name"
                          value={values.name}
                          onChange={handleChange}
                          error={errors.name}
                        />
                      </div>

                    </div>
                    <div>
                      <div className='tw-mb-4'>
                        <ZojaInput
                          label="Email"
                          labelExtraClass='!tw-text-[.9rem]'
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          error={errors.email}
                        />
                      </div>

                      <div className='tw-mb-4'>
                        <ZojaSelect
                          label="Department"
                          options={departments?.map((d: Dictionary) => {
                            return {
                              label: d.name,
                              value: d.id,
                            }
                          })}
                          setSelectedOption={selected => {
                            setSelectedDepartment(selected);
                          }}
                          value={departments?.filter((f:any) => f.id === selectedDepartment)?.[0]?.id}
                        />
                      </div>

                      <ZojaSelect
                        label="Role"
                        options={roleOption}
                        setSelectedOption={selected => {
                          setSelectedRole(selected);
                          setOpenPermissions(true);
                        }}
                        value={selectedRole}
                      />
                      <div className='tw-mt-6'>
                        {openPermissions && selectedRole && (
                          <div>
                            <GrantPermissions
                              selected={selectedPermissions}
                              setSelected={setSelectedPermissions}
                              modules_permissions={currentPermissions}
                              setAttachedPermissions={setAttachedPermissions}
                              attachedPermissions={attachedPermissions}
                            />
                          </div>
                        )}
                      </div>

                      <div className="tw-flex tw-justify-center tw-mt-5 tw-gap-x-6">

                        <ZojaButton
                          extraClass={`tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
                          text={actionBtnText}
                          disabled={isSubmittingInternalUser}
                          loading={isSubmittingInternalUser}
                          loadingText="Submitting"
                        />
                        <ZojaButton
                          extraClass={`tw-bg-transparent tw-font-thin tw-text-isPrimary tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
                          text={'Cancel'}
                          disabled={false}
                          onClick={closeModal}
                        />
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </>
        </section>
      )}
    </Modal>
  );
}

export default CreateInternalUserModal;
