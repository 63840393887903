import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadPaymentListTemplateRequest = createAsyncThunk(
  'downloadPaymentListTemplate',
  async (_, { dispatch }) => {
    const url = `/admin/batch/download-template`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `payment_template.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadPaymentListTemplateRequestSlice = createSlice({
  name: 'downloadPaymentListTemplate',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadPaymentListTemplateRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadPaymentListTemplateRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadPaymentListTemplateRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadPaymentListTemplateRequestReset = downloadPaymentListTemplateRequestSlice.actions.reset;
export const downloadPaymentListTemplateRequestSliceReducer = downloadPaymentListTemplateRequestSlice.reducer;
