import { ZojaButton, ZojaModal } from '../../components/tailwind';
import { icons, images } from '../../utils';


interface ConfirmPaymentProps {
    title?: string,
    subTitle?: string,
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
}

export default function ZojaConfirmPaymentSuccess({ title, subTitle, show, handleClose }: ConfirmPaymentProps) {

    return (
        <>
             <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius=".5rem"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    {/* <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Success!</h3> */}
                    <img src={images.successIconGif} alt="" />
                    <h3 className='tw-text-[1.2rem] tw-text-center tw-text-[#263238]'>{title}</h3>
                    <span className="tw-block tw-text-center tw-text-[.7rem]">{subTitle}</span>

                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <div className='my-3 mt-4'>
                        <ZojaButton
                            onClick={handleClose}
                            text="Close"
                            // loading
                            // disabled
                            extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90 disabled:tw-bg-blue-900'
                        />
                    </div>
                </div>
            </ZojaModal>
        </>
    )
}
