import { cn } from '../../lib/utils';
import { toggleItemChecked } from '../../utils';
import ZojaCheckbox from '../checkbox';

interface AllowedModulesProps {
  selected: any;
  setSelected: any;
  available_modules: any[];
  className?: string;
  itemName?: string;
  error?: string;
  showCheckbox?: boolean;
}

const AllowedModules = ({
  selected,
  setSelected,
  available_modules,
  className,
  itemName,
  showCheckbox = true,
  error,
}: AllowedModulesProps) => {
  return (
    <div>
      <div className={cn(
        'tw-py-3 tw-px-5 tw-rounded',
        className
      )}>
        <div className="tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-6">
          {available_modules?.map((item: any) => (
            <div className="tw-flex tw-items-center tw-gap-x-3" key={item.id}>
             {showCheckbox && <ZojaCheckbox
                isChecked={item.isChecked}
                className={cn(
                  "tw-mb-3",
                  item.name === 'Dashboard' && 'tw-bg-gray-300'
                )}
                onClick={() => item.name !== 'Dashboard' && toggleItemChecked(item.id, selected, itemName, setSelected)}
              />}
              <p className="tw-m-0 tw-text-[#758089]">{item.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllowedModules;
