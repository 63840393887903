import { TableTag, TD, TH, TR, TableContainer, LoginTableContainer } from './style';
import { Dictionary } from '../../../types';

export interface LoginHistoryIProps {
  id: number;
  device: string;
  time: string;
  location?: string;
  authMode?: string;
  loginOutcome?: string;
  ipAddress?: string;
  staffName?: string;
  email?: string;
  machineName?: string;
  logoutTime?: string;
}

export interface TableIPropsIProps {
  type?: string;
  data?: LoginHistoryIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  headerData?: Dictionary;
}

function LoginHistoryTable({ data, setSelectedItem, headerData, type }: TableIPropsIProps) {
  return (
    <LoginTableContainer>
      <TableTag>
        <thead>
          <tr>
            <TH>{headerData?.time}</TH>
            <TH>{headerData?.logoutTime}</TH>
            <TH>{headerData?.device || headerData?.staffName}</TH>
            <TH>{headerData?.location || headerData?.email}</TH>
            <TH>{headerData?.authMode}</TH>
            <TH>{headerData?.loginOutcome}</TH>
          </tr>
        </thead>

        <tbody>
          {data?.map((item: LoginHistoryIProps) => (
            <TR key={item?.id}>
              <TD>{item?.time}</TD>
              <TD>{item?.logoutTime}</TD>
              <TD>{item?.device || item?.staffName}</TD>
              <TD>{item?.location || item?.staffName}</TD>
              <TD>{item?.authMode || 'N/A'}</TD>
              <TD style={{ color: item?.loginOutcome === 'success' ? '#4dab4dde' : '#fd0000' }}>
                {item?.loginOutcome}
              </TD>
            </TR>
          ))}
        </tbody>
      </TableTag>
    </LoginTableContainer>
  );
}

export default LoginHistoryTable;
