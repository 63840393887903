import { useRef, useState } from "react";
import { images } from "../../utils";
import { ZojaButton, ZojaInput } from "../tailwind";
import { toast } from "react-toastify";
import { cn } from "../../lib/utils";

interface CustomFileUploaderProps {
    selectedFile?: File,
    setSelectedFile?: (file: File) => void,
    handleDragOver?: (event: any) => void
    handleDrop?: (event: any) => void
    error?: string,
    handleUpload?: (event: any) => void,
    loading?: boolean
}

export default function CustomFileUploader({ selectedFile, setSelectedFile, handleDragOver, handleDrop, error, handleUpload, loading }: CustomFileUploaderProps) {
    const handleSelectedFile = (event: any) => {
        let file = event.target.files[0]
        setSelectedFile && setSelectedFile(file)
    }
    const handleDropFile = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event?.dataTransfer?.files?.length > 1) {
            toast.error('single file selection is allowed')
            return
        }
        const droppedFiles = event.dataTransfer.files[0];
        handleDrop && handleDrop(droppedFiles)
    }

    return (
        <div className="tw-h-[20rem] tw-p-4 tw-mt-6 tw-rounded-md tw-bg-white tw-shadow-sm tw-flex tw-justify-center tw-items-center"
        onDrop={handleDrop && handleDropFile}
        onDragOver={(e) => e.preventDefault()}
        >
            <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
               {selectedFile && <div className="tw-mb-4 tw-flex tw-items-start tw-gap-1">
                    <img src={images.fileExcelClose} width={50} alt="icon" />
                    <div className="tw-flex tw-flex-col tw-gap-1 tw-mt-4">
                        <span className="tw-block tw-font-medium">{selectedFile.name}</span>
                        {/* <span className="tw-text-xs tw-text-green-700">4.5mb</span> */}
                    </div>
                </div>}
                <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
                    {!selectedFile && <img src={images.fileExel} width={50} alt="icon" />}
                    {error && <span className="tw-mt-3 tw-text-red-500 lg:tw-text-sm">{error}</span>}
                    {
                        !selectedFile &&
                        <span className="tw-block tw-py-3 tw-text-center lg:tw-w-1/2">Drag and drop your file here to upload .xlsx, .xls, .csv are supported. Max size 5mb</span>
                    }
                    <div className="tw-flex tw-items-center tw-gap-1">
                        <span className="tw-block tw-w-20 tw-h-[.05rem] tw-bg-gray-200"></span>
                        <span className="tw-block">Or</span>
                        <span className="tw-block tw-w-20 tw-h-[.05rem] tw-bg-gray-200"></span>
                    </div>
                </div>
                <div className="tw-mt-4 tw-flex tw-items-center tw-gap-4">
                    <label htmlFor="fileUpload"
                        className="tw-p-[.5rem] tw-cursor-pointer tw-px-8 tw-text-[.7rem] tw-font-thin tw-bg-isPrimary tw-text-white tw-rounded-full"
                    >Browse Files
                        {/* <ZojaButton
                            text="Browse Files"
                            extraClass="tw-p-[.3rem] tw-px-8 tw-text-[.7rem] tw-font-thin tw-bg-isPrimary tw-text-white tw-rounded-full"
                        /> */}
                        <input
                            className="tw-hidden"
                            type="file"
                            id="fileUpload"
                            onChange={handleSelectedFile}
                            disabled={loading}
                        />
                    </label>
                    <ZojaButton
                        text="Upload File"
                        extraClass={cn(
                            "tw-p-[.5rem] tw-px-8 tw-text-[.7rem] tw-font-thin tw-bg-gray-300 tw-text-gray-600 tw-rounded-full disabled:!tw-bg-gray-200",
                            selectedFile && "tw-bg-isPrimary tw-text-white",
                            loading && "disabled:tw-bg-isPrimary/80"
                        )}
                        loading={loading}
                        loadingText="Upload File"
                        onClick={handleUpload}
                        disabled={!selectedFile || error !== '' || loading}
                    />
                </div>
            </div>
        </div>
    )
}
