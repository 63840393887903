interface IProps {
    title: string,
    text: string,
    titleExtraclass?: string,
    textExtraclass?: string,
}

const InfoLabel = ({title, text, titleExtraclass, textExtraclass}: IProps) => {
    return (
        <div className='tw-flex tw-flex-col tw-items-start'>
            <span className={`${titleExtraclass} tw-text-xs tw-text-[#162082] md:tw-text-sm`}>{title}</span>
            <span className={`${textExtraclass} tw-text-xs tw-text-[#5E6366] md:tw-text-sm`}>{text}</span>
        </div>
    )
}

export default InfoLabel