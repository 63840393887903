import { H1 } from '../../styles';
import {
  AccountManagementTransactionsTable,
  ExportLabel,
  FilterCard,
  Pagination,
  FilterLabel,
  FilterColapsible,
  ZojaCheckbox,
  CustomDatePicker,
} from '../../components';
import { ZojaSelect } from '../../components/tailwind';
import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getCustomerTransactions, downloadCustomerTransactionsRequest } from '../../redux/slice';
import { Oval } from 'react-loader-spinner';
import { accountManagementTransactionsTableHeaderUpdate } from '../../pages/account/data';
import { Dictionary } from '../../types';
import {
  useSortTable,
  icons,
  handleSelectedOption,
  getSelectedOptionValue,
  formatStartAndEndDateWithDelimeter,
} from '../../utils';

const transactionDateOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

interface AccountManagementTransactionsProps {
  account_number: string;
}

export default function AccountManagementTransactions({ account_number }: AccountManagementTransactionsProps) {
  const dispatch = useAppDispatch();

  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showTransactionDate, setShowTransactionDate] = useState(false);
  const [transactionDate, setTransactionDate] = useState(transactionDateOptions);
  const [transactionDateRange, setTransactionDateRange] = useState({ start_date: '', end_date: '' });

  const [totalPages, setTotalPages] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [filterOptions, setFilterOptions] = useState({
    account_id_phone_number: account_number,
    per_page: perPage,
    page: currentPage,
    date_range: '',
  });

  const customerTransactions = useAppSelector(state => state.customerTransactions);
  const [transactionsData, setTransactionsData] = useState<any[]>(customerTransactions.data?.transactions?.data);
  const { status: downloadCustomerTransactionsStatus } = useAppSelector(state => state.downloadCustomerTransactions);

  const [order, setOrder] = useState('ASC');

  const { handleSortItems } = useSortTable({
    order: order,
    setOrder: setOrder,
    data: transactionsData,
    setData: setTransactionsData,
  });

  const handleExportFile = (type: string) => {
    dispatch(downloadCustomerTransactionsRequest({ ...filterOptions, export: type, account_number: account_number }));
    setShowExportOptions(false);
  };

  const handlePageSizeChange = (newPageSize: any) => {
    setPerPage(newPageSize);
    setCurrentPage(1);
    setFilterOptions({ ...filterOptions, per_page: newPageSize, page: 1 });

    dispatch(
      getCustomerTransactions({
        ...filterOptions,
        per_page: newPageSize,
        page: 1,
      }),
    );
  };

  const handleCurrentPageChange = (newCurrentPage: number) => {
    setCurrentPage(newCurrentPage);
    setFilterOptions({ ...filterOptions, page: newCurrentPage });
    dispatch(getCustomerTransactions({ ...filterOptions, page: newCurrentPage }));
  };

  const handleSubmitFilter = () => {
    setCurrentPage(1);

    if (filterOptions.date_range === 'custom') {
      const transaction_date_range = formatStartAndEndDateWithDelimeter(
        transactionDateRange.start_date,
        transactionDateRange.end_date,
      );
      setFilterOptions({ ...filterOptions, page: 1, date_range: transaction_date_range });
      dispatch(getCustomerTransactions({ ...filterOptions, page: 1, date_range: transaction_date_range }));
      return;
    }

    dispatch(getCustomerTransactions({ ...filterOptions, page: 1 }));
  };

  useEffect(() => {
    if (customerTransactions.data?.transactions?.data?.length > 0) {
      const { last_page, current_page, per_page } = customerTransactions?.data?.transactions;
      setTotalPages(last_page);
      setCurrentPage(current_page);
      setPerPage(per_page);
      setFilterOptions({ ...filterOptions, page: current_page, per_page: per_page });
    }

    if (customerTransactions.status === 'succeeded') {
      let updateTransactionsData: any[] = [];

      customerTransactions.data?.transactions?.data.forEach((item: Dictionary, index: number) => {
        updateTransactionsData.push(item);
      });

      setTransactionsData(updateTransactionsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerTransactions?.data, customerTransactions.status]);

  // close filter on state change
  useEffect(() => {
    setShowFilterOptions(false);
    setShowExportOptions(false);
    // dependency array missing
  }, [customerTransactions.status]);

  // Update filter options when checkboxes or dropdowns change
  useEffect(() => {
    const getTransactionDateValue = getSelectedOptionValue(transactionDate);

    setFilterOptions({
      ...filterOptions,
      date_range: getTransactionDateValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionDate]);

  return (
    <>
      {(showExportOptions || showFilterOptions) && (
        <div
          className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
          onClick={() => {
            setShowExportOptions(false);
            setShowFilterOptions(false);
          }}></div>
      )}
      <div className="tw-px-6 tw-w-full">
        <div className="tw-flex tw-justify-between tw-mb-5 tw-mt-8">
          <H1 left lightBold>
            Transactions
          </H1>

          {customerTransactions.status === 'succeeded' && (
            <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
              <FilterLabel
                title="Filter Options"
                icon={<icons.BsFilter />}
                onClick={() => setShowFilterOptions(true)}
                disabled={downloadCustomerTransactionsStatus === 'loading'}>
                <FilterCard
                  showFilter={showFilterOptions}
                  title="Filter Options"
                  onClick={() => handleSubmitFilter()}
                  extraClass="tw-w-[13rem]">
                  <div className="tw-mt-4 tw-h-[10rem] tw-overflow-y-scroll tw-overflow-hidden tw-z-[1]">
                    <FilterColapsible
                      title="Transaction Date"
                      show={showTransactionDate}
                      onClick={() => setShowTransactionDate(!showTransactionDate)}
                      extraClass="tw-shadow-none">
                      <>
                        <div className="">
                          {transactionDate.map(item => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, transactionDate, setTransactionDate)}
                              />
                            </div>
                          ))}
                        </div>

                        <div
                          className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                            filterOptions.date_range === 'custom' && 'tw-max-h-fit'
                          }`}>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="State Date"
                              value={new Date()}
                              setValue={value =>
                                setTransactionDateRange({ ...transactionDateRange, start_date: value })
                              }
                            />
                          </div>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="End Date"
                              value={new Date()}
                              setValue={value => setTransactionDateRange({ ...transactionDateRange, end_date: value })}
                            />
                          </div>
                        </div>
                      </>
                    </FilterColapsible>
                  </div>
                </FilterCard>
              </FilterLabel>

              <ExportLabel
                title="Export History"
                onClick={() => setShowExportOptions(true)}
                loading={downloadCustomerTransactionsStatus === 'loading'}
                disabled={
                  downloadCustomerTransactionsStatus === 'loading' ||
                  (customerTransactions.status === 'succeeded' &&
                    customerTransactions.data?.transactions?.data?.length < 1)
                }>
                <FilterCard
                  showFilter={showExportOptions}
                  title="Export as"
                  onClick={() => null}
                  showSubmitButton={false}
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <div className="tw-flex tw-flex-col tw-mt-4">
                    <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                      onClick={() => handleExportFile('csv')}>
                      CSV
                    </span>
                  </div>
                </FilterCard>
              </ExportLabel>
            </div>
          )}
        </div>

        <>
          {customerTransactions.status === 'loading' ? (
            <div className="tw-h-[30vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="60"
                width="60"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : customerTransactions.status === 'succeeded' ? (
            <AccountManagementTransactionsTable
              headerData={accountManagementTransactionsTableHeaderUpdate}
              data={transactionsData}
              sorting={handleSortItems}
              order={order}
            />
          ) : (
            <></>
          )}
        </>

        <>
          <div className="tw-pb-2 tw-mt-12 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
            {/* PAGINATION */}
            {customerTransactions.status === 'failed' ? (
              <></>
            ) : (
              customerTransactions.data?.transactions?.data?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={selectedPage => {
                    handleCurrentPageChange(selectedPage);
                  }}
                  isLoading={customerTransactions.status === 'loading'}
                />
              )
            )}

            {/* PAGE SIZE CONTROL FOR SUPER AGENT USERS */}
            {customerTransactions.status === 'loading' || customerTransactions.status === 'failed' ? (
              <></>
            ) : (
              customerTransactions.data.transactions?.data?.length > 0 && (
                <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                  <span className="tw-text-xs tw-w-16">Per page</span>
                  <ZojaSelect
                    options={[
                      { label: '10', value: '10' },
                      { label: '20', value: '20' },
                      { label: '50', value: '50' },
                      { label: '100', value: '100' },
                      { label: '200', value: '200' },
                    ]}
                    value={`${perPage}`}
                    setSelectedOption={selected => handlePageSizeChange(selected)}
                    extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                  />
                </div>
              )
            )}
          </div>
        </>
      </div>
    </>
  );
}
