function NotFound() {
  return (
    <div>
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}

export default NotFound;
