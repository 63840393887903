import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
};

const getMyFreezeRequestsSlice = createSlice({
  name: 'getMyFreezeRequests',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getMyFreezeRequestsRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getMyFreezeRequestsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getMyFreezeRequestsRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getMyFreezeRequestsRequest = createAsyncThunk(
  'getMyFreezeRequests',
  async (payload: Dictionary, { dispatch }) => {
    const { per_page, page } = payload;
    const url = `admin/restrictions/get-my-restrictions`;
    try {
      const response = await api.get(`${url}`, {
        params: {
          per_page,
          page,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

export const getMyFreezeRequestsReset = getMyFreezeRequestsSlice.actions.reset;
export const getMyFreezeRequestsSliceReducer = getMyFreezeRequestsSlice.reducer;
