import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadFinanceReportRequest = createAsyncThunk(
  'downloadFinanceReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      status,
      sort_by,
      exp,
      order_by,
      min_amount,
      max_amount,
      page,
      per_page,
      start_date,
      end_date,
      transaction_type,
      type,
      kyc_level,
      min_fee,
      max_fee,
    } = payload;
    const url = `/admin/report/general/finance?sort_by=${sort_by}&min_amount=${
      min_amount === undefined ? null : min_amount
    }&max_amount=${
      max_amount === undefined ? null : max_amount
    }&status=${status}&export=${exp}&order_by=${order_by}&per_page=${per_page}&page=${page}&start_date=${start_date}&end_date=${end_date}&transaction_type=${transaction_type}&type=${type}&kyc_level=${kyc_level}&min_fee=${
      min_fee === undefined ? null : min_fee
    }&max_fee=${max_fee === undefined ? null : max_fee}`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `financereport.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadFinanceReportSlice = createSlice({
  name: 'downloadFinanceReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadFinanceReportRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadFinanceReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadFinanceReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadFinanceReportReset = downloadFinanceReportSlice.actions.reset;
export const downloadFinanceReportSliceReducer = downloadFinanceReportSlice.reducer;
