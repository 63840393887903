import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { BsFilter } from 'react-icons/bs';
import DatePicker from 'react-multi-date-picker';
import { useNavigate } from 'react-router-dom';
import {
  AppContainer,
  DashboardTotalTransfer,
  StatusLoader,
  TotalTransactionChart,
  TotalTransactionTrend,
} from '../../atoms';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  downloadDashboardTransVolumeIns,
  downloadDashboardTransactionTrend,
  getDashboardTransactionTrend,
  getTransactionVolume,
} from '../../redux/slice';
import { Dictionary } from '../../types';
import { colors, formatRMDatePicker, routesPath } from '../../utils';
import numberFormat from '../../utils/numberFormat';
import { ExportLabel, FilterCard } from '../../components';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { daysDifference } from '../../utils/getDateDifference';
import { toast } from 'react-toastify';

const { DASHBOARD } = routesPath;

const appFeatures: Dictionary = [
  {
    id: 1,
    title: 'Cash requests/provision',
    color: '#05F300',
  },
  {
    id: 2,
    title: 'Transfer-other banks',
    color: '#FF6C02',
  },
  {
    id: 3,
    title: 'Transfer-Zojapay',
    color: '#EBC500',
  },
  {
    id: 4,
    title: 'Electricity',
    color: '#FFD60A',
  },
  {
    id: 5,
    title: 'Airtime & Data Bills',
    color: '#981EF8',
  },
  {
    id: 6,
    title: 'Contactless payment',
    color: '#2DBD9B',
  },
  {
    id: 7,
    title: 'Other bills(cable,betting,etc)',
    color: '#1EEBF8',
  },
];

const trendDataFeatures: Dictionary = [
  {
    id: 1,
    title: 'Week 1',
    color: '#222B88',
  },
  {
    id: 2,
    title: 'Week 2',
    color: '#222B88',
  },
  {
    id: 3,
    title: 'Week 3',
    color: '#222B88',
  },
  {
    id: 4,
    title: 'Week 4',
    color: '#222B88',
  },
  {
    id: 5,
    title: 'Week 4',
    color: '#222B88',
  },
  {
    id: 6,
    title: 'Week 5',
    color: '#222B88',
  },
  {
    id: 7,
    title: 'Week 6',
    color: '#222B88',
  },
];

const payload: Dictionary = {
  start_date: '',
  end_date: '',
};

const apiFeatures = [
  'cash_requests',
  'other_banks_transfers',
  'zojapay_transfers',
  'electricity_bills',
  'airtime_and_data_bills',
  'contactless_payments',
  'other_bills',
];

const initialValues: Dictionary = {
  start_date: '',
  end_date: '',
};

const TransactionInformation = () => {
  const [showFilter, setShowFilter] = useState(false);
  const startDateRef = useRef<any>(null);
  const endDateRef = useRef<any>(null);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [showExportOptions, setShowExportOptions] = useState(false);

  const [trendPayload, setTrendPayload] = useState<any>({ year: '' });
  const [selectedOption, setSelectedOption] = useState<any>('');

  const { status: downloadStatus } = useAppSelector(state => state.downloadUsersRecord);
  const { status: downloadTransactionTrend } = useAppSelector(state => state.downloadTransactionTrends);
  const { status: downloadTransactionVolIns } = useAppSelector(state => state.downloadTransactionVolumeIns);

  const [chartData, setChartData] = useState<any>({
    series: [
      {
        name: '',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 550,
        type: 'bar',
        events: {
          click: function (chart: any, w: any, e: any) {
            // console.log(chart, w, e)
          },
        },
        toolbar: {
          show: false,
        },
      },
      colors: appFeatures.map((data: any) => data.color),
      labels: appFeatures.map((data: any) => data.title),
      plotOptions: {
        bar: {
          columnWidth: '26%',
          distributed: true,
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: appFeatures.map((data: any) => data.title),
        labels: {
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        title: {
          text: 'Amount (millions)',
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return `₦${numberFormat(val)}`;
          },
        },
      },
    },
  });

  const [chartTrendData, setChartTrendData] = useState<any>({
    series: [
      {
        name: '',
        data: [0, 0, 0, 0, 0, 0, 0],
      },
    ],
    options: {
      chart: {
        height: 550,
        type: 'bar',
        events: {
          click: function (chart: any, w: any, e: any) {
            // console.log(chart, w, e)
          },
        },
        toolbar: {
          show: false,
        },
      },
      colors: [],
      labels: [],
      plotOptions: {
        bar: {
          columnWidth: '10%',
          distributed: true,
          borderRadius: 5,
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      xaxis: {
        categories: trendDataFeatures.map((data: any) => data.title),
        labels: {
          style: {
            colors: colors,
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        show: true,
        title: {
          text: 'Amount (M#)',
        },
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return `₦${numberFormat(val)}`;
          },
        },
      },
    },
  });

  const [chartKey, setChartKey] = useState(0);
  const { data, status } = useAppSelector(state => state.transaction_volume);
  const { data: trendData, status: trendStatus } = useAppSelector(state => state.getDashboardTransactionTrend);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { values } = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      console.log(values);
    },
  });

  useEffect(() => {
    dispatch(getTransactionVolume(payload));
    dispatch(getDashboardTransactionTrend(trendPayload));
  }, []);

  useEffect(() => {
    setTrendPayload({
      ...trendPayload,
      year: selectedOption,
    });

    dispatch(getDashboardTransactionTrend({ year: selectedOption }));
  }, [selectedOption]);

  const handleFilterChart = () => {
    dispatch(getTransactionVolume(values));
  };

  useEffect(() => {
    if (status === 'succeeded') {
      const chartSeriesData = apiFeatures.map(d => Number(data[d]?.transaction_volume));
      let series = chartData.series;
      series[0].data = chartSeriesData;
      setChartData({ ...chartData, series: series });
      setChartKey(chartKey + 1);
    }
  }, [status]);

  useEffect(() => {
    if (trendStatus === 'succeeded') {
      const chartSeriesData = trendData?.trend.map((item: { transaction_count: any }) => item.transaction_count);
      const chartCategories = trendData?.trend.map((item: { week_number: any }) => `Week ${item.week_number}`);

      setChartTrendData({
        ...chartTrendData,
        series: [
          {
            name: '',
            data: chartSeriesData,
          },
        ],
        options: {
          ...chartTrendData.options,
          xaxis: {
            ...chartTrendData.options.xaxis,
            categories: chartCategories,
          },
        },
      });
      setChartKey(chartKey + 1);
    }
  }, [trendStatus]);

  const handleExportFile = (type: string) => {
    setShowExportOptions(false);
    dispatch(downloadDashboardTransactionTrend({ exp: type }));
    dispatch(downloadDashboardTransVolumeIns({ exp: type }));
  };

  useEffect(() => {
    if (startDate.length !== 0 && endDate.length !== 0) {
      const differenceInDays: number = daysDifference(formatRMDatePicker(startDate), formatRMDatePicker(endDate));

      if (differenceInDays >= 0) {
        dispatch(
          getTransactionVolume({
            start_date: formatRMDatePicker(startDate),
            end_date: formatRMDatePicker(endDate),
          }),
        );
      } else {
        toast.error('Start Date is greater or equal to end date');
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (downloadTransactionTrend === 'succeeded' && downloadTransactionVolIns === 'succeeded') {
      toast.success('Download Successful');
    }
    if (downloadTransactionTrend === 'failed' && downloadTransactionVolIns === 'failed') {
      toast.error('Download failed');
    }
  }, [downloadTransactionTrend, downloadTransactionVolIns]);

  return (
    <div>
      <AppContainer
        goBack={() => navigate(DASHBOARD)}
        navTitle={`DASHBOARD`}
        navHelper={
          <>
            <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">
              Transaction Information
            </h4>
          </>
        }>
        <section className="tw-p-2 tw-mt-4">
          <div className="tw-flex tw-justify-between">
            <h2 className="tw-text-xl">Total Transactions</h2>
            <div className="tw-flex tw-items-center tw-gap-x-3 md:tw-gap-x-10 md:tw-mr-6">
              {status !== 'loading' && status === 'succeeded' && (
                <div className={`tw-flex tw-items-center tw-gap-x-5 tw-p-1 tw-px-3`}>
                  <div
                    className={`${
                      showFilter
                        ? 'tw-absolute tw-top-16 tw-right-6 tw-bg-white tw-p-3 tw-shadow-zojaShadowThree'
                        : 'tw-hidden'
                    }  tw-flex-co tw-gap-x-6 md:tw-flex`}>
                    <div className="tw-border tw-text-[11.3px] tw-text-gray-400 tw-p-[9px] tw-px-7 tw-rounded-[4px] tw-flex tw-gap-5 tw-relative md:tw-px-8">
                      <span className={`${startDate && 'tw-text-gray-600 tw-font-normal'}`}>
                        {startDate !== '' ? formatRMDatePicker(startDate) : 'Start Date'}
                      </span>{' '}
                      <span
                        className="tw-text-lg tw-text-isPrimary tw-cursor-pointer"
                        onClick={() => startDateRef.current.openCalendar()}>
                        <MdOutlineEditCalendar />{' '}
                      </span>
                      <DatePicker
                        onClose={() => {
                          values.start_date = formatRMDatePicker(startDate);
                        }}
                        value={startDate}
                        ref={startDateRef}
                        onOpenPickNewDate={false}
                        containerClassName="tw-absolute tw-bottom-2 tw-w-[1rem] tw-text-md tw-hidden -tw-mt-7"
                        inputClass="tw-bg-inherit tw-outline-none tw-hidden"
                        onChange={(date: any) => {
                          setStartDate(date);
                          values.start_date = formatRMDatePicker(startDate);
                        }}
                      />
                    </div>
                    <div
                      className={`${
                        showFilter && 'tw-mt-3 md:tw-mt-0'
                      } tw-border tw-text-[11.3px] tw-text-gray-400 tw-p-[9px] tw-px-7 tw-rounded-[4px] tw-flex tw-gap-5 tw-relative md:tw-px-8`}>
                      <span className={`${endDate && 'tw-text-gray-600 tw-font-normal'}`}>
                        {endDate !== '' ? formatRMDatePicker(endDate) : 'End Date'}
                      </span>{' '}
                      <span
                        className="tw-text-lg tw-text-isPrimary tw-cursor-pointer"
                        onClick={() => endDateRef.current.openCalendar()}>
                        <MdOutlineEditCalendar />{' '}
                      </span>
                      <DatePicker
                        onClose={() => {
                          values.end_date = formatRMDatePicker(endDate);
                        }}
                        value={endDate}
                        ref={endDateRef}
                        onOpenPickNewDate={false}
                        containerClassName="tw-absolute tw-bottom-2 tw-w-[1rem] tw-text-md tw-hidden -tw-mt-7"
                        inputClass="tw-bg-inherit tw-outline-none tw-hidden"
                        onChange={(date: any) => {
                          setEndDate(date);
                          values.end_date = formatRMDatePicker(endDate);
                        }}
                      />
                    </div>

                    <div
                      className={`${
                        showFilter ? 'tw' : 'tw-hidden'
                      } tw-items-center tw-gap-x-1 tw-text-sm tw-text-[#5B6871] tw-flex tw-justify-center tw-mt-2 tw-p-2 tw-rounded-md tw-h-fit tw-px-4 tw-bg-gray-200 tw-cursor-pointer md:tw-mt-0`}
                      onClick={() => {
                        handleFilterChart();
                        setShowFilter(false);
                      }}>
                      <span className="tw-text-lg tw-font-medium">
                        <BsFilter />
                      </span>
                      <span className="tw-font-normal tw-tracking-wide">Filter</span>
                    </div>

                    <ExportLabel
                      title="Export"
                      onClick={() => setShowExportOptions(true)}
                      loading={
                        downloadTransactionTrend === 'loading' && downloadTransactionVolIns === 'loading' ? true : false
                      }>
                      <FilterCard
                        showFilter={showExportOptions}
                        title="Export as"
                        onClick={() => null}
                        showSubmitButton={false}
                        extraClass="tw-w-[10rem] -tw-left-28">
                        <div className="tw-flex tw-flex-col tw-mt-4">
                          <span
                            className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                            onClick={() => handleExportFile('csv')}>
                            CSV
                          </span>
                        </div>
                      </FilterCard>
                    </ExportLabel>
                  </div>

                  <span
                    className="tw-text-lg tw-font-medium tw-border tw-p-2 tw-px-4 tw-bg-gray-200 tw-rounded-md tw-text-[#5B6871] md:tw-hidden"
                    onClick={() => setShowFilter(!showFilter)}>
                    <BsFilter />
                  </span>
                </div>
              )}
            </div>
          </div>
          {status !== 'loading' && status === 'succeeded' && (
            <>
              <div className="tw-mt-8 lg:tw-flex lg:tw-gap-16 lg:tw-items-start">
                <TotalTransactionTrend
                  chartData={chartTrendData}
                  data={data}
                  setSelectedOption={setSelectedOption}
                  status={trendStatus}
                />
                <TotalTransactionChart appFeatures={appFeatures} chartData={chartData} />
              </div>
              <hr />
              <DashboardTotalTransfer />
            </>
          )}

          <StatusLoader status={status} width={'80'} height={'80'} wrapperHeight={'tw-h-[60vh]'} />
        </section>
      </AppContainer>
    </div>
  );
};

export default TransactionInformation;
