// transactionFilterOptionsSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';

const initialTransactionValues: Dictionary = {
  transaction_date: '',
  status: '',
  type: '',
  min_amount: '',
  max_amount: '',
  sort_by: '',
  order_by: '',
  filter_accounts: [],
  date_range: '',
  min_charge: '',
  max_charge: '',
  export: '',
  fees_range: '',
  per_page: '',
  page: 1,
  filterType: 'customReport',
};
const initialVolumeValues: Dictionary = {
  transaction_date: '',
  type: '',
  sort_by: '',
  order_by: '',
  date_range: '',
  min_count: '',
  max_count: '',
  export: '',
  per_page: '',
  page: '',
  min_total_value: '',
  max_total_value: '',
  min_total_revenue: '',
  max_total_revenue: '',
  filterType: 'customReport',
};
const initialIncomeValues: Dictionary = {
  filter_accounts: [],
  per_page: '',
  page: '',
  time_range: '',
  min_count: '',
  max_count: '',
  min_total_revenue: '',
  max_total_revenue: '',
  sort_by: '',
  payment_method: '',
  order_by: '',
  profit_margin: '',
};
const initialUserValues: Dictionary = {
  status: '',
  type: '',
  transaction_date: '',
  sort_by: '',
  export: '',
  order_by: '',
  min_amount: '',
  max_amount: '',
  per_page: '',
  page: '',
  last_seen: '',
  location: '',
  filter_accounts: [],
  date_onboarded: '',
  level: '',
  gender: '',
  min_transaction: '',
  max_transaction: '',
  min_balance: '',
  max_balance: '',
  min_age: '',
  max_age: '',
  filterType: 'customReport',
};

const initialGeneralTransactionValues: Dictionary = {
  frequency: '',
  start_date: '',
  end_date: '',
  status: '',
  filter_accounts: [],
  kyc_level: '',
  transaction_type: '',
  min_amount: '',
  max_amount: '',
  type: '',
  sort_by: '',
  order_by: '',
  export: '',
};

const initialGeneralTransactionDateRangeValues: Dictionary = {
  frequency: '',
  start_date: '',
  end_date: '',
  status: '',
  filter_accounts: [],
  kyc_level: '',
  transaction_type: '',
  min_amount: '',
  max_amount: '',
  type: '',
  sort_by: '',
  order_by: '',
  export: '',
  frequency_type: '',
};

const initialReconciliationValues: Dictionary = {
  transaction_type: '',
  transaction_status: '',
  start_date: '',
  end_date: '',
  min_amount: '',
  max_amount: '',
  internal_ref_number: '',
  account_number: '',
  account_name: '',
  sort_by: '',
  order_by: '',
  export: '',
};

const initialFinanceValues: Dictionary = {
  start_date: '',
  end_date: '',
  min_amount: '',
  max_amount: '',
  sort_by: '',
  order_by: '',
  export: '',
  transaction_type: '',
  type: '',
  status: '',
  kyc_level: '',
  min_charge: '',
  max_charge: '',
};

const initialUserAccessValues: Dictionary = {
  start_date: '',
  end_date: '',
  min_amount: '',
  max_amount: '',
  sort_by: '',
  order_by: '',
  export: '',
  userId: '',
  transaction_type: '',
  transaction_status: '',
  internal_ref_number: '',
  account_number: '',
  account_name: '',
};

const initialUserRegValues: Dictionary = {
  sort_by: '',
  order_by: '',
  export: '',
  start_date: '',
  end_date: '',
  min_age: '',
  max_age: '',
  device_type: '',
  source_of_reg: '',
  user_status: '',
  user_level: '',
  user_feedback: '',
  account_verification_status: '',
  gender: '',
  location: '',
};

const filterOptionsSlice = createSlice({
  name: 'filterOptions',
  initialState: initialTransactionValues, // Your initial state here
  reducers: {
    setFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetFilterOptions: state => {
      return initialTransactionValues; // Reset the state to its initial values
    },
  },
});

const userFilterOptionsSlice = createSlice({
  name: 'userFilterOptions',
  initialState: initialUserValues, // Your initial state here
  reducers: {
    setUserFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetUserFilterOptions: state => {
      return initialUserValues; // Reset the state to its initial values
    },
  },
});
const volumeFilterOptionsSlice = createSlice({
  name: 'volumeFilterOptions',
  initialState: initialVolumeValues, // Your initial state here
  reducers: {
    setVolumeFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetVolumeFilterOptions: state => {
      return initialVolumeValues; // Reset the state to its initial values
    },
  },
});

const incomeFilterOptionsSlice = createSlice({
  name: 'incomeFilterOptions',
  initialState: initialIncomeValues, // Your initial state here
  reducers: {
    setIncomeFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetIncomeFilterOptions: state => {
      return initialIncomeValues; // Reset the state to its initial values
    },
  },
});

const generalTransactionFilterOptionsSlice = createSlice({
  name: 'generalTransactionFilterOptions',
  initialState: initialGeneralTransactionValues, // Your initial state here
  reducers: {
    setGeneralTransactionFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetGeneralTransactionFilterOptions: state => {
      return initialGeneralTransactionValues; // Reset the state to its initial values
    },
  },
});

const generalTransactionDateRangeFilterOptionsSlice = createSlice({
  name: 'generalTransactionDateRangeFilterOptions',
  initialState: initialGeneralTransactionDateRangeValues, // Your initial state here
  reducers: {
    setGeneralTransactionDateRangeFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetGeneralTransactionDateRangeFilterOptions: state => {
      return initialGeneralTransactionDateRangeValues; // Reset the state to its initial values
    },
  },
});

const reconciliationFilterOptionsSlice = createSlice({
  name: 'ReconciliationFilterOptions',
  initialState: initialReconciliationValues, // Your initial state here
  reducers: {
    setReconciliationFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetReconciliationFilterOptions: state => {
      return initialReconciliationValues; // Reset the state to its initial values
    },
  },
});

const financeFilterOptionsSlice = createSlice({
  name: 'FinanceFilterOptions',
  initialState: initialFinanceValues, // Your initial state here
  reducers: {
    setFinanceFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetFinanceFilterOptions: state => {
      return initialFinanceValues; // Reset the state to its initial values
    },
  },
});

const userAccessFilterOptionsSlice = createSlice({
  name: 'UserAccessFilterOptions',
  initialState: initialUserAccessValues, // Your initial state here
  reducers: {
    setUserAccessFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetUserAccessFilterOptions: state => {
      return initialUserAccessValues; // Reset the state to its initial values
    },
  },
});

const userRegFilterOptionsSlice = createSlice({
  name: 'UserRegFilterOptions',
  initialState: initialUserRegValues, // Your initial state here
  reducers: {
    setUserRegFilterOptions: (state, action) => {
      return action.payload; // Update the state with the payload
    },
    resetUserRegFilterOptions: state => {
      return initialUserRegValues; // Reset the state to its initial values
    },
  },
});

export const { setFilterOptions, resetFilterOptions } = filterOptionsSlice.actions;
export const { setUserFilterOptions, resetUserFilterOptions } = userFilterOptionsSlice.actions;
export const { setVolumeFilterOptions, resetVolumeFilterOptions } = volumeFilterOptionsSlice.actions;
export const { setIncomeFilterOptions, resetIncomeFilterOptions } = incomeFilterOptionsSlice.actions;
export const { setGeneralTransactionFilterOptions, resetGeneralTransactionFilterOptions } =
  generalTransactionFilterOptionsSlice.actions;
export const { setGeneralTransactionDateRangeFilterOptions, resetGeneralTransactionDateRangeFilterOptions } =
  generalTransactionDateRangeFilterOptionsSlice.actions;
export const { setReconciliationFilterOptions, resetReconciliationFilterOptions } =
  reconciliationFilterOptionsSlice.actions;
export const { setFinanceFilterOptions, resetFinanceFilterOptions } = financeFilterOptionsSlice.actions;
export const { setUserAccessFilterOptions, resetUserAccessFilterOptions } = userAccessFilterOptionsSlice.actions;
export const { setUserRegFilterOptions, resetUserRegFilterOptions } = userRegFilterOptionsSlice.actions;

// export default filterOptionsSlice;

// export const getAllUserReportReset = filterOptionsSlice.actions.reset;

export const filterOptionsSliceReducer = filterOptionsSlice.reducer;
export const userFilterOptionsSliceReducer = userFilterOptionsSlice.reducer;
export const volumeFilterOptionsSliceReducer = volumeFilterOptionsSlice.reducer;
export const incomeFilterOptionsSliceReducer = incomeFilterOptionsSlice.reducer;
export const generalTransactionFilterOptionsSliceReducer = generalTransactionFilterOptionsSlice.reducer;
export const generalTransactionDateRangeFilterOptionsSliceReducer =
  generalTransactionDateRangeFilterOptionsSlice.reducer;
export const reconciliationFilterOptionsSliceReducer = reconciliationFilterOptionsSlice.reducer;
export const financeFilterOptionsSliceReducer = financeFilterOptionsSlice.reducer;
export const userAccessFilterOptionsSliceReducer = userAccessFilterOptionsSlice.reducer;
export const userRegFilterOptionsSliceReducer = userRegFilterOptionsSlice.reducer;
