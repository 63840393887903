import styled from 'styled-components';
import { colors, spacing } from '../../utils';

interface StyleProps {
  backgroundColor?: string;
  type?: string;
  paddingBottom?: boolean;
  borderBottom?: string;
  gap?: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  align-self: flex-start;
  width: 100%;
  column-gap: ${(p: StyleProps) => (p.gap ? p.gap : '')};
  row-gap: ${spacing.xsmall};
  border-bottom: ${(p: StyleProps) => (p.borderBottom ? p.borderBottom : '')};
  background-color: ${(p: StyleProps) => (p.backgroundColor ? p.backgroundColor : 'transparent')};
  padding-left: ${(p: StyleProps) => (p.backgroundColor ? spacing.xxsmall : '0px')};
  padding-right: ${(p: StyleProps) => (p.backgroundColor ? spacing.xxsmall : '0px')};
  padding-top: ${spacing.xxsmall};
  padding-bottom: ${(p: StyleProps) => (p.paddingBottom ? spacing.xxsmall : '0')};
  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;
