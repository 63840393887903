import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../../components/tailwind';
import { icons } from '../../utils';
import ZojaConfirmSuccess from './ConfirmSuccess';

interface AddNewEventCentreProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
}

const tierTypeOptions = [
    {
        label: "select area",
        value: ""
    },
    {
        label: "Tier 1 (3%)",
        value: "Tier 1"
    },
    {
        label: "Tier 2 (5%)",
        value: "Tier 2"
    },
    {
        label: "Tier 3 (10%)",
        value: "Tier 3"
    },
]


export default function AddNewEventCentre({show, handleClose}: AddNewEventCentreProps) {
    return (
        <>
            <ZojaConfirmSuccess
                title='Event Added Successfully'
                subTitle='A new even has been added, check its activity in the events tab.'
                show={false}
                handleClose={() => null}
                onClick={() => null}
                buttonText='Complete'
            />
            <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Add New Event Centre</h3>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <form>
                        <div className='mt-4'>
                            <ZojaInput
                                label='Vendor name'
                                placeholder='Enter vendor name'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className="mt-1">
                            <ZojaSelect options={tierTypeOptions} setSelectedOption={(value) => {
                                console.log(value);
                            }} extraClass={`tw-rounded-[5px] tw-p-1 tw-text-[11px] tw-text-gray-700 tw-border-[#E7E7E7] tw-w-full tw-appearance-none tw-bg-gray-100/40 ${false && 'tw-text-gray-600 tw-font-normal'}`} showArrown={true} arrowDownClass='tw-top-4 tw-text-gray-300 tw-text-xs'
                                label='Tier'
                                labelExtraClass='tw-text-[.83rem]'
                            />
                        </div>
                        <div className='mt-2'>
                            <ZojaInput
                                label='Address'
                                placeholder='Enter vendor address'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className='mt-2'>
                            <ZojaInput
                                label='Capacity'
                                placeholder='100'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className='mt-2'>
                            <ZojaInput
                                label='Phone Number'
                                placeholder='Enter phone number'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className='mt-2'>
                            <ZojaInput
                                label='Email'
                                placeholder='Enter email address'
                                extraClass='placeholder:tw-pl-[1px] tw-pl-3 tw-p-2 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-blue-800'
                            />
                        </div>
                        <div className='my-3 mt-4'>
                            <ZojaButton
                                onClick={() => null}
                                text="Confirm"
                                // loading
                                extraClass='tw-bg-isPrimary tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-isPrimary/90'
                            />
                        </div>
                    </form>
                </div>
            </ZojaModal>
        </>
    )
}
