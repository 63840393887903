import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllReconciliationReportRequest = createAsyncThunk(
  'getAllReconciliationReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      transaction_type,
      transaction_status,
      min_amount,
      max_amount,
      internal_ref_number,
      account_number,
      start_date,
      end_date,
      account_name,
      sort_by,
      order_by,
      exp,
      per_page,
      page,
    } = payload;
    const url = `admin/report/general/reconcilation`;

    try {
      const response = await api.get(`${url}`, {
        params: {
          export: exp,
          per_page,
          transaction_status,
          page,
          account_number,
          account_name,
          internal_ref_number,
          transaction_type,
          min_amount,
          max_amount,
          sort_by,
          order_by,
          start_date,
          end_date,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllReconciliationReportSlice = createSlice({
  name: 'getAllReconciliationReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllReconciliationReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllReconciliationReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllReconciliationReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllReconciliationReportReset = getAllReconciliationReportSlice.actions.reset;
export const getAllReconciliationReportSliceReducer = getAllReconciliationReportSlice.reducer;
