import { MdOutlineEditCalendar } from 'react-icons/md';
import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../../components/tailwind';
import { icons } from '../../utils';
import DatePicker from 'react-multi-date-picker';
import { useRef, useState } from 'react';
import ZojaConfirmSuccess from './ConfirmSuccess';


interface UploadEventFileProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
}

export default function UploadEventFile({ show, handleClose }: UploadEventFileProps) {
    const [value, setValue] = useState(new Date());

    const eventDateRef = useRef<any>(null)

    return (
        <>
            <ZojaConfirmSuccess
                title='New Vendor Added'
                subTitle='Vendor has been added successfully, see details in table.'
                show={false}
                handleClose={() => null}
                onClick={() => null}
                buttonText='Complete'
            />
            <ZojaModal
                show={show}
                handleClose={handleClose}
                height='auto'
                position='center'
                extraClass="md:tw-w-[32rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius='8px'
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-2 tw-p-4'>
                    <h3 className='tw-text-[.95rem] tw-text-[#263238] tw-mb-1'>Upload File</h3>
                    <span className='tw-text-[.9rem] tw-text-[#263238]'>Maximum file is 10mb, format is CSV, xlsv.</span>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={handleClose}
                    ><icons.MdClose /></span>
                    <form>
                        <div className='mt-3'>
                            <div className='tw-flex tw-items-center'>
                                <span className='tw-text-[.85rem] tw-w-[5rem]'>CSV file</span>
                                <label htmlFor="event-document" className='block tw-cursor-pointer tw-w-full tw-pl-3 tw-p-2 tw-text-[.85rem] tw-rounded-[5px] tw-text-gray-700 tw-border tw-flex tw-justify-between'>
                                    <span className='tw-text-gray-300'>no file is selected</span>
                                    <span className='tw-text-[.8rem] tw-text-isPrimary'>choose file</span>
                                </label>
                                <ZojaInput
                                    type='file'
                                    placeholder='Enter event title'
                                    extraClass='tw-hidden'
                                    id='event-document'
                                />
                            </div>
                            <span className='tw-block tw-mt-4 tw-text-[.8rem] tw-text-[#263238]'>download <span className='tw-text-isPrimary'>sample csv</span> and <span className='tw-text-isPrimary'>sample xslv</span> template</span>
                        </div>
                        <div className='my-3 mt-1 tw-flex tw-justify-end tw-gap-x-3'>
                            <ZojaButton
                                onClick={handleClose}
                                text="Cancel"
                                // loading
                                extraClass='tw-bg-white tw-border tw-border-isPrimary tw-grid tw-place-content-center tw-text-isPrimary tw-font-thin tw-text-[.75rem] tw-rounded-[4px] tw-px-5 tw-w-full tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-isPrimary/90'
                                type='button'
                            />
                            <ZojaButton
                                onClick={() => null}
                                text="Upload"
                                // loading
                                extraClass='tw-bg-gray-50 tw-border tw-grid tw-place-content-center tw-text-gray-400 tw-font-thin tw-text-[.75rem] tw-rounded-[4px] tw-w-full tw-px-5 tw-p-2 tw-mt-4 hover:tw-text-white hover:tw-bg-isPrimary'
                                type='button'
                            />
                        </div>
                    </form>
                </div>
            </ZojaModal>
        </>
    )
}
