import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {},
};

const banReasonsListSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBanReasonsList.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getBanReasonsList.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getBanReasonsList.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getBanReasonsList = createAsyncThunk('getBanReasonsList', async (payload: Dictionary, { dispatch }) => {
  const url = `/admin/restrictions/reasons`;

  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const banReasonsListSliceReducer = banReasonsListSlice.reducer;
