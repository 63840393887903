import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllUserReportRequest = createAsyncThunk(
  'getAllUserReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      model_type,
      status,
      reference,
      type,
      min_account_balance,
      max_account_balance,
      max_total_transaction,
      min_total_transaction,
      max_age,
      min_age,
      per_page,
      page,
      sort_by,
      exp,
      order_by,
      date_onboarded,
      last_seen,
      gender: genderparam,
      filter_accounts,
      level,
      location,
    } = payload;
    const url = `admin/report/user`;

    const gender = genderparam === '' ? null : genderparam;

    try {
      const response = await api.get(`${url}`, {
        params: {
          model_type,
          status,
          reference,
          type,
          sort_by,
          export: exp,
          order_by,
          min_balance: min_account_balance,
          max_balance: max_account_balance,
          max_transaction: max_total_transaction,
          min_transaction: min_total_transaction,
          max_age,
          min_age,
          per_page,
          page,
          date_onboarded,
          last_seen,
          gender,
          filter_accounts,
          level,
          location,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllUserReportSlice = createSlice({
  name: 'getAllUserReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllUserReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllUserReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllUserReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllUserReportReset = getAllUserReportSlice.actions.reset;
export const getAllUserReportSliceReducer = getAllUserReportSlice.reducer;
