import React, { useRef, useState } from 'react'
import { AddNewEvent, AddNewEventCenter, AppContainer, EventCenterDetailModal, UploadEventFile } from '../../atoms'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { handleSelectedOption, images, routesPath } from '../../utils';
import { Dictionary } from '../../types';
import { icons } from '../../utils';
import { Oval } from 'react-loader-spinner';
import { TD, TR } from '../../components/tailwind/table/tableElements';
import { ExportLabel, FilterCard, FilterColapsible, FilterColapsibleSingle, FilterLabel, SingleCountCard, ZojaCheckbox, ZojaTable } from '../../components';
import { dateTimeFormat3 } from '../../utils/dateFormat';
import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../../components/tailwind';
import { MdClose, MdOutlineEditCalendar } from 'react-icons/md';
import DatePicker from 'react-multi-date-picker';
import { TDMORE } from '../../components/tailwind/table/tableElementMoreView';
const { DASHBOARD, CASHREQUESTINFORMATION, EVENTCASHREQUESTINFORMATION, EVENTCASHREQUESTSETTINGS } = routesPath;

const cashRequestTabs: Dictionary = [
    {
        id: 1,
        title: 'Active Cash Requests',
        url: CASHREQUESTINFORMATION,
    },
    {
        id: 2,
        title: 'Event Cash Request',
        url: EVENTCASHREQUESTINFORMATION
    },
];

const EventCashRequestTableData = {
    columns: [
        {
            id: 1,
            title: '#',
        },
        {
            id: 2,
            title: 'Event Title',
        },
        {
            id: 3,
            title: 'Location',
        },
        {
            id: 4,
            title: 'Attendees',
        },
        {
            id: 5,
            title: 'Requests',
        },
        {
            id: 6,
            title: 'Agents',
        },
        {
            id: 7,
            title: 'Event Date/Time',
        },
    ],
    data: [
        {
            id: 1,
            cash_requester: 'Ayomide',
            reference: '#KHJHGFHGJHKJXC',
            requester_location: 'Lagos Ikoyi',
            status: 'pending',
            created_at: '11/11/2023- 12:15am',
        },
        {
            id: 2,
            cash_requester: 'Ayomide',
            reference: '#KHJHGFHGJHKJXC',
            requester_location: 'Lagos Ikoyi',
            status: 'accepted',
            created_at: '11/11/2023- 12:15am',
        },
    ],
};
const EventTransactionTableData = {
    columns: [
        {
            id: 1,
            title: '#',
        },
        {
            id: 2,
            title: 'Customer Name',
        },
        {
            id: 3,
            title: 'Agent Name',
        },
        {
            id: 4,
            title: 'Event Title',
        },
        {
            id: 5,
            title: 'Amount',
        },
        {
            id: 6,
            title: 'Charge',
        },
        {
            id: 7,
            title: 'Status',
        },
        {
            id: 8,
            title: 'Date/Time',
        },
    ],
    data: [
        {
            id: 1,
            customer_name: 'Ayomide',
            agent_name: '#KHJHGFHGJHKJXC',
            event_title: 'Lagos Ikoyi',
            amount: '40000',
            charge: '200',
            status: 'pending',
            created_at: '11/11/2023- 12:15am',
        },
    ],
};
const EventCommisionTableData = {
    columns: [
        {
            id: 1,
            title: '#',
        },
        {
            id: 2,
            title: 'Agent Name',
        },
        {
            id: 3,
            title: 'Event Centre',
        },
        {
            id: 4,
            title: 'Level',
        },
        {
            id: 5,
            title: 'Charge',
        },
        {
            id: 6,
            title: 'Commision',
        },
        {
            id: 8,
            title: 'Date/Time',
        },
    ],
    data: [
        {
            id: 1,
            agent_name: 'Caleb Adeshimawa ',
            event_centre: 'Biffco Enterprises Ltd.',
            level: 'Tier 2',
            charge: '40000',
            commision: '200',
            created_at: '11/11/2023- 12:15am',
        },
    ],
};
const EventCenterTableData = {
    columns: [
        {
            id: 1,
            title: '#',
        },
        {
            id: 2,
            title: 'Event Center Name',
        },
        {
            id: 3,
            title: 'Location',
        },
        {
            id: 4,
            title: 'Capacity',
        },
        {
            id: 5,
            title: 'Total Events',
        },
        {
            id: 6,
            title: 'Level',
        },
        {
            id: 8,
            title: 'Total Transaction(₦)',
        },
        {
            id: 9,
            title: '',
        },
    ],
    data: [
        {
            id: 1,
            event_centre: 'Caleb Adeshimawa ',
            location: 'Biffco Enterprises Ltd.',
            capacity: '450',
            total: '40000',
            level: 'Tier 3',
            total_transaction: '65',
        },
    ],
};

const EventCashRequestList = [
    {
        id: 1,
        total: 45,
        helper: 'Total Request',
        title: 'Total Request',
        subtitle: 'Total Records',
    },
    {
        id: 2,
        total: '4,050,000,000',
        helper: 'Transaction Value',
        title: 'Transaction Value(₦)',
        subtitle: 'Total Records',
    },
    {
        id: 3,
        total: '15,000,000',
        helper: 'Commissions',
        title: 'Commissions(₦)',
        subtitle: 'Total Records',
    },
    {
        id: 4,
        total: 45,
        helper: 'Event Centre',
        title: 'Event Centre',
        subtitle: 'Total Records',
    },
];

const sortByOtions = {
    aphabetically: [
        {
            id: 1,
            value: 'name_asc',
            title: 'A-Z',
            isChecked: false,
        },
        {
            id: 2,
            value: 'name_desc',
            title: 'Z-A',
            isChecked: false,
        },
    ],
    orderBy: [
        {
            id: 1,
            value: 'newest',
            title: 'Newest',
            isChecked: false,
        },
        {
            id: 2,
            value: 'oldest',
            title: 'Oldest',
            isChecked: false,
        },
    ],
};

const initialValues: Dictionary = {
    min_amount: '',
    max_amount: '',
    reference: '',
    sort_by: '',
    order_by: '',
    status: '',
    per_page: '',
    page: '',
};

const eventCentreOptions = [
    {
        label: "select area",
        value: ""
    },
    {
        label: "Gbagada",
        value: "Gbagada"
    },
    {
        label: "Ikoyi",
        value: "Ikoyi"
    },
    {
        label: "Maryland",
        value: "Maryland"
    },
]



const dropdownList: Dictionary = [
    {
      id: 1,
      title: 'See details',
      value: 'see_details',
    },
    {
      id: 2,
      title: <span className='tw-text-red-500'>Delete Event Centre</span>,
      value: 'delete_event_centre',
    },
  ];

const EventCashRequest = () => {
    const [eventCashRequestTabs, setEventCashRequestTabs] = useState(EventCashRequestList)
    const [showFilterOptions, setShowFilterOptions] = useState(false);
    const [showSortBy, setShowSortBy] = useState(false);
    const [showExportOptions, setShowExportOptions] = useState(false);
    const [showUploadOptions, setShowUploadOptions] = useState(false);
    const [showAmountRange, setShowAmountRange] = useState(true);
    const [sortBy, setSortBy] = useState(sortByOtions.aphabetically);
    const [orderBy, setOrderBy] = useState(sortByOtions.orderBy);
    const [filterOptions, setFilterOptions] = useState(initialValues);
    const [showAddEventCentreModal, setShowAddEventCentreModal] = useState(false);
    const [showAddEventModal, setShowAddEventModal] = useState(false);
    const [showUploadEventFileModal, setShowUploadEventFileModal] = useState(false);
    const [showEventCentreDetails, setShowEventCentreDetails] = useState(false);
    const [showConfirmDeleteEventModal, setShowConfirmDeleteEventModal] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [openMore, setOpenMore] = useState(false);
    const [selectedRegisteredVendor, setSelectedRegisteredVendor] = useState<any>({});
    const eventDateRef = useRef<any>(null)

    const navigate = useNavigate()
    const location = useLocation();

    const setActiveTabHandler = (tab: string) => setActiveTab(tab);

    // input changer handler
    const handleInputChange = (e: Dictionary) => {
        const { name, value } = e.target;
        setFilterOptions({ ...filterOptions, [name]: value });
    };

    const handleExportFile = (type: string) => {
        let exportPayload = { ...filterOptions, export: type };
        // console.log(exportPayload)
        // dispatch(downloadCashRequest(exportPayload));
        setShowExportOptions(false);
    };

    const handleMoreIconOptions = async (item: any) => {
        if (item.hasOwnProperty('title') && item?.value === "see_details") {
            console.log(item)
            setShowEventCentreDetails(true)
        }
        if (item.hasOwnProperty('title') && item?.value === "delete_event_centre") {
            setShowConfirmDeleteEventModal(true)
        }
    
      };

    return (
        <>
            <AddNewEventCenter
                show={showAddEventCentreModal}
                handleClose={() => setShowAddEventCentreModal(false)}
            />
            <AddNewEvent
                show={showAddEventModal}
                handleClose={() => setShowAddEventModal(false)}
            />
            <UploadEventFile
                show={showUploadEventFileModal}
                handleClose={() => setShowUploadEventFileModal(false)}
            />

            <EventCenterDetailModal
                show={showEventCentreDetails}
                handleClose={() => setShowEventCentreDetails(false)}
            />

            <ZojaModal
                show={showConfirmDeleteEventModal}
                handleClose={() => setShowConfirmDeleteEventModal(false)}
                height='auto'
                position='center'
                extraClass="tw-p-4 tw-relative md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius='10px'
            >
                <div className='tw-grid tw-place-content-center'>
                    <span className='tw-absolute tw-top-2 tw-right-3 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer'
                    onClick={() => setShowConfirmDeleteEventModal(false)}
                    ><MdClose /></span>
                    <div className='tw-mt-8 tw-flex tw-flex-col tw-items-center'>
                        <img src={images.listReject} alt="" width={50} />
                        <p className='tw-mt-2 tw-text-sm tw-text-isPrimary tw-pt-4 tw-px-3 md:tw-px-6'>Are you sure you want to delete this event centre</p>
                        <div className='tw-my-6 tw-px-6 tw-flex tw-justify-between tw-items-center'>
                            <ZojaButton
                                text="Delete Vendor"
                                extraClass='tw-bg-[#FD0000B2] tw-rounded-md tw-font-light tw-p-2.5 tw-px-8 tw-text-white tw-text-xs'
                            />

                            <ZojaButton
                                text="Cancel"
                                extraClass='tw-bg-none tw-font-light tw-p-2.5 tw-px-8 tw-text-isPrimary tw-text-xs'
                                onClick={() => setShowConfirmDeleteEventModal(false)}
                            />
                        </div>
                    </div>
                </div>
            </ZojaModal>

            <ZojaModal
                show={false}
                handleClose={() => null}
                height='auto'
                position='center'
                extraClass="tw-p-4 tw-relative md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
                borderRadius='10px'
            >
                <div className='tw-grid tw-place-content-center'>
                    <span className='tw-absolute tw-top-2 tw-right-3 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer'><MdClose /></span>
                    <div className='tw-mt-8 tw-flex tw-flex-col tw-items-center'>
                        <img src={images.reject} alt="" width={50} />
                        <p className='tw-mt-2 tw-text-sm tw-text-isPrimary tw-pt-4 tw-px-3 md:tw-px-6'>Deactivation successful</p>
                        <div className='tw-my-6 tw-px-6 tw-flex tw-justify-center tw-items-center'>
                            <ZojaButton
                                text="Close"
                                extraClass='tw-bg-isPrimary tw-rounded-md tw-font-light tw-p-2.5 tw-px-8 tw-text-white tw-text-xs'
                            />
                        </div>
                    </div>
                </div>
            </ZojaModal>



            <AppContainer
                goBack={() => navigate(DASHBOARD)}
                navTitle={`DASHBOARD`}
                navHelper={
                    <>
                        <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-[18px]">
                            Event cash requests
                        </h4>
                    </>
                }
                navBarContentRight={
                    <div className="tw-flex tw-items-center tw-gap-x-3 md:tw-gap-x-10 md:tw-mr-6">
                        {/* <NotificationIcon onClick={() => setShowNotificationModal(true)} /> */}
                    </div>
                }>
                <>
                    <section className="tw-mb-4 md:tw-p-0 md:tw-pt-8 md:tw-px-4 md:tw-mb-8">
                        {/* Overlay to hide filter options */}
                        {(showFilterOptions || showSortBy || showExportOptions || showUploadOptions) && (
                            <div
                                className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen tw-top-0 tw-left-0"
                                onClick={() => {
                                    setShowFilterOptions(false);
                                    setShowSortBy(false);
                                    setShowExportOptions(false);
                                    setShowUploadOptions(false);
                                }}></div>
                        )}
                        <div className="tw-mb-28 tw-mt-4">
                            <div className="tw-flex tw-flex-col md:tw-justify-between md:tw-items-center tw-gap-4 md:tw-flex-row md:tw-gap-x-8">
                                <div className='tw-flex md:tw-justify-between tw-items-center tw-gap-x-4 md:tw-gap-x-8'>
                                    {cashRequestTabs.map((items: any) => (
                                        <Link
                                            to={items.url}
                                            key={items.id}
                                            className={`tw-block tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] ${location.pathname === items.url
                                                ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                                                : 'tw-text-[#6A616F]'
                                                }`}>
                                            {items.title}
                                        </Link>
                                    ))}
                                </div>
                                <div className="tw-flex tw-gap-x-3">
                                    <FilterLabel
                                        title="Add Event Centre"
                                        icon={<icons.AiOutlineFolderAdd />}
                                        onClick={() => setShowAddEventCentreModal(true)}
                                        iconClass='tw-text-[1rem]'
                                        titleClass='tw-text-[.7rem] tw-font-normal'
                                        labelExtraclass='tw-p-1.5'
                                    >
                                        <></>
                                    </FilterLabel>
                                    <FilterLabel
                                        title="Settings"
                                        icon={<icons.MdOutlineSettings />}
                                        onClick={() => navigate(EVENTCASHREQUESTSETTINGS)}
                                        iconClass='tw-text-[1rem]'
                                        titleClass='tw-text-[.7rem] tw-font-normal'
                                        labelExtraclass='tw-p-1.5 md:tw-w-[7rem]'
                                    >
                                        <></>
                                    </FilterLabel>
                                    <ExportLabel
                                        title="Upload Event"
                                        onClick={() => setShowUploadOptions(true)}
                                        iconClass='tw-text-[1rem]'
                                        titleClass='tw-text-[.7rem] tw-font-normal'
                                        labelExtraclass='tw-p-1.5'
                                        icon={<icons.AiOutlinePlus />}
                                    // loading={downloadStatus === 'loading' ? true : false}
                                    >
                                        <FilterCard
                                            showFilter={showUploadOptions}
                                            title="Add New"
                                            onClick={() => null}
                                            showSubmitButton={false}
                                            extraClass="tw-w-[10rem] tw-top-10 -tw-left-8">
                                            <div className="tw-flex tw-flex-col tw-mt-4">
                                                <span
                                                    className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                                                    onClick={() => {
                                                        setShowAddEventModal(true)
                                                        setShowUploadOptions(false)
                                                    }}>
                                                    Single Event Input
                                                </span>
                                                <span
                                                    className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                                                    onClick={() => setShowUploadEventFileModal(true)}>
                                                    Bulk Upload .csv
                                                </span>
                                            </div>
                                        </FilterCard>
                                    </ExportLabel>
                                </div>
                            </div>
                            <section className='tw-mt-8'>
                                <div className="tw-grid tw-gap-4 tw-grid-cols-2 xl:tw-grid-cols-4 md:tw-gap-6">
                                    {eventCashRequestTabs?.map(({ id, helper, title, total, subtitle }) => (
                                        <SingleCountCard
                                            key={id}
                                            id={id}
                                            title={title}
                                            total={total}
                                            subtitle={subtitle}
                                            active={activeTab === helper ? true : false}
                                            onClick={() => {
                                                // if (kycsAnalyticsStatus !== 'loading' && kycsStatus !== 'loading') {
                                                setActiveTabHandler(helper);
                                                // }
                                            }}
                                            titleClass='tw-font-medium'
                                        // loading={kycsAnalyticsStatus === 'loading'}
                                        />
                                    ))}
                                </div>
                            </section>
                            <section className="tw-mt-8 tw-h-[5rem] md:tw-mt-12">
                                <div className="tw-my-3 tw-flex tw-flex-col tw-justify-between md:tw-flex-row md:tw-items-center">
                                    <p className="tw-flex tw-items-center tw-gap-x-2 tw-text-sm tw-text-[#222B88] tw-font-[400] tw-order-1 md:-tw-order-1">
                                        <span>
                                            {
                                                activeTab === 'Total Request' ? 'All Requests' :
                                                    activeTab === 'Transaction Value' ? 'All Transactions' :
                                                        activeTab === 'Commissions' ? 'Commissions' :
                                                            activeTab === 'Event Centre' ? 'Registered Vendors' : 'Avalaible Records'
                                            }
                                        </span>
                                    </p>
                                    <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end lg:tw-flex-row lg:tw-gap-x-3 md:tw-items-center">
                                        <div className="tw-relative tw-w-full md:tw-mr-4">
                                            <input
                                                type="text"
                                                name="reference"
                                                id="reference"
                                                // value={filterOptions.reference}
                                                // onChange={handleInputChange}
                                                className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-sm tw-text-isPrimary placeholder:tw-text-xs placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                                                placeholder="Search by reference"
                                                style={{ backgroundColor: 'transparent' }}
                                            />
                                            <span
                                                className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                                            // onClick={handleFetchCashRequest}
                                            >
                                                <icons.FaSearch />
                                            </span>
                                        </div>
                                        <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
                                            <FilterLabel
                                                title="Filter Options"
                                                icon={<icons.BsFilter />}
                                                onClick={() => setShowFilterOptions(true)}>
                                                <FilterCard
                                                    showFilter={showFilterOptions}
                                                    title="Filter Options"
                                                    onClick={() => null}
                                                    extraClass="tw-w-[13rem]">
                                                    <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                                                        <ZojaSelect
                                                            options={[
                                                                { value: '', label: 'Status' },
                                                                { value: 'processing', label: 'Activated' },
                                                                { value: 'pending', label: 'Pending' },
                                                            ]}
                                                            name="status"
                                                            showArrown={true}
                                                            setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                                                            extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                                                            arrowDownClass="tw-right-lg tw-top-3.5"
                                                        />
                                                        <FilterColapsible
                                                            title="Amount"
                                                            show={showAmountRange}
                                                            onClick={() => setShowAmountRange(!showAmountRange)}>
                                                            <>
                                                                <ZojaInput
                                                                    label="Min Amount"
                                                                    id="min_amount"
                                                                    type="number"
                                                                    extraClass="tw-text-xs tw-p-2 tw-mb-2"
                                                                    name="min_amount"
                                                                    value={filterOptions.min_amount}
                                                                    onChange={handleInputChange}
                                                                />
                                                                <ZojaInput
                                                                    label="Max Amount"
                                                                    id="max_amount"
                                                                    name="max_amount"
                                                                    type="number"
                                                                    extraClass="tw-text-xs tw-p-2 tw-mb-2"
                                                                    value={filterOptions.max_amount}
                                                                    onChange={handleInputChange}
                                                                />
                                                            </>
                                                        </FilterColapsible>
                                                    </div>
                                                </FilterCard>
                                            </FilterLabel>

                                            {/* <FilterLabel
                                                title="Sort By"
                                                icon={<icons.TbArrowsSort color="#5E6366" />}
                                                onClick={() => setShowSortBy(true)}>
                                                <FilterCard
                                                    showFilter={showSortBy}
                                                    title="Sort by"
                                                    onClick={() =>null}
                                                    submitButtonText="Apply"
                                                    extraClass="tw-w-[10rem] -tw-left-28">
                                                    <>
                                                        <div className="tw-pt-2">
                                                            {sortBy.map((item: any) => (
                                                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                                                    <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                                                    <ZojaCheckbox
                                                                        isChecked={item.isChecked}
                                                                        onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                        <div className="tw-pt-2 tw-border-">
                                                            {orderBy.map((item: any) => (
                                                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                                                    <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                                                    <ZojaCheckbox
                                                                        isChecked={item.isChecked}
                                                                        onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </>
                                                </FilterCard>
                                            </FilterLabel> */}

                                            <ExportLabel
                                                title="Export"
                                                onClick={() => setShowExportOptions(true)}
                                            // loading={downloadStatus === 'loading' ? true : false}
                                            >
                                                <FilterCard
                                                    showFilter={showExportOptions}
                                                    title="Export as"
                                                    onClick={() => null}
                                                    showSubmitButton={false}
                                                    extraClass="tw-w-[10rem] -tw-left-28">
                                                    <div className="tw-flex tw-flex-col tw-mt-4">
                                                        <span
                                                            className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                                                            onClick={() => handleExportFile('pdf')}>
                                                            PDF
                                                        </span>
                                                        <span
                                                            className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                                                            onClick={() => handleExportFile('csv')}>
                                                            CSV
                                                        </span>
                                                    </div>
                                                </FilterCard>
                                            </ExportLabel>
                                        </div>
                                    </div>
                                </div>
                                {/* {status !== 'loading' && status === 'succeeded' && ( */}
                                <>
                                    {activeTab === 'Total Request' &&
                                        <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28">
                                            <ZojaTable column={EventCashRequestTableData.columns}>
                                                {EventCashRequestTableData.data?.map((data: Dictionary, idx: number) => (
                                                    <TR key={data.id}>
                                                        <TD value={idx + 1} />
                                                        <TD value={data.recipient} extraClass="tw-font-thin" />
                                                        <TD value={data.reference} extraClass="tw-font-thin" />
                                                        <TD value={data.delivery_location} extraClass="tw-font-thin" />
                                                        <TD
                                                            value={data.status}
                                                            extraClass={`${data.status === 'pending'
                                                                ? 'tw-font-thin tw-text-orange-400'
                                                                : data.status === 'processing'
                                                                    ? 'tw-font-thin tw-text-green-500'
                                                                    : 'tw-font-thin'
                                                                }`}
                                                        />
                                                        <TD
                                                            value={data.provider || '-'}
                                                            extraClass={`${!data.cash_provider && 'tw-text-center tw-font-thin'}`}
                                                        />
                                                        <TD value={dateTimeFormat3(data.created_at)} extraClass="tw-font-thin" />
                                                    </TR>
                                                ))}
                                            </ZojaTable>
                                        </div>
                                    }
                                </>
                                {/* )} */}

                                {/* Transaction Table */}
                                {/* {status !== 'loading' && status === 'succeeded' && ( */}
                                <>
                                    {activeTab === 'Transaction Value' &&
                                        <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28">
                                            <ZojaTable column={EventTransactionTableData.columns}>
                                                {EventTransactionTableData.data?.map((data: Dictionary, idx: number) => (
                                                    <TR key={data.id}>
                                                        <TD value={idx + 1} />
                                                        <TD value={data.customer_name} extraClass="tw-font-thin" />
                                                        <TD value={data.agent_name} extraClass="tw-font-thin" />
                                                        <TD value={data.event_title} extraClass="tw-font-thin" />
                                                        <TD value={data.amount} extraClass="tw-font-thin" />
                                                        <TD value={data.charge} extraClass="tw-font-thin" />
                                                        <TD
                                                            value={data.status}
                                                            extraClass={`${data.status === 'pending'
                                                                ? 'tw-font-thin tw-text-orange-400'
                                                                : data.status === 'processing'
                                                                    ? 'tw-font-thin tw-text-green-500'
                                                                    : 'tw-font-thin'
                                                                }`}
                                                        />
                                                        <TD value={data.created_at} extraClass="tw-font-thin" />
                                                        {/* <TD value={dateTimeFormat3(data.created_at)} extraClass="tw-font-thin" /> */}
                                                    </TR>
                                                ))}
                                            </ZojaTable>
                                        </div>
                                    }
                                </>
                                {/* )} */}

                                {/* Commissions Table */}
                                {/* {status !== 'loading' && status === 'succeeded' && ( */}
                                <>
                                    {activeTab === 'Commissions' &&
                                        <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28">
                                            <ZojaTable column={EventCommisionTableData.columns}>
                                                {EventCommisionTableData.data?.map((data: Dictionary, idx: number) => (
                                                    <TR key={data.id}>
                                                        <TD value={idx + 1} />
                                                        <TD value={data.agent_name} extraClass="tw-font-thin" />
                                                        <TD value={data.event_centre} extraClass="tw-font-thin" />
                                                        <TD value={data.level} extraClass="tw-font-thin" />
                                                        <TD value={data.charge} extraClass="tw-font-thin" />
                                                        <TD value={data.commision} extraClass="tw-font-thin" />
                                                        <TD value={data.created_at} extraClass="tw-font-thin" />
                                                        {/* <TD value={dateTimeFormat3(data.created_at)} extraClass="tw-font-thin" /> */}
                                                    </TR>
                                                ))}
                                            </ZojaTable>
                                        </div>
                                    }
                                </>
                                {/* )} */}

                                {/* Event Centre Table */}
                                {/* {status !== 'loading' && status === 'succeeded' && ( */}
                                <>
                                    {activeTab === 'Event Centre' &&
                                        <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28">
                                            <ZojaTable
                                                column={EventCenterTableData.columns}
                                            >
                                                {EventCenterTableData.data?.map((data: Dictionary, idx: number) => (
                                                    <TR key={data.id}>
                                                        <TD value={idx + 1} />
                                                        <TD value={data.event_centre} extraClass="tw-font-thin" />
                                                        <TD value={data.location} extraClass="tw-font-thin" />
                                                        <TD value={data.capacity} extraClass="tw-font-thin" />
                                                        <TD value={data.total} extraClass="tw-font-thin" />
                                                        <TD value={data.level} extraClass="tw-font-thin" />
                                                        <TD value={data.total_transaction} extraClass="tw-font-thin" />
                                                        <TD>
                                                            <div onClick={() => setOpenMore(!true)}>
                                                                {/* <BsThreeDotsVertical /> */}
                                                                <TDMORE
                                                                    handleClose={() => setOpenMore(false)}
                                                                    dropdownList={dropdownList}
                                                                    onActionClick={item => handleMoreIconOptions(item)}
                                                                    setSelectedItem={setSelectedRegisteredVendor}
                                                                />
                                                            </div>
                                                        </TD>

                                                        {openMore ? <div className="tw-bg-white">dropdown</div> : <></>}
                                                    </TR>
                                                ))}
                                            </ZojaTable>
                                        </div>
                                    }
                                </>
                                {/* )} */}

                                <div className="tw-pb-2 md:tw-pb-8">
                                    {/* {data?.cash_requests?.data?.length > 0 && (
                                        <Pagination
                                            isLoading={status === 'loading' ? true : false}
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={selectedPage => {
                                                setCurrentPage(selectedPage);
                                                let currentPage = selectedPage;
                                                let filterBy = { ...filterOptions, page: currentPage };
                                                console.log(filterBy);
                                                dispatch(getCashRequest(filterBy));
                                            }}
                                        />
                                    )} */}
                                </div>
                            </section>
                        </div>

                        {/* {status === 'loading' && (
                            <>
                                <div className="tw-h-[20vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                                    <Oval
                                        height="80"
                                        width="80"
                                        color="#222b88cf"
                                        ariaLabel="tail-spin-loading"
                                        secondaryColor="#222b882b"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                    />
                                </div>
                            </>
                        )} */}

                        {/* {status === 'failed' && (
                            <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                                <h4 className="tw-text-gray-500 tw-text-xl">No Record Found</h4>
                            </div>
                        )} */}
                    </section>
                </>
            </AppContainer>
        </>
    )
}

export default EventCashRequest