import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadDashboardTransactionStats = createAsyncThunk(
  'downloadDashboardTransactionStats',
  async (payload: Dictionary, { dispatch }) => {
    const { type, date_range, exp } = payload;
    const url = `/admin/dashboard/transaction-stats?type=${type}&date_range=${date_range}&export=${exp}`;
    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `transactionStats.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadDashboardTransactionStatsSlice = createSlice({
  name: 'downloadDashboardTransactionStats',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadDashboardTransactionStats.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadDashboardTransactionStats.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadDashboardTransactionStats.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadDashboardTransactionStatsReset = downloadDashboardTransactionStatsSlice.actions.reset;
export const downloadDashboardTransactionStatsSliceReducer = downloadDashboardTransactionStatsSlice.reducer;