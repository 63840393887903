import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as EmptyStateImage } from '../../assets/svg/emptyStateNote.svg';
import { ActivityActionModal, AppContainer } from '../../atoms';
import {
  AdminRequestTable,
  CustomDatePicker,
  DownloadSuccessModal,
  EmptyState,
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  NavTab,
  Pagination,
  RejectAdminRequest,
  ReviewDepartmentModal,
  ReviewRoleModal,
  ReviewUserModal,
  ZojaCheckbox,
} from '../../components';
import { ZojaSelect } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  downloadRequestRecord,
  downloadRequestRecordReset,
  getAdminRequest,
  reviewAdminRequestReset,
} from '../../redux/slice';
import { Dictionary } from '../../types';
import {
  formatStartAndEndDateWithDelimeter,
  getSelectedOptionValue,
  handleSelectedOption,
  images,
  useSortTable,
} from '../../utils';
import icons from '../../utils/icons';
import { UserPageTabs, adminRequestTableHeader } from './data';
import { toast } from 'react-toastify';

const initialValues: Dictionary = {
  status: '',
  sort_by: '',
  order_by: '',
  date_range: '',
  search: '',
};

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
  ],
};

const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'today',
    title: 'Today',
    isChecked: false,
  },
  {
    id: 2,
    value: 'yesterday',
    title: 'Yesterday',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 4,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 5,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 6,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

export default function AdminRequest() {
  const [activePageTab, setActivePageTab] = useState('internal_users');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [selectedRowItem, setSelectedRowItem] = useState<Dictionary>({});
  const [showSortBy, setShowSortBy] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [dateRange, setDateRange] = useState(memberAndLastSeenOptions);
  const [dateRangeValues, setDateRangeValues] = useState({ state_date: '', end_date: '' });
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [totalPages, setTotalPages] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedOption, setSelectedOption] = useState<any>({});
  const [showReviewDepartmentModal, setShowReviewDepartmentModal] = useState(false);
  const [showReviewUserModal, setShowReviewUserModal] = useState(false);
  const [showReviewRoleModal, setShowReviewRoleModal] = useState(false);
  const [showRejectRequestModal, setShowRejectRequestModal] = useState(false);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    per_page: perPage,
    page: currentPage,
    status: '',
    sort_by: '',
    order_by: '',
    date_range: '',
    search: '',
  });

  const reviewAdminRequestState = useAppSelector(state => state.reviewAdminRequest);
  const adminRequest = useAppSelector(state => state.getAdminRequest);
  const downloadRequest = useAppSelector(state => state.downloadRequestRecord);
  const [adminRequestData, setAdminRequestData] = useState<any[]>(adminRequest.data?.requests?.data);

  const [order, setOrder] = useState('ASC');

  const { handleSortItems } = useSortTable({
    order: order,
    setOrder: setOrder,
    data: adminRequestData,
    setData: setAdminRequestData,
  });

  const dispatch = useAppDispatch();

  const successModalRef = useRef<any>(null);

  const handleCurrentPageChange = (newCurrentPage: number) => {
    setCurrentPage(newCurrentPage);
    setFilterOptions({ ...filterOptions, page: newCurrentPage });
    dispatch(getAdminRequest({ ...filterOptions, page: newCurrentPage }));
  };

  const handlePageSizeChange = (newPageSize: any) => {
    setPerPage(newPageSize);
    setCurrentPage(1);
    setFilterOptions({ ...filterOptions, per_page: newPageSize, page: 1 });

    dispatch(
      getAdminRequest({
        ...filterOptions,
        per_page: newPageSize,
        page: 1,
      }),
    );
  };

  const handleMoreIconOptions = (item: any) => {
    setSelectedOption(item);
    if (item.value === 'dept_request_details') {
      setShowReviewDepartmentModal(true);
    }
    if (item.value === 'user_request_details') {
      setShowReviewUserModal(true);
    }
    if (item.value === 'role_request_details') {
      setShowReviewRoleModal(true);
    }
    if (
      item.value === 'reject_role_request' ||
      item.value === 'reject_user_request' ||
      item.value === 'reject_department_request'
    ) {
      setShowRejectRequestModal(true);
    }
  };

  // filter table handler

  const handleSubmitNewRequestFilter = () => {
    const custom_date_range = formatStartAndEndDateWithDelimeter(dateRangeValues.state_date, dateRangeValues.end_date);

    let payload = { ...filterOptions };
    payload = payload.date_range === 'custom' ? { ...payload, date_range: custom_date_range } : payload;
    dispatch(getAdminRequest({ ...payload, search: '', per_page: perPage, page: currentPage }));
  };

  useEffect(() => {
    if (adminRequest.data?.requests?.data?.length > 0) {
      const { last_page, current_page, per_page } = adminRequest?.data?.requests?.meta;
      const getDateRangevalue = getSelectedOptionValue(dateRange);
      const getSelectedSortBy = getSelectedOptionValue(sortBy);
      const getSelectedOrderBy = getSelectedOptionValue(orderBy);

      let date_data = getDateRangevalue;

      setTotalPages(last_page);
      setCurrentPage(current_page);
      setPerPage(per_page);
      setFilterOptions({
        ...filterOptions,
        date_range: date_data,
        sort_by: getSelectedSortBy,
        order_by: getSelectedOrderBy,
        page: current_page,
        per_page: per_page,
      });
    }

    if (adminRequest.status === 'succeeded') {
      let adminRequestData: any[] = [];

      adminRequest.data?.requests?.data?.forEach((item: Dictionary, index: number) => {
        adminRequestData.push(item);
      });

      setAdminRequestData(adminRequestData);
    }
  }, [adminRequest, dateRange, orderBy, sortBy]);

  useEffect(() => {
    dispatch(getAdminRequest({}));
  }, []);

  useEffect(() => {
    if (reviewAdminRequestState.status === 'succeeded') {
      dispatch(getAdminRequest({ ...filterOptions }));
      // dispatch(reviewAdminRequestReset())
    }
  }, [reviewAdminRequestState.status, sortBy, orderBy]);

  useEffect(() => {
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
  }, [adminRequest.status]);

  // filter and export methods
  const handleExportRequestFile = (type: string) => {
    let exportPayload = { ...filterOptions, export: type, search: '' };
    dispatch(downloadRequestRecord(exportPayload));
    setShowExportOptions(false);
  };

  // show download success modal
  useEffect(() => {
    if (downloadRequest.status === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadRequest.status]);

  return (
    <AppContainer
      navTitle={
        <div className="tw-flex tw-gap- tw-items-center">
          <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">ADMIN REQUEST</span>
        </div>
      }>
      <>
        <ActivityActionModal
          isModalVisible={reviewAdminRequestState.status === 'succeeded'}
          closeModal={() => dispatch(reviewAdminRequestReset())}
          title={'You have successfully approved this request'}
          actionText="Close"
          image={images.check}
          actionClick={() => dispatch(reviewAdminRequestReset())}
          isLoading={false}
        />
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            // dispatch(downloadUsersRecordReset());
            dispatch(downloadRequestRecordReset());
            setShowSuccessDownloadModal(false);
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          content="Your document has been downloaded to your device"
        />
        <section className="tw-p-2 tw-mb-28 tw-mt-4 md:tw-mt-7">
          <NavTab navList={UserPageTabs} selected={activePageTab} setSelected={setActivePageTab} />
          {(showFilterOptions || showSortBy || showExportOptions) && (
            <div
              className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen tw-top-0 tw-left-0"
              onClick={() => {
                setShowFilterOptions(false);
                setShowSortBy(false);
                setShowExportOptions(false);
              }}></div>
          )}

          <section className="tw-my-16 tw-flex tw-flex-co lg:tw-flex-row tw-items-start tw-justify-between lg:tw-items-baseline tw-gap-y-2">
            <div className="tw-relative tw-w-fit md:tw-mr-4">
              <input
                type="text"
                name="reference"
                id="reference"
                value={''}
                onChange={e => null}
                className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-[.85rem] tw-text-isPrimary placeholder:tw-text-[.85rem] placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                placeholder="Search records"
                style={{ backgroundColor: 'transparent' }}
              />
              <span
                className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                onClick={() => null}>
                <icons.FaSearch />
              </span>
            </div>
            <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
              <FilterLabel title="Filter Options" icon={<icons.BsFilter />} onClick={() => setShowFilterOptions(true)}>
                {/* <span className='tw-absolute tw-hidden'>here</span> */}
                <FilterCard
                  showFilter={showFilterOptions}
                  title="Filter Options"
                  onClick={() => handleSubmitNewRequestFilter()}
                  extraClass="tw-w-[13rem]">
                  <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                    <ZojaSelect
                      options={[
                        { value: '', label: 'Status' },
                        { value: 'approved', label: 'approved' },
                        { value: 'rejected', label: 'rejected' },
                        { value: 'pending', label: 'pending' },
                      ]}
                      name="status"
                      showArrown={true}
                      setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                      extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                      arrowDownClass="tw-right-lg tw-top-3.5"
                    />
                    <FilterColapsible
                      title="Date Range"
                      show={showDateRange}
                      onClick={() => setShowDateRange(!showDateRange)}
                      extraClass="tw-shadow-none">
                      <>
                        <div className="">
                          {dateRange.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, dateRange, setDateRange)}
                              />
                            </div>
                          ))}
                        </div>
                        <div
                          className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                            filterOptions.date_range === 'custom' && 'tw-max-h-fit'
                          }`}>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="State Date"
                              value={new Date()}
                              setValue={value => setDateRangeValues({ ...dateRangeValues, state_date: value })}
                            />
                          </div>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="End Date"
                              value={new Date()}
                              setValue={value => setDateRangeValues({ ...dateRangeValues, end_date: value })}
                            />
                          </div>
                        </div>
                      </>
                    </FilterColapsible>
                  </div>
                </FilterCard>
              </FilterLabel>

              <FilterLabel
                title="Sort By"
                icon={<icons.TbArrowsSort color="#5E6366" />}
                onClick={() => setShowSortBy(true)}>
                <FilterCard
                  showFilter={showSortBy}
                  title="Sort by"
                  onClick={() => handleSubmitNewRequestFilter()}
                  submitButtonText="Apply"
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <>
                    <div className="tw-pt-2">
                      {sortBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="tw-pt-2 tw-border-">
                      {orderBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </FilterCard>
              </FilterLabel>

              <ExportLabel
                title="Export"
                onClick={() => setShowExportOptions(true)}
                loading={downloadRequest.status === 'loading' ? true : false}>
                <FilterCard
                  showFilter={showExportOptions}
                  title="Export as"
                  onClick={() => null}
                  showSubmitButton={false}
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <div className="tw-flex tw-flex-col tw-mt-4">
                    <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                      onClick={() => handleExportRequestFile('csv')}>
                      CSV
                    </span>
                  </div>
                </FilterCard>
              </ExportLabel>
            </div>
          </section>

          <>
            {adminRequest.status === 'loading' ? (
              <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                <Oval
                  height="60"
                  width="60"
                  color="#222b88cf"
                  ariaLabel="tail-spin-loading"
                  secondaryColor="#222b882b"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : adminRequest.status === 'succeeded' ? (
              <AdminRequestTable
                headerData={adminRequestTableHeader}
                data={adminRequestData}
                sorting={handleSortItems}
                order={order}
                selectedRowItem={selectedRowItem}
                selectedOption={selectedOption}
                setSelectedItem={setSelectedRowItem}
                handleMoreIconOptions={handleMoreIconOptions}
              />
            ) : (
              <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
                <EmptyState
                  img={<EmptyStateImage width={200} height={200} />}
                  description="No Record Found"
                  btnText="Reset Data"
                  btnBackground="tw-bg-isPrimary"
                  showBtn={false}
                />
              </div>
            )}
          </>

          <>
            <div className="tw-pb-2 tw-mt-12 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
              {/* PAGINATION */}
              {adminRequest.status === 'failed' ? (
                <></>
              ) : (
                adminRequest.data?.requests?.data.length > 0 && (
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={selectedPage => {
                      handleCurrentPageChange(selectedPage);
                    }}
                    isLoading={adminRequest.status === 'loading'}
                  />
                )
              )}

              {/* PAGE SIZE CONTROL FOR SUPER AGENT USERS */}
              {adminRequest.status === 'loading' || adminRequest.status === 'failed' ? (
                <></>
              ) : (
                adminRequest?.data?.requests?.data?.length > 0 && (
                  <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                    <span className="tw-text-xs tw-w-16">Per page</span>
                    <ZojaSelect
                      options={[
                        { label: '10', value: '10' },
                        { label: '20', value: '20' },
                        { label: '50', value: '50' },
                        { label: '100', value: '100' },
                        { label: '200', value: '200' },
                      ]}
                      value={`${perPage}`}
                      setSelectedOption={selected => handlePageSizeChange(selected)}
                      extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                    />
                  </div>
                )
              )}
            </div>
          </>
        </section>
        <ReviewDepartmentModal
          show={showReviewDepartmentModal}
          handleClose={() => setShowReviewDepartmentModal(false)}
          departmentDetails={selectedRowItem}
          setShowRejectRequestModal={setShowRejectRequestModal}
        />
        <ReviewUserModal
          show={showReviewUserModal}
          handleClose={() => setShowReviewUserModal(false)}
          userDetails={selectedRowItem}
          setShowRejectRequestModal={setShowRejectRequestModal}
        />
        <ReviewRoleModal
          show={showReviewRoleModal}
          handleClose={() => setShowReviewRoleModal(false)}
          roleDetails={selectedRowItem}
          setShowRejectRequestModal={setShowRejectRequestModal}
        />
        <RejectAdminRequest
          show={showRejectRequestModal}
          handleClose={() => setShowRejectRequestModal(false)}
          selectedData={selectedRowItem}
        />
      </>
    </AppContainer>
  );
}
