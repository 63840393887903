import { RxCaretLeft, RxCaretRight } from 'react-icons/rx';
import { useState } from 'react';

const SimplePagination = ({ itemsPerPage, totalItems, currentPage, setCurrentPage }: any) => {
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + Number(itemsPerPage), totalItems);

  // Generate a sample array of items to simulate your data
  const items = Array.from({ length: totalItems }, (_, index) => `Item ${index + 1}`);

  const nextPage = () => {
    if (currentPage < Math.ceil(totalItems / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div className="tw-justify-start tw-items-center tw-gap-[13px] tw-flex">
        <div className="tw-text-gray-500 tw-text-sm tw-font-normal">
          {startIndex + 1 || '-'} to {endIndex || '-'} of {totalItems}
        </div>
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className="tw-cursor-pointer tw-bg-gray-400 tw-rounded tw-justify-center tw-items-center tw-flex">
          <RxCaretLeft size={30} color="#fff" />
        </button>
        <button
          onClick={nextPage}
          disabled={currentPage === Math.ceil(totalItems / itemsPerPage)}
          className="tw-cursor-pointer tw-bg-gray-400 tw-rounded tw-justify-center tw-items-center tw-flex">
          <RxCaretRight size={30} color="#fff" />
        </button>
      </div>
    </div>
  );
};

export default SimplePagination;
