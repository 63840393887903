import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllUserRegReportRequest = createAsyncThunk(
  'getAllUserRegReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      min_age,
      max_age,
      sort_by,
      order_by,
      exp,
      per_page,
      page,
      start_date,
      end_date,
      device_type,
      source_of_reg,
      user_status,
      user_level,
      user_feedback,
      account_verification_status,
      gender,
      location,
    } = payload;
    const url = `admin/report/general/user-registration-and-growth`;

    try {
      const response = await api.get(`${url}`, {
        params: {
          'age_range[min]': min_age,
          'age_range[max]': max_age,
          sort_by,
          order_by,
          export: exp,
          per_page,
          page,
          'date_onboarded[start_date]': start_date,
          'date_onboarded[end_date]': end_date,
          device_type,
          source_of_reg,
          user_status,
          user_level,
          user_feedback,
          account_verification_status,
          gender,
          location,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllUserRegReportSlice = createSlice({
  name: 'getAllUserRegReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllUserRegReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllUserRegReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllUserRegReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllUserRegReportReset = getAllUserRegReportSlice.actions.reset;
export const getAllUserRegReportSliceReducer = getAllUserRegReportSlice.reducer;
