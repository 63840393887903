import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {},
};

const customerTransactionsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCustomerTransactions.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getCustomerTransactions.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getCustomerTransactions.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getCustomerTransactions = createAsyncThunk('getCustomerTransactions', async (payload: Dictionary) => {
  const { account_id_phone_number, page, per_page, date_range } = payload;
  const url = `/admin/restrictions/account-transactions?account_number=${account_id_phone_number}&page=${page}&per_page=${per_page}&date_range=${date_range}`;

  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const customerTransactionsSliceReducer = customerTransactionsSlice.reducer;
