import styled from 'styled-components';
import { spacing } from '../../utils';

export const UserContainer = styled.div`
  padding-top: ${spacing.small};
  @media (max-width: 768px) {
    /* grid-template-columns: 100% 100%; */
  }
`;

export const UsersContainer = styled.div`
  margin-top: ${spacing.small};
  @media (max-width: 768px) {
    /* grid-template-columns: 100% 100%; */
  }
`;

export const SearchContainer = styled.div`
  // max-width: 16.313em;
  max-width: 20.313em;
  margin-bottom: ${spacing.large};
  margin-top: ${spacing.large};
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableContainer = styled.div`
  margin-bottom: ${spacing.small};
`;
