export type modalTabType = {
  id: string | number;
  title: string;
};

interface ModalIndicatorProps {
  tabList: {
    id: string | number;
    title: string;
  }[];
  selected: {
    id: string | number;
    title: string;
  };
  setSelected: React.Dispatch<
    React.SetStateAction<{
      id: string | number;
      title: string;
    }>
  >;
}

const ModalTabIndicator = ({ tabList, selected, setSelected }: ModalIndicatorProps) => {
  return (
    <>
      <div className="tw-flex tw-items-center tw-gap-x-4 tw-overflow-x-auto md:tw-gap-x-8">
        {tabList.map(item => (
          <div
            key={item.id}
            className={`tw-block tw-cursor-pointer tw-min-w-fit tw-text-xl tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] md:tw-overflow-auto ${
              selected.title === item.title
                ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                : 'tw-text-[#6A616F]'
            }`}
            onClick={() => setSelected(item)}>
            {item.title}
          </div>
        ))}
      </div>
    </>
  );
};

export default ModalTabIndicator;
