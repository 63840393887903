import { AppContainer } from "../../atoms";
function Support() {
  return (
    <AppContainer navTitle='SUPPORT'>
      <div>
        <h1>Support</h1>
        <h1>Support</h1>
        <h1>Support</h1>
      </div>
    </AppContainer>
  );
}

export default Support;
