import moment from 'moment';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { EmptyState, ZojaTable, ZojalActionModal } from '../..';
import { ReactComponent as EmptyStateNote } from '../../../assets/svg/emptyStateNote.svg';
import { cn } from '../../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../../redux/redux-hooks';
import { reviewAdminRequest } from '../../../redux/slice';
import { Dictionary } from '../../../types';
import { ZojaButton } from '../../tailwind';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import { TD, TR } from '../../tailwind/table/tableElements';

export interface TableIPropsIProps {
  data: Dictionary[];
  headerData: Dictionary[];
  sorting?: any;
  order?: string;
  selectedRowItem: any;
  setSelectedItem: (arg: any) => void;
  selectedOption: any;
  handleMoreIconOptions: (item: any) => void;
}

const AdminRequestTable = ({ data, headerData, sorting, order, selectedRowItem, selectedOption, setSelectedItem, handleMoreIconOptions }: TableIPropsIProps) => {

  const reviewAdminRequestState = useAppSelector(state => state.reviewAdminRequest);

  const dispatch = useAppDispatch()

  const [showPromptApprove, setShowPromptApprove] = useState(false)

  const handleOnSelect = (item: any) => {
    setSelectedItem(item);
  };

  const roleDropdownList = [
    {
      id: 1,
      title: 'Role Request Details',
      value: 'role_request_details',
    },
    {
      id: 2,
      title: 'Approve Request',
      value: 'approve_role_request',
    },
    {
      id: 3,
      title: 'Reject Request',
      value: 'reject_role_request',
    },
  ];

  const userDropdownList = [
    {
      id: 1,
      title: 'User Request Details',
      value: 'user_request_details',
    },
    {
      id: 2,
      title: 'Approve Request',
      value: 'approve_user_request',
    },
    {
      id: 3,
      title: 'Reject Request',
      value: 'reject_user_request',
    },
  ];

  const departmentDropdownList = [
    {
      id: 1,
      title: 'Dept Request Details',
      value: 'dept_request_details',
    },
    {
      id: 2,
      title: 'Approve Request',
      value: 'approve_department_request',
    },
    {
      id: 3,
      title: 'Reject Request',
      value: 'reject_department_request',
    },
  ];

  const handleApproveRequest = () => {
    dispatch(reviewAdminRequest({ id: selectedRowItem?.id, data: { status: "approved" } }))
  }

  return (
    <>
      {data?.length >= 1 ? (
        <ZojaTable column={headerData} sorting={sorting} order={order} thClass="tw-text-isBlack" tableHeight={`fit`} hasActionButton>
          <>
            {data?.map((item: any, idx) => (
              <TR key={item.id}>
                <TD value={idx + 1} />
                <TD value={item.request_type} />
                <TD value={item.title || 'N/A'} />
                <TD extraClass={cn(
                  'tw-text-green-500',
                  item?.status === 'pending' && 'tw-text-orange-300',
                  item?.status === 'rejected' && 'tw-text-red-500',
                )} value={item?.status || 'N/A'} />
                <TD value={item.created_by || 'N/A'} />
                <TD value={moment(item.created_at).format('DD-MMM-YYYY - hh:mm a')} />

                <TD>
                  <div onClick={() => handleOnSelect(item)}>
                    <TDMORE
                      dropdownList={
                        item?.request_type === 'User' ? userDropdownList :
                          item?.request_type === 'Role' ? roleDropdownList :
                            item?.request_type === 'Department' ? departmentDropdownList : []

                      }
                      onActionClick={(item: any) => {
                        if ((item?.value === "approve_department_request"
                          || item?.value === "approve_role_request"
                          || item?.value === "approve_user_request")
                        ) {
                          if (selectedRowItem?.status === "approved") {
                            toast.info('Account has already been approved')
                            return
                          }
                          if (selectedRowItem?.status === "rejected") {
                            toast.info('Account has already been rejected')
                            return
                          }
                          setShowPromptApprove(true)
                        }
                        if ((item?.value === "reject_department_request"
                          || item?.value === "reject_role_request"
                          || item?.value === "reject_user_request")
                        ) {
                          if (selectedRowItem?.status === "approved") {
                            toast.info('Account has already been approved')
                            return
                          }
                          if (selectedRowItem?.status === "rejected") {
                            toast.info('Account has already been rejected')
                            return
                          }
                        }
                        handleMoreIconOptions(item)
                      }}
                      setSelectedItem={setSelectedItem}
                    />
                  </div>
                </TD>
              </TR>
            ))}
          </>
        </ZojaTable>
      ) : (
        <div className="tw-flex tw-flex-col tw-justify-center tw-items-center">
          <EmptyState
            img={<EmptyStateNote width={200} height={200} />}
            description="No Record Found"
            btnText="Reset Data"
            btnBackground="tw-bg-isPrimary"
            showBtn={false}
          />
        </div>
      )}

      <ZojalActionModal
        show={showPromptApprove}
        handleClose={() => { setShowPromptApprove(false) }}
        // actionIcon={images.question}
        imgClass='tw-w-16'
        children={<>
          <h5 className='tw-mt-4'>Confirm Aprroval</h5>
          <div className='tw-my-2'>
            <span className='tw-text-sm tw-block tw-my-3'>Are you sure you want to approve this request?</span>
            <div className="tw-mt-6 tw-flex tw-items-center tw-justify-center tw-gap-6">
              <ZojaButton
                text='Approve'
                onClick={handleApproveRequest}
                loading={reviewAdminRequestState.status === 'loading'}
                disabled={reviewAdminRequestState.status === 'loading'}
                loadingText='Approve'
                extraClass='tw-bg-isPrimary tw-font-thin tw-px-8 tw-text-white tw-p-1 tw-rounded-md tw-text-[.9rem]'
              />
              <ZojaButton
                text='Cancel'
                onClick={() => setShowPromptApprove(false)}
                extraClass='tw-bg-background tw-border tw-font-thin tw-px-8 tw-text-gray-600 tw-p-1 tw-rounded-md tw-text-[.9rem]'
              />
            </div>
          </div>
        </>}
      />
    </>
  );
}

export default AdminRequestTable;
