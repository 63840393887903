import React from 'react';
import { ZojaButton, ZojaModal } from '../tailwind';
import { images } from '../../utils';

interface IProps {
  title: string;
  content: string;
  type?: string;
  show: boolean;
  handleClose: () => void;
  contentRef: HTMLDivElement | any;
  closeOnClickOutside?: boolean;
  handleShowSaveReport?: () => void;
}

const DownloadSuccessModal = ({
  title,
  content,
  show,
  contentRef,
  handleClose,
  handleShowSaveReport,
  type,
  closeOnClickOutside = false,
}: IProps) => {
  return (
    <ZojaModal
      show={show}
      height="auto"
      position="center"
      borderRadius="12px"
      handleClose={handleClose}
      closeOnClickOutside={closeOnClickOutside}
      extraClass="md:tw-w-[33.5rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      contentRef={contentRef}
      children={
        <div className="rounded-lg tw-py-8" ref={contentRef}>
          <img src={images.downloadSuccessIcon} className="tw-w-[30%] tw-mx-auto" alt="download-icon" />
          <h1 className="tw-text-[#323348] tw-text-center tw-text-[18px] md:tw-text-xl">{title}</h1>
          <p className="tw-text-[#5E6366] tw-text-center tw-my-6">{content}</p>
          <div className="tw-flex tw-justify-center tw-mb-8">
            <ZojaButton
              text="Finish"
              onClick={handleClose}
              extraClass="tw-p-2 tw-bg-isPrimary tw-cursor-pointer tw-text-white tw-px-7 tw-rounded-[10px]"
            />
          </div>
          {type === 'saveDownloadModal' && (
            <div className="tw-flex tw-justify-center tw-mb-8">
              <ZojaButton
                text="Save This Report"
                onClick={handleShowSaveReport}
                extraClass="tw-p-2 tw-cursor-pointer tw-text-isPrimary tw-px-7 tw-rounded-[10px]"
              />
            </div>
          )}
        </div>
      }
    />
  );
};

export default DownloadSuccessModal;
