interface IProps {
  title: string;
  icon?: React.ReactElement;
  children?: React.ReactElement;
  labelExtraclass?: string;
  iconClass?: string;
  titleClass?: string;
  labelStyle?: any;
  onClick: () => void;
  disabled?: boolean;
}

const FilterLabel = ({
  title,
  titleClass,
  iconClass,
  icon,
  labelExtraclass,
  labelStyle,
  onClick,
  children,
  disabled = false,
}: IProps) => {
  return (
    <div
      className={`tw-relative tw-flex tw-items-center tw-justify-center tw-gap-x-2 tw-bg-white tw-shadow-zojaShadowFive tw-p-2.5 tw-px-3 tw-rounded-sm md:tw-w-36 ${labelExtraclass} ${
        disabled ? `tw-cursor-not-allowed` : `tw-cursor-pointer`
      }`}
      onClick={disabled ? () => {} : onClick}
      style={labelStyle}>
      <span className={`${iconClass} tw-text-[#101828] tw-text-2xl tw-font-semibold`}>{icon}</span>
      <span className={`tw-hidden tw-text-[#222B88] tw-text-sm tw-font-[300] md:tw-block ${titleClass}`}>{title}</span>
      {children}
    </div>
  );
};

export default FilterLabel;
