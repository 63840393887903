import { routesPath } from '../../utils';
const {
  TRANSACTIONREPORT,
  VOLUMEREPORT,
  USERREPORT,
  INCOMEREPORT,
  DAILYREPORT,
  WEEKLYREPORT,
  MONTHLYREPORT,
  USERACTTIVITYREPORT,
  USERREGREPORT,
  SETTLEMENTREPORT,
  FINANCEREPORT,
  RECONCILIATIONREPORT,
} = routesPath;
export const dateOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];
export const dateOptionsLogin = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];
export const dateOptionsOnboard = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];
export const timeRangeOptions = [
  {
    id: 1,
    value: 'one_hour_ago',
    title: '1 Hour Ago',
    isChecked: false,
  },
  {
    id: 2,
    value: 'one_day_ago',
    title: '1 Day Ago',
    isChecked: false,
  },
  {
    id: 3,
    value: 'one_week_ago',
    title: '1 Week Ago',
    isChecked: false,
  },
  {
    id: 4,
    value: 'one_month_ago',
    title: 'One Month Ago',
    isChecked: false,
  },
  {
    id: 5,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

export const kycLevelOptions = [
  {
    id: 1,
    value: 'level one',
    title: 'Basic',
    isChecked: false,
  },
  {
    id: 2,
    value: 'level two',
    title: 'Intermediate',
    isChecked: false,
  },
  {
    id: 3,
    value: 'level three',
    title: 'Premium',
    isChecked: false,
  },
];

export const transactionStatusOptions = [
  {
    id: 1,
    value: 'success',
    title: 'Success',
    isChecked: false,
    textColor: 'tw-text-green-600',
  },
  {
    id: 2,
    value: 'pending',
    title: 'Pending',
    isChecked: false,
    textColor: 'text-orange-500',
  },
  {
    id: 3,
    value: 'failed',
    title: 'Failed',
    isChecked: false,
    textColor: 'text-red-500',
  },
];

export const userStatusOptions = [
  {
    id: 1,
    value: 'active',
    title: 'Active',
    isChecked: false,
  },
  {
    id: 2,
    value: 'inactive',
    title: 'Inactive',
    isChecked: false,
  },
];

export const activityType = [
  {
    id: 1,
    value: 'log_in',
    title: 'Log In',
    isChecked: false,
  },
  {
    id: 2,
    value: 'log_out',
    title: 'Log Out',
    isChecked: false,
  },
];

export const securityEvents = [
  {
    id: 1,
    value: 'failed_login_attempts',
    title: 'Failed Login Attempts',
    isChecked: false,
  },
  {
    id: 2,
    value: 'account_lockout',
    title: 'Account Lockout',
    isChecked: false,
  },
];

export const sessionDuration = [
  {
    id: 1,
    value: '30_mins',
    title: '30 Mins',
    isChecked: false,
  },
  {
    id: 2,
    value: '1_hour',
    title: '1 Hour',
    isChecked: false,
  },
  {
    id: 3,
    value: '24_hours',
    title: '24 Hours',
    isChecked: false,
  },
];

export const transactionTypeOptions = [
  {
    id: 1,
    value: 'zojapay',
    title: 'Zojapay to Zojapay',
    isChecked: false,
  },

  {
    id: 2,
    value: 'other_banks',
    title: 'Zojapay to Other Banks',
    isChecked: false,
  },

  {
    id: 3,
    value: 'nfc',
    title: 'Transfer via NFC Code',
    isChecked: false,
  },
  {
    id: 4,
    value: 'qr',
    title: 'Zojapay via QR Code',
    isChecked: false,
  },
  {
    id: 5,
    value: 'bills',
    title: 'Bills Payment',
    isChecked: false,
  },
  {
    id: 6,
    value: 'cash_request',
    title: 'Cash Request',
    isChecked: false,
  },
  {
    id: 7,
    value: 'reconciliation_credit',
    title: 'Reconciliation Credit',
    isChecked: false,
  },
  {
    id: 8,
    value: 'reconciliation_dedit',
    title: 'Reconciliation Debit',
    isChecked: false,
  },
  {
    id: 9,
    value: 'referral_credit',
    title: 'Referral Credit',
    isChecked: false,
  },
];

export const figureRangeOptions = [
  {
    id: 1,
    value: '0 - 50',
    title: '0 - 50',
    isChecked: false,
  },
  {
    id: 2,
    value: '51 - 100',
    title: '51 - 100',
    isChecked: false,
  },
];

export const locationOptions = [
  {
    id: 1,
    value: 'ikeja',
    title: 'Ikeja',
    isChecked: false,
  },

  {
    id: 2,
    value: 'ojota',
    title: 'Ojota',
    isChecked: false,
  },

  {
    id: 3,
    value: 'ipaja',
    title: 'Ipaja',
    isChecked: false,
  },

  {
    id: 4,
    value: 'alimosho',
    title: 'Alimosho',
    isChecked: false,
  },
  {
    id: 5,
    value: 'isolo',
    title: 'Isolo',
    isChecked: false,
  },
  {
    id: 6,
    value: 'isheri-olofin',
    title: 'Isheri-olofin',
    isChecked: false,
  },
  {
    id: 7,
    value: 'oshodi',
    title: 'Oshodi',
    isChecked: false,
  },
  {
    id: 8,
    value: 'ogudu',
    title: 'Ogudu',
    isChecked: false,
  },
  {
    id: 9,
    value: 'bariga',
    title: 'Bariga',
    isChecked: false,
  },
];

export const genderOptions = [
  {
    id: 1,
    value: 'male',
    title: 'Male',
    isChecked: false,
  },
  {
    id: 2,
    value: 'female',
    title: 'Female',
    isChecked: false,
  },
];

export const timeStamp = [
  {
    id: 1,
    value: '30_mins',
    title: '30 Mins',
    isChecked: false,
  },
  {
    id: 2,
    value: '7_hours',
    title: '7 Hours',
    isChecked: false,
  },
  {
    id: 3,
    value: '24_hours',
    title: '24 Hours',
    isChecked: false,
  },
];

export const settlementType = [
  {
    id: 1,
    value: 'bankTransfer',
    title: 'Bank Transfer',
    isChecked: false,
  },
  {
    id: 2,
    value: 'creditCard',
    title: 'Credit Card',
    isChecked: false,
  },
];

export const chargebacks = [
  {
    id: 1,
    value: 'reversals',
    title: 'Reversals',
    isChecked: false,
  },
  {
    id: 2,
    value: 'chargebacks',
    title: 'Chargebacks',
    isChecked: false,
  },
];

export const settlementDestination = [
  {
    id: 1,
    value: 'bankAccounts',
    title: 'Bank Accounts',
    isChecked: false,
  },
];

export const settlementMethod = [
  {
    id: 1,
    value: 'wireTransfer',
    title: 'Wire Transfer',
    isChecked: false,
  },
];

export const methodOptions = [
  {
    id: 1,
    value: 'card',
    title: 'Fund With Card',
    isChecked: false,
  },
  {
    id: 2,
    value: 'qr',
    title: 'QR Code',
    isChecked: false,
  },
  {
    id: 3,
    value: 'nfc',
    title: 'NFC Phone',
    isChecked: false,
  },
  {
    id: 4,
    value: 'secure_link',
    title: 'Secure Link',
    isChecked: false,
  },
  {
    id: 5,
    value: 'cash_request',
    title: 'Cash Request',
    isChecked: false,
  },
  {
    id: 6,
    value: 'other_banks',
    title: 'Zojapay to Others',
    isChecked: false,
  },
  {
    id: 7,
    value: 'zojapay',
    title: 'Zojapay to Zojapay',
    isChecked: false,
  },
  {
    id: 8,
    value: 'airtime',
    title: 'Airtime',
    isChecked: false,
  },
  {
    id: 9,
    value: 'data',
    title: 'Data',
    isChecked: false,
  },
  {
    id: 10,
    value: 'bills',
    title: 'Bills',
    isChecked: false,
  },
  {
    id: 11,
    value: 'cable_tv',
    title: 'Cable Tv',
    isChecked: false,
  },
  {
    id: 12,
    value: 'electricity',
    title: 'Electricity',
    isChecked: false,
  },
];

export const marginOptions = [
  {
    id: 1,
    value: '0-25',
    title: '0-25',
    isChecked: false,
  },
  {
    id: 2,
    value: '26-50',
    title: '26-50',
    isChecked: false,
  },
  {
    id: 3,
    value: '51-75',
    title: '51-75',
    isChecked: false,
  },
  {
    id: 4,
    value: '76-100',
    title: '76-100',
    isChecked: false,
  },
];

export const channel = [
  {
    id: 1,
    value: 'web',
    title: 'Web',
    isChecked: false,
  },
  {
    id: 2,
    value: 'mobile_app',
    title: 'Mobile App',
    isChecked: false,
  },
];

export const refundStatus = [
  {
    id: 1,
    value: 'completed',
    title: 'Completed',
    isChecked: false,
  },
  {
    id: 2,
    value: 'not_completed',
    title: 'Not Completed',
    isChecked: false,
  },
];

export const userTypeOptions = [
  {
    id: 1,
    value: 'kyc_level_1_3',
    title: 'KYC Level 1-3',
    isChecked: false,
  },
  {
    id: 2,
    value: 'agents',
    title: 'Agents',
    isChecked: false,
  },
];

export const deviceType = [
  {
    id: 1,
    value: 'desktop',
    title: 'Desktop',
    isChecked: false,
  },
  {
    id: 2,
    value: 'mobile',
    title: 'Mobile',
    isChecked: false,
  },
];

export const accountVerification = [
  {
    id: 1,
    value: 'approved',
    title: 'Approved',
    isChecked: false,
  },
  {
    id: 2,
    value: 'failed',
    title: 'Failed',
    isChecked: false,
  },
  {
    id: 3,
    value: 'pending',
    title: 'Pending',
    isChecked: false,
  },
];

export const sourceOfReg = [
  {
    id: 1,
    value: 'mobile',
    title: 'Mobile',
    isChecked: false,
  },
  {
    id: 2,
    value: 'web',
    title: 'Web',
    isChecked: false,
  },
];

export const userLevel = [
  {
    id: 1,
    value: 'level zero',
    title: 'Level 0',
    isChecked: false,
  },
  {
    id: 2,
    value: 'level one',
    title: 'Level 1',
    isChecked: false,
  },
  {
    id: 3,
    value: 'level two',
    title: 'Level 2',
    isChecked: false,
  },
  {
    id: 4,
    value: 'level three',
    title: 'Level 3',
    isChecked: false,
  },
  {
    id: 5,
    value: 'super_agent',
    title: 'Super Agent',
    isChecked: false,
  },
  {
    id: 6,
    value: 'sub_agent',
    title: 'Sub Agent',
    isChecked: false,
  },
];

export const userLevelReg = [
  {
    id: 1,
    value: 'zero',
    title: 'Level 0',
    isChecked: false,
  },
  {
    id: 2,
    value: 1,
    title: 'Level 1',
    isChecked: false,
  },
  {
    id: 3,
    value: 2,
    title: 'Level 2',
    isChecked: false,
  },
  {
    id: 4,
    value: 3,
    title: 'Level 3',
    isChecked: false,
  },
  {
    id: 5,
    value: 'super-agent',
    title: 'Super Agent',
    isChecked: false,
  },
  {
    id: 6,
    value: 'sub-agent',
    title: 'Sub Agent',
    isChecked: false,
  },
];

export const userFeedback = [
  {
    id: 1,
    value: 'bad',
    title: 'Bad',
    isChecked: false,
  },
  {
    id: 2,
    value: 'good',
    title: 'Good',
    isChecked: false,
  },
  {
    id: 3,
    value: 'very_good',
    title: 'Very Good',
    isChecked: false,
  },
];

export const filterType = [
  {
    id: 1,
    value: 'transaction',
    title: 'Transaction Report',
    subTitle: 'Transaction Filter',
    path: TRANSACTIONREPORT,
  },
  {
    id: 2,
    value: 'user',
    title: 'User Report',
    subTitle: 'User Filter',
    path: USERREPORT,
  },
  {
    id: 3,
    value: 'volume',
    title: 'Volume Report',
    subTitle: 'Volume Filter',
    path: VOLUMEREPORT,
  },
  {
    id: 4,
    value: 'income',
    title: 'Income Report',
    subTitle: 'Income Filter',
    path: INCOMEREPORT,
  },
];

export const generalReportFilterType = [
  {
    id: 1,
    value: 'dailyReport',
    title: 'Daily Report',
    subTitle: 'Daily Filter',
    path: DAILYREPORT,
  },
  {
    id: 2,
    value: 'weeklyReport',
    title: 'Weekly Report',
    subTitle: 'Weekly Filter',
    path: WEEKLYREPORT,
  },
  {
    id: 3,
    value: 'monthlyReport',
    title: 'Monthly Report',
    subTitle: 'Monthly Filter',
    path: MONTHLYREPORT,
  },
  {
    id: 4,
    value: 'userRegReport',
    title: 'User Registration',
    subTitle: 'User Registration Filter',
    path: USERREGREPORT,
  },
  {
    id: 5,
    value: 'financialActivities',
    title: 'Financial Report',
    subTitle: 'Financial Filter',
    path: FINANCEREPORT,
  },
  {
    id: 6,
    value: 'userActivities',
    title: 'User Activities Report',
    subTitle: 'User Activities Filter',
    path: USERACTTIVITYREPORT,
  },
  // {
  //   id: 7,
  //   value: 'settlementReport',
  //   title: 'Settlement Report',
  //   subTitle: 'Settlement Filter',
  //   path: SETTLEMENTREPORT,
  // },
  {
    id: 7,
    value: 'reconciliationReport',
    title: 'Reconciliation Report',
    subTitle: 'Reconciliation Filter',
    path: RECONCILIATIONREPORT,
  },
];

export const transactionTypeRecon = [
  {
    id: 1,
    value: 'deposit',
    title: 'Deposit',
    isChecked: false,
  },
  {
    id: 2,
    value: 'withdrawal',
    title: 'Withdrawal',
    isChecked: false,
  },
];

export const transactionTypeGen = [
  {
    id: 1,
    value: 'debit',
    title: 'Debit',
    isChecked: false,
  },
  {
    id: 2,
    value: 'credit',
    title: 'Credit',
    isChecked: false,
  },
];

export const reconciliationStatus = [
  {
    id: 1,
    value: 'reconciled',
    title: 'Reconciled',
    isChecked: false,
  },
  {
    id: 2,
    value: 'unreconciled',
    title: 'Unreconciled',
    isChecked: false,
  },
];

// filter options constants
export const sortByOtions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'transaction_type',
      title: 'Transaction Type',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'largest',
      title: 'Largest',
      isChecked: false,
    },
    {
      id: 4,
      value: 'smallest',
      title: 'Smallest',
      isChecked: false,
    },
  ],
};

// filter options constants for volume and user report
export const sortByOtionsVolUser = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
  ],
};

// filter options constants
export const manageColumnOptions = {
  transaction: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'transaction_date',
      title: 'Transaction Date',
      isChecked: true,
    },
    {
      id: 3,
      value: 'transaction_type',
      title: 'Transaction Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'account_name',
      title: 'Account Name',
      isChecked: true,
    },
    {
      id: 5,
      value: 'amount',
      title: 'Amount(N)',
      isChecked: true,
    },
    {
      id: 6,
      title: 'Timestamp',
      value: 'timestamp',
      isChecked: true,
    },
    {
      id: 7,
      value: 'status',
      title: 'Status',
      isChecked: true,
    },
    {
      id: 8,
      value: 'account_number',
      title: 'Account Number',
      isChecked: true,
    },
    {
      id: 9,
      value: 'phone_number',
      title: 'Phone Number',
      isChecked: true,
    },
    { id: 10, title: 'Reference ID', value: 'reference_id', isChecked: true },
    {
      id: 11,
      value: 'kyc_level',
      title: 'KYC Level',
      isChecked: true,
    },
    {
      id: 12,
      value: 'transaction_fee',
      title: 'Transaction Fee',
      isChecked: true,
    },
  ],
  user: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'lastLoginTime',
      title: 'Last Login time Date',
      isChecked: true,
    },
    {
      id: 3,
      value: 'accountName',
      title: 'Account Name',
      isChecked: true,
    },
    {
      id: 4,
      value: 'accountNumber',
      title: 'Account Number',
      isChecked: true,
    },
    {
      id: 5,
      value: 'phoneNumber',
      title: 'Phone Number',
      isChecked: true,
    },
    {
      id: 6,
      title: 'Account Balance(N)',
      value: 'accountBalance',
      isChecked: true,
    },
    {
      id: 7,
      value: 'status',
      title: 'Status',
      isChecked: true,
    },
    {
      id: 8,
      value: 'kyc',
      title: 'KYC Level',
      isChecked: true,
    },
    {
      id: 9,
      value: 'gender',
      title: 'Gender',
      isChecked: true,
    },
    { id: 10, title: 'Age', value: 'age', isChecked: true },

    { id: 11, title: 'Total Transactions', value: 'totalTransactions', isChecked: true },
    {
      id: 12,
      value: 'location',
      title: 'Location',
      isChecked: true,
    },
    {
      id: 13,
      value: 'dateOnboarded',
      title: 'Date Onboarded',
      isChecked: true,
    },
  ],

  volume: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'paymentMethod',
      title: 'Payment Method',
      isChecked: true,
    },
    {
      id: 3,
      value: 'paymentDate',
      title: 'Payment Date',
      isChecked: true,
    },

    {
      id: 4,
      value: 'transactionCount',
      title: 'Transaction count',
      isChecked: true,
    },
    {
      id: 5,
      value: 'totalValue',
      title: 'Total value(N)',
      isChecked: true,
    },

    {
      id: 6,
      value: 'totalRevenue',
      title: 'Total Revenue',
      isChecked: true,
    },
    // {
    //   id: 9,
    //   value: 'profitMargin',
    //   title: 'Profit Margin(%)',
    //   isChecked: true,
    // },
  ],

  income: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'paymentMethod',
      title: 'Payment Method',
      isChecked: true,
    },
    {
      id: 3,
      value: 'paymentDate',
      title: 'Payment Date',
      isChecked: true,
    },
    {
      id: 4,
      value: 'accountName',
      title: 'Account Name',
      isChecked: true,
    },
    {
      id: 5,
      value: 'accountNumber',
      title: 'Account Number',
      isChecked: true,
    },
    {
      id: 6,
      value: 'referenceID',
      title: 'Reference ID',
      isChecked: true,
    },
    {
      id: 7,
      value: 'paymentCharge',
      title: 'Payment Charge(N)',
      isChecked: true,
    },
    {
      id: 8,
      value: 'expense',
      title: 'Expense(N)',
      isChecked: true,
    },
    {
      id: 9,
      value: 'revenue',
      title: 'Revenue(N)',
      isChecked: true,
    },
    {
      id: 10,
      value: 'profitMargin',
      title: 'Profit Margin(%)',
      isChecked: true,
    },
    {
      id: 11,
      value: 'timestamp',
      title: 'Timestamp',
      isChecked: true,
    },
  ],
  timebased: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'transactionDate',
      title: 'Transaction Date',
      isChecked: true,
    },
    {
      id: 3,
      value: 'transactionType',
      title: 'Transaction Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'accountID',
      title: 'Account ID',
      isChecked: true,
    },

    {
      id: 5,
      value: 'accountName',
      title: 'Account Name',
      isChecked: true,
    },
    {
      id: 6,
      value: 'status',
      title: 'Status',
      isChecked: true,
    },
    {
      id: 7,
      value: 'amount',
      title: 'Amount',
      isChecked: true,
    },
    {
      id: 8,
      value: 'paymentMethod',
      title: 'Payment Method',
      isChecked: true,
    },
    {
      id: 9,
      value: 'transactionChannel',
      title: 'Transaction Channel',
      isChecked: true,
    },
    {
      id: 10,
      value: 'refundStatus',
      title: 'Refund Status',
      isChecked: true,
    },
    {
      id: 11,
      value: 'transactionCode',
      title: 'Transaction Code',
      isChecked: true,
    },
    {
      id: 12,
      value: 'timeStamp',
      title: 'Time Stamp',
      isChecked: true,
    },
    {
      id: 13,
      value: 'transactionFee',
      title: 'Transaction Fee',
      isChecked: true,
    },
  ],
  reconciliation: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'transactionDate',
      title: 'Transaction Date',
      isChecked: true,
    },
    {
      id: 3,
      value: 'transactionType',
      title: 'Transaction Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'accountName',
      title: 'Account Name',
      isChecked: true,
    },

    {
      id: 5,
      value: 'accountNumber',
      title: 'Account Number',
      isChecked: true,
    },
    {
      id: 6,
      value: 'internalRefNum',
      title: 'Internal Ref Num',
      isChecked: true,
    },
    {
      id: 7,
      value: 'status',
      title: 'Status',
      isChecked: true,
    },
    {
      id: 8,
      value: 'amount',
      title: 'Amount (N)',
      isChecked: true,
    },
    {
      id: 9,
      value: 'reconciliationStatus',
      title: 'Reconciliation Status',
      isChecked: true,
    },
    // {
    //   id: 10,
    //   value: 'numberOfReconciliation',
    //   title: 'Number of Reconciliation',
    //   isChecked: true,
    // },
  ],
  settlement: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'date',
      title: 'Date',
      isChecked: true,
    },

    {
      id: 3,
      value: 'settlementType',
      title: 'Settlement Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'bankAccount',
      title: 'Bank Account',
      isChecked: true,
    },

    {
      id: 5,
      value: 'settlementStatus',
      title: 'Settlement Status',
      isChecked: true,
    },
    {
      id: 6,
      value: 'amount',
      title: 'Amount(N)',
      isChecked: true,
    },
    {
      id: 7,
      value: 'settlementMethod',
      title: 'Settlement Method',
      isChecked: true,
    },
    {
      id: 8,
      value: 'bankStatementRef',
      title: 'Bank Statement Ref',
      isChecked: true,
    },
    {
      id: 9,
      value: 'settlementBatchID',
      title: 'Settlement Batch ID',
      isChecked: true,
    },
    {
      id: 10,
      value: 'approvedStatus',
      title: 'Approved Status',
      isChecked: true,
    },
    {
      id: 11,
      value: 'settlementDestination',
      title: 'Settlement Destination',
      isChecked: true,
    },
    {
      id: 12,
      value: 'timeStamp',
      title: 'Time Stamp',
      isChecked: true,
    },
    {
      id: 13,
      value: 'chargebacks',
      title: 'Chargebacks',
      isChecked: true,
    },
  ],
  userRegistration: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'date',
      title: 'Date',
      isChecked: true,
    },

    {
      id: 3,
      value: 'location',
      title: 'Location',
      isChecked: true,
    },
    {
      id: 4,
      value: 'phoneNumber',
      title: 'Phone Number',
      isChecked: true,
    },

    {
      id: 5,
      value: 'accountNumber',
      title: 'Account Number',
      isChecked: true,
    },
    {
      id: 6,
      value: 'accountName',
      title: 'Account Name',
      isChecked: true,
    },
    {
      id: 7,
      value: 'deviceType',
      title: 'Device Type',
      isChecked: true,
    },
    {
      id: 8,
      value: 'userStatus',
      title: 'User Status',
      isChecked: true,
    },

    {
      id: 9,
      value: 'agency',
      title: 'Agency',
      isChecked: true,
    },
    {
      id: 10,
      value: 'sourceOfReg',
      title: 'Source Of Reg',
      isChecked: true,
    },
    {
      id: 11,
      value: 'gender',
      title: 'Gender',
      isChecked: true,
    },
    {
      id: 12,
      value: 'age',
      title: 'Age',
      isChecked: true,
    },
    {
      id: 13,
      value: 'userLevel',
      title: 'User Level',
      isChecked: true,
    },
    {
      id: 14,
      value: 'customerChannel',
      title: 'Customer Channel',
      isChecked: true,
    },
    {
      id: 15,
      value: 'accountVerification',
      title: 'Account Verification',
      isChecked: true,
    },
    {
      id: 16,
      value: 'userFeedback',
      title: 'User Feedback',
      isChecked: true,
    },
  ],
  userActivities: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'userId',
      title: 'User Id',
      isChecked: true,
    },

    {
      id: 3,
      value: 'activityType',
      title: 'Activity Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'deviceType',
      title: 'Device Type',
      isChecked: true,
    },
    {
      id: 5,
      value: 'location',
      title: 'Location',
      isChecked: true,
    },
    {
      id: 6,
      value: 'securityEvents',
      title: 'Security Events',
      isChecked: true,
    },
    {
      id: 7,
      value: 'sessionDuration',
      title: 'Session Duration',
      isChecked: true,
    },
    {
      id: 8,
      value: 'userStatus',
      title: 'User Status',
      isChecked: true,
    },

    {
      id: 9,
      value: 'transactionFrequency',
      title: 'Transaction Frequency',
      isChecked: true,
    },
    {
      id: 10,
      value: 'userRegDate',
      title: 'User Reg Date',
      isChecked: true,
    },
    {
      id: 11,
      value: 'userLevel',
      title: 'User Level',
      isChecked: true,
    },
  ],
  finance: [
    {
      id: 1,
      title: 'S/N',
      value: 'sn',
      isChecked: true,
    },
    {
      id: 2,
      value: 'transactionDate',
      title: 'Transaction Date',
      isChecked: true,
    },
    {
      id: 3,
      value: 'transactionType',
      title: 'Transaction Type',
      isChecked: true,
    },
    {
      id: 4,
      value: 'accountID',
      title: 'Account ID',
      isChecked: true,
    },
    {
      id: 5,
      value: 'status',
      title: 'Status',
      isChecked: true,
    },
    {
      id: 6,
      value: 'amount',
      title: 'Amount',
      isChecked: true,
    },
    {
      id: 7,
      value: 'paymentMethod',
      title: 'Payment Method',
      isChecked: true,
    },
    {
      id: 8,
      value: 'transactionFee',
      title: 'Transaction Fee',
      isChecked: true,
    },
    {
      id: 9,
      value: 'transactionCode',
      title: 'Transaction Code',
      isChecked: true,
    },
    {
      id: 10,
      value: 'userLevel',
      title: 'User Level',
      isChecked: true,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'largest',
      title: 'Largest',
      isChecked: false,
    },
    {
      id: 4,
      value: 'smallest',
      title: 'Smallest',
      isChecked: false,
    },
  ],
};
