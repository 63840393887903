import React, { useEffect, useState } from 'react';

import { AppContainer, TabView } from '../../atoms';
import {
  fontWeight,
  formatStartAndEndDateWithDelimeter,
  getEverySelectedOptionValue,
  getSelectedOptionValue,
  routesPath,
  spacing,
  useDebounce,
} from '../../utils';
import { ReactComponent as Working } from '../../assets/svg/working.svg';
import {
  FilterColapsibleSingle,
  IncomeReportFilter,
  SavedReport,
  TransactionReportFilter,
  UserReportFilter,
  VolumeReportFilter,
} from '../../components';
import {
  dateOptions,
  dateOptionsLogin,
  dateOptionsOnboard,
  figureRangeOptions,
  filterType,
  genderOptions,
  kycLevelOptions,
  locationOptions,
  manageColumnOptions,
  marginOptions,
  methodOptions,
  sortByOtions,
  timeRangeOptions,
  transactionStatusOptions,
  transactionTypeOptions,
  userTypeOptions,
} from './filterOptions';
import { Link, useNavigate } from 'react-router-dom';
import { transactionReportColumn } from './dummyData';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import {
  getAllSavedReportRequest,
  searchUserAccountRequest,
  setFilterOptions,
  setIncomeFilterOptions,
  setUserFilterOptions,
  setVolumeFilterOptions,
} from '../../redux/slice';
const {
  INCOMEREPORT,
  USERREPORT,
  VOLUMEREPORT,
  TRANSACTIONREPORT,
  RECONCILIATIONREPORT,
  SETTLEMENTREPORT,
  USERACTTIVITYREPORT,
  USERREGREPORT,
  FINANCEREPORT,
} = routesPath;

interface ReportFilterOptions {
  id: number;
  title: string;
  value: string;
  subTitle: string;
}

const Report = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState<any>();
  const debouncedSearch = useDebounce(search, 300);

  // REDUX STATE
  const allSavedReportState = useAppSelector(state => state.getAllSavedReport);
  const transactionFilterOptions = useAppSelector(state => state.filterOptions);
  const userFilterOptions = useAppSelector(state => state.userFilterOptions);
  const volumeFilterOptions = useAppSelector(state => state.volumeFilterOptions);
  const incomeFilterOptions = useAppSelector(state => state.incomeFilterOptions);
  const { status: getAllSavedReportStatus, data: getAllSavedReportData } = allSavedReportState;

  const searchedUserAccount = useAppSelector(state => state.searchUserAccount);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    ageRange: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    timeRange: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
    transactionDate: false,
    transactionAmount: false,
    transactionType: false,
    transactionStatus: false,
    feesRange: false,
    transactionCount: false,
    paymentMethod: false,
    profitMargin: false,
    userType: false,
    totalRevenue: false,
    totalAmount: false,
  });

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState({
    sortBy: sortByOtions.aphabetically,
    orderBy: sortByOtions.aphabetically,
    gender: genderOptions,
    accountInfo: [] as any[],
    transactionDate: dateOptions,
    dateOnboarded: dateOptionsOnboard,
    lastLogin: dateOptionsLogin,
    transactionType: transactionTypeOptions,
    transactionStatus: transactionStatusOptions,
    location: locationOptions,
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    feesRange: figureRangeOptions,
    reportFilterType: {
      title: 'Filter Type',
      id: 1,
      value: 'initial',
    },
    manageColumn: manageColumnOptions.transaction,
    visibleColumns: transactionReportColumn.map(column => column.id),
    timeRange: timeRangeOptions,
    revenue: figureRangeOptions,
    profitMargin: marginOptions,
    paymentMethod: methodOptions,
    userType: userTypeOptions,
  });

  const tabViewReportData = [
    { id: 1, isSelected: true, text: 'All Reports' },
    { id: 2, isSelected: false, text: 'Custom Report' },
  ];
  const [tabViewReportSelectedIndex, setTabViewReportSelectedIndex] = useState<any[number]>(1);
  const [showReportFilter, setShowReportFilter] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  // const [transactionFilterOptions, setTransactionFilterOptions] = useState(initialTransactionValues);

  // CHECKS IF THE DATE SELECTED IS CUSTOM
  const dateIsCustom = filterOption.transactionDate.some(
    (item: { value: string; isChecked: any }) => item.value === 'custom' && item.isChecked,
  );

  const isCustomInDateOptions = (dateOptions: any[]) => {
    return dateOptions.some(item => item.value === 'custom' && item.isChecked);
  };
  const isCustomInDateOnboarded = isCustomInDateOptions(filterOption.dateOnboarded);
  const isCustomInLastLogin = isCustomInDateOptions(filterOption.lastLogin);
  const isCustomInTimeRange = isCustomInDateOptions(filterOption.timeRange);

  const handleApplyFilter = () => {
    dispatch(
      setFilterOptions({
        ...transactionFilterOptions,
        transaction_date: dateIsCustom
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.transactionDate),
        status: getEverySelectedOptionValue(filterOption.transactionStatus),
        type: getEverySelectedOptionValue(filterOption.transactionType),
        filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
        fees_range: getEverySelectedOptionValue(filterOption.feesRange),
        level: getEverySelectedOptionValue(filterOption.kycLevel),
        last_seen: getEverySelectedOptionValue(filterOption.kycLevel),
        location: getEverySelectedOptionValue(filterOption.lastLogin),
        date_onboarded: getEverySelectedOptionValue(filterOption.dateOnboarded),
        gender: getEverySelectedOptionValue(filterOption.gender),
      }),
    );
  };

  const handleApplyUserFilter = () => {
    dispatch(
      setUserFilterOptions({
        ...userFilterOptions,
        date_onboarded: isCustomInDateOnboarded
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.dateOnboarded),
        last_seen: isCustomInLastLogin
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.lastLogin),

        gender: getEverySelectedOptionValue(filterOption.gender),
        filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
        level: getEverySelectedOptionValue(filterOption.kycLevel),
        location: getEverySelectedOptionValue(filterOption.location),
      }),
    );
  };

  const handleApplyVolumeFilter = () => {
    dispatch(
      setVolumeFilterOptions({
        ...volumeFilterOptions,
        time_range: isCustomInTimeRange
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.timeRange),
        payment_method: getEverySelectedOptionValue(filterOption.paymentMethod),
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
      }),
    );
  };

  const handleApplyIncomeFilter = () => {
    dispatch(
      setIncomeFilterOptions({
        ...incomeFilterOptions,
        time_range: isCustomInTimeRange
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.timeRange),
        payment_method: getEverySelectedOptionValue(filterOption.paymentMethod),
        profit_margin: getEverySelectedOptionValue(filterOption.profitMargin),
        filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
      }),
    );
  };

  useEffect(() => {
    if (search) {
      dispatch(searchUserAccountRequest({ search: debouncedSearch }));
    }
  }, [dispatch, debouncedSearch]);

  // GET ALL SAVED REPORTS
  useEffect(() => {
    // if (search) {
    dispatch(getAllSavedReportRequest());
    // }
  }, [dispatch]);

  const [reportFilterType, setReportFilterType] = useState<ReportFilterOptions>({
    title: 'Filter Type',
    id: 1,
    value: 'initial',
    subTitle: 'initial',
  });

  useEffect(() => {
    if (searchedUserAccount.status === 'succeeded') {
      let updatedList: any[] = [];

      searchedUserAccount?.data?.users?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          isChecked: false,
          accountNumber: item.account_number,
          name: item.name,
          value: item.name,
          telephone: item.telephone,
        });
      });

      setFilterOption({ ...filterOption, accountInfo: updatedList });
    }
  }, [searchedUserAccount?.data.user, searchedUserAccount?.data?.users, searchedUserAccount.status]);

  function convertQueryStringToFilterOptions(queryString: string, type: string): Record<string, string> {
    const queryParams = new URLSearchParams(queryString);
    const filterOptions: Record<string, string> = {};

    queryParams.forEach((value, param) => {
      filterOptions[param] = value;
    });

    if (type === 'income') {
      dispatch(
        setIncomeFilterOptions({
          ...filterOptions,
        }),
      );
    }
    if (type === 'user') {
      dispatch(
        setUserFilterOptions({
          ...filterOptions,
        }),
      );
    }
    if (type === 'transaction') {
      dispatch(
        setFilterOptions({
          ...filterOptions,
        }),
      );
    }
    if (type === 'volume') {
      dispatch(
        setVolumeFilterOptions({
          ...filterOptions,
        }),
      );
    }

    return filterOptions;
  }

  const handleGenerateReport = () => {
    if (reportFilterType.value === 'transaction') {
      navigate(TRANSACTIONREPORT);
    }
    if (reportFilterType.value === 'user') {
      navigate(USERREPORT);
    }
    if (reportFilterType.value === 'volume') {
      navigate(VOLUMEREPORT);
    }
    if (reportFilterType.value === 'income') {
      navigate(INCOMEREPORT);
    }
  };

  return (
    <AppContainer navTitle="Reports">
      <div className="tw-mt-14">
        {' '}
        <TabView
          data={tabViewReportData}
          setSelectedIndex={setTabViewReportSelectedIndex}
          borderBottom={'6px solid #E7E7E7'}
          paddingBottom={false}
          bottom={'-5px'}
          tabPaddingBottom={'10px'}
          borderBottomWidth={'5px'}
          type="report"
          fontSize={'22px'}
          fontWeight={fontWeight.semiLight}
          gap={spacing.larger}
        />
        {tabViewReportSelectedIndex === 1 && (
          <div className=" tw-pt-9 tw-rounded-lg tw-relative">
            <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex tw-mb-6">
              <h2 className="tw-text-gray-800 tw-text-[22px] tw-font-medium">System Preset Reports</h2>
              <p className="tw-text-slate-500 tw-text-xs tw-font-normal">
                All system generated reports based on frequently needed reports
              </p>
              <div className=" tw-grid lg:tw-grid-cols-3 tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-10 tw-gap-y-16 tw-w-full tw-justify-between">
                <div>
                  <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal">Reconciliation and Settlement Report</h3>
                  <div className="tw-flex tw-flex-col tw-gap-y-5">
                    <Link
                      to={`${RECONCILIATIONREPORT}`}
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Reconciliation Report
                    </Link>
                    {/* <Link
                      to={`${SETTLEMENTREPORT}`}
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Settlement Report
                    </Link> */}
                  </div>
                </div>
                <div>
                  <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal">User Registration and Growth Metrics</h3>
                  <div className="tw-flex tw-flex-col tw-gap-y-5">
                    <Link to={`${USERREGREPORT}`} className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      User Registration and Growth Report
                    </Link>
                  </div>
                </div>
                <div>
                  <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal">Transaction Based Reports</h3>
                  <div className="tw-flex tw-flex-col tw-gap-y-5">
                    <Link
                      to="/generalreport/dailyreport"
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Daily Transaction
                    </Link>
                    <Link
                      to="/generalreport/weeklyreport"
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Weekly Transaction
                    </Link>
                    <Link
                      to="/generalreport/monthlyreport"
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Monthly Transaction
                    </Link>
                  </div>
                </div>
                <div>
                  <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal">Finance Report</h3>
                  <div className="tw-flex tw-flex-col tw-gap-y-5">
                    <Link to={`${FINANCEREPORT}`} className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      Finance Report
                    </Link>
                  </div>
                </div>
                <div>
                  <h3 className="tw-text-zinc-600 tw-text-base tw-font-normal">
                    User Activities and Access Logs Report{' '}
                  </h3>
                  <div className="tw-flex tw-flex-col tw-gap-y-5">
                    <Link
                      to={`${USERACTTIVITYREPORT}`}
                      className="tw-text-blue-900 tw-text-sm tw-font-normal tw-underline">
                      User Activities and Access Logs Report
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-[2px] border border-zinc-400 tw-mb-8"></div>
            <div className="tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex tw-mb-6">
              <h2 className="tw-text-gray-800 tw-text-[22px] tw-font-medium">Saved Reports</h2>
              <p className="tw-text-slate-500 tw-text-xs tw-font-normal">
                These are reports that you have previously saved on the system for reference
              </p>
            </div>
            <div className=" tw-grid lg:tw-grid-cols-4 tw-grid-cols-1 sm:tw-grid-cols-2 tw-gap-x-10 tw-gap-y-4 tw-pb-6">
              <SavedReport
                data={getAllSavedReportData?.saved_reports?.transaction_based}
                type={'transaction'}
                title="Transaction Based Reports"
                onHandleQuery={convertQueryStringToFilterOptions}
                link={TRANSACTIONREPORT}
                getAllSavedReportStatus={getAllSavedReportStatus}
              />
              <SavedReport
                data={getAllSavedReportData?.saved_reports?.user_based}
                type={'user'}
                title="User Based Reports"
                onHandleQuery={convertQueryStringToFilterOptions}
                link={USERREPORT}
                getAllSavedReportStatus={getAllSavedReportStatus}
              />
              <SavedReport
                data={getAllSavedReportData?.saved_reports?.volume_based}
                type={'volume'}
                title="Volume Based Reports"
                onHandleQuery={convertQueryStringToFilterOptions}
                link={VOLUMEREPORT}
                getAllSavedReportStatus={getAllSavedReportStatus}
              />
              <SavedReport
                data={getAllSavedReportData?.saved_reports?.income_based}
                type={'income'}
                title="Income Based Reports"
                onHandleQuery={convertQueryStringToFilterOptions}
                link={INCOMEREPORT}
                getAllSavedReportStatus={getAllSavedReportStatus}
              />
            </div>
          </div>
        )}
        {tabViewReportSelectedIndex === 2 && (
          <div>
            {(showFilterOptions || showReportFilter) && (
              <div
                className="tw-absolute tw-bg-black/0 tw-cursor-pointer  tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
                onClick={() => {
                  setShowReportFilter(false);
                  setShowFilterOptions(false);
                }}></div>
            )}
            <div className="tw-my-9 tw-flex tw-flex-col tw-gap-y-4 lg:tw-flex-row tw-items-center tw-gap-x-8">
              <h3 className="tw-text-gray-800 tw-text-sm tw-font-medium">Select Filter</h3>
              <div className="tw-grid tw-gap-y-2 md:tw-grid-cols-2 md:tw-gap-x-4 tw-gap-x-1">
                <FilterColapsibleSingle
                  title={reportFilterType?.value === '' ? 'Filter Type' : reportFilterType?.title}
                  show={showReportFilter}
                  onClick={() => setShowReportFilter(!showReportFilter)}
                  extraClass="tw-shadow-zojaShadowTwo tw-w-[160px] md:tw-w-[174px]"
                  childClass=" tw-w-[160px] md:tw-w-[174px]">
                  <>
                    <div className="">
                      {filterType.map((item: any) => (
                        <div
                          key={item.id}
                          onClick={() => {
                            setReportFilterType(item);
                            setShowReportFilter(false);
                          }}
                          className="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-cursor-pointer">
                          <p className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</p>
                        </div>
                      ))}
                    </div>
                  </>
                </FilterColapsibleSingle>

                <FilterColapsibleSingle
                  title={reportFilterType.title === 'Filter Type' ? 'Filter By' : reportFilterType.subTitle}
                  show={showFilterOptions}
                  onClick={() => setShowFilterOptions(!showFilterOptions)}
                  extraClass="tw-shadow-zojaShadowTwo tw-w-[160px] md:tw-w-[174px]"
                  childClass="tw-w-[160px] md:tw-w-[174px] tw-z-50">
                  <>
                    {reportFilterType.value === 'transaction' && (
                      <TransactionReportFilter
                        filterOption={filterOption}
                        setFilterOption={setFilterOption}
                        setTransactionFilterOption={setFilterOptions}
                        showOptions={showOptions}
                        setShowOptions={setShowOptions}
                        setSearch={setSearch}
                        searchedUsers={searchedUserAccount.data.users}
                        onClick={() => {
                          handleApplyFilter();
                          setShowFilterOptions(false);
                        }}
                      />
                    )}
                    {reportFilterType.value === 'user' && (
                      <UserReportFilter
                        filterOption={filterOption}
                        setFilterOption={setFilterOption}
                        showOptions={showOptions}
                        setUserFilterOption={setUserFilterOptions}
                        setShowOptions={setShowOptions}
                        setSearch={setSearch}
                        searchedUsers={searchedUserAccount.data.users}
                        onClick={() => {
                          handleApplyUserFilter();
                          setShowFilterOptions(false);
                        }}
                      />
                    )}
                    {reportFilterType.value === 'volume' && (
                      <VolumeReportFilter
                        filterOption={filterOption}
                        setFilterOption={setFilterOption}
                        showOptions={showOptions}
                        setVolumeFilterOption={setVolumeFilterOptions}
                        setShowOptions={setShowOptions}
                        setSearch={setSearch}
                        searchedUsers={searchedUserAccount.data.users}
                        onClick={() => {
                          handleApplyVolumeFilter();
                          setShowFilterOptions(false);
                        }}
                      />
                    )}
                    {reportFilterType.value === 'income' && (
                      <IncomeReportFilter
                        filterOption={filterOption}
                        setFilterOption={setFilterOption}
                        showOptions={showOptions}
                        setIncomeFilterOption={setVolumeFilterOptions}
                        setShowOptions={setShowOptions}
                        setSearch={setSearch}
                        searchedUsers={searchedUserAccount.data.users}
                        onClick={() => {
                          handleApplyIncomeFilter();
                          setShowFilterOptions(false);
                        }}
                      />
                    )}
                  </>
                </FilterColapsibleSingle>
              </div>

              <button
                onClick={() => handleGenerateReport()}
                className={`${
                  reportFilterType.value === 'initial' ? 'text-zinc-400' : 'tw-text-white'
                } text-base font-medium tw-w-[223px] tw-p-2.5 ${
                  reportFilterType.value === 'initial' ? 'tw-bg-neutral-200' : 'tw-bg-isPrimary'
                } tw-rounded-md tw-justify-center tw-items-center tw-gap-2.5`}>
                Generate Report
              </button>
            </div>
            <div className="tw-w-full tw-h-[523px] tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-white tw-rounded-lg ">
              <Working />
              <h3 className="md:tw-w-[353px] tw-text-center tw-text-gray-800 tw-text-sm tw-font-normal tw-relative tw-bottom-[3rem]">
                Select a Filter type above and add filters to this field to generate your report
              </h3>
            </div>
          </div>
        )}
      </div>
    </AppContainer>
  );
};

export default Report;
