
interface PaymentPageTitleProps {
    title: string;
    contentRight?: string | React.ReactNode
}

export default function PaymentPageTitle({ title, contentRight }: PaymentPageTitleProps) {
    return (
        <div className="tw-flex tw-flex-col tw-justify-between tw-gap-2 lg:tw-flex-row lg:tw-items-center">
            <span className="tw-text-sm lg:tw-text-[1rem]">{title}</span>
            {contentRight}
        </div>
    )
}
