import { LucideEye, LucideEyeOff } from "lucide-react";
import { cn } from "../../lib/utils";

interface TogglePasswordProps {
    showPassword: boolean;
    setShowPassword: (arg: boolean) =>  void
    className?: string;
}

export default function TogglePassword({ showPassword = false, setShowPassword, className }: TogglePasswordProps) {
    return (
        <span className={cn(
            'tw-absolute tw-right-4 tw-top-9 tw-cursor-pointer',
            className
        )}
            onClick={() => setShowPassword(!showPassword)}
        >
            {showPassword ? <LucideEye width={20} height={20} /> : <LucideEyeOff width={20} height={20} />}
        </span>
    )
}
