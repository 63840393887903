import React from 'react';
import { ZojaButton, ZojaModal } from '../tailwind';
import { images } from '../../utils';
import { ReactComponent as Noteremove } from '../../assets/svg/noteremove.svg';

interface IProps {
  content: string;
  type?: string;
  show: boolean;
  handleClose: () => void;
  contentRef: HTMLDivElement | any;
  closeOnClickOutside?: boolean;
  handleShowSaveReport?: () => void;
}

const DeactivateModal = ({
  content,
  show,
  contentRef,
  handleClose,
  handleShowSaveReport,
  type,
  closeOnClickOutside = false,
}: IProps) => {
  return (
    <ZojaModal
      show={show}
      height="auto"
      position="center"
      borderRadius="12px"
      handleClose={handleClose}
      closeOnClickOutside={closeOnClickOutside}
      extraClass="md:tw-w-max md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      contentRef={contentRef}
      children={
        <div className="rounded-lg tw-p-8" ref={contentRef}>
          <div className=" tw-grid tw-justify-center">
            <Noteremove />
          </div>
          <p className="tw-text-center tw-my-12 tw-mx-auto tw-text-gray-700 tw-text-sm tw-font-light">{content}</p>
          <div className="tw-flex tw-justify-center tw-mb-8">
            <ZojaButton
              text="Deactivate"
              onClick={handleClose}
              extraClass="tw-p-2 tw-bg-red-500 tw-cursor-pointer tw-text-white tw-px-7 tw-rounded-[10px] tw-text-sm tw-font-medium"
            />
            <ZojaButton
              text="Cancel"
              onClick={handleClose}
              extraClass="tw-p-2 tw-cursor-pointer tw-text-isPrimary tw-px-7 tw-rounded tw-text-sm tw-font-medium"
            />
          </div>
        </div>
      }
    />
  );
};

export default DeactivateModal;
