import { useLocation, useSearchParams } from "react-router-dom";
import { cn } from "../../lib/utils";

interface PaymentContainerProps {
    title: string;
    children: React.ReactNode,
    titleClass?: string;
}

export default function PaymentContainer({title, children, titleClass}: PaymentContainerProps) {
    const [searchParams, setSearchParams] = useSearchParams()
    const PAYMENT_TYPE = searchParams.get('type') ? searchParams.get('type') : 'credit'
    
    const location = useLocation()
    const path = location.pathname

    return (
        <div>
            <div className={cn(titleClass)}>
                <h3 className={cn(
                    "tw-text-sm tw-mb-0",
                )}>
                    {path.includes('bulk') && PAYMENT_TYPE === 'credit' && 'Send Money to Multiple Account'}
                    {path.includes('bulk') && PAYMENT_TYPE === 'debit' && 'Retrieve Funds from Multiple Account'}
                    {path.includes('single') && PAYMENT_TYPE === 'credit' && 'Send Money'}
                    {path.includes('single') && PAYMENT_TYPE === 'debit' && 'Retrieve Money'}
                    
                </h3>
                <span className="tw-text-xs -tw-leading-4 tw-text-[#7E7E96]">{title}</span>
            </div>

            {children}
            
        </div>
    )
}
