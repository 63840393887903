import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {},
};

const searchCustomerAccountStatementSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getSearchCustomerAccountStatement.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getSearchCustomerAccountStatement.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getSearchCustomerAccountStatement.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getSearchCustomerAccountStatement = createAsyncThunk(
  'getSearchCustomerAccountStatement',
  async (payload: Dictionary) => {
    const { account_id_phone_number, page, per_page } = payload;
    const url = `/admin/restrictions/account-details?account_id_phone_number=${account_id_phone_number}&page=${page}&per_page=${per_page}`;

    try {
      const response = await api.get(`${url}`);
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

export const searchCustomerAccountStatementSliceReducer = searchCustomerAccountStatementSlice.reducer;
