import { MdClose } from "react-icons/md"
import { ZojaModal } from "../tailwind"
import { cn } from "../../lib/utils"

interface IProps {
    show: boolean,
    handleClose: () => void,
    actionIcon?: any,
    children: React.ReactElement,
    imgClass?: string;
    modalClass?: string;
    showCloseIcon?: boolean
}

const ZojaActionModal = ({ show, handleClose, actionIcon, children, imgClass, modalClass, showCloseIcon = true }: IProps) => {
    return (
        <ZojaModal
            show={show}
            handleClose={handleClose}
            height='auto'
            position='center'
            extraClass={cn(
                "tw-mt-12 tw-items-center tw-rounded-xl md:tw-w-[28rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto",
                modalClass
            )}
            children={<div className='tw-rounded-lg tw-text-center tw-p-5 tw-flex tw-flex-col tw-items-center tw-relative tw-pt-12'>
                {actionIcon && <img className={cn(
                    'w-fit',
                    imgClass
                )} src={actionIcon} alt="action-img" />}
                {children}

                {showCloseIcon && <span className='tw-text-white tw-bg-isPrimary tw-block tw-p-1 tw-rounded-md tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer' onClick={handleClose}><MdClose /></span>}
            </div>}
        />
    )
}

export default ZojaActionModal