import { TableTag, TableContainer } from './style';
import { TD, TR } from '../../tailwind/table/tableElements';
import { MoreIcon, ZojaTable } from '../..';
import { capitalizeFirstLetter } from '../../../utils';
import { ReactComponent as EmptySearchIcon } from '../../../assets/svg/emptySearch.svg';
import { Dictionary } from '../../../types';
import { TDMORE } from '../../tailwind/table/tableElementMoreView';
import moment from 'moment';
import { cn } from '../../../lib/utils';

export interface RolesAndPermissionTableIPropsIProps {
  id?: number;
  title: string;
  permissionCount: string | number;
  userCount: string | number;
  createdBy: string;
  roleId?: string;
}

export interface RolesAndPermissionTableIProps {
  data: RolesAndPermissionTableIPropsIProps[];
  setSelectedItem?: any;
  backgroundColor?: string;
  header?: boolean;
  onClick: (item: Dictionary) => any;
  sorting?: any;
  order?: string;
  tableColumns?: any;
  dropdownList?: any;
  setSelectedTableItem?: any;
  handleMoreIconOptions: (item: any) => void;
  selectedItem: any;
  setIsSort?: any;
  setSortHeader?: any;
  setOrder?: any;
  setIsNextPage?: any;
}

const dropdownList = [
  {
    id: 1,
    title: 'Role Details',
    value: 'roleDetails',
  },
  // {
  //   id: 2,
  //   title: 'Delete Role',
  //   value: 'deleteRole',
  // },
];

function RolesAndPermissionTable({
  data,
  setSelectedItem,
  onClick,
  sorting,
  order,
  tableColumns,
  handleMoreIconOptions,
  selectedItem,
  setIsSort,
  setSortHeader,
  setOrder,
  setIsNextPage,
}: RolesAndPermissionTableIProps) {
  const handleOnSelect = (item: any) => {
    setSelectedItem(item);
  };

  return (
    <div>
      <ZojaTable column={tableColumns} sorting={sorting} order={order}  setIsSort={setIsSort}
        setOrder={setOrder}
        setIsNextPage={setIsNextPage}
        setSortHeader={setSortHeader}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD value={capitalizeFirstLetter(item.title)} />
            <TD value={item?.modules_count || 'N/A'} />
            <TD extraClass={cn(
              'tw-text-green-500',
              item?.status === 'pending' && 'tw-text-orange-300',
              item?.status === 'rejected' && 'tw-text-red-500',
            )} value={item?.status || 'N/A'} />
            <TD value={item.userCount} />
            <TD value={item.createdBy} />
            <TD value={moment(item.dateCreated).format('DD-MMM-YYYY - hh:mm a')} />

            <TD>
              <div onClick={() => handleOnSelect(item)}>
                <TDMORE
                  dropdownList={dropdownList}
                  onActionClick={item => handleMoreIconOptions(item)}
                  setSelectedItem={setSelectedItem}
                />
              </div>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
}

export default RolesAndPermissionTable;
