import Modal from 'react-modal';
import { colors, boxShadow, capitalizeFirstLetter } from '../../utils';
import { Dictionary } from '../../types';
import { H3, H4 } from '../../styles';
import { ZojaButton } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { createInitiateFreezeRequest, createInitiateFreezeReset } from '../../redux/slice';
import { useEffect } from 'react';
import { showMessage } from '../../utils';
import { ProhibitionFormat } from '../../types';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  prohibitData: Dictionary;
  setProhibitionRequestModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  restrictType: ProhibitionFormat;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: `1px solid ${colors.smokeWhite}`,
    boxShadow: boxShadow.light,
  },
  overlay: { backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999 },
};

function ConfirmProhibitionModal({
  isModalVisible,
  closeModal,
  prohibitData,
  setProhibitionRequestModalVisible,
  restrictType,
}: IProps) {
  const dispatch = useAppDispatch();

  const { account_name, account_number, duration } = prohibitData;

  const createInitiateFreezeState = useAppSelector(state => state.createInitiateFreeze);

  const {
    data: { status_code },
    status: createInitiateFreezeStatus,
  } = createInitiateFreezeState;

  const handleProhibitUser = () => {
    const { reason_for, description_of_reason, restriction_type, account_id, proofs } = prohibitData;

    const formData = new FormData();
    //must be appended for all restriction types
    formData.append('restriction_type', restriction_type);
    formData.append('account_id', account_id);
    formData.append('reason_for', reason_for);
    formData.append('description_of_reason', description_of_reason);

    //must be appended for restrict
    if (restrictType === 'restrict') {
      formData.append('duration', duration);
    }

    //optional
    if (restrictType === 'freeze' || restrictType === 'restrict') {
      if (proofs.length > 0) {
        for (let i = 0; i < proofs.length; i++) {
          formData.append('proofs[]', proofs[i]);
        }
      }
    }

    dispatch(createInitiateFreezeRequest(formData));
  };

  useEffect(() => {
    //I used this method because the backend is returning a different status code that redux will trigger as successful instead of fail.
    if (status_code === 208) {
      const {
        data: {
          error: { message },
        },
      } = createInitiateFreezeState;

      showMessage({
        type: 'error',
        message,
      });
      dispatch(createInitiateFreezeReset());
      return;
    }

    const { error, status } = createInitiateFreezeState;

    if (status_code === 200 || (error === null && status === 'succeeded')) {
      closeModal();
      setProhibitionRequestModalVisible(true);
      dispatch(createInitiateFreezeReset());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInitiateFreezeState]);

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={!!isModalVisible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Confirm Prohibition Modal">
        <div className="tw-w-[60vh] tw-p-1 tw-flex tw-flex-col tw-space-y-4">
          <H3 left bold color={colors.greyVariantEight}>
            {`${capitalizeFirstLetter(restrictType)} User Account`}
          </H3>
          {(restrictType === 'restrict' || restrictType === 'freeze') && (
            <H4 left light color={colors.greyVariantOne}>
              Confirm that you want to initiate
              <span className="tw-font-bold tw-text-isPrimary">{' ' + capitalizeFirstLetter(restrictType) + ' '}</span>
              for
              <span className="tw-font-bold tw-text-isPrimary">{' ' + account_name + ' '}</span>
              with account number
              <span className="tw-font-bold tw-text-isPrimary">{' ' + account_number + ' '}</span>
              from performing outgoing transactions,
              {restrictType === 'restrict' && ` for`}
              {restrictType === 'restrict' && duration && (
                <span className="tw-font-bold tw-text-isPrimary">{' ' + duration + ' '}</span>
              )}
              until a review is carried out on their account.
            </H4>
          )}

          {(restrictType === 'unrestrict' || restrictType === 'unfreeze') && (
            <H4 left light color={colors.greyVariantOne}>
              Confirm that you want to initiate
              <span className="tw-font-bold tw-text-isPrimary">{' ' + capitalizeFirstLetter(restrictType) + ' '}</span>
              for
              <span className="tw-font-bold tw-text-isPrimary">{' ' + account_name + ' '}</span>
              with account number
              <span className="tw-font-bold tw-text-isPrimary">{' ' + account_number + ' '}</span>
              and return their account to normal behaviour.
            </H4>
          )}

          <div className="tw-w-full tw-flex tw-justify-end tw-space-x-8">
            <ZojaButton
              text="Cancel"
              extraClass={`tw-p-1 tw-px-4 tw-font-normal tw-bg-isWhite tw-text-isPrimary tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
              type="button"
              onClick={
                createInitiateFreezeStatus === 'loading'
                  ? () => {}
                  : () => {
                      closeModal();
                    }
              }
            />
            <ZojaButton
              text={`Yes, ${capitalizeFirstLetter(restrictType)}`}
              extraClass={`tw-p-1 tw-px-4 tw-font-normal tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine ${
                restrictType === 'freeze'
                  ? `tw-bg-isRed`
                  : restrictType === 'restrict'
                  ? `tw-bg-[#FFAF56]`
                  : restrictType === 'unfreeze'
                  ? `tw-bg-[#89CFF0]`
                  : `tw-bg-[#4EA78A]`
              }`}
              type="button"
              onClick={() => {
                handleProhibitUser();
              }}
              disabled={createInitiateFreezeStatus === 'loading'}
              loading={createInitiateFreezeStatus === 'loading'}
              loadingClass="tw-justify-center tw-gap-2 tw-items-center"
              loadingText="Loading..."
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ConfirmProhibitionModal;
