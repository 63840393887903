import { useEffect } from 'react';
import useClickOutside from '../../../utils/hooks/useClickOutside';

interface IProps {
  handleClose: () => void;
  closeOnClickOutside?: boolean;
  extraClass?: string;
  contentRef?: any;
  dropdownList?: any;
  setSelectedItem?: any;
  //   height: 'full' | 'auto';
  //   position: 'center' | 'left' | 'right' | 'custom';
  width?: string;
  borderRadius?: string;
  onActionClick: (item: string) => any;
}

const ZojaDropdown = ({
  closeOnClickOutside = false,
  contentRef,
  handleClose,
  dropdownList,
  onActionClick,
  setSelectedItem,
}: IProps) => {
  const { isClickOutside } = useClickOutside(contentRef);
  //   console.log(isClickOutside && closeOnClickOutside && handleClose(), '');

  //   handleClose();
  useEffect(() => {
    isClickOutside && closeOnClickOutside && handleClose();
  }, [closeOnClickOutside, handleClose, isClickOutside]);
  return (
    <div
      className="tw-absolute tw-right-0 tw-w-[163px] tw-px-4 tw-py-2 tw-bg-white tw-rounded-lg tw-flex-col tw-justify-start tw-items-start tw-gap-2 tw-flex tw-z-30 tw-shadow
    "
      //   onClick={() => isClickOutside && closeOnClickOutside && handleClose()}
      ref={contentRef}>
      <h2 className="tw-text-gray-700 tw-text-base tw-font-normal tw-border-b tw-border-zinc-400 tw-w-full">More</h2>
      {dropdownList?.map((item: any) => (
        <p
          className="tw-text-slate-500 tw-text-xs tw-font-normal tw-cursor-pointer tw-w-full"
          key={item.id}
          onClick={() => {
            onActionClick(item);
            setSelectedItem(item);
          }}>
          {item.title}
        </p>
      ))}
    </div>
  );
};

export default ZojaDropdown;
