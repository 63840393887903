import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import { TfiClose } from 'react-icons/tfi';
import { Oval } from 'react-loader-spinner';
import * as yup from 'yup';
import {
  AppContainer,
  AccountManagementInformation,
  AccountManagementTransactions,
  InitiateProhibitionModal,
  ConfirmProhibitionModal,
  SuccessfulProhibitRequestModal,
} from '../../atoms';
import { ZojaButton, ZojaInput } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { getSearchCustomerAccountStatement, getCustomerTransactions } from '../../redux/slice';
import { colors } from '../../utils';
import { H1, H5 } from '../../styles';
import { ProhibitionFormat } from '../../types';

interface inititialValues {
  account_number: string;
}

export default function AccountManagement() {
  const dispatch = useAppDispatch();

  const [showdetails, setShowDetails] = useState(false);
  const [showSearchedRecords, setShowSearchedRecords] = useState(false);
  const [restrictType, setRestrictType] = useState<ProhibitionFormat>('freeze');

  //MODAL VISIBILITY CONTROLS
  const [initiateProhibitionModalVisible, setInitiateProhibitionModalVisible] = useState(false);
  const [confirmProhibitionModalVisible, setConfirmProhibitionModalVisible] = useState(false);
  const [successfulProhibitionRequestModalVisible, setSuccessfulProhibitionRequestModalVisible] = useState(false);

  //I seperated each data due to pressure and complications from the Product team always changing the requirements.
  //Probably when the requirements have been clarified by the Product team, This flow can be combined to aviod code duplication.

  //Data for FREEZING a User
  const [reasonForFreeze, setReasonForFreeze] = useState<string | number>('suspicious transaction');
  const [freezeDescription, setFreezeDescription] = useState('');
  const [freezeFile, setFreezeFile] = useState<null | FileList>(null);

  //Data for RESTRICTING a User
  const [reasonForRestriction, setReasonForRestriction] = useState<string | number>('suspicious transaction');
  const [restrictionDescription, setRestrictionDescription] = useState('');
  const [restrictionFile, setRestrictionFile] = useState<null | FileList>(null);
  const [restrictionDuration, setRestrictionDuration] = useState('1hour');

  //Data for UNFREEZING a User
  const [reasonForUnfreeze, setReasonForUnfreeze] = useState<string | number>('suspicious transaction');
  const [unfreezeDescription, setUnfreezeDescription] = useState('');

  //Data for UNRESTRICTING a User
  const [reasonForUnrestrict, setReasonForUnrestrict] = useState<string | number>('suspicious transaction');
  const [unrestrictDescription, setUnrestrictDescription] = useState('');

  const searchCustomerAccountStatement = useAppSelector(state => state.searchCustomerAccountStatement);

  const fetchDetails = (values: inititialValues) => {
    const detailsPayload = { account_id_phone_number: values.account_number, page: 1, per_page: 10 };
    dispatch(getSearchCustomerAccountStatement(detailsPayload));
  };

  const fetchTransactions = (values: inititialValues) => {
    const transactionsPayload = {
      account_id_phone_number: values.account_number,
      page: 1,
      per_page: 10,
      date_range: '',
    };
    dispatch(getCustomerTransactions(transactionsPayload));
  };

  const handleSearchAccount = (values: inititialValues) => {
    setShowSearchedRecords(true);

    //fetch details
    fetchDetails(values);
    //fetch transactions
    fetchTransactions(values);
  };

  const validationSchema = yup.object().shape({
    account_number: yup
      .string()
      .required('Account number is required.')
      .max(10, 'Account number must be 10 digits.')
      .min(10, 'Account number must be 10 digits.'),
  });

  const { values, errors, handleChange, handleSubmit, touched, resetForm } = useFormik({
    initialValues: { account_number: '' },
    validationSchema: validationSchema,
    onSubmit: handleSearchAccount,
  });

  const customHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowSearchedRecords(false);
    handleChange(event);
  };

  useEffect(() => {
    if (!initiateProhibitionModalVisible && !confirmProhibitionModalVisible) {
      setFreezeDescription('');
      setFreezeFile(null);
      setRestrictionDescription('');
      setRestrictionFile(null);
      setUnfreezeDescription('');
      setUnrestrictDescription('');
    }
  }, [initiateProhibitionModalVisible, confirmProhibitionModalVisible]);

  useEffect(() => {
    if (!successfulProhibitionRequestModalVisible && values.account_number) {
      fetchDetails({ account_number: values.account_number });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfulProhibitionRequestModalVisible]);

  return (
    <AppContainer
      navTitle={
        <span className="tw-ml-3 tw-text-[#5E6366] tw-text-xs tw-font-medium md:tw-text-[1.2rem]">
          Account Management
        </span>
      }>
      <>
        {!showdetails ? (
          <div className="tw-p-2 tw-w-full tw-h-[80vh] tw-flex tw-items-center tw-justify-center">
            <div className="tw-flex tw-flex-col tw-w-[70%] tw-items-start">
              <H1 color={colors.black} bold>
                Search For a User Here
              </H1>

              <form onSubmit={handleSubmit} className="tw-w-full tw-flex tw-gap-3 tw-mt-3 tw-mb-0">
                <div className="tw-w-full tw-mb-0">
                  <ZojaInput
                    value={values.account_number}
                    onChange={customHandleChange}
                    name="account_number"
                    touched={touched}
                    error={errors.account_number}
                    placeholder="Enter account number"
                    extraClass={`tw-border-gray-300 tw-p-2 tw-px-10 placeholder:tw-text-sm focus:tw-outline-none focus:tw-border-isPrimary tw-shadow-zojaShadowNine tw-text-isPrimary`}
                    icon={<CiSearch />}
                    iconColor={colors.primary}
                    iconPosition={`left`}
                    hasClearIcon={true}
                    clearIcon={<TfiClose size={14} />}
                    onClear={resetForm}
                  />
                </div>

                <ZojaButton
                  text="Search"
                  extraClass={`tw-p-3 tw-px-8 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-sm tw-text-xs tw-shadow-zojaShadowNine ${
                    searchCustomerAccountStatement.status === 'loading' && 'tw-h-10'
                  }`}
                  type="submit"
                  disabled={searchCustomerAccountStatement.status === 'loading'}
                />
              </form>

              {showSearchedRecords && touched.account_number && (
                <div
                  onClick={
                    searchCustomerAccountStatement.status === 'succeeded' ? () => setShowDetails(true) : () => {}
                  }
                  className={`tw-w-[40%] md:tw-w-[60%] tw-h-8 tw-mt-1 tw-flex tw-items-center tw-justify-center tw-bg-isPrimary tw-rounded-sm ${
                    searchCustomerAccountStatement.status === 'succeeded' ? `tw-cursor-pointer` : `tw-cursor-default`
                  }`}>
                  {searchCustomerAccountStatement.status === 'loading' ? (
                    <Oval
                      height="14"
                      width="14"
                      color="#ffffff"
                      ariaLabel="tail-spin-loading"
                      secondaryColor="#707bf12b"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                    />
                  ) : searchCustomerAccountStatement.status === 'succeeded' ? (
                    <H5 color={colors.white} light>
                      {searchCustomerAccountStatement?.data?.account_name}
                    </H5>
                  ) : searchCustomerAccountStatement.status === 'failed' ? (
                    <H5 color={colors.white} light>
                      Invalid Account Number
                    </H5>
                  ) : searchCustomerAccountStatement.status === 'idle' ? (
                    <H5 color={colors.white} light>
                      Input the account number to search
                    </H5>
                  ) : (
                    <H5 color={colors.white} light>
                      No user found.
                    </H5>
                  )}
                </div>
              )}

              <h3 className="tw-text-isGreyVariantOne tw-text-base tw-font-light tw-m-0 tw-text-left ">
                Search for a user’s account information and perform financial actions related to that user
              </h3>
            </div>
          </div>
        ) : (
          <div className="tw-mt-6 tw-w-full tw-bg-none tw-shadow-zojaShadowNine tw-py-6 tw-flex tw-flex-col tw-space-y-10">
            {/* Account Summary */}
            <AccountManagementInformation
              setShowDetails={setShowDetails}
              setInitiateProhibitionModalVisible={setInitiateProhibitionModalVisible}
              setRestrictType={setRestrictType}
            />

            {/* Account Transactions */}
            <AccountManagementTransactions account_number={values.account_number} />
          </div>
        )}

        {/* NEW MODALS/ALL MODALS */}
        <>
          {/* Modal to Initiate Restriction/Freeze*/}
          <>
            <InitiateProhibitionModal
              isModalVisible={initiateProhibitionModalVisible}
              closeModal={() => setInitiateProhibitionModalVisible(false)}
              userToProhibitData={
                searchCustomerAccountStatement?.data
                  ? {
                      account_name: searchCustomerAccountStatement.data?.account_name,
                      account_number: searchCustomerAccountStatement.data?.account_number,
                      user_kyc_level: searchCustomerAccountStatement.data?.user_kyc_level,
                      passport: searchCustomerAccountStatement.data?.passport
                        ? searchCustomerAccountStatement.data?.passport
                        : '',
                    }
                  : {
                      account_name: 'N/A',
                      account_number: values.account_number,
                      user_kyc_level: 'N/A',
                      passport: '',
                    }
              }
              restrictType={restrictType}
              setRestrictType={setRestrictType}
              setConfirmProhibitionModalVisible={setConfirmProhibitionModalVisible}
              //details for prohibition controls (freeze)

              setReasonForFreeze={setReasonForFreeze}
              freezeDescription={freezeDescription}
              setFreezeDescription={setFreezeDescription}
              freezeFile={freezeFile}
              setFreezeFile={setFreezeFile}
              //details for prohibition controls (restrict)

              setRestrictionDuration={setRestrictionDuration}
              setReasonForRestriction={setReasonForRestriction}
              restrictionDescription={restrictionDescription}
              setRestrictionDescription={setRestrictionDescription}
              restrictionFile={restrictionFile}
              setRestrictionFile={setRestrictionFile}
              //details for prohibition controls (unfreeze)

              setReasonForUnfreeze={setReasonForUnfreeze}
              unfreezeDescription={unfreezeDescription}
              setUnfreezeDescription={setUnfreezeDescription}
              //details for prohibition controls (unrestrict)

              setReasonForUnrestrict={setReasonForUnrestrict}
              unrestrictDescription={unrestrictDescription}
              setUnrestrictDescription={setUnrestrictDescription}
            />
          </>

          {/* Modal to Confirm any prohibition*/}
          <>
            <ConfirmProhibitionModal
              isModalVisible={confirmProhibitionModalVisible}
              closeModal={() => setConfirmProhibitionModalVisible(false)}
              prohibitData={{
                account_name: searchCustomerAccountStatement?.data?.account_name,
                account_number: searchCustomerAccountStatement?.data?.account_number,
                //things needed for the APIcall
                reason_for:
                  restrictType === 'restrict'
                    ? reasonForRestriction
                    : restrictType === 'freeze'
                    ? reasonForFreeze
                    : restrictType === 'unrestrict'
                    ? reasonForUnrestrict
                    : reasonForUnfreeze,
                duration: restrictType === 'restrict' ? restrictionDuration : '',
                description_of_reason:
                  restrictType === 'restrict'
                    ? restrictionDescription
                    : restrictType === 'freeze'
                    ? freezeDescription
                    : restrictType === 'unrestrict'
                    ? unrestrictDescription
                    : unfreezeDescription,
                restriction_type: restrictType,
                account_id: searchCustomerAccountStatement?.data?.id,
                proofs:
                  restrictType === 'restrict' && restrictionFile
                    ? Array.from(restrictionFile)
                    : restrictType === 'freeze' && freezeFile
                    ? Array.from(freezeFile)
                    : [],
              }}
              setProhibitionRequestModalVisible={setSuccessfulProhibitionRequestModalVisible}
              restrictType={restrictType}
            />
          </>

          {/* Successfully Initiated any type of prohibition*/}
          <>
            <SuccessfulProhibitRequestModal
              isModalVisible={successfulProhibitionRequestModalVisible}
              closeModal={() => setSuccessfulProhibitionRequestModalVisible(false)}
              requestData={{
                account_name: searchCustomerAccountStatement?.data?.account_name,
                account_number: searchCustomerAccountStatement?.data?.account_number,
              }}
              restrictType={restrictType}
            />
          </>
        </>
      </>
    </AppContainer>
  );
}
