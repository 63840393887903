import { cn } from '../../lib/utils';
import { toggleItemChecked } from '../../utils';
import ZojaCheckbox from '../checkbox';

interface GrantPermissionsProps {
  selected: any;
  setSelected: any;
  modules_permissions: any[];
  attachedPermissions: any[];
  setAttachedPermissions: (arg: any) => void;
  className?: string;
  itemName?: string;
  error?: string;
  showCheckbox?: boolean;
}



const GrantPermissions = ({
  selected,
  setSelected,
  modules_permissions,
  attachedPermissions,
  setAttachedPermissions,
  className,
  itemName,
  showCheckbox = true,
  error,
}: GrantPermissionsProps) => {
  
  // set permissions to state on click of checkbox
  // remove permission if permission already exist 
  const handleAttachPermission = (data: string) => {
    setAttachedPermissions((prevArray: any) =>
      prevArray.includes(data)
        ? prevArray.filter((item: any) => item !== data)
        : [...prevArray, data]
    );
  }

  return (
    <div>
      <div className={cn(
        'tw-py-5 tw-px-5 tw-border tw-rounded-md',
        className
      )}>
        <div className="tw-flex tw-flex-col tw-gap-x-4 tw-gap-y-2 tw-h-[14rem] tw-overflow-y-scroll">
          {modules_permissions?.length ? modules_permissions?.map((item: any) => (
            <div className="tw-p-4 tw-m-2 tw-rounded-md tw-shadow-md tw-shadow-gray-200" key={item.id}>
              <p className="tw-m-0 tw-text-gray-700 tw-font-normal tw-text-[.9rem] tw-w-full">{item.module_name}</p>
              <hr className='tw-m-0 tw-p-0 tw-border-gray-400' />
              <div className="tw-mt-3 tw-flex tw-flex-wrap tw-gap-x-4 tw-gap-y-">
                {item?.permissions?.map((item: any) => (
                  <div className="tw-flex tw-items-center tw-gap-x-3" key={item.id}>
                    <p className="tw-m-0 tw-text-[#758089] tw-text-[.9rem">{item.name}</p>
                    {showCheckbox &&
                      <ZojaCheckbox
                        isChecked={attachedPermissions?.includes(item.name)}
                        className={cn(
                          "tw-mb-3",
                          item.name === 'Dashboard' && 'tw-bg-gray-300'
                        )}
                        // onClick={() => toggleItemChecked(item.id, selected, itemName, setSelected)}
                        onClick={() => handleAttachPermission(item.name)}
                      />}
                  </div>
                ))}
              </div>
            </div>
          )) : <div>Permission not available</div>

          }
        </div>
      </div>
    </div>
  );
};

export default GrantPermissions;
