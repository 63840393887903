import { memo, useRef, useState } from 'react';
import { ReactComponent as CalendarIcon } from '../../assets/svg/calender.svg';
import DatePicker from 'react-multi-date-picker';
import { formatRMDatePicker, yearDateFormat } from '../../utils';
import { Dictionary } from '../../types';
import { useFormik } from 'formik';

interface IProps {
  selectedDate: any;
  onCalendarClose?: () => void;
  onCalendarOpen?: () => void;
  onClickOutside?: () => void;
  onFocus?: () => void;
  onSelect?: () => void;
  required?: boolean;
  placeholderText?: string;
  timeFormat?: string;
  wrapperClassName?: string;
  readOnly?: boolean;
  preventOpenOnFocus?: boolean;
  openToDate?: any;
  name?: string;
  minDate?: any;
  maxDate?: any;
  title?: string;
}

const initialValues: Dictionary = {
  start_date: '',
};

function RDatePicker({ selectedDate, title }: IProps) {
  const [date, setDate] = useState<any>('');
  const startDateRef = useRef<any>(null);

  const { values } = useFormik({
    initialValues: initialValues,
    onSubmit: values => {
      console.log(values);
    },
  });
  return (
    <div className="tw-flex tw-flex-col">
      <div className="tw-flex tw-flex-row tw-items-end tw-gap-[-10px]">
        <div className="tw-flex tw-flex-col tw-p-0 tw-m-0">
          <span className="tw-text-sm tw-text-[#222B88]">{title}</span>

          <div className={`tw-flex-co tw-gap-x-1 md:tw-flex`}>
            <div className="tw-text-[11.3px] tw-text-gray-400 tw-p-[2px] tw-rounded-[4px] tw-flex tw-gap-3 tw-relative">
              <span className={`${date && 'tw-text-gray-600 tw-font-normal'}`}>
                {date !== '' ? yearDateFormat(date) : 'DD/MM/YYYY'}
              </span>{' '}
              <span
                className="tw-text-lg tw-text-isPrimary tw-cursor-pointer"
                onClick={() => startDateRef.current.openCalendar()}>
                <CalendarIcon />{' '}
              </span>
              <DatePicker
                onClose={() => {
                  values.start_date = formatRMDatePicker(date);
                }}
                value={date}
                ref={startDateRef}
                onOpenPickNewDate={false}
                containerClassName="tw-absolute tw-bottom-2 tw-w-[1rem] tw-text-md tw-hidden -tw-mt-7"
                inputClass="tw-bg-inherit tw-outline-none tw-hidden"
                onChange={(date: any) => {
                  setDate(date);
                  selectedDate(date);
                  values.start_date = formatRMDatePicker(date);
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="tw-flex tw-w-full tw-h-[2px] tw-bg-black"></div>
    </div>
  );
}

export default memo(RDatePicker);
