import { Routes, Route } from 'react-router-dom';
import { Dashboard, TransactionInformation } from '../pages/dashboard';
import { Login } from '../pages/login';
import { Kyc, PendingKyc, RejectedKyc, VerifiedKyc } from '../pages/kyc';
import { Support } from '../pages/support';
import { Users, RoleDetails, UserDetails, CreateRole, RolesPermissions } from '../pages/users';
import { Customers } from '../pages/customers';
import { Settlements, Reconciliation, ReconcilationUserDetails } from '../pages/settlements';
import {
  NewNotificationPage,
  NotificationUpdate,
  NewArticles,
  NewFaq,
  Settings,
  UpdateArticle,
  EditFaq,
} from '../pages/settings';
import {
  FinanceReport,
  GeneralReport,
  IncomeReport,
  Report,
  TransactionReport,
  UserAccessReport,
  UserRegistrationReport,
  UserReport,
  VolumeReport,
  ReconciliationReport,
} from '../pages/report';
import { NotFound } from '../pages/notFound';
import {
  Freeze,
  MyFreezeRequests,
  UnFreeze,
  UnfreezeAccount,
  ApproveFreezeRequest,
  ApproveUnFreezeRequest,
  RejectedRequests,
  Ban,
  UnbanAccount,
  ApproveUnbanRequest,
  RejectedBanRequests,
  UnbanRequests,
  Restrictions,
  RestrictionRequests,
  UnRestrictionRequests,
  RejectedRestrictionRequests,
  RestrictionRequestsInitiator,
  UnrestrictAccount,
  ApproveRestrictRequest,
  ApproveUnrestriction,
} from '../pages/account';
import { PrivateRoute } from './privateRoute';
import { routesPath } from '../utils';
import { Transactions } from '../pages/transactions';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import KycCustomerInformation from '../pages/kyc/KycCustomerInformation';
import { AgentProfile, CashRequestInformation, EventCashRequest, EventCashRequestSettings } from '../pages/cashRequest';
// import AccountTransaction from '../pages/account/transaction';
import { AccountTransaction, ApproveBanRequest, BanAndUnban, AccountManagement } from '../pages/account';
import { PaymentPage } from '../pages/payments';
import AdminRequest from '../pages/users/newRequest';
import Departments from '../pages/departments/departments';
import ClaimAccount from '../pages/onboarding/claimAccount';

function AppRoute() {
  const {
    LOGIN,
    CLAIMACCOUNT,
    DASHBOARD,
    SUPPORT,
    KYC,
    SETTLEMENTS,
    RECONCILIATION,
    RECONCILIATIONUSERDETAILS,
    USERS,
    ACCOUNTMANAGEMENT,
    BANUNBAN,
    SETTINGS,
    VERIFIEDKYC,
    PENDINGKYC,
    REJECTEDKYC,
    FREEZEREQUESTS,
    UNFREEZEREQUESTS,
    UNBANREQUESTS,
    APPROVEBANREQUEST,
    UNBANACCOUNT,
    REJECTEDREQUEST,
    REJECTEDBANREQUESTS,
    APPROVEFREEZEREQUEST,
    APPROVEUNFREEZEREQUEST,
    // USERDETAILS,
    MYFREEZEREQUEST,
    APPROVEUNBANREQUEST,
    TRANSACTIONS,
    TRANSACTIONINFORMATION,
    NEWAPPNOTIFICATION,
    APPNOTIFICATIONUPDATE,
    EMAILNOTIFICATION,
    EMAILNOTIFICATIONUPDATE,
    NEWARTICLE,
    ARTICLEUPDATE,
    NEWFAQ,
    FAQUPDATE,
    USERROLES,
    ROLESANDPERMISSIONS,
    DEPARTMENT,
    ADMIN_REQUEST,
    CREATEUSERROLES,
    CASHREQUESTINFORMATION,
    EVENTCASHREQUESTINFORMATION,
    EVENTCASHREQUESTSETTINGS,
    KYCCUSTOMERINFORMATION,
    REPORT,
    GENERATEDREPORT,
    VOLUMEREPORT,
    USERREPORT,
    INCOMEREPORT,
    TRANSACTIONREPORT,
    USERACTTIVITYREPORT,
    USERREGREPORT,
    RECONCILIATIONREPORT,
    FINANCEREPORT,
    AGENTPROFILE,
    PAYMENTS,
    SINGLE_PAYMENTS,
    BULK_PAYMENTS,
    INITIATE_SINGLE_PAYMENTS,
    CUSTOMERS,
    CUSTOMERDETAILS,
    UNFREEZEACCOUNT,
    RESTRICTIONACCOUNTS,
    RESTRICTIONS,
    RESTRICTIONREQUESTS,
    UNRESTRICTIONREQUESTS,
    REJECTEDRESTRICTIONREQUESTS,
    RESTRICTIONREQUESTSINITIATOR,
    UNRESTRICTACCOUNT,
    APPROVERESTRICTREQUEST,
    REVIEWRESTRICTREQUEST,
    APPROVEUNRESTRICTREQUEST,
  } = routesPath;
  // const authState = useAppSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route path={LOGIN} element={<Login />} />
        <Route path={CLAIMACCOUNT} element={<ClaimAccount />} />
        <Route element={<PrivateRoute />}>
          <Route path={DASHBOARD} element={<Dashboard />} />
          <Route path={TRANSACTIONINFORMATION} element={<TransactionInformation />} />
          <Route path={CASHREQUESTINFORMATION} element={<CashRequestInformation />} />
          <Route path={EVENTCASHREQUESTINFORMATION} element={<EventCashRequest />} />
          <Route path={EVENTCASHREQUESTSETTINGS} element={<EventCashRequestSettings />} />
          <Route path={`${AGENTPROFILE}:id`} element={<AgentProfile />} />
          <Route path={KYC} element={<Kyc />} />
          <Route path={`${VERIFIEDKYC}`} element={<VerifiedKyc />} />
          <Route path={`${PENDINGKYC}`} element={<PendingKyc />} />
          <Route path={`${REJECTEDKYC}`} element={<RejectedKyc />} />
          <Route path={`${KYCCUSTOMERINFORMATION}:id`} element={<KycCustomerInformation />} />
          {/* I think I have to delete this UserDetails Route permanently, but I am leaving it here for now.  */}
          {/* Route has been changed to CUSTOMERDETAILS */}
          {/* <Route path={`${USERDETAILS}:id`} element={<UserDetails />} /> */}
          <Route path={USERS} element={<Users />} />
          <Route path={BANUNBAN} element={<Ban />} />
          <Route path={FREEZEREQUESTS} element={<Freeze />} />
          <Route path={RESTRICTIONS} element={<Restrictions />} />
          <Route path={RESTRICTIONREQUESTS} element={<RestrictionRequests />} />
          <Route path={RESTRICTIONREQUESTSINITIATOR} element={<RestrictionRequestsInitiator />} />
          <Route path={UNRESTRICTIONREQUESTS} element={<UnRestrictionRequests />} />
          <Route path={REJECTEDRESTRICTIONREQUESTS} element={<RejectedRestrictionRequests />} />
          <Route path={MYFREEZEREQUEST} element={<MyFreezeRequests />} />
          <Route path={REJECTEDREQUEST} element={<RejectedRequests />} />
          <Route path={REJECTEDBANREQUESTS} element={<RejectedBanRequests />} />
          <Route path={UNFREEZEREQUESTS} element={<UnFreeze />} />
          <Route path={UNBANREQUESTS} element={<UnbanRequests />} />
          <Route path={`${APPROVEFREEZEREQUEST}:id`} element={<ApproveFreezeRequest />} />
          <Route path={`${UNRESTRICTACCOUNT}:id`} element={<UnrestrictAccount />} />
          <Route path={`${REVIEWRESTRICTREQUEST}:id`} element={<ApproveRestrictRequest />} />
          <Route path={`${APPROVERESTRICTREQUEST}:id`} element={<ApproveRestrictRequest />} />
          <Route path={`${APPROVEUNRESTRICTREQUEST}:id`} element={<ApproveUnrestriction />} />
          <Route path={`${APPROVEUNBANREQUEST}:id`} element={<ApproveUnbanRequest />} />
          <Route path={`${APPROVEUNFREEZEREQUEST}:id`} element={<ApproveUnFreezeRequest />} />
          <Route path={`${UNFREEZEACCOUNT}:id`} element={<UnfreezeAccount />} />
          <Route path={`${UNBANACCOUNT}:id`} element={<UnbanAccount />} />
          <Route path={ACCOUNTMANAGEMENT} element={<AccountManagement />} />
          <Route path={BANUNBAN} element={<BanAndUnban />} />
          <Route path={SUPPORT} element={<Support />} />
          <Route path={SETTLEMENTS} element={<Settlements />} />
          <Route path={RECONCILIATION} element={<Reconciliation />} />
          <Route path={`${RECONCILIATIONUSERDETAILS}:id`} element={<ReconcilationUserDetails />} />
          <Route path={ROLESANDPERMISSIONS} element={<RolesPermissions />} />
          <Route path={DEPARTMENT} element={<Departments />} />
          <Route path={ADMIN_REQUEST} element={<AdminRequest />} />
          <Route path={`${USERROLES}:id`} element={<RoleDetails />} />
          <Route path={CREATEUSERROLES} element={<CreateRole />} />
          <Route path={SETTINGS} element={<Settings />} />
          <Route path={NEWAPPNOTIFICATION} element={<NewNotificationPage />} />
          <Route path={`${APPNOTIFICATIONUPDATE}:id`} element={<NotificationUpdate />} />
          <Route path={EMAILNOTIFICATION} element={<NewNotificationPage />} />
          <Route path={`${EMAILNOTIFICATIONUPDATE}:id`} element={<NotificationUpdate />} />
          <Route path={NEWARTICLE} element={<NewArticles />} />
          <Route path={`${ARTICLEUPDATE}:id`} element={<UpdateArticle />} />
          <Route path={NEWFAQ} element={<NewFaq />} />
          <Route path={`${FAQUPDATE}:id`} element={<EditFaq />} />
          <Route path={TRANSACTIONS} element={<Transactions />} />
          <Route path={REPORT} element={<Report />} />
          <Route path={VOLUMEREPORT} element={<VolumeReport />} />
          <Route path={USERREPORT} element={<UserReport />} />
          <Route path={INCOMEREPORT} element={<IncomeReport />} />
          <Route path={FINANCEREPORT} element={<FinanceReport />} />
          <Route path={RECONCILIATIONREPORT} element={<ReconciliationReport />} />
          <Route path={USERACTTIVITYREPORT} element={<UserAccessReport />} />
          <Route path={USERREGREPORT} element={<UserRegistrationReport />} />
          <Route path={GENERATEDREPORT} element={<GeneralReport />} />
          <Route path="/generalreport/:componentName" element={<GeneralReport />} />

          <Route path={TRANSACTIONREPORT} element={<TransactionReport />} />
          {/* <Route path={PAYMENTS} element={<PaymentPage />} /> */}
          <Route path={SINGLE_PAYMENTS} element={<PaymentPage />} />
          <Route path={BULK_PAYMENTS} element={<PaymentPage />} />
          <Route path={CUSTOMERS} element={<Customers />} />
          <Route path={`${CUSTOMERDETAILS}:id`} element={<UserDetails />} />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route
          path="/test"
          element={
            <>
              <div className="tw-w-1/2 tw-mx-auto">
                {Array.from({ length: 3 }, (_, idx) => (
                  <Skeleton key={idx} count={2} className="tw-w-full tw-bg-red-400" />
                ))}
              </div>
            </>
          }
        />
      </Routes>
    </>
  );
}

export default AppRoute;
