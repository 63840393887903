import { ReactElement } from 'react';

interface TRProps {
  id?: string;
  children?: ReactElement | ReactElement[];
  onClick?: () => void | null;
}

type THProps = {
  id?: string | number;
  value?: any;
};
interface TDProps extends TRProps {
  value?: any;
  extraClass?: string;
}

type TBODYProps = {
  children: ReactElement | ReactElement[];
};

type THEADProps = {
  children: ReactElement | ReactElement[];
};

const TR = ({ id, children, onClick }: TRProps) => {
  return (
    <tr
      key={id}
      className="tw-text-xs tw-capitalize  tw-p-6 tw-shadow-xs tw-cursor-pointer tw-rounded-3xl"
      onClick={onClick}>
      {' '}
      {children}{' '}
    </tr>
  );
};

const TH = ({ id, value }: THProps) => {
  return (
    <th
      key={id}
      className="tw-p-4 tw-px-8 tw-py-5 tw-text-sm tw-font-[500] tw-left tw-whitespace-nowrap tw-text-[#263238]">
      {value}
    </th>
  );
};

const TD = ({ id, value, onClick, children, extraClass }: TDProps) => {
  return (
    <td
      className={`${extraClass} tw-px-8 tw-py-5 tw-text-sm text-zinc-600 tw-font-normal tw-whitespace-nowrap tw-relative`}
      onClick={onClick}>
      {value}
      {children}
    </td>
  );
};

const TBODY = ({ children }: TBODYProps) => {
  return <tbody>{children}</tbody>;
};

const THEAD = ({ children }: THEADProps) => {
  return <thead className="tw-border-b-2 tw-border-gray-200"> {children} </thead>;
};

export { TH, TD, TR, THEAD, TBODY };
