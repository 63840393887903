import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AppContainer } from '../../atoms';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { downloadCashRequest, downloadCashRequestReset, getCashRequest } from '../../redux/slice';
import { Dictionary } from '../../types';
import { getSelectedOptionValue, handleSelectedOption, routesPath, useDelayedFunction } from '../../utils';
import {
  DownloadSuccessModal,
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  Pagination,
  ZojaCheckbox,
  ZojaTable,
} from '../../components';
import { TD, TR } from '../../components/tailwind/table/tableElements';
import { icons } from '../../utils';
import { ZojaInput, ZojaSelect } from '../../components/tailwind';
import { dateTimeFormat3 } from '../../utils/dateFormat';
const { DASHBOARD, CASHREQUESTINFORMATION, EVENTCASHREQUESTINFORMATION } = routesPath;

const cashRequestTabs: Dictionary = [
  {
    id: 1,
    title: 'Active Cash Requests',
    url: CASHREQUESTINFORMATION,
  },
  {
    id: 2,
    title: 'Event Cash Request',
    url: EVENTCASHREQUESTINFORMATION
  },
];

const activeCashRequestTableData = {
  columns: [
    {
      id: 1,
      title: '#',
    },
    {
      id: 2,
      title: 'Cash Requester',
    },
    {
      id: 3,
      title: 'Reference',
    },
    {
      id: 4,
      title: 'Requester Location',
    },
    {
      id: 5,
      title: 'Status',
    },
    {
      id: 6,
      title: 'Cash Provider',
    },
    {
      id: 7,
      title: 'Amount',
    },
    {
      id: 8,
      title: 'Provider Location',
    },
    {
      id: 9,
      title: 'Date-Time',
    },
  ],
  data: [
    {
      id: 1,
      cash_requester: 'Ayomide',
      reference: '#KHJHGFHGJHKJXC',
      requester_location: 'Lagos Ikoyi',
      status: 'pending',
      cash_provider: '',
      amount: '40000',
      provider_location: '-',
      created_at: '11/11/2023- 12:15am',
    },
    {
      id: 2,
      cash_requester: 'Ayomide',
      reference: '#KHJHGFHGJHKJXC',
      requester_location: 'Lagos Ikoyi',
      status: 'accepted',
      cash_provider: '',
      amount: '70000',
      provider_location: '-',
      created_at: '11/11/2023- 12:15am',
    },
  ],
};

const sortByOtions = {
  aphabetically: [
    {
      id: 1,
      value: 'name_asc',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'name_desc',
      title: 'Z-A',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
  ],
};

const initialValues: Dictionary = {
  min_amount: '',
  max_amount: '',
  reference: '',
  sort_by: '',
  order_by: '',
  status: '',
  per_page: '',
  page: '',
};

const CashRequestInformation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(5);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [showAmountRange, setShowAmountRange] = useState(true);
  const [sortBy, setSortBy] = useState(sortByOtions.aphabetically);
  const [orderBy, setOrderBy] = useState(sortByOtions.orderBy);
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const successModalRef = useRef<any>(null);

  const delay = 1000;
  const { delayedFunction } = useDelayedFunction(delay);

  const { data, status } = useAppSelector(state => state.cashRequest);
  const { status: downloadStatus } = useAppSelector(state => state.downloadCashRequest);

  // input changer handler
  const handleInputChange = (e: Dictionary) => {
    const { name, value } = e.target;
    setFilterOptions({ ...filterOptions, [name]: value });
  };

  // handle fetch cash request
  const handleFetchCashRequest = () => {
    dispatch(getCashRequest(filterOptions));
  };
  // handle download cash request
  const handleExportFile = (type: string) => {
    let exportPayload = { ...filterOptions, export: type };
    // console.log(exportPayload)
    dispatch(downloadCashRequest(exportPayload));
    setShowExportOptions(false);
  };

  // get selected sort_by options
  useEffect(() => {
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    setFilterOptions({ ...filterOptions, sort_by: getSelectedSortBy });
  }, [sortBy]);

  // get selected order_by options
  useEffect(() => {
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);
    setFilterOptions({ ...filterOptions, order_by: getSelectedOrderBy });
  }, [orderBy]);

  // fetch cash request on component mount
  useEffect(() => {
    dispatch(getCashRequest(filterOptions));
  }, []);

  // close filter on state change
  useEffect(() => {
    setShowFilterOptions(false);
    setShowExportOptions(false);
    setShowSortBy(false);
  }, [status]);

  // update current page and set payload on pagination button click
  useEffect(() => {
    if (data?.cash_requests?.data?.length > 0) {
      const {
        meta: { last_page },
      } = data?.cash_requests;
      setTotalPages(last_page);
      setFilterOptions({ ...filterOptions, page: currentPage });
    }
  }, [data, currentPage]);

  // fetch cash request by reference on input change
  useEffect(() => {
    if (filterOptions.reference.length >= 2 || filterOptions.reference.length === 0) {
      delayedFunction(handleFetchCashRequest);
    }
  }, [filterOptions.reference]);

  useEffect(() => {
    if (downloadStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadStatus]);

  return (
    <div>
      <AppContainer
        goBack={() => navigate(DASHBOARD)}
        navTitle={`DASHBOARD`}
        navHelper={
          <>
            <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-[18px]">
              Active cash requests
            </h4>
          </>
        }
        navBarContentRight={
          <div className="tw-flex tw-items-center tw-gap-x-3 md:tw-gap-x-10 md:tw-mr-6">
            {/* <NotificationIcon onClick={() => setShowNotificationModal(true)} /> */}
          </div>
        }>
        <>
          <DownloadSuccessModal
            show={showSuccessDownloadModal}
            handleClose={() => {
              downloadCashRequestReset();
              setShowSuccessDownloadModal(false);
            }}
            closeOnClickOutside={false}
            contentRef={successModalRef}
            title="Item Downloaded"
            content="Your document has been downloaded to your device"
          />
          <section className="tw-mb-4 md:tw-p-0 md:tw-pt-8 md:tw-px-4 md:tw-mb-8">
            {/* Overlay to hide filter options */}
            {(showFilterOptions || showSortBy || showExportOptions) && (
              <div
                className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
                onClick={() => {
                  setShowFilterOptions(false);
                  setShowSortBy(false);
                  setShowExportOptions(false);
                }}></div>
            )}
            <div className="tw-mb-28 tw-mt-4">
              <div className="tw-flex tw-items-center tw-gap-x-4 md:tw-gap-x-8">
                {cashRequestTabs.map((items: any) => (
                  <Link
                    to={items.url}
                    key={items.id}
                    className={`tw-block tw-text-sm tw-no-underline hover:tw-text-[#222B88] md:tw-text-[16px] ${
                      location.pathname === items.url
                        ? 'tw-text-[#222B88] tw-font-medium tw-border-b-2 tw-border-b-[#222B88]'
                        : 'tw-text-[#6A616F]'
                    }`}>
                    {items.title}
                  </Link>
                ))}
              </div>
              <section className="tw-mt-8 tw-h-[5rem] md:tw-mt-12">
                <div className="tw-my-3 tw-flex tw-flex-col tw-justify-between md:tw-flex-row ">
                  <p className="tw-flex tw-items-center tw-gap-x-2 tw-text-sm tw-text-[#222B88] tw-font-[400] tw-order-1 md:-tw-order-1">
                    <span>Page</span>{' '}
                    <span className="tw-w-6 tw-h-6 tw-rounded-[4px] tw-p-2 tw-bg-isPrimary/5 tw-grid tw-place-content-center">
                      {data?.cash_requests?.meta?.current_page || 0}
                    </span>
                  </p>
                  <div className="tw-flex tw-flex-col tw-gap-2 tw-items-end lg:tw-flex-row lg:tw-gap-x-3 md:tw-items-center">
                    <div className="tw-relative tw-w-full md:tw-mr-4">
                      <input
                        type="text"
                        name="reference"
                        id="reference"
                        value={filterOptions.reference}
                        onChange={handleInputChange}
                        className="tw-w-full tw-pl-1 tw-bg-none tw-border-b tw-text-sm tw-text-isPrimary placeholder:tw-text-xs placeholder:tw-text-[#162082] placeholder:tw-font-thin focus:tw-outline-none md:tw-w-72"
                        placeholder="Search by reference"
                        style={{ backgroundColor: 'transparent' }}
                      />
                      <span
                        className="tw-text-xs tw-absolute tw-right-0 tw-top-1 tw-text-isPrimary tw-cursor-pointer"
                        onClick={handleFetchCashRequest}>
                        <icons.FaSearch />
                      </span>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-x-2 md:tw-gap-x-4">
                      <FilterLabel
                        title="Filter Options"
                        icon={<icons.BsFilter />}
                        onClick={() => setShowFilterOptions(true)}>
                        <FilterCard
                          showFilter={showFilterOptions}
                          title="Filter Options"
                          onClick={handleFetchCashRequest}
                          extraClass="tw-w-[13rem]">
                          <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                            <ZojaSelect
                              options={[
                                { value: '', label: 'Status' },
                                { value: 'processing', label: 'Activated' },
                                { value: 'pending', label: 'Pending' },
                              ]}
                              name="status"
                              showArrown={true}
                              setSelectedOption={selected => setFilterOptions({ ...filterOptions, status: selected })}
                              extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88]"
                              arrowDownClass="tw-right-lg tw-top-3.5"
                            />
                            <FilterColapsible
                              title="Amount"
                              show={showAmountRange}
                              onClick={() => setShowAmountRange(!showAmountRange)}>
                              <>
                                <ZojaInput
                                  label="Min Amount"
                                  id="min_amount"
                                  type="number"
                                  extraClass="tw-text-xs tw-p-2 tw-mb-2"
                                  name="min_amount"
                                  value={filterOptions.min_amount}
                                  onChange={handleInputChange}
                                />
                                <ZojaInput
                                  label="Max Amount"
                                  id="max_amount"
                                  name="max_amount"
                                  type="number"
                                  extraClass="tw-text-xs tw-p-2 tw-mb-2"
                                  value={filterOptions.max_amount}
                                  onChange={handleInputChange}
                                />
                              </>
                            </FilterColapsible>
                          </div>
                        </FilterCard>
                      </FilterLabel>

                      <FilterLabel
                        title="Sort By"
                        icon={<icons.TbArrowsSort color="#5E6366" />}
                        onClick={() => setShowSortBy(true)}>
                        <FilterCard
                          showFilter={showSortBy}
                          title="Sort by"
                          onClick={handleFetchCashRequest}
                          submitButtonText="Apply"
                          extraClass="tw-w-[10rem] -tw-left-28">
                          <>
                            <div className="tw-pt-2">
                              {sortBy.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="tw-pt-2 tw-border-">
                              {orderBy.map((item: any) => (
                                <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                                  <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                                  <ZojaCheckbox
                                    isChecked={item.isChecked}
                                    onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                                  />
                                </div>
                              ))}
                            </div>
                          </>
                        </FilterCard>
                      </FilterLabel>

                      <ExportLabel
                        title="Export"
                        onClick={() => setShowExportOptions(true)}
                        loading={downloadStatus === 'loading' ? true : false}>
                        <FilterCard
                          showFilter={showExportOptions}
                          title="Export as"
                          onClick={() => null}
                          showSubmitButton={false}
                          extraClass="tw-w-[10rem] -tw-left-28">
                          <div className="tw-flex tw-flex-col tw-mt-4">
                            <span
                              className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                              onClick={() => handleExportFile('pdf')}>
                              PDF
                            </span>
                            <span
                              className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                              onClick={() => handleExportFile('csv')}>
                              CSV
                            </span>
                          </div>
                        </FilterCard>
                      </ExportLabel>
                    </div>
                  </div>
                </div>
                {status !== 'loading' && status === 'succeeded' && (
                  <>
                    <div className="tw-mb-4 md:tw-h-[60vh] md:tw-mb-28">
                      <ZojaTable column={activeCashRequestTableData.columns}>
                        {data?.cash_requests?.data?.map((data: Dictionary, idx: number) => (
                          <TR key={data.id}>
                            <TD value={idx + 1} />
                            <TD value={data.recipient} extraClass="tw-font-thin" />
                            <TD value={data.reference} extraClass="tw-font-thin" />
                            <TD value={data.delivery_location} extraClass="tw-font-thin" />
                            <TD
                              value={data.status}
                              extraClass={`${
                                data.status === 'pending'
                                  ? 'tw-font-thin tw-text-orange-400'
                                  : data.status === 'processing'
                                  ? 'tw-font-thin tw-text-green-500'
                                  : 'tw-font-thin'
                              }`}
                            />
                            <TD
                              value={data.provider || '-'}
                              extraClass={`${!data.cash_provider && 'tw-text-center tw-font-thin'}`}
                            />
                            <TD value={data.amount} extraClass="tw-font-thin" />
                            <TD value={data.location} extraClass="tw-font-thin" />
                            <TD value={dateTimeFormat3(data.created_at)} extraClass="tw-font-thin" />
                          </TR>
                        ))}
                      </ZojaTable>
                    </div>
                  </>
                )}
                <div className="tw-pb-2 md:tw-pb-8">
                  {data?.cash_requests?.data?.length > 0 && (
                    <Pagination
                      isLoading={status === 'loading' ? true : false}
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={selectedPage => {
                        setCurrentPage(selectedPage);
                        let currentPage = selectedPage;
                        let filterBy = { ...filterOptions, page: currentPage };
                        console.log(filterBy);
                        dispatch(getCashRequest(filterBy));
                      }}
                    />
                  )}
                </div>
              </section>
            </div>

            {status === 'loading' && (
              <>
                <div className="tw-h-[20vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
                  <Oval
                    height="80"
                    width="80"
                    color="#222b88cf"
                    ariaLabel="tail-spin-loading"
                    secondaryColor="#222b882b"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                </div>
              </>
            )}
            {status === 'failed' && (
              <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                <h4 className="tw-text-gray-500 tw-text-xl">No Record Found</h4>
              </div>
            )}
          </section>
        </>
      </AppContainer>
    </div>
  );
};

export default CashRequestInformation;
