import Modal from 'react-modal';
import { colors, boxShadow, images, capitalizeFirstLetter } from '../../utils';
import { H2, H4 } from '../../styles';
import { ZojaButton } from '../../components/tailwind';
import { ProhibitionFormat } from '../../types';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  requestData: { account_name: string; account_number: string };
  restrictType: ProhibitionFormat;
}

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: `1px solid ${colors.smokeWhite}`,
    boxShadow: boxShadow.light,
  },
  overlay: { backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 999 },
};

function SuccessfulProhibitRequestModal({ isModalVisible, closeModal, requestData, restrictType }: IProps) {
  const { account_name, account_number } = requestData;

  return (
    <>
      <Modal
        ariaHideApp={false}
        isOpen={!!isModalVisible}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Succesfful Prohibit Modal">
        <div className="tw-w-[30vw] tw-p-1 tw-flex tw-flex-col tw-items-center tw-space-y-6 tw-text-center">
          <img src={images.blockSuccess} alt="" className="tw-w-[200px]" />

          <H2 bold color={colors.greyVariantOne}>
            {`${capitalizeFirstLetter(restrictType)} Request Sent`}
          </H2>

          <H4>
            Your
            <span className="tw-font-bold tw-text-isPrimary">{' ' + capitalizeFirstLetter(restrictType) + ' '}</span>
            request for
            <span className="tw-font-bold tw-text-isPrimary  tw-text-center">{' ' + account_name + ' '}</span>
            with account number
            <span className="tw-font-bold tw-text-isPrimary  tw-text-center">{' ' + account_number + ' '}</span>
            has been sent for review and approval.
          </H4>

          <div className="tw-w-full tw-flex tw-justify-center tw-space-x-8">
            <ZojaButton
              text="Finish"
              extraClass={`tw-p-3 tw-px-12 tw-font-normal tw-bg-isPrimary tw-text-white tw-rounded-md tw-text-xs tw-shadow-zojaShadowNine`}
              type="button"
              onClick={closeModal}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SuccessfulProhibitRequestModal;
