import { combineReducers } from '@reduxjs/toolkit';
import {
  loginSliceReducer,
  authSliceReducer,
  logoutSliceReducer,
  getTransactionsSliceReducer,
  getArticlesSliceReducer,
  getReconciliationAccountSliceReducer,
  getReconciliationAccountDetailSliceReducer,
  reconcileAccountSliceReducer,
  getEscalationAgentsSliceReducer,
  createEscalationTicketSliceReducer,
  getTransactionByIdSliceReducer,
  getArticleByIdSliceReducer,
  exportTransactionByIdToMailSliceReducer,
  settlementAnalyticsSliceReducer,
  getKycsSliceReducer,
  getKycsAnalyticsSliceReducer,
  getKycCustomerSliceReducer,
  kycVerificationSliceReducer,
  getSuperAgentsSliceReducer,
  getUsersSliceReducer,
  getUserProfileSliceReducer,
  getUserVerificationsSliceReducer,
  getProfileViewHistorySliceReducer,
  getLoginHistorySliceReducer,
  getUserTransactionsSliceReducer,
  getUserSavedBanksSliceReducer,
  deleteUserSavedBankSliceReducer,
  updateUserStatusSliceReducer,
  getUserProfileTransactionSliceReducer,
  getDocumentHistorySliceReducer,
  getUserSubAgentsSliceReducer,
  getAllTransactionsSliceReducer,
  downloadTransactionByIdSliceReducer,
  downloadTransactionsSliceReducer,
  getInternalUsersSliceReducer,
  getRolesDropDownSliceReducer,
  createInternalUserSliceReducer,
  updateInternalUserSliceReducer,
  resetInternalUserPasswordSliceReducer,
  getRolesSliceReducer,
  createRoleSliceReducer,
  createArticleReducer,
  updateRoleSliceReducer,
  getSingleRoleSliceReducer,
  deleteRoleSliceReducer,
  updateArticleSliceReducer,
  deleteArticleSliceReducer,
  getAllFaqsSliceReducer,
  createFaqSliceReducer,
  getTagsSliceReducer,
  deleteFaqSliceReducer,
  updateFaqSliceReducer,
  getFaqSliceReducer,
  dashboardSliceReducer,
  customerGrowthInsightSliceReducer,
  transactionVolumeSliceReducer,
  getNotificationByIdSliceReducer,
  getNotificationSliceReducer,
  deleteNotificationSliceReducer,
  createNotificationSliceReducer,
  updateNotificationSliceReducer,
  settingsCountSliceReducer,
  cashRequestSliceReducer,
  downloadCashRequestSliceReducer,
  downloadInternalUserRecordSliceReducer,
  downloadRolePermissionSliceReducer,
  downloadUsersRecordSliceReducer,
  downloadTransactionSliceReducer,
  downloadKycCustomerSliceReducer,
  downloadTransactionReportSliceReducer,
  downloadVolumeReportSliceReducer,
  downloadIncomeReportSliceReducer,
  downloadUserReportSliceReducer,
  downloadReconciliationReportSliceReducer,
  downloadGeneralTransactionReportSliceReducer,
  downloadFinanceReportSliceReducer,
  getAllTransactionReportSliceReducer,
  getAllUserReportSliceReducer,
  getAllVolumeReportSliceReducer,
  getAllIncomeReportSliceReducer,
  filterOptionsSliceReducer,
  searchUserAccountSliceReducer,
  userFilterOptionsSliceReducer,
  volumeFilterOptionsSliceReducer,
  incomeFilterOptionsSliceReducer,
  reconciliationFilterOptionsSliceReducer,
  saveReportSliceReducer,
  getAllSavedReportSliceReducer,
  getLocationsSliceReducer,
  getAllGeneralTransactionReportSliceReducer,
  generalTransactionFilterOptionsSliceReducer,
  financeFilterOptionsSliceReducer,
  getAllReconciliationReportSliceReducer,
  getAllFinanceReportSliceReducer,
  getAllUserAccessReportSliceReducer,
  getAllUserRegReportSliceReducer,
  downloadUserAccessReportSliceReducer,
  downloadUserRegReportSliceReducer,
  userAccessFilterOptionsSliceReducer,
  userRegFilterOptionsSliceReducer,
  generalTransactionDateRangeFilterOptionsSliceReducer,
  customerAccountStatementSliceReducer,
  searchCustomerAccountStatementSliceReducer,
  customerTransactionsSliceReducer,
  downloadCustomerTransactionsSliceReducer,
  downloadCustomerAccountStatementSliceReducer,
  dashboardTransactionStatsSliceReducer,
  downloadDashboardTransactionStatsSliceReducer,
  dashboardTransactionTrendSliceReducer,
  uploadPaymentListSliceReducer,
  getUploadedPaymentListSliceReducer,
  getSinglePaymentListSliceReducer,
  downloadPaymentListTemplateRequestSliceReducer,
  reviewPaymentUploadSliceReducer,
  approvePaymentUploadSliceReducer,
  downloadDashboardTransactionTrendSliceReducer,
  downloadDashboardTransVolumeInsSliceReducer,
  getPermissionsSliceReducer,
  getRestrictionsSliceReducer,
  getBanHistorySliceReducer,
  getBanRequestByIdSliceReducer,
  getBanReasonsSliceReducer,
  createSendRestrictionForApprovalSliceReducer,
  banReasonsListSliceReducer,
  customerBanHistorySliceReducer,
  createInitiateFreezeReducer,
  getCompletedBatchPaymentSliceReducer,
  createRestrictionInitiationSliceReducer,
  getMyFreezeRequestsSliceReducer,
  restrictionApprovalSliceReducer,
  initiateSinglePaymentSliceReducer,
  getSingleEntryPaymentListSliceReducer,
  reviewSinglePaymentUploadSliceReducer,
  approveSinglePaymentSliceReducer,
  getCompletedSinglePaymentSliceReducer,
  getDepartmentsSliceReducer,
  createDepartmentSliceReducer,
  updateDepartmentSliceReducer,
  downloadDepartmentRecordsSliceReducer,
  updateDepartmentStatusSliceReducer,
  getAllModulesSliceReducer,
  getAdminRequestSliceReducer,
  getAdminRequestDetailsSliceReducer,
  reviewAdminRequestSliceReducer,
  getDepartmentsDropDownSliceReducer,
  getUserDetailsSliceReducer,
  downloadRequestRecordSliceReducer,
  adminClaimAccountSliceReducer,
  getPhoneVerificationSliceReducer,
  getPhoneVerificationByIdSliceReducer,
  approveChangeVerificationSliceReducer,
  getAccountUpdateHistorySliceReducer,
} from './slice';






const rootReducer = combineReducers({
  login: loginSliceReducer,
  auth: authSliceReducer,
  logout: logoutSliceReducer,
  getTransactions: getTransactionsSliceReducer,
  getReconciliationAccount: getReconciliationAccountSliceReducer,
  getReconciliationAccountDetail: getReconciliationAccountDetailSliceReducer,
  reconcileAccount: reconcileAccountSliceReducer,
  getEscalationAgents: getEscalationAgentsSliceReducer,
  createEscalationTicket: createEscalationTicketSliceReducer,
  getTransactionById: getTransactionByIdSliceReducer,
  exportTransactionByIdToMail: exportTransactionByIdToMailSliceReducer,
  settlementAnalytics: settlementAnalyticsSliceReducer,
  getKycs: getKycsSliceReducer,
  getKycsAnalytics: getKycsAnalyticsSliceReducer,
  getKycCustomer: getKycCustomerSliceReducer,
  kycVerification: kycVerificationSliceReducer,
  getSuperAgents: getSuperAgentsSliceReducer,
  getUsers: getUsersSliceReducer,
  getUserProfile: getUserProfileSliceReducer,
  getUserVerifications: getUserVerificationsSliceReducer,
  getProfileViewHistory: getProfileViewHistorySliceReducer,
  getLoginHistory: getLoginHistorySliceReducer,
  getUserTransactions: getUserTransactionsSliceReducer,
  getUserSavedBanks: getUserSavedBanksSliceReducer,
  deleteUserSavedBank: deleteUserSavedBankSliceReducer,
  updateUserStatus: updateUserStatusSliceReducer,
  getUserProfileTransaction: getUserProfileTransactionSliceReducer,
  getDocumentHistory: getDocumentHistorySliceReducer,
  getUserSubAgents: getUserSubAgentsSliceReducer,
  getAllTransactions: getAllTransactionsSliceReducer,
  downloadTransactionById: downloadTransactionByIdSliceReducer,
  downloadTransactions: downloadTransactionsSliceReducer,
  getInternalUsers: getInternalUsersSliceReducer,
  getRolesDropDown: getRolesDropDownSliceReducer,
  createInternalUser: createInternalUserSliceReducer,
  updateInternalUser: updateInternalUserSliceReducer,
  resetInternalUserPassword: resetInternalUserPasswordSliceReducer,
  getRoles: getRolesSliceReducer,
  createRole: createRoleSliceReducer,
  createArticle: createArticleReducer,
  updateRole: updateRoleSliceReducer,
  getSingleRole: getSingleRoleSliceReducer,
  deleteRole: deleteRoleSliceReducer,
  getArticles: getArticlesSliceReducer,
  getArticleById: getArticleByIdSliceReducer,
  updateArticle: updateArticleSliceReducer,
  deleteArticle: deleteArticleSliceReducer,
  getAllFaqs: getAllFaqsSliceReducer,
  createFaq: createFaqSliceReducer,
  getTags: getTagsSliceReducer,
  deleteFaq: deleteFaqSliceReducer,
  updateFaq: updateFaqSliceReducer,
  getFaq: getFaqSliceReducer,
  dashboard: dashboardSliceReducer,
  customer_growth_insight: customerGrowthInsightSliceReducer,
  transaction_volume: transactionVolumeSliceReducer,
  getNotificationById: getNotificationByIdSliceReducer,
  getNotification: getNotificationSliceReducer,
  deleteNotification: deleteNotificationSliceReducer,
  createNotification: createNotificationSliceReducer,
  updateNotification: updateNotificationSliceReducer,
  settingsCount: settingsCountSliceReducer,
  cashRequest: cashRequestSliceReducer,
  downloadCashRequest: downloadCashRequestSliceReducer,
  downloadUsersRecord: downloadUsersRecordSliceReducer,
  downloadInternalUserRecord: downloadInternalUserRecordSliceReducer,
  downloadRolePermission: downloadRolePermissionSliceReducer,
  ddownloadUsersRecord: downloadUsersRecordSliceReducer,
  downloadTransaction: downloadTransactionSliceReducer,
  downloadKycCustomer: downloadKycCustomerSliceReducer,
  downloadTransactionReport: downloadTransactionReportSliceReducer,
  downloadGeneralTransactionReport: downloadGeneralTransactionReportSliceReducer,
  downloadVolumeReport: downloadVolumeReportSliceReducer,
  downloadIncomeReport: downloadIncomeReportSliceReducer,
  downloadUserReport: downloadUserReportSliceReducer,
  downloadFinanceReport: downloadFinanceReportSliceReducer,
  downloadUserAccessReport: downloadUserAccessReportSliceReducer,
  downloadReconciliationReport: downloadReconciliationReportSliceReducer,
  downloadUserRegReport: downloadUserRegReportSliceReducer,
  getAllTransactionReport: getAllTransactionReportSliceReducer,
  getAllUserReport: getAllUserReportSliceReducer,
  getAllVolumeReport: getAllVolumeReportSliceReducer,
  getAllIncomeReport: getAllIncomeReportSliceReducer,
  filterOptions: filterOptionsSliceReducer,
  userFilterOptions: userFilterOptionsSliceReducer,
  volumeFilterOptions: volumeFilterOptionsSliceReducer,
  incomeFilterOptions: incomeFilterOptionsSliceReducer,
  reconciliationFilterOptions: reconciliationFilterOptionsSliceReducer,
  userAccessFilterOptions: userAccessFilterOptionsSliceReducer,
  userRegFilterOptions: userRegFilterOptionsSliceReducer,
  financeFilterOptions: financeFilterOptionsSliceReducer,
  generalTransactionFilterOptions: generalTransactionFilterOptionsSliceReducer,
  generalTransactionDateRangeFilterOptions: generalTransactionDateRangeFilterOptionsSliceReducer,
  searchUserAccount: searchUserAccountSliceReducer,
  saveReportSlice: saveReportSliceReducer,
  getAllSavedReport: getAllSavedReportSliceReducer,
  getLocations: getLocationsSliceReducer,
  getAllGeneralTransactionReport: getAllGeneralTransactionReportSliceReducer,
  getAllReconciliationReport: getAllReconciliationReportSliceReducer,
  getAllFinanceReport: getAllFinanceReportSliceReducer,
  getAllUserAccessReport: getAllUserAccessReportSliceReducer,
  getAllUserRegReport: getAllUserRegReportSliceReducer,
  customerAccountStatement: customerAccountStatementSliceReducer,
  searchCustomerAccountStatement: searchCustomerAccountStatementSliceReducer,
  customerTransactions: customerTransactionsSliceReducer,
  downloadCustomerTransactions: downloadCustomerTransactionsSliceReducer,
  createInitiateFreeze: createInitiateFreezeReducer,
  downloadCustomerAccountStatement: downloadCustomerAccountStatementSliceReducer,
  banReasonsList: banReasonsListSliceReducer,
  customerBanHistory: customerBanHistorySliceReducer,
  getDashboardTransactionStatistics: dashboardTransactionStatsSliceReducer,
  downloadDashboardTransactionStats: downloadDashboardTransactionStatsSliceReducer,
  getDashboardTransactionTrend: dashboardTransactionTrendSliceReducer,
  uploadPaymentList: uploadPaymentListSliceReducer,
  getUploadedPaymentList: getUploadedPaymentListSliceReducer,
  getSinglePaymentList: getSinglePaymentListSliceReducer,
  downloadPaymentListTemplate: downloadPaymentListTemplateRequestSliceReducer,
  reviewPaymentUpload: reviewPaymentUploadSliceReducer,
  approvePaymentUpload: approvePaymentUploadSliceReducer,
  downloadTransactionTrends: downloadDashboardTransactionTrendSliceReducer,
  downloadTransactionVolumeIns: downloadDashboardTransVolumeInsSliceReducer,
  getPermissions: getPermissionsSliceReducer,
  getRestrictions: getRestrictionsSliceReducer,
  getBanHistory: getBanHistorySliceReducer,
  getBanRequestById: getBanRequestByIdSliceReducer,
  getBanReasons: getBanReasonsSliceReducer,
  createSendRestrictionForApproval: createSendRestrictionForApprovalSliceReducer,
  getCompletedBatchPayment: getCompletedBatchPaymentSliceReducer,
  createRestrictionInitiation: createRestrictionInitiationSliceReducer,
  getMyFreezeRequests: getMyFreezeRequestsSliceReducer,
  restrictionApproval: restrictionApprovalSliceReducer,
  initiateSinglePayment: initiateSinglePaymentSliceReducer,
  getSingleEntryPaymentList: getSingleEntryPaymentListSliceReducer,
  reviewSinglePaymentUpload: reviewSinglePaymentUploadSliceReducer,
  approveSinglePayment: approveSinglePaymentSliceReducer,
  getCompletedSinglePayment: getCompletedSinglePaymentSliceReducer,
  getDepartments: getDepartmentsSliceReducer,
  createDepartment: createDepartmentSliceReducer,
  updateDepartment: updateDepartmentSliceReducer,
  downloadDepartmentRecords: downloadDepartmentRecordsSliceReducer,
  updateDepartmentStatus: updateDepartmentStatusSliceReducer,
  getAllModules: getAllModulesSliceReducer,
  getAdminRequest: getAdminRequestSliceReducer,
  getAdminRequestDetails: getAdminRequestDetailsSliceReducer,
  reviewAdminRequest: reviewAdminRequestSliceReducer,
  getDepartmentsDropDown: getDepartmentsDropDownSliceReducer,
  getUserDetails: getUserDetailsSliceReducer,
  downloadRequestRecord: downloadRequestRecordSliceReducer,
  adminClaimAccount: adminClaimAccountSliceReducer,
  getPhoneVerification: getPhoneVerificationSliceReducer,
  approveChangeVerification: approveChangeVerificationSliceReducer,
  getPhoneVerificationById: getPhoneVerificationByIdSliceReducer,
  getAccountUpdateHistory: getAccountUpdateHistorySliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
