import { ZojaButton } from '../tailwind';

interface IProps {
  title?: string;
  showFilter: boolean;
  children: React.ReactElement;
  onClick: () => void;
  submitButtonText?: string;
  showSubmitButton?: boolean;
  extraClass?: string;
}

const index = ({
  showFilter,
  title,
  onClick,
  submitButtonText,
  showSubmitButton = true,
  extraClass,
  children,
}: IProps) => {
  return showFilter ? (
    <div
      className={`${extraClass} tw-bg-white tw-shadow-zojaShadowFour tw-absolute tw-top-0 -tw-left-32 tw-rounded-md tw-p-5 tw-z-40`}>
      <span className="tw-text-[#323348] tw-font-normal tw-text-sm tw-pl-1 tw-pb-1.5 tw-block tw-border-b tw-border-b-[#B6B6B6] md:tw-text-[15px]">
        {title}
      </span>
      {children}
      {showSubmitButton ? (
        <ZojaButton
          onClick={onClick}
          text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
          extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin"
        />
      ) : null}
    </div>
  ) : null;
};

export default index;
