import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as yup from "yup";
import { ZojaButton, ZojaModal, ZojaSelect } from '../../components/tailwind';
import { cn } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { approvePaymentUploadRequest, approvePaymentUploadReset } from '../../redux/slice';
import { reviewPaymentUploadRequest, reviewPaymentUploadReset } from '../../redux/slice/reviewPaymentUploadSlice';
import { icons } from '../../utils';
import ZojaPaymentRejectionSuccess from './PaymentRejectionSuccess';


interface RejectPaymentFileUploadProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
    tab?: string,
    selectedPaymentItemId: string | number
}

interface initialState {
    status: string,
    rejection_reason: string,
    rejection_message: string
}

export default function RejectPaymentFileUpload({ show, handleClose, tab, selectedPaymentItemId }: RejectPaymentFileUploadProps) {
    const reviewPaymentUploadState = useAppSelector((state) => state.reviewPaymentUpload)
    const {status: reviewPaymentUploadStatus } = reviewPaymentUploadState

    const approvePaymentUploadState = useAppSelector((state) => state.approvePaymentUpload)
    const {status: approvePaymentUploadStatus } = approvePaymentUploadState

    const dispatch = useAppDispatch()

    const handleSubmitReject = async (values: any) => {
        // console.log(values, tab)
       tab === 'Pending Reviews' && dispatch(reviewPaymentUploadRequest({bulkImportId: selectedPaymentItemId, data: {...values}}))
       tab === 'Pending Approval' && dispatch(approvePaymentUploadRequest({bulkImportId: selectedPaymentItemId, data: {...values}}))
    }

    const { values, errors, touched, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik<initialState>({
        initialValues: {
            status: "REJECTED",
            rejection_reason: "",
            rejection_message: ""
        },
        validationSchema: yup.object().shape({
            status: yup.string().required(),
            rejection_message: yup.string().required('rejection message is required')
        }),
        onSubmit: handleSubmitReject
    })

    useEffect(() => {
        if (
            reviewPaymentUploadStatus === "succeeded" 
            || reviewPaymentUploadStatus === "failed" 
            || approvePaymentUploadStatus === "succeeded"
            || approvePaymentUploadStatus === "failed"
            
            ) {
            handleClose()
            resetForm()
        }
    },[reviewPaymentUploadStatus, approvePaymentUploadStatus])


    return (
        <>
            
            <ZojaPaymentRejectionSuccess
                show={((reviewPaymentUploadStatus === "succeeded" && reviewPaymentUploadState?.data?.bulk_import?.review_status === 'REJECTED') || (approvePaymentUploadStatus === 'succeeded' && approvePaymentUploadState?.data?.bulk_import?.approval_status === 'REJECTED'))}
                title='File Rejected'
                subTitle={`Transaction ${ tab === 'Review Payment' ? 'initiator' : 'reviewer'} will be notified of file rejection and reason for rejection `}
                handleClose={() => {
                    resetForm()
                    dispatch(reviewPaymentUploadReset())
                    dispatch(approvePaymentUploadReset())
                }}
            />
            <ZojaModal
                show={show}
                handleClose={() => {
                    resetForm()
                    handleClose()
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Reason for Rejection</h3>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            resetForm()
                           handleClose()
                        }}
                    ><icons.MdClose /></span>
                    <form onSubmit={handleSubmit}>
                        <div className='mt-4'>
                            <ZojaSelect
                                label='Reason'
                                options={[
                                    { label: '--Select Reason--', value: '' },
                                    { label: 'Incomplete data', value: 'Incomplete data' },
                                    { label: 'Wrong account number', value: 'Wrong account number' },
                                    { label: 'Others', value: 'Others' },
                                ]}
                                setSelectedOption={(selected) => {
                                    setFieldValue('rejection_reason', selected)
                                }}
                                onChange={() => null}
                                touched={touched}
                                error={errors.rejection_reason}
                            />
                        </div>
                        <div className='mt-4'>
                            <textarea
                                className={cn(
                                    "placeholder:tw-pl-[1px tw-w-full tw-border tw-pl-3 tw-p-2 tw-h-32 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-gray-300",
                                    touched.rejection_message && errors.rejection_message && 'tw-border-red-500'
                                )}
                                name='rejection_message'
                                value={values.rejection_message}
                                onChange={handleChange}
                                placeholder='Enter Description'
                            >

                            </textarea>
                            {touched.rejection_message && errors.rejection_message && <span className='tw-text-sm tw-text-red-500'>{errors.rejection_message}</span>}
                        </div>
                        <div className='my-3 mt-4'>
                            <ZojaButton
                                type='submit'
                                text="Reject"
                                loading={reviewPaymentUploadStatus === "loading" || approvePaymentUploadStatus === "loading"}
                                disabled={reviewPaymentUploadStatus === "loading" || approvePaymentUploadStatus === "loading"}
                                extraClass='tw-bg-red-500 tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-red-600/90 disabled:tw-bg-red-300'
                            />
                        </div>
                    </form>
                </div>
            </ZojaModal>
        </>
    )
}
