const spacing = {
  xxsmall: '4px',
  xsmall: '8px',
  small_3: '16px',
  small_2: '18px',
  small: '24px',
  medium: '48px',
  large: '64px',
  larger: '80px',
  xlarge: '96px',
};

export default spacing;
