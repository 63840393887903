import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadUserReportRequest = createAsyncThunk(
  'downloadUserReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      status,
      last_seen,
      location,
      sort_by,
      order_by,
      exp,
      filter_accounts,
      page,
      per_page,
      date_onboarded,
      level,
      gender,
      min_transaction,
      max_transaction,
      min_balance,
      max_balance,
      min_age,
      max_age,
    } = payload;
    const url = `/admin/report/user?sort_by=${sort_by}&status=${status}&export=${exp}&order_by=${order_by}&per_page=${per_page}&page=${page}&last_seen=${last_seen}&location=${location}&filter_accounts=${filter_accounts}&date_onboarded=${date_onboarded}&level=${level}&gender=${gender}&min_transaction=${
      min_transaction === undefined ? null : min_transaction
    }&max_transaction=${max_transaction === undefined ? null : max_transaction}&min_balance=${
      min_balance === undefined ? null : min_balance
    }&max_balance=${max_balance === undefined ? null : max_balance}&min_age=${
      min_age === undefined ? null : min_age
    }&max_age=${max_age === undefined ? null : max_age}`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `userreport.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadUserReportSlice = createSlice({
  name: 'downloadUserReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadUserReportRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadUserReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadUserReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadUserReportReset = downloadUserReportSlice.actions.reset;
export const downloadUserReportSliceReducer = downloadUserReportSlice.reducer;
