import { useEffect, useState } from 'react';
import {
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  SingleCountCard,
  ZojaCheckbox,
} from '../../components';
import { ZojaSelect } from '../../components/tailwind';
import { dateRangeOptioned } from '../../utils/dateRangeOptions';
import { useAppSelector, useAppDispatch } from '../../redux/redux-hooks';
import { handleSelectedOptionHandler, icons, yearDateFormat } from '../../utils';
import { DatePickerContainer } from '../../pages/settlements/style';
import { AtomDatePicker } from '..';
import { downloadDashboardTransactionStats, getDashboardTransactionStats } from '../../redux/slice';
import { Dictionary } from '../../types';
import { daysDifference } from '../../utils/getDateDifference';
import { toast } from 'react-toastify';

const DashboardTotalTransfer = () => {
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [checkedBox, setCheckedBox] = useState<any>({});
  const [showDateRange, setShowDateRange] = useState(false);

  const [showExportOptions, setShowExportOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>('');

  const [dateRangeOption, setDateRangeOption] = useState(dateRangeOptioned);
  const [payload, setPayload] = useState<Dictionary>({
    type: '',
    date_range: '',
    exp: '',
  });
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const { data, status } = useAppSelector(state => state.getDashboardTransactionStatistics);

  const dispatch = useAppDispatch();

  const { status: downloadStatus } = useAppSelector(state => state.downloadDashboardTransactionStats);

  const handleExportFile = (type: string) => {
    dispatch(downloadDashboardTransactionStats({ ...payload, exp: type }));
    setShowExportOptions(false);
  };

  useEffect(() => {
    dispatch(getDashboardTransactionStats(payload));
  }, []);

  useEffect(() => {
    setPayload({
      ...payload,
      type: selectedOption,
    });
    dispatch(
      getDashboardTransactionStats({
        ...payload,
        type: selectedOption,
      }),
    );
  }, [selectedOption]);

  useEffect(() => {
    if (startDate.length !== 0 && endDate.length !== 0) {
      const differenceInDays: number = daysDifference(startDate, endDate);

      if (differenceInDays >= 0) {
        setPayload({
          ...payload,
          date_range: `${yearDateFormat(startDate)}:${yearDateFormat(endDate)}`,
        });
        dispatch(
          getDashboardTransactionStats({
            ...payload,
            date_range: `${yearDateFormat(startDate)}:${yearDateFormat(endDate)}`,
          }),
        );
      } else {
        toast.error('Start Date is greater than End date');
      }
    }
  }, [startDate, endDate]);

  const HandleFilterCard = () => {
    setPayload({
      ...payload,
      date_range: checkedBox?.[0]?.value,
    });
    dispatch(
      getDashboardTransactionStats({
        ...payload,
        date_range: checkedBox?.[0]?.value,
      }),
    );

    setShowFilterOptions(false);
  };

  return (
    <>
      <section className="mt-4">
        <h2 className="tw-text-xl">Total Transfer</h2>
        {/* Overlay to hide filter options */}
        {(showFilterOptions || showExportOptions) && (
          <div
            className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
            onClick={() => {
              setShowFilterOptions(false);
              setShowExportOptions(false);
            }}></div>
        )}
        <div className="tw-my-6 tw-flex tw-justify-between md:tw-gap-5 tw-flex-col lg:tw-flex-row">
          <ZojaSelect
            options={[
              { label: 'Select Transfer Type', value: '' },
              { label: 'Free Transfers', value: 'free' },
              { label: 'Charged Transfers', value: 'charged' },
            ]}
            setSelectedOption={setSelectedOption}
            extraClass="!tw-w-[15rem]"
          />

          <div className="tw-flex tw-flex-row tw-gap-3">
            <DatePickerContainer>
              <div className="tw-flex tw-items-end md:tw-text-sm tw-text-xs md:tw-text-items-start tw-text-[#263238] tw-font-extrabold md:tw-font-bold ">
                See Data From:
              </div>
              <AtomDatePicker selectedDate={setStartDate} title={'From'} />
              <AtomDatePicker selectedDate={setEndDate} title={'To'} />
            </DatePickerContainer>

            <FilterLabel
              title="Filter Options"
              icon={<icons.BsFilter />}
              // isOpen={showFilterOptions}
              onClick={() => setShowFilterOptions(true)}>
              <FilterCard
                showFilter={showFilterOptions}
                title="Filter Options"
                onClick={HandleFilterCard}
                extraClass="tw-w-[13rem]">
                <div className="tw-mt-4 tw-h-[10rem] tw-overflow-y-scroll tw-overflow-hidden">
                  <FilterColapsible
                    title="Date Range"
                    show={showDateRange}
                    onClick={() => setShowDateRange(!showDateRange)}
                    extraClass="tw-shadow-none">
                    <>
                      <div className="">
                        {dateRangeOption.map((item: any) => (
                          <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                            <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                            <ZojaCheckbox
                              isChecked={item.isChecked}
                              onClick={() =>
                                handleSelectedOptionHandler(item, dateRangeOption, setDateRangeOption, setCheckedBox)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    </>
                  </FilterColapsible>
                </div>
              </FilterCard>
            </FilterLabel>

            <ExportLabel
              title="Export"
              onClick={() => setShowExportOptions(true)}
              loading={downloadStatus === 'loading' ? true : false}>
              <FilterCard
                showFilter={showExportOptions}
                title="Export as"
                onClick={() => null}
                showSubmitButton={false}
                extraClass="tw-w-[10rem] -tw-left-28">
                <div className="tw-flex tw-flex-col tw-mt-4">
                  <span className="tw-text-xs tw-cursor-pointer tw-text-isGrey" onClick={() => handleExportFile('csv')}>
                    CSV
                  </span>
                </div>
              </FilterCard>
            </ExportLabel>
          </div>
        </div>

        <div className="tw-overflow-x-auto">
          <table className="tw-w-full">
            <thead>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] tw-whitespace-nowrap md:tw-text-[1rem]">
                  Total Transaction Count :{' '}
                  <span className="tw-font-medium tw-text-[#162082]">
                    {data.analytics && Object.keys(data?.analytics).length !== 0 && status === 'succeeded'
                      ? data?.analytics?.total_transaction_count
                      : '-'}
                  </span>{' '}
                </td>
                <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] tw-whitespace-nowrap md:tw-text-[1rem]">
                  Total Transaction Value :{' '}
                  <span className="tw-font-medium tw-text-[#162082]">
                    {' '}
                    {data.analytics && Object.keys(data?.analytics).length !== 0 && status === 'succeeded'
                      ? data?.analytics?.total_transaction_value
                      : '-'}
                  </span>{' '}
                </td>
                <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] tw-whitespace-nowrap md:tw-text-[1rem]">
                  Total User Count :{' '}
                  <span className="tw-font-medium tw-text-[#162082]">
                    {' '}
                    {data.analytics && Object.keys(data?.analytics).length !== 0 && status === 'succeeded' ? '-' : '-'}
                  </span>{' '}
                </td>
                <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] tw-whitespace-nowrap md:tw-text-[1rem]">
                  Total Revenue :{' '}
                  <span className="tw-font-medium tw-text-[#162082]">
                    {' '}
                    {data.analytics && Object.keys(data?.analytics).length !== 0 && status === 'succeeded'
                      ? data?.analytics?.total_revenue
                      : '_'}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="tw-my-8 tw-grid tw-gap-4 tw-grid-cols-3 xl:tw-grid-cols-3 md:tw-gap-6">
            <SingleCountCard
              id={1}
              title={'Successful Transfers'}
              total={
                data.analytics && Object.keys(data?.analytics).length !== 0 ? data?.analytics?.successful_count : '-'
              }
              subtitle={''}
              titleClass="tw-font-medium tw-py-3 !tw-text-green-500"
              loading={status === 'loading'}
            />
            <SingleCountCard
              id={1}
              title={'Pending Transfers'}
              total={data.analytics && Object.keys(data?.analytics).length !== 0 ? data?.analytics?.pending_count : '-'}
              subtitle={''}
              titleClass="tw-font-medium tw-py-3 !tw-text-yellow-500"
              loading={status === 'loading'}
            />
            <SingleCountCard
              id={1}
              title={'Failed Transfers'}
              total={data.analytics && Object.keys(data?.analytics).length !== 0 ? data?.analytics?.failed_count : '-'}
              subtitle={''}
              titleClass="tw-font-medium tw-py-3 !tw-text-red-500"
              loading={status === 'loading'}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardTotalTransfer;
