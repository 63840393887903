interface IProps {
    title: string,
    imgUrl: string | any,
    subTitle?: string,
    hasIndicator?: boolean,
    imgClass?: string,
    handleClick?: () => void
}

const VerificationDocumentCard = ({ title, imgUrl, subTitle, hasIndicator = true, imgClass, handleClick }: IProps) => {
    return (
        <div className='tw-flex tw-flex-col tw-gap-y-2'>
            <span className='tw-flex tw-items-center tw-gap-x-3 tw-text-xs tw-text-[#5E6366] md:tw-text-sm'>
                <span className={`tw-text-xs tw-font-medium`}>{title}</span>
                {hasIndicator ? <span className='tw-text-[10px] tw-bg-green-400 tw-text-white tw-px-2 tw-rounded-full tw-h-3 tw-grid tw-place-content-center'>verified</span> : ''}
            </span>
            <img src={imgUrl} alt="" className={`${imgClass} tw-w-40 tw-h-40 lg:tw-w-52 lg:tw-h-52 tw-object-cover tw-rounded-md`} onClick={handleClick}/>
            {subTitle && <span className='tw-mt-3 tw-text-xs'>{subTitle}</span>}
        </div>
    )
}

export default VerificationDocumentCard