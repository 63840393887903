import { ZojaButton } from '../tailwind';
import { toggleItemChecked, toggleItemCheckedOneItem } from '../../utils';
import ReusableFilter from '../reusableFilter';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { Dictionary } from '../../types';

const UserReportFilter = ({
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  submitButtonText,
  setUserFilterOption,
  onClick,
  setSearch,
  searchedUsers,
}: any) => {
  const dispatch = useAppDispatch();

  const userFilterOptions = useAppSelector(state => state.userFilterOptions);

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setUserFilterOption({ ...userFilterOptions, [name]: value }));
  };

  return (
    <div>
      <div className="tw-h-[40vh] hide_scrollbar">
        <div className="tw-mt-4">
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            filterOptions={filterOption}
            title="Age Range"
            show={showOptions.ageRange}
            setShow={() => setShowOptions({ ...showOptions, ageRange: !showOptions.ageRange })}
            extraClass="tw-shadow-none"
            type="text"
            label="Age"
            id="age"
            inputType="number"
            name="age"
            onHandleInputChange={handleInputChange}
            filterParams={userFilterOptions}
          />
          <ReusableFilter
            title="Account info"
            items={filterOption.accountInfo}
            itemName={'accountInfo'}
            show={showOptions.accountInfo}
            setShow={() => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })}
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            filterOptions={filterOption}
            extraClass="tw-shadow-none"
            setSearch={setSearch}
            searchedUsers={searchedUsers}
            type={'inputCheck'}
            searchedUserAccountState={searchedUserAccountState}
          />
          <ReusableFilter
            setChecked={toggleItemCheckedOneItem}
            setItem={setFilterOption}
            itemName={'lastLogin'}
            filterOptions={filterOption}
            title="Last Login Time"
            show={showOptions.loginTime}
            setShow={() => setShowOptions({ ...showOptions, loginTime: !showOptions.loginTime })}
            extraClass="tw-shadow-none"
            items={filterOption.lastLogin}
            type={'checkDate'}
          />
          <ReusableFilter
            setChecked={toggleItemCheckedOneItem}
            setItem={setFilterOption}
            itemName={'dateOnboarded'}
            filterOptions={filterOption}
            title="Date Onboarded"
            show={showOptions.onboarded}
            setShow={() => setShowOptions({ ...showOptions, onboarded: !showOptions.onboarded })}
            extraClass="tw-shadow-none"
            items={filterOption.dateOnboarded}
            type={'checkDate'}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'kycLevel'}
            filterOptions={filterOption}
            title="KYC Level"
            show={showOptions.kycLevel}
            setShow={() => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })}
            extraClass="tw-shadow-none"
            items={filterOption.kycLevel}
          />
          <ReusableFilter
            setItem={setFilterOption}
            itemName={'Account Balance'}
            filterOptions={filterOption}
            title="Account Balance"
            show={showOptions.accountBalance}
            setShow={() => setShowOptions({ ...showOptions, accountBalance: !showOptions.accountBalance })}
            extraClass="tw-shadow-none"
            type="text"
            label="Amount"
            id="account_balance"
            inputType="number"
            name="account_balance"
            onHandleInputChange={handleInputChange}
            filterParams={userFilterOptions}
          />
          <ReusableFilter
            setItem={setFilterOption}
            itemName={'totalTransaction'}
            filterOptions={filterOption}
            title="Total Transaction"
            show={showOptions.totalTransaction}
            setShow={() => setShowOptions({ ...showOptions, totalTransaction: !showOptions.totalTransaction })}
            extraClass="tw-shadow-none"
            type="text"
            label="Count"
            id="total_transaction"
            inputType="number"
            name="total_transaction"
            onHandleInputChange={handleInputChange}
            filterParams={userFilterOptions}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'location'}
            filterOptions={filterOption}
            title="Location"
            show={showOptions.location}
            setShow={() => setShowOptions({ ...showOptions, location: !showOptions.location })}
            extraClass="tw-shadow-none"
            items={filterOption.location}
          />
          <ReusableFilter
            setChecked={toggleItemChecked}
            setItem={setFilterOption}
            itemName={'gender'}
            filterOptions={filterOption}
            title="Gender"
            show={showOptions.gender}
            setShow={() => setShowOptions({ ...showOptions, gender: !showOptions.gender })}
            extraClass="tw-shadow-none"
            items={filterOption.gender}
          />
        </div>
      </div>
      <ZojaButton
        onClick={onClick}
        text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
        extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin  tw-mb-4"
      />
    </div>
  );
};

export default UserReportFilter;
