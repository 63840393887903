import ReactApexChart from "react-apexcharts";

export interface IProps {
    appFeatures: any;
    chartData: any;
  }

const TotalTransactionChart = ({ appFeatures, chartData }: IProps) => {
  return (
    <div className="">
      <h2 className="tw-text-[.75rem] tw-text-gray-400 tw-font-normal">Total Transactions Chart</h2>
      <div className="tw-flex md:tw-justify-end">
        <div className="tw-mt-4 tw-grid tw-grid-cols-2 tw-gap-3 md:tw-grid-cols-4 md:tw-w-[100%] md:tw-gap-x-3">
          {appFeatures.map(({ id, title, color }: any) => (
            <span
              key={id}
              className="tw-flex tw-items-center tw-gap-x-1 tw-text-[10px] tw-text-[#5E6366] md:tw-text-[14px]">
              {' '}
              <span className="tw-w-5 tw-h-1.5 tw-rounded-full" style={{ backgroundColor: color }}></span>{' '}
              <span className="tw-text-[#162082] tw-text-[.5rem] tw-">{title}</span>{' '}
            </span>
          ))}
        </div>
      </div>
      <div id="chart" className="tw-mt-6">
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={350} />
      </div>
    </div>
  );
};

export default TotalTransactionChart;