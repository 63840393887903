import { useState } from 'react';
import { Container, BtnContainer } from './style';
import { Modal, LoginHistoryTable, EmptyState } from '../../components';
import { H2, H3, H5 } from '../../styles';
import { colors, spacing } from '../../utils';
import { LoginHistoryIProps } from '../../components/tables/loginHistoryTable';
import { Oval } from 'react-loader-spinner';
import { ReactComponent as EmptyStateNote } from '../../assets/svg/emptyStateNote.svg';
import AccountUpdateHistoryTable from '../../components/tables/accountUpdateHistoryTable';
import { AccountUpdateHistoryIProps } from '../../components/tables/accountUpdateHistoryTable';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  title?: string;
  data: AccountUpdateHistoryIProps[];
  headerData3?: any;

  actionClick: () => void;
  isLoading: boolean;
  loginAttemptsData?: any;
}

function AccountUpdateHistoryModal({ isModalVisible, closeModal, title, data, headerData3, isLoading }: IProps) {
  const [tabViewLoginHistorySelectedIndex, setTabViewLoginHistorySelectedIndex] = useState<any[number]>(1);

  // THIS FUNCTION RESETS FILTERS
  const handleReset = () => {
    window.location.reload();
  };

  return (
    <Modal title={title} isModalVisible={isModalVisible} closeModal={closeModal}>
      {isLoading ? (
        <Container style={{ display: 'flex' }}>
          <Oval
            height="500"
            width="80"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </Container>
      ) : (
        <Container>
          {data.length > 0 ? (
            <AccountUpdateHistoryTable headerData3={headerData3} data={data} />
          ) : (
            <H2
              center
              semiBold
              color={colors.grey}
              style={{
                marginTop: spacing.small,
                marginBottom: spacing.small,
              }}>
              <EmptyState
                img={<EmptyStateNote width={200} height={200} />}
                description="No Record Found"
                btnText="Reset Data"
                btnBackground="tw-bg-isPrimary"
                onClick={() => handleReset()}
              />
            </H2>
          )}
          <div
            style={{
              width: '100%',
              marginTop: spacing.xsmall,
            }}></div>
        </Container>
      )}
    </Modal>
  );
}

export default AccountUpdateHistoryModal;
