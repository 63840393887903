import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadUserRegReportRequest = createAsyncThunk(
  'downloadUserRegReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      min_age,
      max_age,
      sort_by,
      order_by,
      exp,
      per_page,
      page,
      start_date,
      end_date,
      device_type,
      source_of_reg,
      user_status,
      user_level,
      user_feedback,
      account_verification_status,
      gender,
      location,
    } = payload;
    const url = `/admin/report/general/user-registration-and-growth?sort_by=${sort_by}&export=${exp}&order_by=${order_by}&per_page=${per_page}&page=${page}&date_onboarded[start_date]=${start_date}&date_onboarded[end_date]=${end_date}&age_range[min]=${min_age}&age_range[max]=${max_age}&device_type=${device_type}&source_of_reg=${source_of_reg}&user_status=${user_status}&user_level=${user_level}&user_feedback=${user_feedback}&account_verification_status=${account_verification_status}&gender=${gender}&location=${location}`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `userregreport.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadUserRegReportSlice = createSlice({
  name: 'downloadUserRegReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadUserRegReportRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadUserRegReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadUserRegReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadUserRegReportReset = downloadUserRegReportSlice.actions.reset;
export const downloadUserRegReportSliceReducer = downloadUserRegReportSlice.reducer;
