import React from 'react';
import { ZojaButton, ZojaInput, ZojaModal } from '../tailwind';
import { images } from '../../utils';
import { Oval } from 'react-loader-spinner';

interface IProps {
  title: string;
  content: string;
  value: string;
  saveReportStatus?: string;
  show: boolean;
  handleClose: () => void;
  onSave: () => void;
  contentRef: HTMLDivElement | any;
  closeOnClickOutside?: boolean;
  handleShowSaveReport?: () => void;
  onHandleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const SaveReportModal = ({
  title,
  content,
  show,
  contentRef,
  handleClose,
  onHandleChange,
  closeOnClickOutside = false,
  value,
  onSave,
  saveReportStatus,
}: IProps) => {
  return (
    <ZojaModal
      show={show}
      height="auto"
      position="center"
      borderRadius="12px"
      handleClose={handleClose}
      closeOnClickOutside={closeOnClickOutside}
      extraClass="md:tw-w-[33.5rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      contentRef={contentRef}
      children={
        <div className="rounded-lg tw-p-6" ref={contentRef}>
          <h1 className="tw-text-gray-700 tw-text-[18px] md:tw-text-xl">{title}</h1>
          <p className="tw-text-[#5E6366] tw-text-[14px] tw-my-6">{content}</p>
          <ZojaInput
            label={`Report Name`}
            labelExtraClass={'tw-text-gray-700 tw-text-sm tw-font-normal tw-capitalize'}
            id={`report`}
            extraClass=" tw-p-4 tw-mb-2 tw-text-sm tw-font-normal tw-capitalize"
            name={`report`}
            value={value}
            onChange={onHandleChange}
          />
          <div className="tw-flex tw-items-center">
            {saveReportStatus !== 'loading' ? (
              <ZojaButton
                text="Save"
                onClick={onSave}
                extraClass={`tw-p-2 tw-mt-4 ${
                  value.length < 1 ? 'tw-bg-gray-400 tw-cursor-default' : 'tw-bg-isPrimary tw-cursor-pointer'
                }  tw-text-white tw-px-7 tw-rounded-[10px]`}
                disabled={value.length < 1 ? true : false}
              />
            ) : (
              <span className="tw-block tw-w-max tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-8 tw-rounded-[4px]">
                <Oval height="20" width="20" color="#ffffff" ariaLabel="tail-spin-loading" secondaryColor="#ffffff" />
              </span>
            )}
            <ZojaButton
              text="Close"
              onClick={handleClose}
              extraClass="tw-p-2 tw-mt-4 tw-bg-white tw-cursor-pointer tw-text-isPrimary tw-px-7 tw-rounded-[10px]"
            />
          </div>
        </div>
      }
    />
  );
};

export default SaveReportModal;
