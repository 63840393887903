import { ReactElement, useRef, useState } from 'react';
import { BsThreeDots } from 'react-icons/bs';
import ZojaDropdown from '../dropdown';

interface TRProps {
  id?: string;
  children?: ReactElement | ReactElement[];
  onClick?: () => void | null;
  setSelectedItem?: any;
}

interface TDProps extends TRProps {
  value?: any;
  extraClass?: string;
  handleClose?: () => void;
  dropdownList: any;
  onActionClick: (item: string) => any;
}

const TR = ({ id, children, onClick }: TRProps) => {
  return (
    <tr
      key={id}
      className="tw-text-xs tw-capitalize tw-bg-white tw-p-6 tw-shadow-xs tw-cursor-pointer tw-shadow-zojaShadowNine"
      onClick={onClick}>
      {' '}
      {children}{' '}
    </tr>
  );
};

const TDMORE = ({
  id,
  value,
  onClick,
  children,
  extraClass,
  handleClose,
  dropdownList,
  onActionClick,
  setSelectedItem,
}: TDProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  return (
    <td
      className={`tw-text-sm tw-text-[#162082] tw-font-light tw-whitespace-nowrap tw-relative ${extraClass}`}
      onClick={onClick}>
      <div className="tw-cursor-pointer" onClick={() => toggleDropdown()}>
        <BsThreeDots />
      </div>
      {isOpen && (
        <ZojaDropdown
          handleClose={() => setIsOpen(false)}
          contentRef={contentRef}
          closeOnClickOutside={true}
          dropdownList={dropdownList}
          setSelectedItem={setSelectedItem}
          onActionClick={item => onActionClick(item)}
        />
      )}
    </td>
  );
};

export { TDMORE, TR };
