import { useState, useLayoutEffect, memo, useEffect } from 'react';
import { CountInfoCard } from '../../components';
import { Container, Content } from './style';
import { CountInfoCardIProps } from '../../components/cards/countInfoCard';
import { routesPath } from '../../utils';
import { useNavigate } from 'react-router-dom';

export interface CountInfoIProps {
  data: CountInfoCardIProps[];
  setSelectedData?: any;
  type?: string;
  shadow?: string;
  loading?: boolean;
  tag?: string;
  cardTypeUrl?: any;
  initialSelectedCardIndex?: number;
  selectedCard?: any;
}

const CountInfo = ({
  data,
  setSelectedData,
  type,
  shadow,
  tag,
  loading,
  initialSelectedCardIndex,
  selectedCard,
  cardTypeUrl,
}: CountInfoIProps) => {
  const navigate = useNavigate();

  const [dataList, setDataList] = useState(data as CountInfoCardIProps[]);

  useLayoutEffect(() => {
    let result: CountInfoCardIProps[] = [];
    data.forEach((item: CountInfoCardIProps, index) =>
      result.push({
        id: item.id,
        isSelected: type === 'settings' ? false : index === initialSelectedCardIndex ? true : false,
        count: item.count,
        title: item.title,
        tag: item?.tag,
      }),
    );
    setDataList(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    navigate(cardTypeUrl);
  }, [selectedCard]);

  const handleOnSelectCard = (item: CountInfoCardIProps) => {
    const itemToEdit = item;
    const updatedData: CountInfoCardIProps[] = [...dataList].map((el: CountInfoCardIProps) => {
      if (itemToEdit.title === el.title) {
        el.isSelected = true;
      } else {
        el.isSelected = false;
      }
      return el;
    });
    setSelectedData(itemToEdit);
    setDataList(updatedData);
  };

  return (
    <>
      <Container>
        {dataList.map((item: CountInfoCardIProps) => (
          <Content key={item.id}>
            <CountInfoCard
              isSelected={item.isSelected}
              onClick={() => {
                if (!loading) {
                  handleOnSelectCard(item);
                }
              }}
              count={item.count ?? 0}
              title={item.title}
              shadow={shadow}
              loading={loading}
            />
          </Content>
        ))}
      </Container>
    </>
  );
};

export default CountInfo;
