//Old Table Headers.
export const accountManagementTransactionsTableHeader = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Transaction Type',
    value: 'transactionType',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Narration',
    value: 'narration',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Amount',
    value: 'amount',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Old Balance',
    value: 'oldBalance',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'New Balance',
    value: 'newBalance',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: 'Date&Time',
    value: 'dateAndTime',
    hasSortIcon: true,
  },
  {
    id: 9,
    title: '',
    hasSortIcon: false,
  },
];

export const accountManagementTransactionsTableHeaderUpdate = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Date&Time',
    value: 'dateAndTime',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Reference ID',
    value: 'referenceId',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Debit',
    value: 'debit',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Credit',
    value: 'credit',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Balance',
    value: 'balance',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: 'Transaction Details',
    value: 'transactionDetails',
    hasSortIcon: true,
  },
];

export const freezeRequestsData = [
  {
    id: 1,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 2,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 3,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 4,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 5,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 6,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 7,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 8,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 9,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 10,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
];

export const freezeAccountReviewerHeaderData = [
  {
    id: 1,
    title: 'S/N',
    value: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Full Name',
    value: 'fullName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account Number',
    value: 'accountNumber',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'KYC',
    value: 'kyc',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Reason for Freeze',
    value: 'reasonForFreeze',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Added',
    value: 'date',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: 'Action',
    hasSortIcon: false,
  },
];

export const rejectedBanAccountsHeaderData = [
  {
    id: 1,
    title: 'S/N',
    value: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Full Name',
    value: 'fullName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account Number',
    value: 'accountNumber',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'KYC',
    value: 'kyc',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Reason for Freeze',
    value: 'reasonForFreeze',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Rejection Reason',
    value: 'rejection_reason',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Added',
    value: 'date',
    hasSortIcon: true,
  },
  // {
  //   id: 7,
  //   title: 'Action',
  //   hasSortIcon: false,
  // },
];

export const freezeAccountsInitiatorHeaderData = [
  {
    id: 1,
    title: 'S/N',
    value: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Full Name',
    value: 'fullName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account Number',
    value: 'accountNumber',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'KYC',
    value: 'kyc',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Reason for Freeze',
    value: 'reasonForFreeze',
    hasSortIcon: true,
  },
  // {
  //   id: 6,
  //   title: 'Account Status',
  //   value: 'accountStatus',
  //   hasSortIcon: true,
  // },
  {
    id: 6,
    title: 'Date Added',
    value: 'date',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Action',
    hasSortIcon: false,
  },
];

export const unFreezeRequestsData = [
  {
    id: 1,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 2,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 3,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 4,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 5,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 6,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 7,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 8,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 9,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
  {
    id: 10,
    fullName: 'Wade Warren',
    accountNumber: '0000000000',
    kyc: 'Level 1',
    reasonForFreeze: 'Unauthorized Transac...',
    accountStatus: 'Freeze',
    date: 'dd/mm/yyyy',
    action: 'Action',
  },
];

export const unFreezeRequestsHeaderData = [
  {
    id: 1,
    title: 'S/N',
    value: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Full Name',
    value: 'fullName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account Number',
    value: 'accountNumber',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'KYC',
    value: 'kyc',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Reason for Freeze',
    value: 'reasonForFreeze',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Status',
    value: 'status',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Added',
    value: 'date',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: 'Action',
    hasSortIcon: false,
  },
];

export const restrictedAccountsHeaderData = [
  {
    id: 1,
    title: 'S/N',
    value: 'S/N',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Full Name',
    value: 'fullName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account Number',
    value: 'accountNumber',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'KYC',
    value: 'kyc',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Reason for Restriction',
    value: 'reasonForRestriction',
    hasSortIcon: true,
  },
  // {
  //   id: 6,
  //   title: 'Status',
  //   value: 'status',
  //   hasSortIcon: true,
  // },
  {
    id: 6,
    title: 'Date Added',
    value: 'date',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Action',
    hasSortIcon: false,
  },
];
