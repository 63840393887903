import { ReactElement, Fragment } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { cn } from '../../../lib/utils';

interface TRProps {
  id?: string;
  children?: ReactElement | any[];
  onClick?: () => void | null;
  extraClass?: string;
}

type THProps = {
  id?: string | number;
  value?: any;
  order?: string;
  hasSortIcon?: boolean;
  headerValue?: any;
  sorting?: (col: string) => void | null;
  extraClass?: string;
  padding?: string;
  setIsSort?: any;
  setSortHeader?: any;
  setOrder?: any;
  setIsNextPage?: any;
};
interface TDProps extends TRProps {
  value?: any;
  extraClass?: string;
  children?: any;
}

type TBODYProps = {
  children: ReactElement | ReactElement[];
};

type THEADProps = {
  children: ReactElement | ReactElement[];
};

const TR = ({ id, children, onClick, extraClass }: TRProps) => {
  return (
    <tr
      key={id}
      className={cn(
        `tw-text-xs tw-capitalize tw-bg-white tw-p-6 tw-shadow-xs tw-shadow-zojaShadowNine`,
        onclick ? `tw-cursor-pointer` : `tw-cursor-default`,
        extraClass
      )}
      onClick={onClick}>
      {children}
    </tr>
  );
};

const TH = ({
  id,
  value,
  headerValue,
  sorting,
  order,
  hasSortIcon,
  extraClass,
  padding = `4`,
  setIsSort,
  setSortHeader,
  setOrder,
  setIsNextPage,
}: THProps) => {
  return (
    <th
      key={id}
      className={`tw-p-${padding} tw-text-sm tw-font-[500] tw-left tw-whitespace-nowrap tw-shadow-zojaShadowNine ${extraClass}`}>
      <div className="tw-flex tw-items-center tw-gap-x-2">
        <div
          className={`tw-mb-0 tw-relative ${headerValue ? `tw-cursor-pointer` : ``}`}
          onClick={() => {
            // console.log("headerValue", headerValue);
            // order && order === "ASC" ? setOrder("ASC") : setOrder("DSC");
            setIsSort && setIsSort(true);
            setIsNextPage && setIsNextPage(false);
            setSortHeader && setSortHeader(headerValue);
            sorting && sorting(headerValue);
          }}>
          {value}
          {hasSortIcon ? (
            <div className="tw-flex tw-flex-col ">
              <FaCaretUp size={10} color="#c4c4c4" className="tw-absolute tw-top-[2px] -tw-right-3" />
              <FaCaretDown size={10} color="#c4c4c4" className="tw-absolute tw-bottom-[2px] -tw-right-3" />
            </div>
          ) : null}
        </div>
      </div>
    </th>
  );
};

const TD = ({ id, value, onClick, children, extraClass = `tw-whitespace-nowrap` }: TDProps) => {
  return (
    <td className={cn(
      "tw-p-3 tw-text-sm tw-text-[#162082] tw-font-light tw-relative",
      extraClass
    )} onClick={onClick}>
      {value}
      {children}
    </td>
  );
};
const CUSTOMTD = ({ id, value, onClick, children, extraClass = `tw-whitespace-wrap` }: TDProps) => {
  return (
    <td className={`tw-p-3 tw-text-xs tw-text-[#162082] tw-font-light tw-relative ${extraClass}`} onClick={onClick}>
      {value &&
        value.split('\n').map((line: any, index: number) => (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        ))}
      {children}
    </td>
  );
};

const TBODY = ({ children }: TBODYProps) => {
  return <tbody>{children}</tbody>;
};

const THEAD = ({ children }: THEADProps) => {
  return <thead className="tw-bg-gray-50 tw-border-b-2 tw-border-gray-200"> {children} </thead>;
};

export { TH, TD, TR, THEAD, TBODY, CUSTOMTD };
