import { useState } from 'react';
import { Container } from './style';
import { TabButton } from '../../components';
import { TabIProps } from '../../components/tab';

export interface TabViewIPropsIProps {
  data: TabIProps[];
  setSelectedIndex: any[number];
  backgroundColor?: string;
  type?: string;
  tabViewSelectedIndex?: number;
  loginAttemptsData?: any;
}

function TabViewLoginHistory({
  data,
  backgroundColor,
  setSelectedIndex,
  tabViewSelectedIndex,
  type,
  loginAttemptsData,
}: TabViewIPropsIProps) {
  const [dataList, setDataList] = useState(data);

  const handleOnSelect = (item: TabIProps) => {
    const itemToEdit = item;
    const updatedData: TabIProps[] = [...dataList].map((el: TabIProps) => {
      if (itemToEdit.text === el.text) {
        el.isSelected = true;
      } else {
        el.isSelected = false;
      }
      return el;
    });

    setDataList(updatedData);
    setSelectedIndex(itemToEdit.id);
  };

  console.log(loginAttemptsData, '----');
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '1px',
          alignItems: 'center',
          width: '100%',
          borderBottom: '1px solid #e9e0e0',
        }}>
        <Container>
          {dataList.map((item: TabIProps) => (
            <TabButton
              onClick={() => handleOnSelect(item)}
              key={item.id}
              isSelected={item.isSelected}
              text={item.text}
              paddingRight={dataList.length === item.id ? false : true}
              type={type}
              tabViewSelectedIndex={tabViewSelectedIndex}
            />
          ))}
        </Container>
        <div>
          {dataList[0]?.isSelected && (
            <div style={{ display: 'flex' }}>
              <p
                style={{
                  margin: '0rem',
                  marginRight: '1.5rem',
                  fontFamily: 'verdana',
                  fontWeight: '600',
                  fontSize: '12px',
                }}>
                Successful Logins: <span style={{ color: '#4dab4dde' }}>{loginAttemptsData?.success}</span>
              </p>
              <p
                style={{
                  margin: '0rem',
                  marginRight: '1.5rem',
                  fontFamily: 'verdana',
                  fontWeight: '600',
                  fontSize: '12px',
                }}>
                Failed Logins: <span style={{ color: '#fd0000' }}>{loginAttemptsData?.fail}</span>
              </p>
            </div>
          )}

          {dataList[1]?.isSelected && <p style={{ margin: '0rem' }}>Total Profile Views:</p>}
        </div>
      </div>
    </>
  );
}

export default TabViewLoginHistory;
