import { useNavigate } from 'react-router-dom';
import { AppContainer } from '../../atoms';
import {
  dateFormat,
  formatStartAndEndDateWithDelimeter,
  getEverySelectedOptionValue,
  getSelectedOptionValue,
  replaceCommasInQueryParam,
  routesPath,
  useDebounce,
} from '../../utils';
import { DownloadSuccessModal } from '../../components';
import { transactionReportColumn } from './dummyData';
import { Dictionary } from '../../types';
import { useEffect, useRef, useState } from 'react';
import {
  saveReportRequest,
  searchUserAccountRequest,
  setFilterOptions as setTransactionFilterOptions,
  resetFilterOptions,
  saveReportReset,
} from '../../redux/slice';
import {
  dateOptions,
  figureRangeOptions,
  genderOptions,
  kycLevelOptions,
  locationOptions,
  manageColumnOptions,
  sortByOtions,
  transactionStatusOptions,
  transactionTypeOptions,
} from './filterOptions';
import TransactionGeneratedReport from '../../atoms/generatedReports/TransactionGeneratedReport';
import { useAppSelector, useAppDispatch } from '../../redux/redux-hooks';
import { getAllTransactionReportRequest } from '../../redux/slice';
import {
  downloadTransactionReportRequest,
  downloadTransactionReportReset,
} from '../../redux/slice/downloadTransactionReportSlice';
import SaveReportModal from '../../components/modalTypes/SaveReportModal';
import moment from 'moment';
const { REPORT } = routesPath;

export interface transactionReportRow {
  id: number;
  sn: number;
  transactionDate: string;
  transactionType: string;
  accountName: string;
  amount: number;
  timestamp: string;
  status: string;
  accountNumber: string;
  phoneNumber: string;
  referenceID: number;
  kyc: string;
  fee: number;
}

const TransactionReport = () => {
  // REDUX STATES
  const allTransactionReportState = useAppSelector(state => state.getAllTransactionReport);
  const { status: getAllTransactionReportStatus, data: getAllTransactionReportData } = allTransactionReportState;
  const transactionFilterOptions = useAppSelector(state => state.filterOptions);
  const { status: downloadTransactionReportStatus } = useAppSelector(state => state.downloadTransactionReport);
  const searchedUserAccount = useAppSelector(state => state.searchUserAccount);
  const { status: saveReportStatus } = useAppSelector(state => state.saveReportSlice);

  // ROUTER HOOKS
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REFS
  const successModalRef = useRef<any>(null);
  const saveReportModalRef = useRef<any>(null);

  //   REACT STATES
  const [showManageColumn, setShowManageColumn] = useState(false);
  const [search, setSearch] = useState<any>();
  const [totalItems, setTotalItems] = useState();
  const [currentPage, setCurrentPage] = useState(allTransactionReportState?.data?.meta?.current_page || 1);
  const [perPage, setPerPage] = useState();
  const [transactionReportData, setTransactionReportData] = useState<transactionReportRow[]>([]);
  const [selectedPageOption, setSelectedPageOption] = useState<'10' | '20' | '30'>();
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [showSuccessSavedModal, setShowSuccessSavedModal] = useState(false);
  const [showSaveReportModal, setShowSaveReportModal] = useState(false);
  const [reportName, setReportName] = useState('');
  const [reportQueries, setReportQueries] = useState('');

  //  DEBOUNCE HOOK
  const debouncedSearch = useDebounce(search, 300);

  // Grouped state variables for show options
  const [showOptions, setShowOptions] = useState({
    ageRange: false,
    totalTransaction: false,
    location: false,
    accountBalance: false,
    filterType: false,
    loginTime: false,
    gender: false,
    onboarded: false,
    sortBy: false,
    reportFilter: false,
    kycLevel: false,
    accountInfo: false,
    exportOptions: false,
    bigFilter: true,
    manageColumn: false,
    transactionDate: false,
    transactionAmount: false,
    transactionType: false,
    transactionStatus: false,
    feesRange: false,
  });

  const [showOptionsSmallScreen, setShowOptionsSmallScreen] = useState(false);

  // Grouped state variables for filter options
  const [filterOption, setFilterOption] = useState({
    sortBy: sortByOtions.aphabetically,
    orderBy: sortByOtions.orderBy,
    gender: genderOptions,
    accountInfo: [] as any[],
    transactionDate: dateOptions,
    dateOnboarded: dateOptions,
    lastLogin: dateOptions,
    transactionType: transactionTypeOptions,
    transactionStatus: transactionStatusOptions,
    location: locationOptions,
    kycLevel: kycLevelOptions,
    dateRange: { start_date: '', end_date: '' },
    selectedOption: '10',
    feesRange: figureRangeOptions,
    reportFilterType: {
      title: 'Filter Type',
      id: 1,
      value: 'initial',
    },
    manageColumn: manageColumnOptions.transaction,
  });

  // CHECKS IF THE DATE SELECTED IS CUSTOM
  const dateIsCustom = filterOption.transactionDate?.some(
    (item: { value: string; isChecked: any }) => item.value === 'custom' && item.isChecked,
  );

  const initialValues: Dictionary = {
    transaction_date: '',
    reference: '',
    type: '',
    min_amount: '',
    max_amount: '',
    sort_by: '',
    order_by: '',
  };

  const [manageColumn, setManageColumn] = useState(manageColumnOptions.transaction);

  const [visibleColumns, setVisibleColumns] = useState(transactionReportColumn.map(column => column.id));
  const [filterOptions, setFilterOptions] = useState(initialValues);

  const handleSubmitFilter = () => {
    dispatch(getAllTransactionReportRequest(transactionFilterOptions));
    setShowOptions({ ...showOptions, sortBy: false });
  };

  // close filter on state change
  useEffect(() => {
    setShowOptions({ ...showOptions, sortBy: false });
  }, [getAllTransactionReportStatus]);

  // filter and export methods
  const handleExportFile = (type: string) => {
    dispatch(
      downloadTransactionReportRequest({ ...transactionFilterOptions, exp: type, per_page: selectedPageOption || 10 }),
    );
    setShowOptions({ ...showOptions, exportOptions: false });
  };

  // Input changer handler
  const handleInputChange = ({ target: { name, value } }: Dictionary) => {
    dispatch(setTransactionFilterOptions({ ...transactionFilterOptions, [name]: value }));
  };

  // this processes strings to be used as saved reports
  useEffect(() => {
    const urlLink = getAllTransactionReportData?.links?.last;

    // Find the index of the first "?" and the last "&"
    const firstQuestionMarkIndex = urlLink?.indexOf('?');
    const lastAmpersandIndex = urlLink?.lastIndexOf('&');

    // Extract the substring between the first "?" and the last "&"
    const extractedParams =
      firstQuestionMarkIndex !== -1 ? urlLink?.substring(firstQuestionMarkIndex + 1, lastAmpersandIndex) : '';

    if (extractedParams?.startsWith('https://')) {
      // Set expectedParams to an empty string if it starts with "https://"
      setReportQueries('');
    } else {
      setReportQueries(replaceCommasInQueryParam(extractedParams));
    }
  }, [downloadTransactionReportStatus, getAllTransactionReportData?.links?.last, reportQueries]);

  // THIS EFFECT UPDATES THE FILTER OPTIONS FOR TRANSACTIONS
  useEffect(() => {
    dispatch(
      setTransactionFilterOptions({
        ...transactionFilterOptions,
        transaction_date: dateIsCustom
          ? formatStartAndEndDateWithDelimeter(filterOption.dateRange.start_date, filterOption.dateRange.end_date)
          : getSelectedOptionValue(filterOption.transactionDate),
        status: getEverySelectedOptionValue(filterOption.transactionStatus),
        type: getEverySelectedOptionValue(filterOption.transactionType),
        filter_accounts: getEverySelectedOptionValue(filterOption.accountInfo, 'account'),
        fees_range: getEverySelectedOptionValue(filterOption.feesRange),
        sort_by: getSelectedOptionValue(filterOption.sortBy),
        order_by: getSelectedOptionValue(filterOption.orderBy),
        per_page: selectedPageOption,
        page: currentPage,
        level: getEverySelectedOptionValue(kycLevelOptions),
      }),
    );
  }, [dispatch, filterOption, selectedPageOption, dateIsCustom, currentPage]);

  // THIS EFFECT CALLS GET USER ENDPOINT WHEN selectedPageOption CHANGES
  useEffect(() => {
    if (selectedPageOption) {
      dispatch(getAllTransactionReportRequest({ transactionFilterOptions, per_page: selectedPageOption }));
    }
  }, [selectedPageOption]);

  useEffect(() => {
    if (currentPage) {
      dispatch(getAllTransactionReportRequest({ ...transactionFilterOptions, page: currentPage }));
    }
  }, [currentPage]);

  useEffect(() => {
    return () => {
      dispatch(resetFilterOptions()); // Reset the filter options when the component is unmounted
    };
  }, [dispatch]);

  // THIS EFFECT CALLS SEARCH ENDPOINT WHEN THE DEBOUNCED SEARCH VALUE CHANGES
  useEffect(() => {
    if (search) {
      dispatch(searchUserAccountRequest({ search: debouncedSearch }));
    }
  }, [dispatch, debouncedSearch]);

  useEffect(() => {
    if (downloadTransactionReportStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadTransactionReportStatus]);

  useEffect(() => {
    dispatch(getAllTransactionReportRequest(transactionFilterOptions));
  }, [dispatch, filterOptions]);

  // THIS EFFECT UPDATES THE TRANSACTIONS REPORT DATA
  useEffect(() => {
    if (getAllTransactionReportStatus === 'succeeded') {
      let updatedList: transactionReportRow[] = [];

      allTransactionReportState?.data?.data.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1 || 0,
          sn: index + 1 || 0,
          transactionDate: dateFormat(item?.created_at) || '-',
          transactionType: item?.type || '-',
          accountName: item?.source_account?.name || '-',
          amount: item?.amount || '-',
          timestamp: `${moment(item?.created_at).fromNow(true)} ago` || '-',
          accountNumber: item?.source_account?.number || '-',
          phoneNumber: item?.user?.telephone || '-',
          referenceID: item?.transaction_reference || '-',
          kyc: item?.user?.kyc_level || '-',
          status: item?.status || '-',
          fee: item?.charge || '-',
        });
      });

      const {
        meta: { per_page, total },
      } = allTransactionReportState?.data;
      setTotalItems(total);
      setPerPage(per_page);

      setTransactionReportData(updatedList);
    }
  }, [getAllTransactionReportStatus, allTransactionReportState]);

  // CALLS API WHEN THE APPLY FILTER BUTTON IS CLICKED
  const handleApplyFilter = () => {
    dispatch(getAllTransactionReportRequest(transactionFilterOptions));
  };

  // THIS EFFECT UPDATES THE USERLIST DATA
  useEffect(() => {
    if (searchedUserAccount.status === 'succeeded') {
      let updatedList: any[] = [];

      searchedUserAccount?.data?.users?.forEach((item: any, index: number) => {
        updatedList.push({
          id: index + 1,
          isChecked: false,
          accountNumber: item.account_number,
          name: item.name,
          value: item.name,
          telephone: item.telephone,
        });
      });

      setFilterOption({ ...filterOption, accountInfo: updatedList });
    }
  }, [searchedUserAccount?.data.user, searchedUserAccount?.data?.users, searchedUserAccount.status]);

  const handleShowSaveReport = () => {
    setShowSaveReportModal(true);
    setShowSuccessDownloadModal(false);
  };

  // SAVES REPORT WHEN BUTTON IS CLICKED
  const handleReportSave = () => {
    const payload = { query: reportQueries, name: reportName, type: 'transaction' };
    dispatch(saveReportRequest(payload));
  };

  //SHOWS SAVE REPORT MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSaveReportModal(false);
    }
  }, [saveReportStatus]);

  //SHOWS SAVED SUCCESS MODAL
  useEffect(() => {
    if (saveReportStatus === 'succeeded') {
      setShowSuccessSavedModal(true);
    }
  }, [saveReportStatus]);

  // input changer handler for saved report
  const handleSavedReportInputChange = (e: { target: { name: any; value: any } }) => {
    const { value } = e.target;
    setReportName(value);
  };

  // FUNCTION TO CHANGE PAGE
  const onChangePagination = (selectedPage: any) => {
    setCurrentPage(selectedPage);
    let currentPage = selectedPage;

    let filterBy = { ...transactionFilterOptions, page: currentPage };
    dispatch(getAllTransactionReportRequest(filterBy));
  };

  return (
    <AppContainer
      navTitle="Reports"
      goBack={() => navigate(REPORT)}
      navHelper={
        <>
          <h4 className="tw-uppercase tw-my-0 tw-text-[#323348] tw-text-xs md:tw-text-2xl">Reports</h4>
        </>
      }>
      <div>
        <DownloadSuccessModal
          show={showSuccessDownloadModal}
          handleClose={() => {
            setShowSuccessDownloadModal(false);
            dispatch(downloadTransactionReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Item Downloaded"
          handleShowSaveReport={() => {
            handleShowSaveReport();
            dispatch(downloadTransactionReportReset());
          }}
          content="Your document has been downloaded to your device"
          type="saveDownloadModal"
        />
        <DownloadSuccessModal
          show={showSuccessSavedModal}
          handleClose={() => {
            setShowSuccessSavedModal(false);
            dispatch(saveReportReset());
          }}
          closeOnClickOutside={false}
          contentRef={successModalRef}
          title="Report Saved"
          content="Your report has been saved successfully"
        />
        <SaveReportModal
          show={showSaveReportModal}
          handleClose={() => setShowSaveReportModal(false)}
          closeOnClickOutside={false}
          contentRef={saveReportModalRef}
          onSave={handleReportSave}
          onHandleChange={handleSavedReportInputChange}
          title="Save your Report"
          value={reportName}
          saveReportStatus={saveReportStatus}
          content="Give your report a name and see it on your report homepage to reprint anytime, and would not be automatically deleted with rest of your history"
        />

        <h1 className="tw-text-gray-800 tw-text-2xl tw-font-medium tw-my-7">Transaction Report</h1>
        <div className="">
          <TransactionGeneratedReport
            onSubmitSort={() => handleSubmitFilter()}
            onApplyFilter={() => handleApplyFilter()}
            filterOptions={filterOptions}
            setFilterOptions={setFilterOptions}
            column={transactionReportColumn}
            tableData={transactionReportData}
            onExport={handleExportFile}
            manageColumnOptions={manageColumnOptions}
            filterOption={filterOption}
            setManageColumn={setManageColumn}
            manageColumn={manageColumn}
            setFilterOption={setFilterOption}
            showOptions={showOptions}
            setShowOptions={setShowOptions}
            showManageColumn={showManageColumn}
            setShowManageColumn={setShowManageColumn}
            setSearch={setSearch}
            searchedUsers={searchedUserAccount.data.users}
            handleInputChange={handleInputChange}
            setSelectedPageOption={setSelectedPageOption}
            itemsPerPage={perPage}
            totalItems={totalItems}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            transactionState={allTransactionReportState}
            visibleColumns={visibleColumns}
            transactionReportStatus={getAllTransactionReportStatus}
            setVisibleColumns={setVisibleColumns}
            downloadStatus={downloadTransactionReportStatus}
            onChangePagination={onChangePagination}
            showOptionsSmallScreen={showOptionsSmallScreen}
            setShowOptionsSmallScreen={setShowOptionsSmallScreen}
            transactionFilterOptions={transactionFilterOptions}
          />
        </div>
      </div>
    </AppContainer>
  );
};

export default TransactionReport;
