const images = {
  user: 'https://style.anu.edu.au/_anu/4/images/placeholders/person.png',
  userProfileImage: require('../assets/images/user_profile.png'),
  logo: require('../assets/images/logo.png'),
  logoMain: require('../assets/images/logoMain.png'),
  emptyList: require('../assets/images/emptyList.png'),
  freezeIcon: require('../assets/images/ac_unit.png'),
  restrictIcon: require('../assets/images/avg_pace.png'),
  list: require('../assets/images/list.png'),
  listReject: require('../assets/images/list-reject.png'),
  check: require('../assets/images/check.png'),
  reject: require('../assets/images/reject.png'),
  logoBordered: require('../assets/images/logoBordered.png'),
  searchingFileGif: require('../assets/gif/searchingFile.gif'),
  spinnerGif: require('../assets/gif/spinner.gif'),
  successIconGif: require('../assets/gif/successIcon.gif'),
  reactivateUser: require('../assets/images/reactivateUser.png'),
  deactivateUser: require('../assets/images/deactivateUser.png'),
  sent: require('../assets/images/sent.png'),
  totalCashIcon: require('../assets/images/total-cash-icon.png'),
  totalReconciliationIcon: require('../assets/images/total-reconciliation-icon.png'),
  supportTicketIcon: require('../assets/images/support-ticket-icon.png'),
  agentIcon: require('../assets/images/agent-icon.png'),
  calendarEditIcon: require('../assets/images/calendar-edit-icon.png'),
  downloadSuccessIcon: require('../assets/gif/success_download_icon.gif'),
  rotationIcon: require('../assets/images/rotation.png'),
  zoomOut: require('../assets/images/zoomout.png'),
  zoomIn: require('../assets/images/zoomin.png'),
  emptyState: require('../assets/images/empty.png'),
  fileExel: require('../assets/images/file-excel.png'),
  fileExcelEmpty: require('../assets/images/file-search-empty.png'),
  fileExcelClose: require('../assets/images/file-excel-close.png'),
  fileUploadRejected: require('../assets/gif/fileRejected.gif'),
  cancel: require('../assets/gif/cancel.gif'),
  blockSuccess: require('../assets/gif/Ban.gif'),
  question: require('../assets/images/question.png'),
};

export default images;
