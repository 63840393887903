import { ReactElement } from 'react';
import Spinner from '../../assets/gif/spinner.gif';
import { Container, BtnContainer } from './style';
import { Modal, Button } from '../../components';
import { H2, H6, H5, H3 } from '../../styles';
import { colors, spacing } from '../../utils';
import { ZojaButton } from '../../components/tailwind';

export interface IProps {
  isModalVisible: boolean;
  closeModal: () => void;
  title?: string;
  text?: string;
  actionText: string;
  secondaryActionText?: string;
  actionClick: () => void;
  image?: string;
  isLoading?: boolean;
  children?: ReactElement;
  actionBtnBackgroundColor?: string;
  requestStatus?: string;
}

function ActivityActionModal({
  isModalVisible,
  closeModal,
  title,
  text,
  requestStatus,
  actionText,
  actionClick,
  image,
  secondaryActionText,
  isLoading,
  children,
  actionBtnBackgroundColor = `tw-bg-isPrimary`,
}: IProps) {
  return (
    <Modal title="" isModalVisible={isModalVisible} closeModal={closeModal}>
      {!isLoading ? (
        <Container>
          <div
            style={{
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: spacing.xsmall,
              marginBottom: spacing.small,
            }}>
            <img width={50} height={50} src={image} alt="loading..." />
          </div>
          {title && (
            <H2 style={{ marginBottom: spacing.small, fontSize: '1rem' }}>
              {title}
            </H2>
          )}
          <p color={colors.primary} className='tw-text-[.8rem]'>{text}</p>
          {children && <div style={{ marginTop: spacing.small, width: '100%' }}>{children}</div>}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              columnGap: '15px',
              marginTop: spacing.small,
            }}>
            {/* {secondaryActionText && <BtnContainer />} */}
            <div>
              <ZojaButton
                extraClass={`${actionBtnBackgroundColor} tw-font-thin tw-text-white tw-text-[14px] tw-p-1.5 tw-px-8 tw-rounded-[4px]`}
                text={actionText}
                loading={requestStatus === 'loading'}
                disabled={requestStatus === 'loading' ? true : false}
                onClick={actionClick}
                loadingText={actionText}
              />
            </div>
            {secondaryActionText && (
              <div>
                <H3 onClick={closeModal} color={colors.primary} style={{ cursor: 'pointer' }}>
                  {secondaryActionText}
                </H3>
              </div>
            )}
          </div>
        </Container>
      ) : (
        <Container>
          <img width={200} height={200} src={Spinner} alt="loading..." />

          <H5>Please wait loading...</H5>
        </Container>
      )}
    </Modal>
  );
}

export default ActivityActionModal;
