import { toast } from 'react-toastify';
import { useAppSelector } from '../../../redux/redux-hooks';
import { routesPath } from '../../../utils';
import BannedAccounts from './bannedAccounts';
import UnbanRequests from './unbanRequests';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
const { DASHBOARD } = routesPath;

const Ban = () => {
  const navigate = useNavigate();
  const auth = useAppSelector(state => state.auth);

  // CHECKS IF USER MEETS PERMISSION REQUIREMENT

  const permissionsToCheck = [
    'initiate account restriction',
    'review account restriction',
    'approve account restriction',
  ];

  const isInitiateAccountRestrictionIncluded = auth?.data?.permissions?.some(
    (action: { name: string }) => action.name === 'initiate account restriction',
  );
  const isReviewAccountRestrictionIncluded = auth?.data?.permissions?.some(
    (action: { name: string }) => action.name === 'review account restriction',
  );
  const isApproveAccountRestrictionIncluded = auth?.data?.permissions?.some(
    (action: { name: string }) => action.name === 'approve account restriction',
  );

  // Checks if all freeze permissions are absent
  const arePermissionsAbsent = permissionsToCheck.every(
    permissionToCheck => !auth?.data?.permissions.some((item: { name: string }) => item.name === permissionToCheck),
  );

  useEffect(() => {
    if (arePermissionsAbsent) {
      toast.error("You don't have the permission to view this route");
      navigate(DASHBOARD);
    }
  }, [arePermissionsAbsent]);

  if (isInitiateAccountRestrictionIncluded) {
    return <BannedAccounts />;
  }
  if (isReviewAccountRestrictionIncluded) {
    return <UnbanRequests />;
  }
  if (isApproveAccountRestrictionIncluded) {
    return <UnbanRequests />;
  }
};

export default Ban;
