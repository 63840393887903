import { CustomerTableHeader } from '../../types';

export const activeUsersTableHeaders: CustomerTableHeader = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Name',
    value: 'name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account No',
    value: 'accountNo',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone No',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'KYC Level',
    value: 'kycLevel',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Last Seen',
    value: 'lastSeen',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Onboarded',
    value: 'dateOnboarded',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: '',
    hasSortIcon: false,
  },
];

export const superAgentsTableHeaders: CustomerTableHeader = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Name',
    value: 'name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'Account No',
    value: 'accountNo',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone No',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Sub Agents',
    value: 'subAgents',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Last Seen',
    value: 'lastSeen',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Onboarded',
    value: 'dateOnboarded',
    hasSortIcon: true,
  },
  {
    id: 8,
    title: '',
    hasSortIcon: false,
  },
];

export const inActiveUsersTableHeaders: CustomerTableHeader = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Name',
    value: 'name',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Account No',
    value: 'accountNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Phone No',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'KYC Level',
    value: 'kycLevel',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Date Deactivated',
    value: 'dateDeactivated',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

export const pendingVerificationTableHeaders: CustomerTableHeader = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'profileName',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Validation Type',
    value: 'validationType',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Phone Number',
    value: 'phoneNumber',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Email Address',
    value: 'email',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Submitted',
    value: 'dateSubmitted',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: 'Action',
    hasSortIcon: false,
  },
];

export const pendingVerificationData: any = [
  {
    id: 1,
    name: 'Wade Warren',
    validationType: 'Phone Number Change',
    phone: '234816489202',
    date: '11/11/2023',
  },
  {
    id: 2,
    name: 'Wade Warren',
    validationType: 'Phone Number Change',
    phone: '234816489202',
    date: '11/11/2023',
  },
];

export const activeUserSearchType = 'active';
export const superAgentUserSearchType = 'superAgent';
export const inactiveUserSearchType = 'inactive';
