import { MdClose } from 'react-icons/md';
import { ZojaButton, ZojaInput, ZojaModal, ZojaSelect } from '../tailwind';
import { images } from '../../utils';
import { Dictionary } from '../../types';
import { Oval } from 'react-loader-spinner';
import { useState } from 'react';
import { Formik } from 'formik';
import TextArea from '../textArea';

interface ShowApproveBanSuccessModalProps {
  show: boolean;
  handleClose: () => void;
  handleFinish: () => void;
  title: string;
  description: string;
  descriptionTwo?: string;
  buttoText?: string;
  userName?: string;
  image?: string;
}

export default function ShowApproveBanSuccessModal({
  show,
  handleClose,
  title,
  description,
  descriptionTwo,
  buttoText,
  userName,
  image,
  handleFinish = () => '',
}: ShowApproveBanSuccessModalProps) {
  return (
    <ZojaModal
      show={show}
      handleClose={handleClose}
      height="auto"
      position="center"
      extraClass="md:tw-w-[30rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
      borderRadius="8px">
      <div className=" tw-px-12 tw-py-10">
        <span
          className="tw-absolute tw-top-11 tw-right-10 tw-bg-isPrimary tw-text-white tw-p-1 tw-rounded-md tw-w-fit tw-text-sm tw-cursor-pointer"
          onClick={handleFinish}>
          <MdClose />
        </span>
        <div>
          <img src={image} alt="success" className="tw-w-32 tw-h-32 tw-m-auto" />
          <h2 className="tw-font-medium tw-text-lg tw-mb-4 tw-text-center">{title}</h2>
          <p className="tw-text-base tw-text-isGreyVariantOne tw-text-center">
            {description} <span className="tw-font-medium">{userName}</span> {descriptionTwo}
          </p>
          <div className="tw-flex tw-justify-center tw-mt-8">
            <ZojaButton
              text={buttoText}
              onClick={handleFinish}
              extraClass="tw-bg-isPrimary tw-text-white tw-px-[72px] tw-text-center tw-py-3"
            />
          </div>
        </div>
      </div>
    </ZojaModal>
  );
}
