import { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { ActivityActionModal, AppContainer } from '../../atoms';
import {
  CustomDatePicker,
  ExportLabel,
  FilterCard,
  FilterColapsible,
  FilterLabel,
  NavTab,
  Pagination,
  RolesAndPermissionTable,
  SearchInput,
  ZojaCheckbox
} from '../../components';
import { ZojaButton, ZojaSelect } from '../../components/tailwind';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { deleteRoleRequest, deleteRoleReset, downloadRolePermission, getRolesRequest } from '../../redux/slice';
import { Dictionary } from '../../types';
import {
  colors,
  formatStartAndEndDateWithDelimeter,
  getSelectedOptionValue,
  handleSelectedOption,
  icons,
  images,
  routesPath,
  useDelayedFunction,
  useSortTable,
} from '../../utils';
import { CustomUseSortTable } from '../../utils/hooks/useSortTable';
import { UserPageTabs, rolesAndPermissionDataHeader } from './data';
import { InternalUserTop, InternalUsersContainer, UserContainer } from './style';

const { CREATEUSERROLES, ROLESANDPERMISSIONS, USERS, USERROLES } = routesPath;

const initialValues: Dictionary = {
  status: '',
  kyc_level: '',
  last_seen: '',
  sort_by: '',
  order_by: '',
  date_onboarded: '',
  deactivated_at: '',
};
const memberAndLastSeenOptions = [
  {
    id: 1,
    value: 'one_week',
    title: 'One week',
    isChecked: false,
  },
  {
    id: 2,
    value: 'thirty_days',
    title: '30 Days',
    isChecked: false,
  },
  {
    id: 3,
    value: 'six_months',
    title: '6 Months',
    isChecked: false,
  },
  {
    id: 4,
    value: 'custom',
    title: 'Custom',
    isChecked: false,
  },
];

// filter options constants
const sortByOptions = {
  aphabetically: [
    {
      id: 1,
      value: 'az',
      title: 'A-Z',
      isChecked: false,
    },
    {
      id: 2,
      value: 'za',
      title: 'Z-A',
      isChecked: false,
    },
    {
      id: 3,
      value: 'kyc_level',
      title: 'Kyc Level',
      isChecked: false,
    },
  ],
  orderBy: [
    {
      id: 1,
      value: 'newest',
      title: 'Newest',
      isChecked: false,
    },
    {
      id: 2,
      value: 'oldest',
      title: 'Oldest',
      isChecked: false,
    },
    {
      id: 3,
      value: 'last_seen',
      title: 'Last Seen',
      isChecked: false,
    },
  ],
};

//Table more dropdown
const dropdownList: Dictionary = [
  {
    id: 1,
    title: 'Edit',
    value: 'edit',
  },
  {
    id: 2,
    title: 'Deactivate',
    value: 'deactivate',
  },
  {
    id: 3,
    title: 'Reactivate',
    value: 'reactivate',
  },
  {
    id: 4,
    title: 'Reset Password',
    value: 'resetPassword',
  },
  {
    id: 5,
    title: 'View Login History',
    value: 'viewLoginHistory',
  },
];

const roleDetails = 'Role Details';
const roleDeleteRole = 'Delete Role';

const RolesPermissions = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // REDUX
  const { status: downloadRolePermissionStatus } = useAppSelector(state => state.downloadRolePermission);

  const rolesState = useAppSelector(state => state.getRoles);
  const { status: rolesStatus } = rolesState;

  const deleteRoleState = useAppSelector(state => state.deleteRole);
  const { status: deleteRoleStatus } = deleteRoleState;

  // STATES
  const [searchUserRoleValue, setSearchUserRoleValue] = useState('');
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState(initialValues);
  const [perPage, setPerPage] = useState<any>('10');
  const [onboardedDateRange, setOnboardedDateRange] = useState({ state_date: '', end_date: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [showDateOnboarded, setShowDateOnboarded] = useState(false);
  const [dateOnboarded, setDateOnboarded] = useState(memberAndLastSeenOptions);
  const [showSortBy, setShowSortBy] = useState(false);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [sortBy, setSortBy] = useState(sortByOptions.aphabetically);
  const [orderBy, setOrderBy] = useState(sortByOptions.orderBy);
  const [userRolesDataList, setUserRolesDataList] = useState<any[]>([]);
  const [selectedRoleItem, setSelectedRoleItem] = useState<Dictionary>({});
  const [roleMoreIconIsVisible, setRoleMoreIconIsVisible] = useState(false);
  const [showSuccessDownloadModal, setShowSuccessDownloadModal] = useState(false);
  const [deleteRoleIsModalVisible, setDeleteRoleIsModalVisible] = useState(false);
  const [selectedInternalUserItem, setSelectedInternalUserItem] = useState<Dictionary>({});
  const [order, setOrder] = useState('ASC');
  const [rolePermissionTotalPages, setRolePermissionTotalPages] = useState(5);
  const [deleteRoleSuccessIsModalVisible, setDeleteRoleSuccessIsModalVisible] = useState(false);
  const [rolesPermissionData, setRolesPermissionData] = useState([
    {
      value: '',
      label: '',
      permissionCount: '',
      permissions: [],
    },
  ]);
  const [isSort, setIsSort] = useState<any>(false);
  const [sortHeader, setSortHeader] = useState<any>('');
  const [isNextPage, setIsNextPage] = useState<any>(false);

  const { CustomHandleSortItems } = CustomUseSortTable({
    order: order,
    setOrder,
    data: userRolesDataList,
    setData: setUserRolesDataList,
    isNextPage: isNextPage,
  });

  // EFFECTS
  useEffect(() => {
    dispatch(
      getRolesRequest({
        last_seen: '',
        role_creator: '',
        sort_by: '',
        order_by: '',
        date_range: '',
        term: '',
        page: currentPage,
        per_page: perPage,
      }),
    );
  }, [currentPage]);

  useEffect(() => {
    if (rolesStatus === 'succeeded') {
      let updateRolesData: any[] = [];
      rolesState?.data?.roles?.data?.forEach((item: Dictionary) => {
        updateRolesData.push({
          value: item.name,
          label: item.name,
          permissionCount: item.permission_count,
          permissions: item.permissions,
        });
      });
      setRolesPermissionData(updateRolesData);
    }
  }, [rolesState]);

  useEffect(() => {
    let resultRoles: any[] = [];
    if (rolesStatus === 'succeeded') {
      rolesState?.data?.roles?.data?.forEach((el: Dictionary, index: number) => {
        resultRoles.push({
          title: el.name,
          permissionCount: el?.permission_count,
          userCount: el?.user_count,
          createdBy: el?.role_creator === null ? 'N/A' : el?.role_creator,
          roleId: el?.id,
          modules_count: el?.modules_count,
          status: el?.status,
          id: el?.id,
          dateCreated: el?.created_at,
        });
      });

      const last_page = rolesState?.data?.roles?.meta?.last_page;

      setRolePermissionTotalPages(last_page);
      // setUserRolesData(resultRoles);
      setUserRolesDataList(resultRoles);
    }
  }, [rolesState]);

  useEffect(() => {
    if (deleteRoleStatus === 'succeeded') {
      setDeleteRoleSuccessIsModalVisible(true);
    }
  }, [deleteRoleState]);

  // show download success modal
  useEffect(() => {
    if (downloadRolePermissionStatus === 'succeeded') {
      setShowSuccessDownloadModal(true);
    }
  }, [downloadRolePermissionStatus]);

  // fetch role and permission by term on input change
  const delay = 1000;
  const { delayedFunction } = useDelayedFunction(delay);
  useEffect(() => {
    if (searchUserRoleValue.length >= 2 || searchUserRoleValue.length === 0) {
      delayedFunction(handleSubmitRolePermissionFilter);
    }
  }, [searchUserRoleValue]);

  useEffect(() => {
    setShowFilterOptions(false);
    setShowSortBy(false);
    setShowExportOptions(false);
  }, [rolesStatus]);

  // on check get onboarded value
  useEffect(() => {
    const getOnboardedvalue = getSelectedOptionValue(dateOnboarded);
    const getSelectedSortBy = getSelectedOptionValue(sortBy);
    const getSelectedOrderBy = getSelectedOptionValue(orderBy);

    let onboarded_data = getOnboardedvalue;

    setFilterOptions({
      ...filterOptions,
      date_onboarded: onboarded_data,
      sort_by: getSelectedSortBy,
      order_by: getSelectedOrderBy,
    });
  }, [dateOnboarded, sortBy, orderBy]);

  const handleOnchangeRole = (value: any) => {
    setSearchUserRoleValue(value);
  };

  // filter table handler
  const handleSubmitRolePermissionFilter = () => {
    const roleCreator = filterOptions?.role_creator ? filterOptions?.role_creator : '';
    const onboarded_date_range =
      onboardedDateRange.state_date && onboardedDateRange.end_date
        ? formatStartAndEndDateWithDelimeter(onboardedDateRange.state_date, onboardedDateRange.end_date)
        : '';
    let payload = { ...filterOptions };
    payload =
      payload.date_onboarded === 'custom'
        ? { ...payload, date_range: onboarded_date_range }
        : { ...payload, date_range: filterOptions.date_onboarded };
    dispatch(
      getRolesRequest({
        ...payload,
        term: searchUserRoleValue,
        per_page: perPage,
        page: currentPage,
        role_creator: roleCreator,
      }),
    );
  };

  const handleExportRolePermissionFile = (type: string) => {
    const roleCreator = filterOptions?.role_creator ? filterOptions?.role_creator : '';
    const dateRange = filterOptions?.date_range ? filterOptions?.date_range : '';
    let exportPayload = {
      ...filterOptions,
      export: type,
      role_creator: roleCreator,
      date_range: dateRange,
      term: searchUserRoleValue,
    };
    dispatch(downloadRolePermission(exportPayload));
    setShowExportOptions(false);
  };

  const handleRoleModalOpen = (item: Dictionary) => {
    setSelectedRoleItem(item);
    setRoleMoreIconIsVisible(true);
  };

  const handleRoleMoreIconOptions = (item: any) => {
    setRoleMoreIconIsVisible(false);
    if (item.title === roleDetails) {
      navigate(`${USERROLES}${selectedRoleItem?.id.toString()}`);
    }

    if (item.title === roleDeleteRole) {
      setDeleteRoleIsModalVisible(true);
    }
  };

  // Custom Hooks
  const { handleSortItems } = useSortTable({
    order: order,
    setOrder,
    data: userRolesDataList,
    setData: setUserRolesDataList,
  });

  // handle page number change
  const handlePageNumberChange = (pageNumber: any, type: string) => {
    setPerPage(pageNumber);

    const onboarded_date_range = formatStartAndEndDateWithDelimeter(
      onboardedDateRange.state_date,
      onboardedDateRange.end_date,
    );

    const roleCreator = filterOptions?.role_creator ? filterOptions?.role_creator : '';

    let payload = { ...filterOptions };
    payload =
      payload.date_onboarded === 'custom'
        ? { ...payload, date_range: onboarded_date_range }
        : { ...payload, date_range: filterOptions.date_onboarded };

    if (type === 'role') {
      dispatch(
        getRolesRequest({
          ...payload,
          role_creator: roleCreator,
          term: searchUserRoleValue,
          page: currentPage,
          per_page: pageNumber,
        }),
      );
    }
  };

  const handleDeleteRole = () => {
    setRoleMoreIconIsVisible(false);
    dispatch(deleteRoleRequest({ id: selectedRoleItem?.roleId }));
  };

  const handleSuccessDeleteRole = () => {
    setDeleteRoleSuccessIsModalVisible(false);
    setDeleteRoleIsModalVisible(false);
    dispatch(deleteRoleReset());
  };

  return (
    <AppContainer navTitle={`USER ROLES`}>
      <UserContainer>
        <NavTab navList={UserPageTabs} />
        {(showFilterOptions || showSortBy || showExportOptions) && (
          <div
            className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-screen
           tw-top-0 tw-left-0"
            onClick={() => {
              setShowFilterOptions(false);
              setShowSortBy(false);
              setShowExportOptions(false);
            }}></div>
        )}
        <InternalUsersContainer>
          <InternalUserTop className="tw-flex tw-flex-col tw-items-center lg:tw-flex-row lg:tw-justify-between">
            <ZojaButton
              extraClass={`tw-bg-isPrimary tw-font-thin tw-text-white tw-text-[14px] tw-p-2.5 tw-px-4 tw-rounded-[4px]`}
              text={'New Role'}
              icon={<AiOutlinePlus color={colors.white} size={15} />}
              onClick={() => navigate(CREATEUSERROLES)}
            />
            <div className="tw-flex tw-items-center tw-justify-end tw-gap-x-4 md:tw-justify-between md:tw-mr-4">
              <SearchInput
                backgroundColor={'transparent'}
                name="searchRoleValue"
                value={searchUserRoleValue}
                onChange={(e: any) => handleOnchangeRole(e.target.value)}
                placeholder="Search record"
              />
              <FilterLabel title="Filter Options" icon={<icons.BsFilter />} onClick={() => setShowFilterOptions(true)}>
                {/* <span className='tw-absolute tw-hidden'>here</span> */}
                <FilterCard
                  showFilter={showFilterOptions}
                  title="Filter Options"
                  onClick={() => handleSubmitRolePermissionFilter()}
                  extraClass="tw-w-[13rem]">
                  <div className="tw-mt-4 tw-h-[14rem] tw-overflow-y-scroll tw-overflow-hidden">
                    <ZojaSelect
                      options={[
                        { value: '', label: 'Role Creator' },
                        { value: 'super_admin_1', label: 'Super Admin 1' },
                        { value: 'manager_1', label: 'Manager 1' },
                        { value: 'manager_2', label: 'Manager 2' },
                      ]}
                      name="status"
                      showArrown={true}
                      setSelectedOption={selected => setFilterOptions({ ...filterOptions, role_creator: selected })}
                      extraClass="tw-text-xs tw-p-3.5 tw-bg-white tw-border-0 tw-shadow-zojaShadowFive tw-rounded-md tw-font-normal tw-text-[#222B88] tw-mb-2"
                      arrowDownClass="tw-right-lg tw-top-3.5"
                    />

                    <FilterColapsible
                      title="Date Range"
                      show={showDateOnboarded}
                      onClick={() => setShowDateOnboarded(!showDateOnboarded)}
                      extraClass="tw-shadow-none">
                      <>
                        <div className="">
                          {dateOnboarded.map((item: any) => (
                            <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                              <span className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</span>
                              <ZojaCheckbox
                                isChecked={item.isChecked}
                                onClick={() => handleSelectedOption(item, dateOnboarded, setDateOnboarded)}
                              />
                            </div>
                          ))}
                        </div>
                        <div
                          className={`tw-mt-2 tw-overflow-hidden tw-max-h-0 ${
                            filterOptions.date_onboarded === 'custom' && 'tw-max-h-fit'
                          }`}>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="State Date"
                              value={new Date()}
                              setValue={value => setOnboardedDateRange({ ...onboardedDateRange, state_date: value })}
                            />
                          </div>
                          <div className="tw-mb-2">
                            <CustomDatePicker
                              label="End Date"
                              value={new Date()}
                              setValue={value => setOnboardedDateRange({ ...onboardedDateRange, end_date: value })}
                            />
                          </div>
                        </div>
                      </>
                    </FilterColapsible>
                  </div>
                </FilterCard>
              </FilterLabel>

              <FilterLabel
                title="Sort By"
                icon={<icons.TbArrowsSort color="#5E6366" />}
                onClick={() => setShowSortBy(true)}>
                <FilterCard
                  showFilter={showSortBy}
                  title="Sort by"
                  onClick={() => handleSubmitRolePermissionFilter()}
                  submitButtonText="Apply"
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <>
                    <div className="tw-pt-2">
                      {sortBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => handleSelectedOption(item, sortBy, setSortBy)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="tw-pt-2 tw-border-">
                      {orderBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => handleSelectedOption(item, orderBy, setOrderBy)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </FilterCard>
              </FilterLabel>

              <ExportLabel
                title="Export"
                onClick={() => setShowExportOptions(true)}
                loading={downloadRolePermissionStatus === 'loading' ? true : false}>
                <FilterCard
                  showFilter={showExportOptions}
                  title="Export as"
                  onClick={() => null}
                  showSubmitButton={false}
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <div className="tw-flex tw-flex-col tw-mt-4">
                    <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                      onClick={() => handleExportRolePermissionFile('pdf')}>
                      PDF
                    </span>
                    <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey"
                      onClick={() => handleExportRolePermissionFile('csv')}>
                      CSV
                    </span>
                  </div>
                </FilterCard>
              </ExportLabel>
            </div>
          </InternalUserTop>
          {rolesStatus === 'loading' ? (
            <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
              <Oval
                height="80"
                width="80"
                color="#222b88cf"
                ariaLabel="tail-spin-loading"
                secondaryColor="#222b882b"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </div>
          ) : (
            <>
              <RolesAndPermissionTable
                data={userRolesDataList}
                onClick={(item: Dictionary) => handleRoleModalOpen(item)}
                setSelectedItem={setSelectedRoleItem}
                selectedItem={selectedInternalUserItem}
                tableColumns={rolesAndPermissionDataHeader}
                dropdownList={dropdownList}
                sorting={CustomHandleSortItems}
                order={order}
                handleMoreIconOptions={handleRoleMoreIconOptions}
                setIsSort={setIsSort}
                setSortHeader={setSortHeader}
                setIsNextPage={setIsNextPage}
                setOrder={setOrder}
              />
              {userRolesDataList.length >= 1 && (
                <div className="tw-pb-2 tw-flex tw-flex-col tw-items-center tw-gap-3 md:tw-pb-8 md:tw-flex-row">
                  <Pagination
                    currentPage={currentPage}
                    totalPages={rolePermissionTotalPages}
                    onPageChange={selectedPage => {
                      setCurrentPage(selectedPage);
                      setIsNextPage(true);
                      setIsSort(true)
                    }}
                  />
                  <div className="-tw-mt-12 tw-flex tw-items-center tw-h-fit">
                    <span className="tw-text-xs tw-w-16">Per page</span>
                    <ZojaSelect
                      options={[
                        { label: '10', value: '10' },
                        { label: '20', value: '20' },
                        { label: '50', value: '50' },
                        { label: '100', value: '100' },
                        { label: '200', value: '200' },
                      ]}
                      value={rolesState?.data?.roles?.meta?.per_page}
                      setSelectedOption={selected => handlePageNumberChange(selected, 'role')}
                      extraClass="tw-rounded-none tw-text-xs tw-py-2 tw-w-10"
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </InternalUsersContainer>
        <ActivityActionModal
          actionClick={handleDeleteRole}
          closeModal={() => {
            setDeleteRoleIsModalVisible(false);
            dispatch(deleteRoleReset());
          }}
          actionText="Delete"
          secondaryActionText="Cancel"
          isModalVisible={deleteRoleIsModalVisible}
          text={'Are you sure you want to delete this role?'}
          image={images.reactivateUser}
          isLoading={deleteRoleStatus === 'loading'}
          actionBtnBackgroundColor={`tw-bg-isRed`}
        />
        <ActivityActionModal
          actionClick={handleSuccessDeleteRole}
          closeModal={handleSuccessDeleteRole}
          actionText="Close"
          isModalVisible={deleteRoleSuccessIsModalVisible}
          text={'You have successfully deleted the Role'}
          image={images.check}
          isLoading={false}
          actionBtnBackgroundColor={`tw-bg-isPrimary`}
        />
      </UserContainer>
    </AppContainer>
  );
};

export default RolesPermissions;
