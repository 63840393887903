import { Key, useState } from 'react';
import {
  BigFilterCard,
  ExportLabel,
  FilterCard,
  FilterColapsibleSingle,
  FilterLabel,
  FilterLabelVariant2,
  ManageColumn,
  ReusableFilter,
  SimplePagination,
  ZojaCheckbox,
  ZojaReportTable,
} from '../../components';
import { filterType } from '../../pages/report/filterOptions';
import {
  handleCheckboxChange,
  icons,
  setOneOptionTrue,
  toggleColumns,
  // toggleColumn,
  toggleItemChecked,
  toggleItemCheckedOneItem,
} from '../../utils';
import { ZojaSelect } from '../../components/tailwind';
import { Dictionary } from '../../types';
import { TR, TD } from '../../components/tailwind/reportTable/tableElements';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import { transactionReportColumn } from '../../pages/report/dummyData';
import { useNavigate } from 'react-router-dom';
import { BiColumns } from 'react-icons/bi';
import { Oval } from 'react-loader-spinner';
import { useAppSelector } from '../../redux/redux-hooks';

export interface transactionReportColumn {
  id: number;
  title: string;
  ref: string;
}

export interface transactionReportRow {
  id: number;
  [key: string]: any; // Allow any additional properties
}

interface ReportFilterOptions {
  id: number;
  title: string;
  value: string;
}

const TransactionGeneratedReport = ({
  onSubmitSort,
  filterOptions,
  onApplyFilter,
  setSelectedPageOption,
  column,
  tableData,
  onExport,
  manageColumnOptions,
  filterOption,
  setFilterOption,
  showOptions,
  setShowOptions,
  setSearch,
  searchedUsers,
  handleInputChange,
  itemsPerPage,
  totalItems,
  currentPage,
  transactionReportStatus,
  setCurrentPage,
  transactionState,
  visibleColumns,
  setVisibleColumns,
  downloadStatus,
  onChangePagination,
  showOptionsSmallScreen,
  setShowOptionsSmallScreen,
  transactionFilterOptions,
}: any) => {
  const navigate = useNavigate();

  const searchedUserAccountState = useAppSelector(state => state.searchUserAccount);

  const [reportFilterType, setReportFilterType] = useState<ReportFilterOptions>({
    title: 'Filter Type',
    id: 1,
    value: 'initial',
  });
  const [showReportFilter, setShowReportFilter] = useState(false);
  const [manageColumn, setManageColumn] = useState(manageColumnOptions.transaction);
  const [pendingChanges, setPendingChanges] = useState<number[]>(transactionReportColumn.map(column => column.id));

  const midScreen = window.innerWidth < 768;

  const handleManageClick = (e: { stopPropagation: () => void } | undefined) => {
    e?.stopPropagation();
    setShowOptions({ ...showOptions, manageColumn: false });
    setVisibleColumns(pendingChanges);
  };

  const handleManageCancel = (e: { stopPropagation: () => void } | undefined) => {
    e?.stopPropagation();
    setPendingChanges(transactionReportColumn.map(column => column.id));
    setVisibleColumns(transactionReportColumn.map(column => column.id));
    setShowOptions({ ...showOptions, manageColumn: false });
  };

  return (
    <div className="flex tw-flex-col md:tw-flex md:tw-flex-row tw-gap-x-4">
      {(showOptions?.exportOptions || showOptions?.sortBy || showOptions?.manageColumn) && (
        <div
          className="tw-absolute tw-bg-black/0 tw-cursor-pointer tw-z-30 tw-w-full tw-h-[1000vh] md:tw-h-sreen
           tw-top-0 tw-left-0"
          onClick={() => {
            setShowOptions({
              ...showOptions,
              exportOptions: false,
              sortBy: false,
              manageColumn: false,
            });
          }}></div>
      )}
      {showOptions.bigFilter ? (
        <div className="tw-bg-white tw-shadow-zojaShadowFour tw-top-0 -tw-left-32 tw-rounded-md tw-z-40 tw-p-4">
          <div className=" tw-mb-4">
            <FilterColapsibleSingle
              title={reportFilterType?.value === '' ? 'Filter Type' : reportFilterType?.title}
              show={showReportFilter}
              onClick={() => {
                setShowReportFilter(!showReportFilter);
              }}
              extraClass="tw-shadow-none tw-w-full"
              childClass=" tw-w-full tw-relative">
              <>
                <div className="">
                  {filterType.map((item: any) => (
                    <div
                      key={item.id}
                      onClick={() => {
                        setReportFilterType(item);
                        setShowReportFilter(false);
                        navigate(item.path);
                      }}
                      className="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-cursor-pointer">
                      <p className="tw-text-[13px] tw-mt-4 tw-text-[#84919A]">{item.title}</p>
                    </div>
                  ))}
                </div>
              </>
            </FilterColapsibleSingle>
          </div>
          <BigFilterCard title="Applicable Filters" onClick={onApplyFilter} extraClass="w-full md:tw-w-[13rem]">
            <div className="tw-gap-x-1 md:tw-flex-col tw-mt-4 tw-overflow-y-scroll tw-overflow-x-scroll tw-overflow-hidden tw-z-[1]">
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'transactionStatus'}
                filterOptions={filterOption}
                title="Transaction Status"
                show={showOptions.transactionStatus}
                // setShow={() => setShowOptions({ ...showOptions, transactionStatus: !showOptions.transactionStatus })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('transactionStatus', setShowOptions)
                    : () => setShowOptions({ ...showOptions, transactionStatus: !showOptions.transactionStatus })
                }
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                items={filterOption.transactionStatus}
                setShowSmall={() => setShowOptionsSmallScreen(!showOptionsSmallScreen)}
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'accountInfo'}
                filterOptions={filterOption}
                title="Account info"
                show={showOptions.accountInfo}
                // setShow={() => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('accountInfo', setShowOptions)
                    : () => setShowOptions({ ...showOptions, accountInfo: !showOptions.accountInfo })
                }
                items={filterOption.accountInfo}
                type={'inputCheck'}
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                setSearch={setSearch}
                searchedUsers={searchedUsers}
                searchedUserAccountState={searchedUserAccountState}
                setShowSmall={() => setShowOptionsSmallScreen(!showOptionsSmallScreen)}
              />
              <ReusableFilter
                setChecked={toggleItemCheckedOneItem}
                setItem={setFilterOption}
                itemName={'transactionDate'}
                filterOptions={filterOption}
                title="Transaction Date"
                show={showOptions.transactionDate}
                // setShow={() => setShowOptions({ ...showOptions, transactionDate: !showOptions.transactionDate })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('transactionDate', setShowOptions)
                    : () => setShowOptions({ ...showOptions, transactionDate: !showOptions.transactionDate })
                }
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                items={filterOption.transactionDate}
                type={'checkDate'}
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'kycLevel'}
                filterOptions={filterOption}
                title="KYC Level"
                show={showOptions.kycLevel}
                // setShow={() => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('kycLevel', setShowOptions)
                    : () => setShowOptions({ ...showOptions, kycLevel: !showOptions.kycLevel })
                }
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                items={filterOption.kycLevel}
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'transactionAmount'}
                filterOptions={filterOption}
                title="Transaction Amount"
                show={showOptions.transactionAmount}
                // setShow={() => setShowOptions({ ...showOptions, transactionAmount: !showOptions.transactionAmount })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('transactionAmount', setShowOptions)
                    : () => setShowOptions({ ...showOptions, transactionAmount: !showOptions.transactionAmount })
                }
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                items={filterOption.transactionAmount}
                type="text"
                label="Amount"
                id="amount"
                inputType="number"
                name="amount"
                onHandleInputChange={handleInputChange}
                filterParams={transactionFilterOptions}
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'transactionType'}
                filterOptions={filterOption}
                title="Transaction Type"
                show={showOptions.transactionType}
                // setShow={() => setShowOptions({ ...showOptions, transactionType: !showOptions.transactionType })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('transactionType', setShowOptions)
                    : () => setShowOptions({ ...showOptions, transactionType: !showOptions.transactionType })
                }
                items={filterOption.transactionType}
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
              />
              <ReusableFilter
                setChecked={toggleItemChecked}
                setItem={setFilterOption}
                itemName={'feesRange'}
                filterOptions={filterOption}
                title="Fees Range(N)"
                show={showOptions.feesRange}
                // setShow={() => setShowOptions({ ...showOptions, feesRange: !showOptions.feesRange })}
                setShow={
                  midScreen
                    ? () => setOneOptionTrue('feesRange', setShowOptions)
                    : () => setShowOptions({ ...showOptions, feesRange: !showOptions.feesRange })
                }
                extraClass="tw-shadow-none tw-w-full"
                extraClassChildren="tw-left-[30vw] tw-z-10 md:tw-z-0 tw-mt-5 md:tw-mt-2"
                items={filterOption.feesRange}
              />
            </div>
          </BigFilterCard>
        </div>
      ) : null}

      <div className="tw-overflow-x-auto tw-w-full">
        {/* TOP TOOLBAR */}
        <div className=" tw-mb-6 tw-flex tw-items-center tw-justify-between tw-mt-5 lg:tw-flex-row tw-flex-col tw-gap-y-3">
          <div className="tw-items-center tw-gap-4 tw-flex tw-justify-between tw-w-full lg:tw-w-max">
            <div
              onClick={() => setShowOptions({ ...showOptions, bigFilter: !showOptions.bigFilter })}
              className="tw-items-center gap-2 tw-flex tw-cursor-pointer">
              <MdOutlineRemoveRedEye size={20} />
              <h3 className="tw-text-blue-900 tw-text-sm tw-font-normal tw-m-0">
                {showOptions.bigFilter ? 'Full View' : 'Exit Full View'}
              </h3>
            </div>
            <div className="tw-items-center tw-gap-4 tw-flex">
              <div className="tw-text-gray-700 tw-text-sm tw-font-medium">Table View</div>

              <ZojaSelect
                options={[
                  {
                    label: '10',
                    value: 10,
                  },
                  {
                    label: '20',
                    value: 20,
                  },
                  {
                    label: '30',
                    value: 30,
                  },
                ]}
                setSelectedOption={setSelectedPageOption}
              />
            </div>
            {/* MANAGE COLUMNS */}
            <FilterLabelVariant2
              icon={<BiColumns />}
              title="Manage Columns"
              onClick={() => setShowOptions({ ...showOptions, manageColumn: true })}>
              <ManageColumn
                showFilter={showOptions.manageColumn}
                title=""
                onClick={(e: { stopPropagation: () => void } | undefined) => handleManageClick(e)}
                submitButtonText="Save"
                extraClass="tw-w-max -tw-left-28 tw-h-[50vh] hide_scrollbar"
                onCancel={e => {
                  handleManageCancel(e);
                }}>
                <>
                  <div className="tw-pt-2">
                    {manageColumn.map((item: any) => (
                      <div
                        key={item.id}
                        className="tw-flex tw-justify-between tw-items-center tw-gap-3 tw-border-b tw-pb-2">
                        <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>

                        <input
                          className=" tw-cursor-pointer"
                          type="checkbox"
                          onClick={() => {
                            toggleColumns(item.id, pendingChanges, setPendingChanges);
                          }}
                          onChange={() => handleCheckboxChange(item.id, setManageColumn)}
                          checked={pendingChanges.includes(item.id)}
                        />
                      </div>
                    ))}
                  </div>
                </>
              </ManageColumn>
            </FilterLabelVariant2>
          </div>
          <div className="tw-items-center tw-gap-4 tw-flex">
            <div className="tw-order-2 md:tw-order-1">
              <FilterLabel
                title="Sort By"
                icon={<icons.TbArrowsSort color="#5E6366" />}
                onClick={() => setShowOptions({ ...showOptions, sortBy: true })}>
                <FilterCard
                  showFilter={showOptions.sortBy}
                  title="Sort by"
                  onClick={() => onSubmitSort()}
                  submitButtonText="Apply"
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <>
                    <div className="tw-pt-2">
                      {filterOption?.sortBy?.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            // onClick={() => handleSelectedOption(item, sortBy, setSortBy)}

                            onClick={() => toggleItemCheckedOneItem(item.id, filterOption, 'sortBy', setFilterOption)}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="tw-pt-2 tw-border-">
                      {filterOption.orderBy.map((item: any) => (
                        <div key={item.id} className="tw-flex tw-justify-between tw-items-center tw-gap-3">
                          <span className="tw-mt-4 tw-text-[13px] tw-text-[#84919A]">{item.title}</span>
                          <ZojaCheckbox
                            isChecked={item.isChecked}
                            onClick={() => toggleItemCheckedOneItem(item.id, filterOption, 'orderBy', setFilterOption)}
                          />
                        </div>
                      ))}
                    </div>
                  </>
                </FilterCard>
              </FilterLabel>
            </div>
            <div className="tw-order-1 md:tw-order-2">
              <ExportLabel
                title="Download Report"
                onClick={() => setShowOptions({ ...showOptions, exportOptions: true })}
                loading={downloadStatus === 'loading' ? true : false}>
                <FilterCard
                  showFilter={showOptions.exportOptions}
                  title="Export as"
                  onClick={() => null}
                  showSubmitButton={false}
                  extraClass="tw-w-[10rem] -tw-left-28">
                  <div className="tw-flex tw-flex-col tw-mt-4">
                    {/* <span
                      className="tw-text-xs tw-cursor-pointer tw-text-isGrey tw-border-b tw-border-b-[#f4f3f3] tw-pb-2 tw-mb-2"
                      onClick={() => onExport('pdf')}>
                      PDF
                    </span> */}
                    <span className="tw-text-xs tw-cursor-pointer tw-text-isGrey" onClick={() => onExport('csv')}>
                      CSV
                    </span>
                  </div>
                </FilterCard>
              </ExportLabel>
            </div>
          </div>
        </div>
        {transactionState.status === 'loading' ? (
          <div className="tw-h-[50vh] tw-mx-auto tw-flex tw-justify-center tw-items-center">
            <Oval
              height="80"
              width="80"
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        ) : (
          <div className="tw-rounded-3xl tw-bg-white">
            <div className=" ">
              <ZojaReportTable visibleColumns={visibleColumns} column={column} filteredTable={true}>
                {tableData.map((data: Dictionary, idx: number) => (
                  <TR key={data.sn}>
                    {column
                      .filter((column: { id: number }) => visibleColumns.includes(column.id))
                      .map((column: { id: Key | null | undefined; ref: string | number }) => (
                        <TD key={column.id}>{data[column.ref]}</TD>
                      ))}
                  </TR>
                ))}
              </ZojaReportTable>
              {tableData.length < 1 ? (
                <div className="tw-w-full tw-py-3 tw-mx-auto">
                  <p className="tw-text-center tw-font-semibold">No record found</p>
                </div>
              ) : (
                ''
              )}
              <div className=" tw-px-4">
                <hr className=" tw-h-1 tw-relative tw-top-2 tw-border-none tw-bg-black" />
              </div>
              <div className=" tw-px-8 tw-py-4">
                <SimplePagination
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />

                {/* <Pagination
                  isLoading={transactionReportStatus === 'loading' ? true : false}
                  currentPage={currentPage}
                  totalPages={totalItems}
                  onPageChange={selectedPage => {
                    onChangePagination(selectedPage);
                  }}
                /> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionGeneratedReport;
