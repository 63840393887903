import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
  filters: Dictionary;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
  filters: {},
};

const customerBanHistorySlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getCustomerBanHistory.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getCustomerBanHistory.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getCustomerBanHistory.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getCustomerBanHistory = createAsyncThunk('getCustomerBanHistory', async (payload: Dictionary) => {
  const { account_number } = payload;
  const url = `/admin/restrictions/ban-history?account_number=${account_number}`;

  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const customerBanHistorySliceReducer = customerBanHistorySlice.reducer;
