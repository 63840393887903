import { Oval } from 'react-loader-spinner';

const StatusLoader = ({ status, width, height, wrapperHeight, showEmptyState = true }: any) => {
  return (
    <>
      {status === 'loading' && (
        <>
          <div className={`${wrapperHeight} tw-flex tw-justify-center tw-items-center`}>
            <Oval
              height={width}
              width={height}
              color="#222b88cf"
              ariaLabel="tail-spin-loading"
              secondaryColor="#222b882b"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
            />
          </div>
        </>
      )}
      {status === 'failed' && showEmptyState && (
        <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
          <h4 className="tw-text-gray-500 tw-text-xl">No Record Found</h4>
        </div>
      )}
    </>
  );
};

export default StatusLoader;