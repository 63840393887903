import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadCustomerTransactionsRequest = createAsyncThunk(
  'downloadCustomerTransactions',
  async (payload: Dictionary, { dispatch }) => {
    const { export: exp, account_number, date_range, page, per_page } = payload;
    const url = `/admin/restrictions/account-transactions?account_number=${account_number}&export=${exp}&date_range=${date_range}&page=${page}&per_page=${per_page}`;

    try {
      const response = await api.get<any, Blob>(url, {
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `account_transactions.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadCustomerTransactionsSlice = createSlice({
  name: 'downloadCustomerTransactions',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadCustomerTransactionsRequest.pending, state => {
      state.status = 'loading';
      // showMessage({ type: 'info', message: 'Exporting File...' });
    });
    builder.addCase(downloadCustomerTransactionsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadCustomerTransactionsRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadCustomerTransactionsReset = downloadCustomerTransactionsSlice.actions.reset;
export const downloadCustomerTransactionsSliceReducer = downloadCustomerTransactionsSlice.reducer;
