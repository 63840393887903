import { cn } from '../../lib/utils';
import { images } from '../../utils';

interface PaymentEmptyStateProps {
  text: string;
  containerClass?: string;
  imageWidth?: number;
}

export default function PaymentEmptyState({ text, containerClass, imageWidth = 120 }: PaymentEmptyStateProps) {
  return (
    <div
      className={cn(
        'tw-h-[20rem] tw-p-4 tw-mt-6 tw-rounded-md tw-bg-white tw-shadow-sm tw-flex tw-justify-center tw-items-center',
        containerClass,
      )}>
      <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <div className="tw-flex tw-flex-col tw-items-center tw-justify-center">
          <img src={images.fileExcelEmpty} width={imageWidth} alt="icon" />
          <span className="tw-block tw-py-3 tw-text-center">{text}</span>
        </div>
      </div>
    </div>
  );
}
