import { MdClose } from "react-icons/md"
import { ZojaButton, ZojaModal } from "../tailwind"
import { images } from "../../utils"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"
import { useRef, useState } from "react"
import VerificationDocumentCard from "../cards/verificationDocumentCard"
import { InfoLabel } from "../../atoms"
import { Dictionary } from "../../types"

interface IProps {
    show: boolean,
    handleClose: () => void,
    imageUrl: any,
    level?: string,
    to_compare: any,
    compareAgainstDocUrl: string
}

const TEST_IMAGE_1 = 'https://images.pexels.com/photos/17719366/pexels-photo-17719366/free-photo-of-portrait-of-woman-on-street.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'

const CompareDocumentModal = ({ show, handleClose, imageUrl, level, to_compare, compareAgainstDocUrl }: IProps) => {
    const [rotate, setRotate] = useState(0)
    const contentRef = useRef(null)

    return (
        <ZojaModal
            show={show}
            handleClose={handleClose}
            closeOnClickOutside={true}
            contentRef={contentRef}
            height='auto'
            position='center'
            extraClass='tw-mt-12 tw-items-center tw-rounded-xl lg:tw-w-[65rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto'
            children={<div className='tw-rounded-lg tw-text-center tw-p-10 tw-flex tw-flex-col tw-items-center tw-relative tw-pt-12' ref={contentRef}>
                <span className="tw-text-center tw-text-sm tw-font-medium tw-text-[#162082] tw-block tw-mb-4">Compare Document</span>

                <div className={`tw-grid ${level !== 'LEVEL 2' ? 'tw-grid-cols-1 md:tw-grid-cols-2' : 'tw-grid-cols-2'} tw-items-center tw-gap-4`}>
                    <div className="tw-relative">
                        {
                            level === 'LEVEL 2' ?
                                <VerificationDocumentCard
                                    title=''
                                    imgUrl={to_compare?.left_doc_url || TEST_IMAGE_1}
                                    hasIndicator={false}
                                    imgClass='md:tw-w-[55rem] md:tw-h-80 lg:tw-w-[55rem] lg:tw-h-80'
                                    handleClick={() => null}
                                /> :
                                <div className='tw-mt- tw-grid tw-grid-cols-2 tw-flex-wra tw-gap-6 md:tw-grid-cols-1 md:tw-w-[55rem] md:tw-h-48 lg:tw-w-[55rem] lg:tw-h-48'>
                                    {
                                        level === 'LEVEL 3' ?
                                            <>
                                                <InfoLabel title="Address" text={to_compare?.basic_info?.address} textExtraclass="md:tw-w-[35%] tw-text-left" />
                                                <InfoLabel title={to_compare?.basic_info?.lga} text='Ogbadibo' />
                                                <InfoLabel title="State" text={to_compare?.basic_info?.state} />
                                            </> :
                                            <>
                                                <InfoLabel title="Business Name" text={to_compare?.basic_info?.business_name} />
                                                <InfoLabel title="Business Address" text={to_compare?.basic_info?.business_address} textExtraclass="tw-text-left" />
                                                <InfoLabel title="Registration Number" text={to_compare?.basic_info?.business_registration_number} />
                                            </>

                                    }

                                </div>
                        }
                        <div className={`tw-flex tw-justify-between tw-items-center tw-mt-4 ${level !== 'LEVEL 2' && 'tw-absolute -tw-bottom-20 tw-w-full'}`}>
                            <span className={`tw-text-xs tw-font-medium tw-text-[#162082]`}>{level === 'LEVEL 2' ? 'ID Card Image' : 'Text Inputs'}</span>
                            <img src={images.rotationIcon} className="tw-w-[1rem]" alt="icon" />
                        </div>
                    </div>
                    <div>
                        <VerificationDocumentCard
                            title=''
                            imgUrl={compareAgainstDocUrl || TEST_IMAGE_1}
                            hasIndicator={false}
                            imgClass='md:tw-w-[55rem] md:tw-h-80 lg:tw-w-[55rem] lg:tw-h-80'
                            handleClick={() => null}
                        />
                        <div className="tw-flex tw-justify-between tw-items-center tw-mt-4">
                            <span className={`tw-text-xs tw-font-medium tw-text-[#162082]`}>Uploaded {level === 'LEVEL 2' ? 'Selfie' : 'Doc'}</span>
                            <img src={images.rotationIcon} className="tw-w-[1rem]" alt="icon" />
                        </div>
                    </div>
                </div>

                <span className='tw-text-white tw-bg-isPrimary tw-block tw-p-1 tw-rounded-md tw-absolute tw-top-4 tw-right-4 tw-cursor-pointer' onClick={handleClose}><MdClose /></span>
            </div>}
        />
    )
}

export default CompareDocumentModal