import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from "yup";
import { cn } from '../../lib/utils';
import { useAppDispatch, useAppSelector } from '../../redux/redux-hooks';
import { reviewAdminRequest, reviewAdminRequestReset } from '../../redux/slice';
import { Dictionary } from '../../types';
import { icons } from '../../utils';
import { ZojaButton, ZojaModal, ZojaSelect } from '../tailwind';
import { ZojaPaymentRejectionSuccess } from '../../atoms';


interface RejectAdminRequestProps {
    show: boolean,
    handleClose: () => void,
    contentRef?: any,
    tab?: string,
    selectedData: Dictionary
}

interface initialState {
    status: string,
    reason: string,
    description: string
}

export default function RejectAdminRequest({ show, handleClose, tab, selectedData }: RejectAdminRequestProps) {
    const [showIsRejected, setShowIsRejected] = useState(false)
    const reviewAdminRequestState = useAppSelector((state) => state.reviewAdminRequest)
    const {status: reviewRejectRequestStatus } = reviewAdminRequestState

    const approveSinglePaymentState = useAppSelector((state) => state.approveSinglePayment)
    const {status: approveSinglePaymentStatus } = approveSinglePaymentState


    const dispatch = useAppDispatch()

    const handleFormSubmit = (data: Dictionary) => {
        dispatch(reviewAdminRequest({ id: selectedData?.id, data: { ...data } }))
      }

    const { values, errors, touched, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik<initialState>({
        initialValues: {
            status: "rejected",
            reason: "",
            description: ""
        },
        validationSchema: yup.object().shape({
            status: yup.string().required(),
            description: yup.string().required('rejection message is required')
        }),
        onSubmit: handleFormSubmit
    })

    useEffect(() => {
        if (
            reviewRejectRequestStatus === "succeeded" 
            || reviewRejectRequestStatus === "failed" 
            ) {
            handleClose()
            resetForm()
        }
    },[reviewRejectRequestStatus])

    useEffect(() => {
        if (!show && (reviewRejectRequestStatus === 'succeeded')) {
            resetForm()
            setShowIsRejected(true)
            dispatch(reviewAdminRequestReset())
        }
    }, [show])


    return (
        <>
            
            <ZojaPaymentRejectionSuccess
                show={showIsRejected}
                title='Request Rejected'
                subTitle={`The initiator will be notified of request rejection and reason for rejection `}
                handleClose={() => {
                    resetForm()
                    setShowIsRejected(false)
                }}
            />
            <ZojaModal
                show={show}
                handleClose={() => {
                    resetForm()
                    handleClose()
                }}
                height='auto'
                position='center'
                extraClass="md:tw-w-[26rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            >
                <div className='tw-mx-3 tw-py-5  md:tw-mx-12 tw-p-4'>
                    <h3 className='tw-text-[.9rem] tw-text-[#263238]'>Reason for Rejection</h3>
                    <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                        onClick={() => {
                            resetForm()
                           handleClose()
                        }}
                    ><icons.MdClose /></span>
                    <form onSubmit={handleSubmit}>
                        {/* <div className='mt-4'>
                            <ZojaSelect
                                label='Reason'
                                options={[
                                    { label: '--Select Reason--', value: '' },
                                    { label: 'Incomplete data', value: 'Incomplete data' },
                                    { label: 'Wrong account number', value: 'Wrong account number' },
                                    { label: 'Others', value: 'Others' },
                                ]}
                                setSelectedOption={(selected) => {
                                    setFieldValue('reason', selected)
                                }}
                                onChange={() => null}
                                touched={touched}
                                error={errors.reason}
                            />
                        </div> */}
                        <div className='mt-4'>
                            <textarea
                                className={cn(
                                    "placeholder:tw-pl-[1px tw-w-full tw-border tw-pl-3 tw-p-2 tw-h-32 tw-text-[.85rem] placeholder:tw-text-[.8rem] tw-rounded-[5px] tw-text-gray-700 tw-border-[#E7E7E7] focus:tw-outline-none focus:tw-border-gray-300",
                                    touched.description && errors.description && 'tw-border-red-500'
                                )}
                                name='description'
                                value={values.description}
                                onChange={handleChange}
                                placeholder='Enter Description'
                            >

                            </textarea>
                            {touched.description && errors.description && <span className='tw-text-sm tw-text-red-500'>{errors.description}</span>}
                        </div>
                        <div className='my-3 mt-4'>
                            <ZojaButton
                                type='submit'
                                text="Reject"
                                loading={reviewRejectRequestStatus === "loading" || approveSinglePaymentStatus === "loading"}
                                disabled={reviewRejectRequestStatus === "loading" || approveSinglePaymentStatus === "loading"}
                                extraClass='tw-bg-red-500 tw-grid tw-place-content-center tw-text-white tw-font-thin tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2 tw-mt-4 hover:tw-bg-red-600/90 disabled:tw-bg-red-300'
                            />
                        </div>
                    </form>
                </div>
            </ZojaModal>
        </>
    )
}
