import { useNavigate, useParams } from 'react-router-dom';
import { AppContainer } from '../../../atoms';
import { formatFileSize, images } from '../../../utils';
import { ZojaButton } from '../../../components/tailwind';
import { ReactComponent as DocIcon } from '../../../assets/images/doc_image.svg';
import {
  createSendRestrictionForApprovalRequest,
  createSendRestrictionForApprovalnReset,
  getBanReasonsRequest,
  getBanRequestByIdRequest,
  restrictionApprovalRequest,
  restrictionApprovalReset,
} from '../../../redux/slice';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../redux/redux-hooks';
import { Dictionary } from '../../../types';
import moment from 'moment';
import { RejectBanRequestModal, ShowApproveBanSuccessModal } from '../../../components';
import { Oval } from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { MoveLeft } from 'lucide-react';

interface ApproveUnrestrictionProps {
  type?: string;
}
const ApproveUnrestriction = ({ type }: ApproveUnrestrictionProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const banRequestState = useAppSelector(state => state.getBanRequestById);
  const { status: banRequestStatus } = banRequestState;

  const banReasonsState = useAppSelector(state => state.getBanReasons);
  const { status: banReasonsStatus } = banReasonsState;

  const sendRestrictionForApprovalState = useAppSelector(state => state.createSendRestrictionForApproval);
  const { status: approveBanStatus } = sendRestrictionForApprovalState;

  const restrictionApprovalState = useAppSelector(state => state.restrictionApproval);
  const { status: restrictionApprovalStatus } = restrictionApprovalState;

  const auth = useAppSelector(state => state.auth);

  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [showApprovedModal, setShowApprovedModal] = useState<boolean>(false);
  const [showApprovedRejectModal, setShowApprovedRejectModal] = useState<boolean>(false);
  const [userData, setUserData] = useState<Dictionary>();
  const [reasonsForBan, setReasonsForBan] = useState<any>();
  const [approveType, setApproveType] = useState<any>('');

  const isApproveAccountRestrictionIncluded = auth?.data?.permissions?.some(
    (action: { name: string }) => action.name === 'approve account restriction',
  );

  useEffect(() => {
    dispatch(getBanRequestByIdRequest({ restrictionId: id }));
  }, []);

  useEffect(() => {
    if (showRejectModal === true) {
      dispatch(getBanReasonsRequest());
    }
  }, [showRejectModal]);

  useEffect(() => {
    setUserData(banRequestState.data);
  }, [banRequestState.data]);

  useEffect(() => {
    if (banReasonsStatus === 'succeeded' && banReasonsState?.data?.length > 0) {
      const updatedList: any[] = [];

      banReasonsState?.data?.forEach((item: Dictionary, index: number) => {
        updatedList.push({ id: index + 1, label: item, value: item });
      });

      setReasonsForBan([
        { id: updatedList.length + 1, label: 'Select Reason', value: '' },
        ...updatedList,
        { id: updatedList.length + 1, label: 'Others(specify below)', value: 'others' },
      ]);
    }
  }, [banReasonsState?.data, banReasonsStatus]);

  const handleRejectban = (payload: any) => {
    setApproveType('reject');

    if (payload.reason === 'others') {
      if (payload.description.length < 2) {
        toast.error('Please add a reason');
      } else {
        isApproveAccountRestrictionIncluded
          ? dispatch(
              restrictionApprovalRequest({
                id: id,
                status: 'rejected',
                description: payload.description,
                reason: payload.reason,
              }),
            )
          : dispatch(
              createSendRestrictionForApprovalRequest({
                id: id,
                status: 'rejected',
                description: payload.description,
                reason: payload.reason,
              }),
            );
      }
    } else {
      isApproveAccountRestrictionIncluded
        ? dispatch(
            restrictionApprovalRequest({
              id: id,
              status: 'rejected',
              description: payload.description,
              reason: payload.reason,
            }),
          )
        : dispatch(createSendRestrictionForApprovalRequest({ id: id, status: 'rejected', reason: payload.reason }));
    }
  };

  const handleApproveban = () => {
    isApproveAccountRestrictionIncluded
      ? dispatch(restrictionApprovalRequest({ id: id, status: 'approved' }))
      : dispatch(createSendRestrictionForApprovalRequest({ id: id, status: 'awaiting_approval' }));
    setApproveType('approve');
  };

  useEffect(() => {
    if (approveBanStatus === 'succeeded') {
      if (approveType === 'approve') {
        setShowApprovedModal(true);
      } else {
        setShowApprovedRejectModal(true);
      }
      setShowRejectModal(false);
      dispatch(createSendRestrictionForApprovalnReset());
    }
  }, [approveBanStatus]);

  useEffect(() => {
    if (restrictionApprovalStatus === 'succeeded') {
      if (approveType === 'approve') {
        setShowApprovedModal(true);
      } else {
        setShowApprovedRejectModal(true);
      }
      setShowRejectModal(false);
      dispatch(restrictionApprovalReset());
    }
  }, [restrictionApprovalStatus]);

  return (
    <AppContainer
      navTitle={
        <div className="tw-flex tw-gap- tw-items-center">
          <div className="tw-flex tw-gap-2 tw-items-center">
            <div
              onClick={() => navigate(-1)}
              className="tw-flex tw-gap-1 tw-text-[#5E6366] tw-cursor-pointer tw-no-underline tw-text-[1rem] tw-font-normal hover:tw-text-gray-500">
              <span className="tw-hidden md:tw-block">
                {' '}
                <MoveLeft />{' '}
              </span>
              <span className="tw-text-[#5E6366] tw-no-underline tw-text-[1rem] tw-font-normal">
                ACCOUNT RESTRICTION
              </span>
            </div>
            <span className="tw-border-gray-600 tw-font-bold tw-block tw-border tw-h-6"></span>
          </div>
          <span className="tw-ml-3 tw-text-[#5E6366] tw-text-[1rem] tw-font-medium">
            {isApproveAccountRestrictionIncluded ? 'APPROVE UNRESTRICTION REQUEST' : 'REVIEW UNRESTRICTION REQUEST'}
          </span>
        </div>
      }>
      {banRequestStatus === 'loading' ? (
        <div className="tw-h-screen tw-mx-auto tw-flex tw-justify-center tw-items-center">
          <Oval
            height="80"
            width="80"
            color="#222b88cf"
            ariaLabel="tail-spin-loading"
            secondaryColor="#222b882b"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      ) : (
        <div className="tw-max-w-[701px]">
          <>
            <div className="tw-flex">
              <h2 className="tw-mt-14 tw-text-xl tw-text-[#323348] tw-font-medium tw-mb-7">
                {isApproveAccountRestrictionIncluded ? 'Approve unrestriction Request' : 'Review unrestriction Request'}
              </h2>
            </div>
            <div className="tw-w-[70%]">
              <div className="tw-flex md:tw-flex-row tw-flex-col md:tw-justify-between md:tw-items-center tw-mr-10 tw-gap-y-2">
                <div className=" tw-flex tw-items-center tw-gap-x-3">
                  <img src={images.user} alt="" width={30} height={30} className=" tw-rounded-full" />
                  <p className=" tw-text-isPrimary tw-text-lg m-0">{userData?.attributes?.name}</p>
                </div>
                <p className="m-0 tw-font-semibold tw-text-isPrimary tw-capitalize">
                  KYC: <span className="tw-font-normal">{userData?.attributes?.kyc_level}</span>
                </p>
              </div>
            </div>
            <hr className="tw-mt-[5px] tw-border tw-border-[#BEBEBE] tw-mb-14" />
            <div className="tw-mb-6">
              <p className="tw-text-sm tw-mb-1">{moment(userData?.created_at).format('DD/MM/YYYY')}</p>
              <h3 className="tw-font-bold tw-text-base tw-mb-1">{userData?.attributes?.reason_for}</h3>
              <p className=" tw-text-isGreyVariantEight tw-text-sm">{userData?.attributes?.description_of_reason}</p>
            </div>
            {userData?.attributes?.proofs?.length > 0 &&
              userData?.attributes?.proofs?.map((item: any) => (
                <div className="tw-text-xs">
                  <div className="tw-flex tw-gap-x-2 tw-items-center tw-mb-2">
                    <DocIcon />
                    <p className="tw-mb-0 tw-font-medium tw-text-[#09002F]">{item?.file_name}</p>
                  </div>
                  <p className="tw-text-[#BEBEBE] tw-font-light">{formatFileSize(item?.size)}</p>
                </div>
              ))}
            <div className="tw-mt-16 tw-flex tw-gap-x-6 tw-items-center">
              <ZojaButton
                text={isApproveAccountRestrictionIncluded ? 'Approve Ban Request' : 'Send for Approval'}
                extraClass="tw-bg-isPrimary tw-text-white tw-py-3 tw-px-7 tw-font-medium tw-text-sm tw-rounded-[4px]"
                onClick={handleApproveban}
                disabled={
                  isApproveAccountRestrictionIncluded
                    ? restrictionApprovalStatus === 'loading'
                    : approveBanStatus === 'loading'
                }
                loading={
                  showRejectModal
                    ? false
                    : isApproveAccountRestrictionIncluded
                    ? restrictionApprovalStatus === 'loading'
                    : approveBanStatus === 'loading'
                }
                loadingText={
                  isApproveAccountRestrictionIncluded ? 'Approve Unrestriction Request' : 'Send for Approval'
                }
              />
              <ZojaButton
                text={isApproveAccountRestrictionIncluded ? 'Reject Unrestriction Request' : 'Reject Unrestriction'}
                extraClass="tw-font-medium tw-text-sm tw-text-isRed hover:tw-bg-isRed hover:tw-text-white tw-rounded-[4px] tw-py-3 tw-px-7"
                onClick={() => setShowRejectModal(true)}
              />
            </div>
          </>

          <RejectBanRequestModal
            show={showRejectModal}
            handleRejectBanRequest={handleRejectban}
            handleClose={() => setShowRejectModal(false)}
            reasonsForBan={reasonsForBan}
            loadingReasons={banReasonsStatus === 'loading'}
            title="Unrestriction"
            loading={
              isApproveAccountRestrictionIncluded
                ? restrictionApprovalStatus === 'loading'
                : approveBanStatus === 'loading'
            }
          />

          <ShowApproveBanSuccessModal
            show={showApprovedModal}
            handleClose={() => setShowApprovedModal(false)}
            title={'Unrestriction Request Sent'}
            description="Unrestriction request for "
            descriptionTwo="has been reviewed and sent for approval"
            buttoText="Finish"
            userName={userData?.attributes?.name}
            handleFinish={() => navigate(-1)}
            image={images.downloadSuccessIcon}
          />

          <ShowApproveBanSuccessModal
            show={showApprovedRejectModal}
            image={images.cancel}
            handleClose={() => setShowApprovedRejectModal(false)}
            title={'Unrestriction Request Rejected'}
            description="Unrestriction request for "
            descriptionTwo="has been reviewed and rejected"
            buttoText="Finish"
            userName={userData?.attributes?.name}
            handleFinish={() => navigate(-1)}
          />
        </div>
      )}
    </AppContainer>
  );
};

export default ApproveUnrestriction;
