import React, { useEffect, useState } from 'react';
import { Dictionary } from '../../../types';
import { ZojaTable } from '../..';
import { TD, TR } from '../../tailwind/table/tableElements';
import { dateTimeFormat3 } from '../../../utils/dateFormat';
import { FaChevronRight } from 'react-icons/fa';
import { useAppDispatch } from '../../../redux/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../../utils';
import moment from 'moment';
const { KYCCUSTOMERINFORMATION } = routesPath;

interface IProps {
  data: Dictionary[];
  level?: string;
  tab?: string;
  sorting?: any;
  order?: string;
}

const tableColumnsLevel1 = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Verification Entry',
    value: 'verificationEntry',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 7,
    title: '',
    hasSortIcon: false,
  },
];

const tableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];

const tableColumnsLevel2 = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Identity Type',
    value: 'identityType',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];

const tableColumnsLevel3 = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Bill Type',
    value: 'billType',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Approved',
    value: 'created_at',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];
const pendingTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Date Created',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: '',
    hasSortIcon: false,
  },
];

const rejectedTableColumns = [
  {
    id: 1,
    title: '#',
    hasSortIcon: false,
  },
  {
    id: 2,
    title: 'Profile Name',
    value: 'userName',
    hasSortIcon: true,
  },
  {
    id: 3,
    title: 'BVN',
    value: 'bvn',
    hasSortIcon: true,
  },
  {
    id: 4,
    title: 'Phone Number',
    value: 'phoneNo',
    hasSortIcon: true,
  },
  {
    id: 5,
    title: 'Rejection Reason',
    hasSortIcon: true,
  },
  {
    id: 6,
    title: 'Date Rejected',
    hasSortIcon: false,
  },
  // {
  //     id: 7,
  //     title: '',
  // },
];

const verifiedUsers: string = 'approved';
const pendingUsers: string = 'pending';
const rejectedUsers: string = 'rejected';

const KycTable = ({ data, tab, level, sorting, order }: IProps) => {
  const [kycTableColumns, setKycTableColumns] = useState(tableColumns);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleRowClicked = (id: any) => {
    navigate(`${KYCCUSTOMERINFORMATION}${id}`, {
      state: {
        kycLvl: level,
        verificationType:
          tab === 'verified_users'
            ? verifiedUsers
            : tab === 'pending_verifications'
            ? pendingUsers
            : tab === 'rejected_verifications'
            ? rejectedUsers
            : '',
      },
    });
  };

  useEffect(() => {
    if (tab === 'rejected_verifications') {
      setKycTableColumns(rejectedTableColumns);
      // console.log(data)
    } else if (tab === 'pending_verifications') {
      setKycTableColumns(pendingTableColumns);
    } else {
      if (level === 'LEVEL 3') {
        setKycTableColumns(tableColumnsLevel3);
      } else if (level === 'LEVEL 2') {
        setKycTableColumns(tableColumnsLevel2);
      } else if (level === 'LEVEL 1') {
        setKycTableColumns(tableColumnsLevel1);
      } else if (level === 'Agency') {
        setKycTableColumns(tableColumns);
      }
    }
  }, [level, tab]);

  return (
    <div>
      <ZojaTable column={kycTableColumns} sorting={sorting} order={order}>
        {data?.map((item: Dictionary, idx: number) => (
          <TR key={item.id}>
            <TD value={idx + 1} />
            <TD>{item.userName}</TD>
            <TD>{item.bvn}</TD>
            <TD>{item.phoneNo}</TD>
            <TD extraClass={`${tab === 'rejected_verifications' ? '' : 'tw-hidden'}`}>
              {
                // item.rejection_reason
                item?.verifications
                  ?.filter((f: Dictionary) => f.status === 'rejected')
                  .map((data: Dictionary) => (
                    <div key={data.id} className="tw-flex tw-gap-2">
                      <span>{data.verification_type}: </span>
                      <span>{data.comment} </span>
                    </div>
                  ))
              }
            </TD>
            {/* <TD extraClass={`${tab === 'verified_users' && level === 'Agency' ? '' : 'tw-hidden'}`}>CAC</TD> */}
            <TD extraClass={`${tab === 'verified_users' && level === 'LEVEL 3' ? '' : 'tw-hidden'}`}>
              {item?.verifications?.length > 0 &&
              (item?.verifications[0]?.verification_type === 'address-verification' ||
                item?.verifications[1]?.verification_type === 'address-verification' ||
                item?.verifications[2]?.verification_type === 'address-verification' ||
                item?.verifications[3]?.verification_type === 'address-verification')
                ? item?.verifications[0]?.bill_type.split('-').join(' ')
                : 'N/A'}
            </TD>
            <TD extraClass={`${tab === 'verified_users' && level === 'LEVEL 2' ? '' : 'tw-hidden'}`}>
              {item?.verifications?.length > 0 &&
              item?.verifications[1]?.verification_type === 'identity-card-verification'
                ? item?.verifications[1]?.identity_card_type.split('-').join(' ')
                : 'N/A'}
            </TD>

            <TD extraClass={`${tab === 'verified_users' && level === 'LEVEL 1' ? '' : 'tw-hidden'}`}>
              {item?.verifications?.length > 0 &&
              item?.verifications[0]?.verification_type === 'bvn-selfie-verification'
                ? 'BVN Image'
                : 'BVN'}
            </TD>
            <TD value={moment(item.created_at).format('DD MMMM, YYYY - hh:mm a')} />
            <TD
              extraClass={`${tab !== 'rejected_verifications' ? '' : 'tw-hidden'}`}
              onClick={() => {
                tab !== 'rejected_verifications' && handleRowClicked(item.detailsId);
              }}>
              <span className="tw-text-xs tw-cursor-pointer">
                {' '}
                <FaChevronRight />
              </span>
            </TD>
          </TR>
        ))}
      </ZojaTable>
    </div>
  );
};

export default KycTable;
