import styled from 'styled-components';
import { colors, fontWeight } from '../../../utils';

interface StyleProps {
  backgroundColor?: string;
  color?: string;
}

export const TableContainer = styled.div`
  width: 100%;
  overflow: auto;
`;

export const LoginTableContainer = styled.div`
  width: 70rem;
  height: 40rem;
`;

export const TransactionContainer = styled.div`
  width: 100%;
  background-color: ${(p: StyleProps) => (p.backgroundColor ? p.backgroundColor : colors.white)};
`;

export const TableTag = styled.table`
  border-collapse: separate;
  border-spacing: 0 8px;
  width: 100%;
  overflow-x: hidden;
`;

export const TH = styled.th`
  font-family: Rubik;
  padding: 8px;
  color: ${colors.primary};
  font-weight: ${fontWeight.semiLight};
  font-size: 0.875rem;
`;

export const TD = styled.td`
  font-family: Rubik;
  padding: 1.3rem 0.7rem;
  background-color: #f3f3f5;
  color: #474f9e;
  font-size: 0.9rem;
  font-weight: 400;
  text-transform: capitalize;

  ,
  :first-child {
    border-radius: 5px 0 0 5px;
  }
  :last-child {
    border-radius: 0 5px 5px 0;
  }
`;

export const TR = styled.tr`
  border-radius: 10px;
  margin-bottom: 10px;
`;
