import ReactApexChart from 'react-apexcharts';
import { currencyFormat } from '../../utils';
import { ZojaSelect } from '../../components/tailwind';
import { Oval } from 'react-loader-spinner';

export interface IProps {
  chartData: any;
  data: any;
  setSelectedOption?: any;
  status?: any;
}

const TotalTransactionTrend = ({ chartData, data, setSelectedOption, status }: IProps) => {
  return (
    <div className="lg:tw-w-[25rem]">
      <h2 className="tw-text-[.75rem] tw-text-gray-400 tw-font-normal">Total Transactions trend</h2>

      <div className="">
        <div className="tw-flex tw-flex-col">
          <div className="tw-flex tw-px-0 tw-items-end tw-justify-end tw-mb-[-10px]">
            <ZojaSelect
              options={[
                { label: 'View in Weekly', value: '' },
                { label: 'View in Yearly', value: 'year' },
              ]}
              setSelectedOption={setSelectedOption}
              extraClass="!tw-w-[9rem] !tw-h-[2rem] !tw-text-sm tw-py-0"
              showArrown={true}
              arrowDownClass="tw-right-lg tw-top-3.5 tw-pl-5"
            />
          </div>
          <div id="chart" className="">
            {status === 'loading' ? (
              <div className={`tw-h-[30vh] tw-flex tw-justify-center tw-items-center`}>
                <Oval
                  height={'50'}
                  width={'50'}
                  color="#222b88cf"
                  ariaLabel="tail-spin-loading"
                  secondaryColor="#222b882b"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={250} />
            )}
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] md:tw-text-[1rem]">
                Total Transaction Value :
              </td>
              <td className="tw-font-medium tw-text-[#162082]">
                {currencyFormat(Number(data?.total_transaction_volume || 0.0))}
              </td>
            </tr>
            <tr>
              <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] md:tw-text-[1rem]">
                Total Transaction Count :
              </td>
              <td className="tw-font-medium tw-text-[#162082]">{Number(data?.total_transactions_count || 0.0)}</td>
            </tr>
            <tr>
              <td className="tw-text-[10px] tw-p-2 tw-px-3 tw-text-[#7E7E96] md:tw-text-[1rem]">Total Revenue :</td>
              <td className="tw-font-medium tw-text-[#162082]">{currencyFormat(Number(data?.total_revenue || 0.0))}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TotalTransactionTrend;
