import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Dictionary } from '../../types';
import api from '../../api/api';

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}
const initialState: InitState = {
  data: {},
  status: 'idle',
  error: null,
};

const getBanReasonsSlice = createSlice({
  name: 'getBanReasons',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getBanReasonsRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getBanReasonsRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    });
    builder.addCase(getBanReasonsRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getBanReasonsRequest = createAsyncThunk('getBanReasons', async () => {
  const url = `admin/restrictions/reasons`;
  try {
    const response = await api.get(`${url}`);
    return response?.data;
  } catch (err) {
    throw err;
  }
});

export const getBanReasonsReset = getBanReasonsSlice.actions.reset;
export const getBanReasonsSliceReducer = getBanReasonsSlice.reducer;
