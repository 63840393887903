import styled from 'styled-components';
import { colors, spacing } from '../../utils';

export const Container = styled.div`
  padding: 0 ${spacing.xsmall};

  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70rem;
  height: 40rem;

  @media (max-width: 700px) {
    width: 500px;
    height: 500px;
  }
  @media (max-width: 576px) {
    width: 300px;
    height: 500px;
  }
  @media (max-width: 480px) {
    width: 300px;
  }
`;

export const BtnContainer = styled.div`
  width: fit-content;
  background-color: white;
  margin: 0;
  padding: ${spacing.xsmall} ${spacing.small_2};
  border: 2px solid #222b88;
  border-radius: 8px;
  float: left;
`;
