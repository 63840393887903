import {
  AppContainer
} from '../../atoms';
import KycContainer from './KycContainer';

function Kyc() {
  return (
    <AppContainer navTitle="KYC">
      <KycContainer/>
    </AppContainer>
  );
}

export default Kyc;
