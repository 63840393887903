import { ZojaButton, ZojaModal } from '../../components/tailwind'
import { images } from '../../utils'
import icons from '../../utils/icons';

interface ZojaConfirmSuccessProps {
    show: boolean,
    handleClose: () => void,
    title: string,
    subTitle: string,
    onClick: () => void,
    buttonText: string,
    hasCloseButton?: boolean,
}

export default function ZojaConfirmSuccess({ show, handleClose, title, subTitle, onClick, buttonText, hasCloseButton }: ZojaConfirmSuccessProps) {
    return (
        <ZojaModal
            show={show}
            handleClose={handleClose}
            height='auto'
            position='center'
            extraClass="md:tw-w-[32rem] md:tw-right-0 md:tw-left-0 md:tw-top-0 md:tw-bottom-0 md:tw-m-auto tw-mt-12"
            borderRadius='12px'
        >
            <>
               {hasCloseButton && <span className='tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer'
                    onClick={handleClose}
                ><icons.MdClose /></span>}
                <div className='tw-mb-12 tw-text-center tw-grid tw-place-content-center'>
                    <div className='tw-grid tw-place-content-center'>
                        <img
                            src={images.downloadSuccessIcon} alt="success-img"
                            className='tw-w-48'
                        />
                    </div>
                    <h1 className='tw-text-xl'>{title}</h1>
                    <p className='tw-pt-4 tw-px-3 md:tw-px-16'>{subTitle}</p>
                    <ZojaButton
                        text={buttonText}
                        extraClass='tw-mt-6 tw-bg-isPrimary tw-rounded-lg tw-font-light tw-p-3 tw-px-8 tw-text-white'
                        onClick={onClick}
                    />
                </div>
            </>
        </ZojaModal>
    )
}
