import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const getAllIncomeReportRequest = createAsyncThunk(
  'getAllIncomeReport',
  async (payload: Dictionary, { dispatch }) => {
    const {
      model_type,
      reference,
      per_page,
      page,
      exp,
      filter_accounts,
      time_range,
      min_count,
      max_count,
      min_total_revenue,
      max_total_revenue,
      sort_by,
      payment_method,
      order_by,
      profit_margin,
    } = payload;
    const url = `admin/report/income`;

    try {
      const response = await api.get(`${url}`, {
        params: {
          model_type,
          reference,
          sort_by,
          export: exp,
          order_by,
          per_page,
          page,
          filter_accounts,
          time_range,
          min_count,
          max_count,
          min_revenue_range: min_total_revenue,
          max_revenue_range: max_total_revenue,
          payment_method,
          profit_margin_range: profit_margin,
        },
      });
      return response?.data;
    } catch (err) {
      throw err;
    }
  },
);

const getAllIncomeReportSlice = createSlice({
  name: 'getAllIncomeReport',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(getAllIncomeReportRequest.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(getAllIncomeReportRequest.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(getAllIncomeReportRequest.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const getAllIncomeReportReset = getAllIncomeReportSlice.actions.reset;
export const getAllIncomeReportSliceReducer = getAllIncomeReportSlice.reducer;
