import { BsFilter } from 'react-icons/bs';
import { ZojaButton } from '../tailwind';

interface IProps {
  title?: string;
  showFilter?: boolean;
  children: React.ReactElement;
  onClick: () => void;
  submitButtonText?: string;
  showSubmitButton?: boolean;
  extraClass?: string;
}

const index = ({
  showFilter,
  title,
  onClick,
  submitButtonText,
  showSubmitButton = true,
  extraClass,
  children,
}: IProps) => {
  return (
    <div className={`${extraClass} `}>
      <span className="tw-text-[#323348] tw-font-normal tw-text-sm tw-pl-1 tw-pb-1.5 md:tw-text-[15px] tw-flex tw-gap-x-4">
        <BsFilter size={20} /> <span>{title}</span>
      </span>
      <div className="tw-h-full md:tw-h-[22rem] hide_scrollbar tw-overflow-y-scroll">{children}</div>
      {showSubmitButton ? (
        <ZojaButton
          onClick={onClick}
          text={`${submitButtonText ? submitButtonText : 'Apply Filter'}`}
          extraClass="tw-mt-6 tw-bg-isPrimary tw-text-white tw-text-sm tw-rounded-[4px] tw-w-full tw-p-2.5 tw-font-thin"
        />
      ) : null}
    </div>
  );
};

export default index;
