import { icons } from '../../utils';

interface IProps {
  title?: string;
  show: boolean;
  children: React.ReactElement;
  onClick: () => void;
  submitButtonText?: string;
  extraClass?: string;
  extraClassTitle?: string;
  shadow?: string;
  padding?: string;
  extraClassChildren?: string;
}

const FilterColapsible = ({
  show,
  title,
  onClick,
  submitButtonText,
  extraClass,
  children,
  extraClassTitle,
  shadow,
  padding,
  extraClassChildren,
}: IProps) => {
  return (
    <div>
      <span
        className={`${extraClass} tw-flex tw-items-center tw-justify-between tw-text-xs ${
          padding ? padding : 'tw-p-3.5'
        } tw-bg-white tw-border-0 tw-cursor-pointer ${shadow ? shadow : 'tw-shadow-zojaShadowFive'} tw-rounded-md`}
        onClick={onClick}>
        <span className={`${extraClassTitle} tw-font-normal tw-text-[#222B88]`}>{title}</span>
        <span
          className={`tw-text-isGreyVariantFour tw-transition-transform tw-duration-500 tw-rotate-0 ${
            show && 'tw-rotate-180'
          }`}>
          {/* {show ? <icons.FaChevronUp /> : <icons.FaChevronDown />} */}
          <icons.FaChevronDown />
        </span>
      </span>
      <div
        className={`tw-bg-white tw-mt-3 tw-transition-all tw-duration-300 tw-overflow-hidden tw-max-h-0 ${extraClassChildren} ${
          show && `tw-max-h-fit tw-overflow-y-scroll `
        }`}>
        {children}
      </div>
    </div>
  );
};

export default FilterColapsible;
