import React, { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-multi-date-picker';
import { MdOutlineEditCalendar } from 'react-icons/md';
import { formatRMDatePicker } from '../../utils';
import { title } from 'process';

interface IProps {
  label?: string;
  placeholder?: string;
  setValue: (event: any) => void;
  value: any;
  onOpenPickNewDate?: boolean;
}

const CustomDatePicker = ({ label, placeholder, value, setValue, onOpenPickNewDate }: IProps) => {
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const datePickerRef = useRef<any>(null);

  const handleDateChange = (date: any) => {
    const new_date = formatRMDatePicker(date);
    setSelectedDate(new_date);
    setValue(new_date);
  };

  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.openCalendar();
    }
  };

  return (
    <div>
      <span className="tw-block tw-text-[#84919A] tw-text-[11px]">{label}</span>
      <div className="tw-border tw-text-[11.3px] tw-text-gray-400 tw-p-[9px] tw-px-7 tw-rounded-[4px] tw-flex tw-gap-5 tw-relative md:tw-px-8">
        <span className={`${selectedDate && 'tw-text-gray-600 tw-text-[10px] tw-font-normal'}`}>
          {selectedDate !== null ? selectedDate : placeholder ? placeholder : 'YY-MM-DD'}
        </span>
        <span className="tw-text-lg tw-text-isPrimary tw-cursor-pointer" onClick={openDatePicker}>
          <MdOutlineEditCalendar />
        </span>
        <DatePicker
          ref={datePickerRef}
          containerClassName="tw-absolute tw-bottom-2 tw-w-[1rem] tw-text-md tw-hidden -tw-mt-7"
          inputClass="tw-bg-inherit tw-outline-none tw-hidden"
          onChange={handleDateChange}
          onOpenPickNewDate={onOpenPickNewDate}
          portal
        />
      </div>
    </div>
  );
};

export default CustomDatePicker;
