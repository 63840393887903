import { AiFillSetting, AiOutlineFolder, AiOutlineFolderAdd, AiOutlinePlus } from "react-icons/ai";
import { HiDotsVertical } from "react-icons/hi";
import { FaSearch, FaChevronDown, FaChevronUp, FaCheck } from "react-icons/fa";
import { BiChevronDown } from "react-icons/bi";
import { BsFilter } from "react-icons/bs";
import { TbArrowsSort } from "react-icons/tb";
import { FiDownload } from "react-icons/fi";
import { MdClose, MdOutlineSell, MdOutlineSettings, MdSettings } from "react-icons/md";

const icons = {
    AiOutlinePlus,
    HiDotsVertical,
    FaSearch,
    BiChevronDown,
    BsFilter,
    TbArrowsSort,
    FiDownload,
    FaChevronDown,
    FaChevronUp,
    FaCheck,
    MdOutlineSell,
    MdSettings,
    AiFillSetting,
    MdOutlineSettings,
    AiOutlineFolderAdd,
    MdClose
}
export default icons