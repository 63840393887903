import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api/api';

type Dictionary = {
  [key: string]: any;
};

interface InitState {
  data: Dictionary;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: any;
}

const initialState = {
  data: {},
  status: 'idle',
  error: null,
} as InitState;

export const downloadKycCustomer = createAsyncThunk(
  'downloadKycCustomer',
  async (payload: Dictionary, { dispatch }) => {
    const url = `admin/kycs`;
    let filters = payload?.filter ? { ...payload.filter } : {};
    const exp = payload.export;

    try {
      const response = await api.get<any, Blob>(`${url}${payload.kycLevel}`, {
        params: {
          // per_page: payload.per_page,
          // page: payload.page,
          export: exp,
          ...filters,
        },
        responseType: 'blob',
      });

      const uri = window.URL.createObjectURL(response);

      const link = document.createElement('a');
      link.href = uri;
      link.setAttribute('download', `kyc_customer.${exp}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      // showMessage({ type: 'success', message: 'Download Successfully' });
      return response;
    } catch (err) {
      throw err;
    }
  },
);

const downloadKycCustomerSlice = createSlice({
  name: 'downloadKycCustomer',
  initialState,
  reducers: {
    reset: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: builder => {
    builder.addCase(downloadKycCustomer.pending, state => {
      state.status = 'loading';
    });
    builder.addCase(downloadKycCustomer.fulfilled, (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.error = null;
    });
    builder.addCase(downloadKycCustomer.rejected, (state, action) => {
      state.status = 'failed';
      state.error = action.payload;
    });
  },
});

export const downloadKycCustomerReset = downloadKycCustomerSlice.actions.reset;
export const downloadKycCustomerSliceReducer = downloadKycCustomerSlice.reducer;
